import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Paper, Button } from '@material-ui/core';
import { Notifications, TabPanel } from '../../../shared';
import { goPuffApi as api } from '../../../api/goPuff';
import { MarkTmsStatusModal } from './markTmsStatusModal';
import { UploadTMSData } from './uploadTMSData';
import { ViewTMSUploads } from './viewTMSUploads';

const GoPuffTMSUpload = () => {
    const [selectedTab, setSelectedTab] = useState('0');
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [modalOpen, setModalOpen] = useState(false);
    const [row, setRow] = useState(null);
    const [status, setStatus] = useState('');
    const [action, setAction] = useState('');
    const [data, setData] = useState([]);
    const [bulkIDs, setBulkIDs] = useState([]);

    const handleChange = (e, val) => {
        setSelectedTab(val);
    };

    const getData = async () => {
        const res = await api.getTMSPalletUploads();
        if (res.status === false) {
            setNotification({ text: `No data found ${res.msg}`, level: 'error' });
            setData([]);
            return;
        }

        setData(res.data.rows);
    };

    useEffect(() => {
        getData();
    }, []);

    const onHandleButtonClick = (row, action, status) => {
        setModalOpen(true);
        setRow(row);
        setAction(action);
        setStatus(status);
    };

    const columns = [
        { accessor: 'shipmentID', Header: 'Shipment ID' },
        { accessor: 'shipDate', Header: 'Ship Date' },
        { accessor: 'deliverToSiteDate', Header: 'Deliver To Site' },
        { accessor: 'purchaseOrderNumber', Header: 'PO Number' },
        { accessor: 'carrier', Header: 'Carrier' },
        { accessor: 'pallets', Header: 'Pallets' },
        {
            accessor: 'actualPallets',
            Header: 'Actual Pallets',
            editable: selectedTab === '0',
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        { accessor: 'weight', Header: 'Weight' },
        { accessor: 'pieces', Header: 'Pieces' },
        { accessor: 'goPuffSiteID', Header: 'GoPuff Site ID' },
        { accessor: 'locationName', Header: 'Location Name' },
        { accessor: 'status', Header: 'Status', hide: selectedTab === '1' },
        {
            accessor: 'arrivedButton',
            Header: 'Mark Arrived At Origin',
            hide: selectedTab === '1',
            Cell: ({ row }) => (
                <Button onClick={() => onHandleButtonClick(row, 'ArrivedAtOrigin', 'Arrived')}>Arrived</Button>
            ),
        },
        {
            accessor: 'inRouteButton',
            Header: 'Mark En Route',
            hide: selectedTab === '1',
            Cell: ({ row }) => (
                <Button onClick={() => onHandleButtonClick(row, 'Enroute', 'En Route')}>En Route</Button>
            ),
        },
        {
            accessor: 'deliveredButton',
            Header: 'Mark Completed Shipment',
            hide: selectedTab === '1',
            Cell: ({ row }) => (
                <Button onClick={() => onHandleButtonClick(row, 'CompletedShipment', 'Delivered')}>Delivered</Button>
            ),
        },
    ];

    return (
        <div>
            {modalOpen && (
                <MarkTmsStatusModal
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    row={row}
                    action={action}
                    status={status}
                    setNotification={setNotification}
                    getData={getData}
                    bulkIDs={bulkIDs}
                    setBulkIDs={setBulkIDs}
                    setAction={setAction}
                />
            )}
            <Notifications options={notification} />
            <Paper>
                <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                    <Tab label="TMS Uploads" value="0" />
                    <Tab label="Upload TMS Data" value="1" />
                </Tabs>
                <TabPanel selectedTab={selectedTab} index={'0'}>
                    <ViewTMSUploads
                        data={data}
                        setNotification={setNotification}
                        columns={columns}
                        setBulkIDs={setBulkIDs}
                        setModalOpen={setModalOpen}
                        getData={getData}
                    />
                </TabPanel>
                <TabPanel selectedTab={selectedTab} index={'1'}>
                    <UploadTMSData setNotification={setNotification} columns={columns} getData={getData} />
                </TabPanel>
            </Paper>
        </div>
    );
};

export { GoPuffTMSUpload };
