import React, { useState, useEffect, useContext } from 'react';
import { Button, Tabs, Tab, CircularProgress, makeStyles } from '@material-ui/core';
import { productionApi as api } from '../../../../api/production';
import { catalogApi } from '../../../../api/catalog';
import { FormComponent, TabPanel } from '../../../../shared/index';
import { Notifications, DataTable } from '../../../../shared/index';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { UserContext } from '../../../../contexts/UserContext';
import { CarouselModal } from './CarouselModal';
import { PhotoModal } from './PhotoModal';
import { CarouselItems } from './CarouselItems';

const useStyles = makeStyles({
    progress: {
        margin: '50px 0',
    },
});

const Carousels = () => {
    const classes = useStyles();
    let [week, setWeek] = useState('');
    let [year, setYear] = useState('');

    const [mostRecentPackGroups, setMostRecentPackGroups] = useState([]);

    let [packGroups, setPackGroups] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [selectedTab, setSelectedTab] = useState('');
    let [carouselModalOpen, setCarouselModalOpen] = useState(false);
    let [photoModalOpen, setPhotoModalOpen] = useState(false);
    let [selectedPhoto, setSelectedPhoto] = useState({});
    const [brands, setBrands] = useState([]);
    const user = useContext(UserContext);

    let addItemToCarousel = async (carouselID, sellableItemID) => {
        let res = await api.addItemToCarousel(carouselID, sellableItemID);
        if (!res.status) {
            setNotification({ text: 'Failed to add item to carousel. ' + res.msg, level: 'error' });
            return false;
        }

        getData();
        return true;
    };

    let addBundleToCarousel = async (carouselID, sellableBundleID) => {
        let res = await api.addBundleToCarousel(carouselID, sellableBundleID);
        if (!res.status) {
            setNotification({ text: 'Failed to add bundle to carousel. ' + res.msg, level: 'error' });
            return false;
        }

        getData();
        return true;
    };

    let removeBundleFromCarousel = async (carouselID, sellableBundleID) => {
        let res = await api.removeBundleFromCarousel(carouselID, sellableBundleID);
        if (!res.status) {
            setNotification({ text: 'Failed to remove bundle from carousel', level: 'error' });
            return false;
        }

        getData();
        return true;
    };

    let removeItemFromCarousel = async (carouselID, sellableItemID) => {
        let res = await api.removeItemFromCarousel(carouselID, sellableItemID);
        if (!res.status) {
            setNotification({ text: 'Failed to remove item from carousel', level: 'error' });
            return false;
        }

        getData();
        return true;
    };

    let deleteCarousel = async (carouselID) => {
        let res = await api.deleteCarousel(carouselID);
        if (!res.status) {
            setNotification({ text: 'Failed to delete carousel', level: 'error' });
            return false;
        }

        getData();
        return true;
    };

    let createCarousel = async (formData) => {
        if (formData.packGroupIDs.length === 0) {
            formData.packGroupIDs = [packGroups[selectedTab].id];
        }
        formData.sponsoredBrands = formData.sponsoredBrands.map((item) => item.value).join(';');
        let res = await api.createCarousel(formData);
        if (!res.status) {
            setNotification({ text: 'Failed to create carousel. ' + res.msg, level: 'error' });
            return false;
        }

        getData();
        return true;
    };

    let getData = async (newWeek, newYear) => {
        let res = await api.getPackGroups({
            facilityID: user.getFacilityID(),
            week: newWeek || week,
            year: newYear || year,
        });
        if (res.status === true) {
            let data = res.data;
            setWeek(data.week);
            setYear(data.year);

            let promises = data.packGroups.map(async (group) => {
                let carousels = await api.getCarousels(group.id);
                let sellableItems = await api.getSellableItems(group.id);
                let sellableBundles = await api.getSellableBundles(group.id);
                if (!carousels.status) {
                    setNotification({ text: 'Failed to get carousels', level: 'error' });
                    return group;
                }
                group.carousels = carousels.data.carousels.map((item) => {
                    item.numItems = item.items.length;
                    item.carouselType = item.type;
                    item.brands = item.brands.map((item) => ({ ...item, value: item.name, text: item.name }));
                    return item;
                });
                group.items = sellableItems.data.sellableItems;
                group.bundles = sellableBundles.data.sellableBundles;
                return group;
            });
            let packGroupArr = await Promise.all(promises);
            setPackGroups(data.packGroups || []);
            if (!selectedTab) {
                setSelectedTab(data.packGroups[0] ? '0' : '');
            }
        }
    };

    let getBrands = async () => {
        let res = await catalogApi.getBrands();
        if (!res.status) {
            setNotification({ text: 'Failed to get brands', level: 'error' });
            return;
        }
        setBrands(res.data.brands);
    };

    const getPackGroupsCurrentNext = async () => {
        let res = await api.getPackGroupsCurrentNext();
        if (!res.status) {
            setNotification({ text: 'Failed to get pack groups for current and next week', level: 'error' });
            return;
        }

        setMostRecentPackGroups(res.data);
    };

    useEffect(() => {
        getData();
        getBrands();
        getPackGroupsCurrentNext();
    }, []);

    let formFields = [
        {
            name: 'week',
            initialValue: week,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Week',
            inputProps: { required: true, type: 'number', max: '53' },
        },
        {
            name: 'year',
            initialValue: year,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Year',
            inputProps: { required: true, type: 'number', maxLength: '4' },
        },
    ];

    return (
        <div>
            <Notifications options={notification} />
            <h2>Carousels</h2>
            <PhotoModal
                open={photoModalOpen}
                selectedPhoto={selectedPhoto}
                setPhotoModalOpen={setPhotoModalOpen}
                updateItemPhoto={async (carouselID, key, file) => {
                    let res = await api.updateCarousel(carouselID, { [key]: file });
                    if (!res.status) {
                        setNotification({ text: 'Failed to update item photo: ' + res.msg, level: 'error' });
                    } else {
                        getData();
                    }
                    return res.status;
                }}
            />
            <CarouselModal
                brands={brands}
                open={carouselModalOpen}
                createCarousel={createCarousel}
                onClose={() => {
                    setCarouselModalOpen(false);
                }}
            />

            {week && year && (
                <FormComponent
                    formFields={formFields}
                    button={{ gridValue: 2, text: 'Set Week and Year' }}
                    onSubmit={async (formData) => {
                        getData(formData.week, formData.year);
                    }}
                />
            )}
            {selectedTab && (
                <div>
                    <Tabs
                        onChange={(event, value) => {
                            setSelectedTab(value);
                        }}
                        value={selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        {packGroups.map((group, index) => (
                            <Tab key={group.id} label={`Pack Group ${group.name}`} value={`${index}`} />
                        ))}
                    </Tabs>
                    {packGroups.map((group, packGroupIndex) => {
                        return (
                            <TabPanel selectedTab={selectedTab} key={group.id} index={`${packGroupIndex}`}>
                                <DataTable
                                    columns={[
                                        { Header: 'ID', accessor: 'id' },
                                        {
                                            Header: 'Rank Number',
                                            accessor: 'rankNumber',
                                            editable: true,
                                            editProps: { type: 'input', required: true },
                                        },
                                        { Header: 'Type', accessor: 'carouselType' },
                                        {
                                            Header: 'Name',
                                            accessor: 'name',
                                            editable: true,
                                            editProps: { type: 'input', required: true },
                                        },
                                        { Header: 'Num Items', accessor: 'numItems' },
                                        {
                                            Header: 'Featured',
                                            accessor: 'featured',
                                            type: 'checkbox',
                                            editable: true,
                                            customEdit: async (row) => {
                                                let confirmed = false;
                                                if (row.featured) {
                                                    confirmed = window.confirm(
                                                        'Make featured carousel non featured? This will remove images, description, and other featured carousel data'
                                                    );
                                                } else {
                                                    confirmed = true;
                                                }

                                                if (confirmed) {
                                                    let res = await api.updateCarousel(row.id, {
                                                        featured: !row.featured,
                                                    });
                                                    if (!res.status) {
                                                        setNotification({
                                                            text: `Failed to update carousel: ${res?.msg}`,
                                                            level: 'error',
                                                        });
                                                        return false;
                                                    }
                                                    getData();
                                                    return res.status;
                                                }
                                            },
                                        },
                                        {
                                            Header: 'Large Image',
                                            accessor: 'photoUrlLarge',
                                            Cell: ({ row }) => {
                                                if (!row.original.featured) {
                                                    return null;
                                                }
                                                return (
                                                    <Button
                                                        id={`LargeImage_${row.id}_upload`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setSelectedPhoto({
                                                                item: row.original,
                                                                key: 'photoUrlLarge',
                                                            });
                                                            setPhotoModalOpen(true);
                                                        }}
                                                        style={{ margin: '3px', width: '100%' }}
                                                    >
                                                        {!!row.original.photoUrlLarge && (
                                                            <img height="100" src={row.original.photoUrlLarge} />
                                                        )}
                                                        {!row.original.photoUrlLarge && <CloudUploadIcon />}
                                                    </Button>
                                                );
                                            },
                                        },
                                        {
                                            Header: 'Small Image',
                                            accessor: 'photoUrlSmall',
                                            Cell: ({ row }) => {
                                                if (!row.original.featured) {
                                                    return null;
                                                }
                                                return (
                                                    <Button
                                                        id={`SmallImage_${row.id}_upload`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setSelectedPhoto({
                                                                item: row.original,
                                                                key: 'photoUrlSmall',
                                                            });
                                                            setPhotoModalOpen(true);
                                                        }}
                                                        style={{ margin: '3px', width: '100%' }}
                                                    >
                                                        {!!row.original.photoUrlSmall && (
                                                            <img height="100" src={row.original.photoUrlSmall} />
                                                        )}
                                                        {!row.original.photoUrlSmall && <CloudUploadIcon />}
                                                    </Button>
                                                );
                                            },
                                        },
                                        {
                                            Header: 'Description',
                                            accessor: 'description',
                                            editable: (row) => row.original.featured,
                                            editProps: {
                                                type: 'input',
                                                multiline: true,
                                            },
                                        },
                                        {
                                            Header: 'Background Color',
                                            accessor: 'backgroundColor',
                                            cellStyle: (row) => {
                                                if (!row.backgroundColor) {
                                                    return {};
                                                }
                                                return { backgroundColor: row.backgroundColor };
                                            },
                                            editable: (row) => row.original.featured,
                                        },
                                        {
                                            Header: 'Percent Off',
                                            accessor: 'percentOff',
                                            editable: (row) => row.original.featured,
                                            editProps: {
                                                inputType: 'number',
                                                type: 'input',
                                                integer: true,
                                            },
                                        },
                                        {
                                            Header: 'Link URL',
                                            accessor: 'linkUrl',
                                            editable: true,
                                            editProps: { type: 'input', required: true },
                                        },
                                        {
                                            Header: 'Link Title',
                                            accessor: 'linkTitle',
                                            editable: true,
                                            editProps: { type: 'input', required: true },
                                        },
                                        {
                                            Header: 'Link Descriptor',
                                            accessor: 'linkDescriptor',
                                            editable: true,
                                            editProps: { type: 'input', required: true },
                                        },
                                        {
                                            Header: 'L1 Aisle ID',
                                            accessor: 'l1AisleID',
                                            editable: true,
                                            editProps: { type: 'input', required: false },
                                        },
                                        {
                                            Header: 'L2 Aisle ID',
                                            accessor: 'l2AisleID',
                                            editable: true,
                                            editProps: { type: 'input', required: false },
                                        },
                                        {
                                            Header: 'Sponsored Brands',
                                            accessor: 'brands',
                                            editable: true,
                                            editProps: {
                                                type: 'autocomplete-multiple-chips',
                                                options: brands,
                                                modalHeader: 'Add brands to carousel',
                                                addButtonText: 'Add Brands',
                                                addItem: async ({ values, row }) => {
                                                    let res = await catalogApi.createSponsoredBrandMapping(
                                                        'carousel',
                                                        row.id,
                                                        values
                                                    );
                                                    if (res.status === true) {
                                                        getData();
                                                        return true;
                                                    } else {
                                                        setNotification({ level: 'error', text: res.msg });
                                                        return false;
                                                    }
                                                },
                                                removeItem: async ({ item }) => {
                                                    if (window.confirm('Remove brand sponsor from carousel?')) {
                                                        let res = await catalogApi.removeSponsoredBrandMapping(item.id);
                                                        if (res.status === true) {
                                                            getData();
                                                        }
                                                    }
                                                },
                                            },
                                        },
                                        {
                                            Header: 'Delete Carousel',
                                            Cell: ({ row }) => {
                                                return (
                                                    <Button
                                                        onClick={async () => {
                                                            if (window.confirm('Delete Carousel?') === true) {
                                                                let carouselID = row.original.id;
                                                                let res = await deleteCarousel(carouselID);
                                                                return res;
                                                            }
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>
                                                );
                                            },
                                        },
                                    ]}
                                    expandable={true}
                                    editable={true}
                                    duplicateRowSettings={{
                                        enable: true,
                                        CustomFormComponent: (
                                            <CarouselModal
                                                createCarousel={createCarousel}
                                                mostRecentPackGroups={mostRecentPackGroups}
                                            />
                                        ),
                                        onSuccess: () => {
                                            getData();
                                            setNotification({ text: 'Carousel Created', level: 'success' });
                                        },
                                    }}
                                    ExpansionComponent={
                                        <CarouselItems
                                            setNotification={setNotification}
                                            addItemToCarousel={addItemToCarousel}
                                            addBundleToCarousel={addBundleToCarousel}
                                            removeItemFromCarousel={removeItemFromCarousel}
                                            removeBundleFromCarousel={removeBundleFromCarousel}
                                            sellableItems={group.items}
                                            sellableBundles={group.bundles}
                                            getData={getData}
                                        />
                                    }
                                    data={group.carousels || []}
                                    saveEdit={async ({ id, field, value, row }) => {
                                        let res = await api.updateCarousel(id, { [field]: value });
                                        if (!res.status) {
                                            setNotification({
                                                text: `Failed to update carousel: ${res?.msg}`,
                                                level: 'error',
                                            });
                                            return false;
                                        }
                                        getData();
                                        return res.status;
                                    }}
                                />

                                <Button
                                    onClick={() => {
                                        setCarouselModalOpen(true);
                                    }}
                                >
                                    Add Carousel
                                </Button>
                            </TabPanel>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export { Carousels };
