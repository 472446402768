import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { DataTable, Notifications } from '../../../../shared';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';
import { NegativeVarianceHistoryExpansion } from './negativeVarianceHistoryExpansion';
import { CircularProgress } from '@material-ui/core';
const warehouseInventoryV2Api = new WarehouseInventoryV2Api();

const NegativeVarianceHistory = ({}) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user } = useContext(UserContext);

    //Column Definitions
    const columns = [
        { Header: 'Started At', accessor: 'startedAtDisplayTimestamp' },
        { Header: 'Resolved At', accessor: 'resolvedAtDisplayTimestamp' },
        { Header: 'Partner SKU', accessor: 'partnerSku' },
        { Header: 'Partner Name', accessor: 'companySource' },
        { Header: 'MM SKU', accessor: 'mmSku' },
        { Header: 'Item Type', accessor: 'itemType' },
        { Header: 'Item Name', accessor: 'itemName' },
        { Header: 'Sku', accessor: 'mmSku' },
        { Header: 'Highest Negative Variance', accessor: 'highestNegativeVariance' },
        { Header: 'Current Negative Variance', accessor: 'currentNegativeVariance' },
        { Header: 'Resolved', accessor: 'resolved', type: 'checkbox' },
    ];

    useEffect(() => {
        const getNegativeVarianceHistory = async () => {
            setLoading(true);
            const result = await warehouseInventoryV2Api.getNegativeVarianceHistory(user.facilityID);
            setLoading(false);
            if (result.status) {
                setRows(result.data.items);
            } else {
                setNotification({ text: result.msg, level: 'error' });
            }
        };

        getNegativeVarianceHistory();
    }, []);

    return (
        <>
            <Notifications options={notification} />
            {loading ? (
                <CircularProgress color="inherit" size={48} />
            ) : (
                <DataTable
                    csvExport={true}
                    csvExportFileName="negative_variance"
                    columns={columns}
                    data={rows}
                    expandable={true}
                    ExpansionComponent={<NegativeVarianceHistoryExpansion />}
                />
            )}
        </>
    );
};

export { NegativeVarianceHistory };
