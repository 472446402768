import { mmFetch } from './mmFetch';
import { mmFetchWithResponse } from './mmFetchWithResponse';

let api = {
    getRoles: async () => {
        if (import.meta.env.DEV) {
            let roles = {
                Catalog: {
                    tabs: [
                        { location: 'catalog/item-information', name: 'Item Information' },
                        { location: 'catalog/items', name: 'Items' },
                        { location: 'catalog/taxonomy', name: 'Taxonomy' },
                    ],
                },
                Compensations: {
                    tabs: [
                        { location: 'compensations/mass-credit', name: 'Mass Credit' },
                        { location: 'compensations/out-of-stock', name: 'Out Of Stock' },
                    ],
                },
                FBM: {
                    tabs: [
                        { location: 'fbm/advance-shipping-notification', name: 'ASN' },
                        { location: 'fbm/hospital', name: 'Hospital' },
                        { location: 'fbm/inventory', name: 'Inventory' },
                        { location: 'fbm/items', name: 'Items' },
                        { location: 'fbm/orders', name: 'Orders' },
                        { location: 'fbm/packaging', name: 'Packaging ASN' },
                        { location: 'fbm/shipments', name: 'Shipments' },
                        { location: 'fbm/reports', name: 'Reports' },
                        { location: 'fbm/accounts', name: 'Accounts' },
                    ],
                },
                'Customer Management': { tabs: [], location: 'custmanage' },
                Finance: { tabs: [{ location: 'finance/payments', name: 'Payments' }] },
                Growth: { tabs: [{ location: 'growth/campaigns', name: 'Campaigns' }] },
                'Information Technology': {
                    tabs: [
                        { location: 'infoTech/employeeBox', name: 'Employee Box' },
                        { location: 'infoTech/jalapenoAccounts', name: 'Jalapeno Accounts' },
                    ],
                },
                Logistics: {
                    tabs: [
                        { location: 'logistics/line-haul-management', name: 'Line Haul Management' },
                        { location: 'logistics/inbound-trucks', name: 'Inbound trucks' },
                        { location: 'logistics/outbound-trucks', name: 'Outbound trucks' },
                        { location: 'logistics/move-purchased', name: 'Move Purchased Shipments' },
                        { location: 'logistics/zip-map', name: 'Zip Map' },
                        { location: 'logistics/zip-map-results', name: 'Zip Map Results' },
                    ],
                },
                Marketing: {
                    tabs: [
                        { location: 'marketing/discounts', name: 'Discounts' },
                        { location: 'marketing/produceList', name: 'Produce List' },
                        { location: 'marketing/referralProgram', name: 'Referral Program' },
                        { location: 'marketing/specialOrders', name: 'Special Orders' },
                    ],
                },
                Marketplace: { tabs: [], location: 'marketplace' },
                Procurement: { tabs: [{ location: 'procurement/viewPurchaseOrder', name: 'Purchase Orders' }] },
                Product: {
                    tabs: [
                        { location: 'product/feature-flag', name: 'Feature Flags' },
                        { location: 'product/plus-membership-offers', name: 'Plus Membership Offers' },
                    ],
                },
                Production: {
                    tabs: [
                        { location: 'production/custom', name: 'Custom/Marketplace' },
                        { location: 'production/featured', name: 'Featured Items' },
                        { location: 'production/pack-groups', name: 'Pack Groups' },
                        { location: 'production/sellable-items', name: 'Sellable Items' },
                        { location: 'production/carousels', name: 'Carousels' },
                    ],
                },
                Receiving: {
                    tabs: [
                        { location: 'receiving/documents', name: 'PO Documents' },
                        { location: 'receiving/receiving', name: 'Receiving' },
                        { location: 'receiving/inbound-receiving', name: 'Inbound Receiving' },
                    ],
                },
                Packaging: {
                    tabs: [
                        { location: 'packaging/packaging', name: 'Create/Edit' },
                        { location: 'packaging/auto-assign', name: 'Auto Assign' },
                        { location: 'packaging/assign', name: 'Assign' },
                        { location: 'packaging/purchase', name: 'Purchase' },
                    ],
                },
                Shipping: {
                    tabs: [
                        { location: 'shipping/batchelor', name: 'Batchelor' },
                        { location: 'shipping/reprint', name: 'Batchelor Reprint' },
                        { location: 'shipping/failed-to-purchase', name: 'HELP Needs a Human' },
                        { location: 'shipping/convert-pack-group', name: 'Incorrect Pack Group' },
                        { location: 'shipping/purchase-label', name: 'Purchase Label' },
                        { location: 'shipping/special-orders', name: 'Special Orders' },
                        { location: 'shipping/vip', name: 'VIP Labels' },
                    ],
                },
                System: {
                    tabs: [
                        { location: 'system/marketplace-inventory', name: 'Marketplace Inventory' },
                        { location: 'system/order-deep-dive', name: 'Order Deep Dive' },
                        { location: 'system/live-inventory', name: 'Live Inventory' },
                    ],
                },
                Training: { tabs: [], location: 'training' },
                Vendor: { tabs: [], location: 'vendor' },
                Warehouse: {
                    tabs: [
                        { location: 'warehouse/lots', name: 'Lots' },
                        { location: 'warehouse/pack-lines', name: 'Pack Lines' },
                        { location: 'warehouse/rack-labels', name: 'Rack Labels' },
                        { location: 'warehouse/netsuite', name: 'NetSuite' },
                    ],
                },
            };
            return {
                status: true,
                data: {
                    roles,
                    rolesLong: [
                        {
                            name: 'CREATE_EDIT_FBM_ITEMS',
                        },
                        {
                            name: 'CREATE_FAM_SUBSCRIPTION',
                        },
                        {
                            name: 'VIEW_FAM_SUBSCRIPTIONS',
                        },
                        {
                            name: 'VIEW_IMPORTED_ORDERS',
                        },
                        {
                            name: 'VIEW_CURATED_LISTS',
                        },
                        {
                            name: 'EDIT_FAM_SUBSCRIPTIONS',
                        },
                    ],
                    training: true,
                    user: {
                        id: 1031,
                        facilityID: 11,
                        facilityCluster: 'losangeles',
                        facilityClusterIds: [11],
                        name: '',
                        language: 'EN',
                        cycleCountManager: true,
                    },
                },
            };
        }
        let url = 'api/admin/v1/check/roles';
        let res = await mmFetchWithResponse(url);
        return res;
    },
    logOut: async () => {
        let url = 'api/admin/v1/logout';
        try {
            let response = await mmFetch(url, { method: 'POST' });
            if (response.ok === true) {
                window.location.assign('/login');
            }
        } catch (e) {
            window.location.assign('/login');
        }
    },
};

export { api };
