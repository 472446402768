import React, { useState, useEffect } from 'react';
import { Notifications, FormComponent, DataTable, TabPanel } from '..';
import { Button, Tabs, Tab, Paper, Toolbar, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ComparisonModal } from './ComparisonModal';

const UploadCSVAndReviewComponent = ({
    fileLabel,
    title,
    subtitle,
    columns,
    upload,
    confirm,
    onSuccess,
    downloadTemplate,
    downloadTemplateText = 'Download Template',
    downloadTemplateWithDummyData,
    downloadTemplateWithDummyDataText = 'Download Template with Dummy Data',
    exportUploadResults = false,
    multipleFiles = false,
    maxNumFiles = null,
    additionalFormFields = [],
    hideValidRowsOnError = false,
    expandable = false,
    expansionComponent = null,
    showConfirmationModal = false,
    confirmationModalKeyName = '',
}) => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [uploadResults, setUploadResults] = useState(null);
    let [toolbarActions, setToolbarActions] = useState([]);
    let [formFields, setFormFields] = useState([]);

    let clearAction = {
        name: `Clear File${multipleFiles ? 's' : ''}`,
        action: () => {
            setToolbarActions([]);
            setUploadResults(null);
        },
    };
    let disabledUploadAction = {
        name: 'Confirm Upload',
        action: () => {},
        disabled: true,
    };
    let uploadAction = null;

    useEffect(() => {
        let ff = additionalFormFields;
        ff.push({
            name: 'file',
            inputElement: 'file',
            accept: ['.csv'],
            multiple: multipleFiles,
            maxNumFiles,
            label: fileLabel,
            gridValue: 12,
        });
        setFormFields(ff);
    }, []);

    let confirmUpload = async (key) => {
        setToolbarActions([clearAction, disabledUploadAction]);

        if (!key) {
            setNotification({ text: 'No key provided for upload', level: 'error' });
            return;
        }

        if (confirm instanceof Function === false) {
            console.log('No confirm function provided');
            return;
        }

        let res = await confirm(key);

        if (!res.status) {
            setNotification({
                text: 'Failed to create uploaded items' + (!!res.msg ? `. ${res.msg}` : ''),
                level: 'error',
            });

            setToolbarActions([clearAction, uploadAction]);

            return;
        }

        setNotification({ text: 'Items uploaded', level: 'success' });
        setUploadResults(null);
        setToolbarActions([]);
        if (onSuccess instanceof Function) {
            onSuccess(res.data);
        }
    };

    let uploadCSV = async (formData) => {
        if (upload instanceof Function === false) {
            console.log('No upload function provided');
            return;
        }

        let res = await upload(formData);
        if (!res.status) {
            setNotification({ text: 'Failed to get results from upload. ' + res.msg, level: 'error' });
            return;
        }

        let data = res.data;
        let results = res.data.results
            .filter((row) => !hideValidRowsOnError || !data.error || row?.errors?.length > 0)
            .map((row) => {
                row.errorsArr = row.errors;
                row.errors = row?.errors?.join('. ');
                row.warnings = row?.warnings?.join('. ');
                return row;
            });
        data.results = results;

        let actions = [clearAction];

        if (!data.error) {
            uploadAction = {
                name: 'Confirm Upload',
                action: () => {
                    let key = data.key;
                    confirmUpload(key);
                },
            };
            actions.push(uploadAction);
        }

        setUploadResults(data);
        setToolbarActions(actions);
    };

    return (
        <div>
            <Notifications options={notification} />
            {!!title && <h3>{title}</h3>}
            {!!subtitle && <h4>{subtitle}</h4>}
            {!uploadResults && (
                <>
                    <Grid container justifycontent="space-evenly">
                        {downloadTemplateWithDummyData instanceof Function && (
                            <Grid item xs>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        downloadTemplateWithDummyData();
                                    }}
                                >
                                    {downloadTemplateWithDummyDataText}
                                </Button>
                            </Grid>
                        )}
                        {downloadTemplate instanceof Function && (
                            <Grid item xs>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        downloadTemplate();
                                    }}
                                >
                                    {downloadTemplateText}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    {formFields.length > 0 && (
                        <FormComponent
                            formFields={formFields}
                            onSubmit={async (formData, resetForm) => {
                                await uploadCSV(formData);
                            }}
                        />
                    )}
                </>
            )}
            {showConfirmationModal && uploadResults?.changedData && uploadResults.changedData.some((item) => item) && (
                <>
                    <ComparisonModal
                        data={uploadResults.changedData}
                        confirmationModalKeyName={confirmationModalKeyName}
                    />
                </>
            )}
            {!!uploadResults && (
                <>
                    {uploadResults.error ? (
                        <Alert severity="error">
                            There are errors in the uploaded file. Please review the following errors and upload a
                            corrected csv.
                        </Alert>
                    ) : !!uploadResults.warning ? (
                        <Alert severity="warning">Some warnings were found. Expand rows to see more.</Alert>
                    ) : (
                        <Alert severity="success">No errors detected. Please review and confirm.</Alert>
                    )}
                    {uploadResults?.errors?.length > 0 && (
                        <div>
                            Errors In File{multipleFiles ? 's' : ''}: {uploadResults.errors.join('. ')}
                        </div>
                    )}
                    {uploadResults?.warnings?.length > 0 && (
                        <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                            {uploadResults.warnings.map((item, ind) => (
                                <Alert key={`${ind}`} severity="warning">
                                    {item}
                                </Alert>
                            ))}
                        </div>
                    )}
                    {multipleFiles ? (
                        <ResultsMultipleFiles
                            data={uploadResults.results}
                            columns={columns}
                            toolbarActions={toolbarActions}
                            exportUploadResults={exportUploadResults}
                        />
                    ) : (
                        <DataTable
                            columns={columns}
                            toolbarActions={toolbarActions}
                            data={uploadResults.results}
                            rowStyle={(row) => {
                                if (row.errors?.length) {
                                    return { color: 'red', border: '3px solid red' };
                                } else if (row.warnings?.length) {
                                    return { color: '#FFB300', border: '3px solid #FFB300' };
                                }
                                return {};
                            }}
                            csvExport={exportUploadResults}
                            expandable={expandable}
                            ExpansionComponent={expansionComponent}
                        />
                    )}
                </>
            )}
        </div>
    );
};

const ResultsMultipleFiles = ({ data, columns, toolbarActions, exportUploadResults }) => {
    let [selectedTab, setSelectedTab] = useState(0);
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Toolbar>
                {toolbarActions.map((item, index) => (
                    <ToolbarButton key={index} item={item} />
                ))}
            </Toolbar>
            <Tabs
                variant="scrollable"
                onChange={handleChange}
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                {data.map((item, index) => {
                    let error = false;
                    if (item?.errors?.length) {
                        error = true;
                    }
                    for (let i = 0; i < item.rows.length; i++) {
                        let row = item.rows[i];
                        if (row.errors?.length) {
                            error = true;
                            break;
                        }
                    }
                    return (
                        <Tab
                            style={{ color: error ? 'red' : undefined }}
                            label={`${item.name ? item.name : index}`}
                            value={index}
                            key={`${index}`}
                        />
                    );
                })}
            </Tabs>
            {data.map((item, index) => {
                return (
                    <TabPanel selectedTab={selectedTab} index={index} key={`${index}`}>
                        {item?.errors?.length > 0 && <div>Errors In File: {item.errors}</div>}
                        {item?.warnings?.length > 0 && (
                            <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                                <div>Warnings</div>
                                <Alert severity="warning">{item.warnings}</Alert>
                            </div>
                        )}
                        {item.hasOwnProperty('info') && item.info.length && <div>{item.info}</div>}
                        <DataTable
                            columns={columns}
                            data={item.rows}
                            rowStyle={(row) => {
                                if (row.errors?.length) {
                                    return { color: 'red', border: '3px solid red' };
                                } else if (row.warnings?.length) {
                                    return { color: '#FFB300', border: '3px solid #FFB300' };
                                }
                                return {};
                            }}
                            csvExport={exportUploadResults}
                        />
                    </TabPanel>
                );
            })}
        </Paper>
    );
};

const ToolbarButton = ({ item }) => {
    return (
        <Button
            color="secondary"
            style={{ marginLeft: '20px' }}
            key={item.name}
            variant="contained"
            onClick={async () => {
                await item.action();
            }}
        >
            {item.name}
        </Button>
    );
};

export { UploadCSVAndReviewComponent };
