import React, { useContext, useEffect, useState } from 'react';
import { Notifications } from '../../../../../shared';
import { Button, CircularProgress } from '@material-ui/core';
import { warehouseApi } from '../../../../../api/warehouse';
import { UserContext } from '../../../../../contexts/UserContext';

const LotNotInLocationAction = ({ lotId, locationId, locInvType, onSuccess, onCancel }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [loading, setLoading] = useState(false);
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('LotNotInLocationAction intialized incorrectly onSuccess is not a function');
        }
        if (!(onCancel instanceof Function)) {
            console.error('LotNotInLocationAction initialized incorrectly onCancel is not a function');
        }
    }, [onSuccess]);

    const handleSubmit = async () => {
        setLoading(true);
        const result = await warehouseApi.lotNotInLocation(lotId, locationId, locInvType, user.facilityID);
        setLoading(false);
        if (result?.status) {
            onSuccess();
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <div>
            <Notifications options={notification} />
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSubmit} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Confirm'}
            </Button>
        </div>
    );
};

export { LotNotInLocationAction };
