import React, { useState } from 'react';
import {
    makeStyles,
    Box,
    Button,
    ButtonGroup,
    TextField,
    Typography,
    Tooltip
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    dateInputs: {
        display: 'flex',
        marginTop: theme.spacing(1),
        '& > *:first-child': {
            marginRight: theme.spacing(1)
        }
    },
    quickSelectButtons: {
        display: 'flex',
        justifyContent: 'center',
        '& .MuiButtonGroup-root': {
            '& .MuiButton-root': {
                backgroundColor: 'white',
                border: `1px solid ${theme.palette.primary.main}`,
                '&:hover': {
                    backgroundColor: theme.palette.primary.light,
                    '& .MuiTypography-root': {
                        backgroundColor: theme.palette.primary.light,
                        color: 'white'
                    }
                }
            }
        }
    },
    iconContainer: {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    timeText: {
        position: 'absolute',
        bottom: -2,
        right: -4,
        fontSize: '0.6rem',
        padding: '0 2px',
        borderRadius: 2,
        fontWeight: 'bold',
        backgroundColor: 'white'
    }
}));

const QuickSelectButton = ({ onClick, timeText, tooltip }) => {
    const classes = useStyles();
    return (
        <Tooltip title={tooltip}>
            <Button onClick={onClick}>
                <Box className={classes.iconContainer}>
                    <AccessTimeIcon />
                    <Typography className={classes.timeText}>
                        {timeText}
                    </Typography>
                </Box>
            </Button>
        </Tooltip>
    );
};

const DateRangeFilter = ({
    type,
    name,
    deletable,
    onFilterChange,
    includeTime = false
}) => {
    const classes = useStyles();

    const formatDate = (date) => {
        if (includeTime) {
            // Adjust for local timezone
            const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
            return localDate.toISOString().slice(0, 16); // YYYY-MM-DDThh:mm
        }
        return date.toISOString().split('T')[0];
    };

    const formatDisplayDate = (dateString) => {
        if (!dateString) return '';
        
        // Parse the date string and create a new Date object
        const date = new Date(dateString);
        
        if (includeTime) {
            // Format for display in chip (MM/DD/YYYY h:mm am/pm) in local timezone
            return date.toLocaleString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }).replace(',', ''); // Remove comma between date and time
        }
        
        // Format for display in chip (MM/DD/YYYY)
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });
    };

    const now = new Date();
    const today = formatDate(now);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState(today);
    const [error, setError] = useState('');

    const validateDates = (start, end) => {
        if (start && end && new Date(end) < new Date(start)) {
            setError('End date cannot be earlier than start date');
            return false;
        }
        setError('');
        return true;
    };

    const handleDateChange = (newStartDate, newEndDate) => {
        const start = newStartDate || startDate;
        const end = newEndDate || endDate;

        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            const isValid = validateDates(start, end);
            if (isValid) {
                onFilterChange({
                    type,
                    data: {
                        startDate: start,
                        endDate: end
                    },
                    chipLabel: `${name}: ${formatDisplayDate(start)} - ${formatDisplayDate(end)}`,
                    deletable
                });
            }
        }
    };

    const handleQuickSelect = (days) => {
        const end = new Date();
        const start = new Date();
        start.setDate(end.getDate() - days);
        
        // Ensure we're working with local dates
        const formattedStart = formatDate(start);
        const formattedEnd = formatDate(end);

        setStartDate(formattedStart);
        setEndDate(formattedEnd);
        setError('');
        onFilterChange({
            type,
            data: {
                startDate: formattedStart,
                endDate: formattedEnd
            },
            chipLabel: `${name}: ${formatDisplayDate(formattedStart)} - ${formatDisplayDate(formattedEnd)}`,
            deletable
        });
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.quickSelectButtons}>
                <ButtonGroup size="small" color="primary">
                    <QuickSelectButton
                        onClick={() => handleQuickSelect(1)}
                        timeText="24H"
                        tooltip="Past 24 Hours"
                    />
                    <QuickSelectButton
                        onClick={() => handleQuickSelect(3)}
                        timeText="3D"
                        tooltip="Past 3 Days"
                    />
                    <QuickSelectButton
                        onClick={() => handleQuickSelect(7)}
                        timeText="7D"
                        tooltip="Past 7 Days"
                    />
                </ButtonGroup>
            </Box>

            <Box className={classes.dateInputs}>
                <TextField
                    label="Start Date"
                    type={includeTime ? "datetime-local" : "date"}
                    value={startDate}
                    onChange={(e) => handleDateChange(e.target.value, null)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error}
                    inputProps={{
                        max: today
                    }}
                />
                <TextField
                    label="End Date"
                    type={includeTime ? "datetime-local" : "date"}
                    value={endDate}
                    onChange={(e) => handleDateChange(null, e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    error={!!error}
                />
            </Box>
        </Box>
    );
};

export { DateRangeFilter };
