import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Tabs,
    Tab,
    Paper,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
} from '@material-ui/core';
import { DataTable, Notifications, TabPanel } from '../../../shared';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { receivingApi } from '../../../api/receiving';
import { qualityApi } from '../../../api/quality';
import { procurementApi } from '../../../api/procurement';
import { packagingApi as packagingApi } from '../../../api/packaging';
import { ViewItemsTable } from '../../../components/procurement/components/viewPurchaseOrder';
import { UserContext } from '../../../contexts/UserContext';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { ItemDidNotArriveModal } from './itemDidNotArriveModal';
import { QualityReceivingModuleResetModal } from './qualityReceivingModuleResetModal';
import { ReceiveLineItemWithPalletsModal } from './directedReceiving/receiveLineItemWithPalletsModal';
import { PrintReceivedPalletAndCaseLabelsModal } from './receivedLabels/printReceivedPalletAndCaseTagsModal';

const colors = {
    blue: '#485bbd',
    highlighter: '#fbfe42',
    red: '#9F0000',
    green: '#0C892F',
};

let statusColors = {
    OPEN: '#FAFF00',
    SUBMITTED: '#00FF29',
    ISSUE: '#FF0000',
    IN_PROGRESS: '#FF7A00',
    PLACED: '#0500FF',
    RECEIVED: '#AD00FF',
    PAUSED: '#6699cc',
    QC_COMPLETE: '#00FF29',
    PAID: 'black',
    PENDING_REJECTION: '#e65a5a',
    REJECTED: '#FF0000',
    REJECTION_DENIED: '#023020',
};

let statusTextColors = {
    IN_PROGRESS: 'black',
    OPEN: 'black',
    ISSUE: 'white',
    PLACED: 'white',
    RECEIVED: 'white',
    PAUSED: 'white',
    SUBMITTED: 'black',
    QC_COMPLETE: 'black',
    PAID: 'white',
    PENDING_REJECTION: 'black',
    REJECTED: 'white',
    REJECTION_DENIED: 'white',
};

const useStyles = makeStyles((theme) => {
    return {
        panelContainer: {
            borderColor: theme.palette.ivy.main,
            borderWidth: '3px',
            borderStyle: 'solid',
            borderRadius: '5px',
            margin: '10px 10px',
            padding: '0px',
        },
        panelSummary: {
            height: '70px',
            fontSize: '16px',
            padding: '25px',
        },
    };
});

const ModifyPOModal = ({ open, close, rowData }) => {
    return (
        <Dialog open={open} onClose={close} fullWidth={true} maxWidth="lg" height="90%">
            <DialogTitle>Modify Purchase Order {rowData.id}</DialogTitle>
            <DialogContent>
                <ViewItemsTable receivingPOID={rowData.id} />
            </DialogContent>
        </Dialog>
    );
};

const DirectedReceivingItemModal = ({ rowData, closeModal, setNotification, issueCodes, editEnabled, photoRows }) => {
    let [error, setError] = useState(null);
    let [pageIdx, setPageIdx] = useState(rowData.initialPageIdx);
    let [poMapID, setPoMapID] = useState(rowData.poMapID);
    let [poID, setPoID] = useState(rowData.poID);
    let [qualityReceivingData, setQualityReceivingData] = useState(rowData.qualityReceivingData);
    const [caseCounts, setCaseCounts] = useState(
        Object.keys(rowData.qualityReceivingData.caseCounts).map((key) => rowData.qualityReceivingData.caseCounts[key])
    );
    let [selectedInternalInspectionTab, setSelectedInternalInspectionTab] = useState('0');
    let [selectedExternalInspectionTab, setSelectedExternalInspectionTab] = useState('0');
    let [selectedUnitCountTab, setSelectedUnitCountTab] = useState('0');
    const [expanded, setExpanded] = useState(false);
    const [enlargedPhotoID, setEnlargedPhotoID] = useState(null);

    const handleInternalInspectionTabChange = (event, value) => {
        setSelectedInternalInspectionTab(value);
    };
    const handleExternalInspectionTabChange = (event, value) => {
        setSelectedExternalInspectionTab(value);
    };

    const handleUnitCountTabChange = (event, value) => {
        setSelectedUnitCountTab(value);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const externalPhotoCodes = [
        'PRODUCT_SAMPLE',
        'VENDOR_BARCODE',
        'NUTRITION_LABEL',
        'CLOSE_UP_EXTERNAL',
        'EXTERNAL_COLLECTION_ANGLE_1',
        'EXTERNAL_COLLECTION_ANGLE_2',
        'EXTERNAL_COLLECTION_ANGLE_3',
        'EXTERNAL_COLLECTION_ANGLE_4',
        'EXTERNAL_SCALE_PHOTO',
    ];

    const internalPhotoCodes = [
        'PULP_TEMP',
        'CLOSE_UP_INTERNAL',
        'INTERNAL_COLLECTION_ANGLE_1',
        'INTERNAL_COLLECTION_ANGLE_2',
        'INTERNAL_COLLECTION_ANGLE_3',
        'INTERNAL_COLLECTION_ANGLE_4',
        'INTERNAL_SCALE_PHOTO',
    ];

    const caseCountPhotoCodes = ['UPC_BARCODE', 'BROKEN_UNITS'];

    useEffect(() => {
        setPageIdx(rowData.initialPageIdx);
    }, []);

    const getExternalSummaryTableData = () => {
        let formattedSummaryData = {};
        Object.keys(qualityReceivingData.externalIssues).forEach((key) => {
            let issues = qualityReceivingData.externalIssues[key].issues;
            if (issues) {
                issues.forEach((issue) => {
                    if (!formattedSummaryData[issue.issueType]) {
                        formattedSummaryData[issue.issueType] = {
                            cases: 0,
                            units: 0,
                        };
                    }
                    formattedSummaryData[issue.issueType].cases++;
                    formattedSummaryData[issue.issueType].units += parseFloat(issue.unitsDamaged);
                });
            }
        });

        let formattedDataArray = [];
        Object.keys(formattedSummaryData).forEach((key) => {
            formattedDataArray.push({
                id: key,
                issueType: key,
                casesInspected: formattedSummaryData[key].cases,
                unitsDamaged: formattedSummaryData[key].units,
            });
        });

        return formattedDataArray;
    };

    const summaryDataTableCols = [
        { accessor: 'issueType', Header: 'Damage Reason', editable: false, useFilter: false, sortable: false },
        { accessor: 'casesInspected', Header: 'Number of Cases', editable: false, useFilter: false, sortable: false },
        { accessor: 'unitsDamaged', Header: 'Unit Qty', editable: false, useFilter: false, sortable: false },
    ];

    const getInternalSummaryTableData = () => {
        let formattedSummaryData = {};
        Object.keys(qualityReceivingData.internalIssues).forEach((key) => {
            let issues = qualityReceivingData.internalIssues[key].issues;
            if (issues) {
                issues.forEach((issue) => {
                    if (!formattedSummaryData[issue.issueType]) {
                        formattedSummaryData[issue.issueType] = {
                            cases: 0,
                            units: 0,
                        };
                    }
                    formattedSummaryData[issue.issueType].cases++;
                    formattedSummaryData[issue.issueType].units += parseFloat(issue.unitsDamaged);
                });
            }
        });

        let formattedDataArray = [];
        Object.keys(formattedSummaryData).forEach((key) => {
            formattedDataArray.push({
                id: key,
                issueType: key,
                casesInspected: formattedSummaryData[key].cases,
                unitsDamaged: formattedSummaryData[key].units,
            });
        });

        return formattedDataArray;
    };

    const getExternalUnitsDamagedPercent = () => {
        let unitsDamaged = 0;
        let unitsInspected = 0;
        Object.keys(qualityReceivingData.externalIssues).forEach((key) => {
            unitsInspected += qualityReceivingData.externalIssues[key].unitsInspected || 0;
            let issues = qualityReceivingData.externalIssues[key].issues;
            if (issues) {
                issues.forEach((issue) => {
                    unitsDamaged += parseFloat(issue.unitsDamaged);
                });
            }
        });
        if (unitsInspected == 0) {
            return 0;
        }
        return unitsDamaged / unitsInspected;
    };

    const getInternalUnitsDamagedPercent = () => {
        let unitsDamaged = 0;
        let unitsInspected = 0;
        Object.keys(qualityReceivingData.internalIssues).forEach((key) => {
            unitsInspected += qualityReceivingData.internalIssues[key].unitsInspected || 0;
            let issues = qualityReceivingData.internalIssues[key].issues;
            if (issues) {
                issues.forEach((issue) => {
                    unitsDamaged += parseFloat(issue.unitsDamaged);
                });
            }
        });
        if (unitsInspected == 0) {
            return 0;
        }
        return unitsDamaged / unitsInspected;
    };

    const unitCountSummaryDataTableCols = [
        { accessor: 'caseIdx', Header: 'Case Number', editable: false, useFilter: false, sortable: false },
        { accessor: 'unitCount', Header: 'Number of Units', editable: editEnabled, useFilter: false, sortable: false },
    ];

    const unitWeightSummaryDataTableCols = [
        { accessor: 'unitNumber', Header: 'unitNumber', editable: false, useFilter: false, sortable: false },
        { accessor: 'weight', Header: 'Weight(oz)', editable: editEnabled, useFilter: false, sortable: false },
    ];

    const getFormattedWeightSummaryData = (caseIdx) => {
        let caseWeights = caseCounts[caseIdx];
        let formattedData = [];
        if (caseWeights.unitWeights) {
            caseWeights.unitWeights.forEach((unitWeight, idx) => {
                formattedData.push({
                    id: idx,
                    unitNumber: idx + 1,
                    weight: unitWeight,
                });
            });
        }
        return formattedData;
    };

    const inspectionTable = [
        {
            id: 'pallets',
            label: '# of PALLETS Inspected',
            value: qualityReceivingData.palletsInspectedExternal,
        },
        {
            id: 'cases',
            label: '# of CASES Inspected',
            value: qualityReceivingData.casesInspectedExternal,
        },
        {
            id: 'units',
            label: '# of UNITS Inspected',
            value: Object.values(qualityReceivingData.externalIssues).reduce(
                (sum, issue) => sum + issue.unitsInspected,
                0
            ),
        },
    ];

    const inspectionTableInternal = [
        {
            id: 'pallets',
            label: '# of PALLETS Inspected',
            value: qualityReceivingData.palletsInspectedInternal,
        },
        {
            id: 'cases',
            label: '# of CASES Inspected',
            value: qualityReceivingData.casesInspectedInternal,
        },
        {
            id: 'units',
            label: '# of UNITS Inspected',
            value: Object.values(qualityReceivingData.internalIssues).reduce(
                (sum, issue) => sum + issue.unitsInspected,
                0
            ),
        },
        {
            id: 'pulpTemp',
            label: 'Pulp Temp(˚F)',
            value: qualityReceivingData.levelTwoPulpTemp,
        },
    ];

    const unitsPerCaseTable = [
        {
            id: 'casesCounted',
            label: '# of CASES Inspected',
            value: qualityReceivingData.casesCounted,
        },
    ];

    const inspectionCols = [
        { accessor: 'label', Header: '', editable: false, useFilter: false, sortable: false },
        {
            accessor: 'value',
            Header: '',
            editable: (row) => editEnabled && row.original.id !== 'units',
            useFilter: false,
            sortable: false,
        },
    ];

    const getUnitCountSummaryData = () => {
        let formattedSummaryData = [];
        if (qualityReceivingData.caseCounts) {
            Object.keys(qualityReceivingData.caseCounts).forEach((caseIdx) => {
                formattedSummaryData.push({
                    id: caseIdx,
                    caseIdx: caseIdx,
                    unitCount: qualityReceivingData.caseCounts[caseIdx].unitCount,
                });
            });
        }
        return formattedSummaryData;
    };

    const getBackgroundHeaderColor = (pageIdx) => {
        if (pageIdx == 0) {
            return '#0C892F';
        } else if (pageIdx == 1) {
            return '#3F51B5';
        } else if (pageIdx == 2) {
            return '#9F0000';
        }
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={true}
            onClose={() => {
                setEnlargedPhotoID(null);
                closeModal();
            }}
        >
            <DialogTitle
                style={{ backgroundColor: getBackgroundHeaderColor(pageIdx), color: 'white', textAlign: 'center' }}
            >
                {pageIdx == 1 ? 'External Issues' : pageIdx == 0 ? 'Units per Case' : 'Internal Issues'}
            </DialogTitle>

            <DialogContent>
                <div
                    style={{
                        outline: 0,
                        backgroundColor: 'white',
                        width: '700px',
                        margin: '10% auto',
                        padding: '10px',
                    }}
                >
                    {pageIdx == 0 && (
                        <div>
                            <Paper>
                                <Tabs
                                    onChange={handleUnitCountTabChange}
                                    value={selectedUnitCountTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Summary" value="0" />
                                    <Tab label="Photos" value="1" />
                                </Tabs>
                                <TabPanel selectedTab={selectedUnitCountTab} index={'0'}>
                                    <div>
                                        <h3>% DISCREPANCY: {qualityReceivingData.unitCountVariance || 0}</h3>
                                    </div>
                                    <DataTable
                                        data={unitsPerCaseTable}
                                        columns={inspectionCols}
                                        editable={editEnabled}
                                        showPagination={false}
                                        saveEdit={async ({ id, field, value }) => {
                                            let response = await qualityApi.recordCasesCounted(
                                                qualityReceivingData.id,
                                                value,
                                                'external'
                                            );
                                            if (response.status === false) {
                                                let message = 'Error';
                                                if (response) {
                                                    message += ': ' + response.msg;
                                                }
                                                setNotification({ text: message, level: 'error' });
                                            } else {
                                                setNotification({ text: 'Cases Inspected recorded', level: 'success' });
                                                setQualityReceivingData(response.data.itemInspected);
                                                return;
                                            }
                                        }}
                                    />

                                    {!(
                                        qualityReceivingData.baseUnitsPerCase >= 500 &&
                                        qualityReceivingData.purchasingCaseUom == 'lb'
                                    ) && (
                                        <DataTable
                                            data={getUnitCountSummaryData()}
                                            columns={unitCountSummaryDataTableCols}
                                            editable={editEnabled}
                                            showPagination={false}
                                            saveEdit={async ({ id, field, value }) => {
                                                let response = await qualityApi.recordCaseUnitCountValue(
                                                    qualityReceivingData.id,
                                                    id,
                                                    value
                                                );
                                                if (response.status === false) {
                                                    let message = 'Error';
                                                    if (response) {
                                                        message += ': ' + response.msg;
                                                    }
                                                    setNotification({ text: message, level: 'error' });
                                                } else {
                                                    setNotification({
                                                        text: 'Case unit count recorded',
                                                        level: 'success',
                                                    });
                                                    setQualityReceivingData(response.data.itemInspected);
                                                    return;
                                                }
                                            }}
                                        />
                                    )}
                                    {qualityReceivingData.baseUnitsPerCase >= 500 &&
                                        qualityReceivingData.purchasingCaseUom == 'lb' && (
                                            <div>
                                                {caseCounts &&
                                                    caseCounts.map((caseCounted, caseIdx) => {
                                                        return (
                                                            <Accordion
                                                                expanded={expanded == `case_${caseIdx}`}
                                                                onChange={handleAccordionChange(`case_${caseIdx}`)}
                                                                key={`case_${caseIdx}`}
                                                            >
                                                                <AccordionSummary
                                                                    aria-controls="panel1bh-content"
                                                                    id="panel1bh-header"
                                                                >
                                                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                                        {`Case ${caseIdx + 1}`}
                                                                    </Typography>
                                                                    <AccordionDetails>
                                                                        {caseCounted.unitWeights && (
                                                                            <DataTable
                                                                                data={getFormattedWeightSummaryData(
                                                                                    caseIdx
                                                                                )}
                                                                                columns={unitWeightSummaryDataTableCols}
                                                                                editable={editEnabled}
                                                                                showPagination={false}
                                                                                saveEdit={async ({
                                                                                    id,
                                                                                    field,
                                                                                    value,
                                                                                }) => {
                                                                                    let response =
                                                                                        await qualityApi.recordCaseUnitWeightValue(
                                                                                            qualityReceivingData.id,
                                                                                            caseIdx,
                                                                                            id,
                                                                                            value
                                                                                        );
                                                                                    if (response.status === false) {
                                                                                        let message = 'Error';
                                                                                        if (response) {
                                                                                            message +=
                                                                                                ': ' + response.msg;
                                                                                        }
                                                                                        setNotification({
                                                                                            text: message,
                                                                                            level: 'error',
                                                                                        });
                                                                                    } else {
                                                                                        setNotification({
                                                                                            text: 'Unit weight recorded',
                                                                                            level: 'success',
                                                                                        });
                                                                                        setQualityReceivingData(
                                                                                            response.data.itemInspected
                                                                                        );
                                                                                        return;
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </AccordionDetails>
                                                                </AccordionSummary>
                                                            </Accordion>
                                                        );
                                                    })}
                                            </div>
                                        )}
                                </TabPanel>
                                <TabPanel selectedTab={selectedUnitCountTab} index={'1'}>
                                    {enlargedPhotoID && (
                                        <div>
                                            <div
                                                style={{ position: 'relative', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setEnlargedPhotoID(null);
                                                }}
                                            >
                                                <ZoomOutIcon
                                                    style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}
                                                />
                                                <img
                                                    src={photoRows.find((photo) => photo.id === enlargedPhotoID).s3URL}
                                                    alt={photoRows.find((photo) => photo.id === enlargedPhotoID).code}
                                                    width={'100%'}
                                                />
                                            </div>

                                            <div
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        procurementApi.downloadPODocument(
                                                            poID,
                                                            enlargedPhotoID,
                                                            photoRows
                                                                .find((photo) => photo.id === enlargedPhotoID)
                                                                .documentURL.replace(/^.*[\\\/]/, '')
                                                        );
                                                    }}
                                                >
                                                    Download
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                    {!enlargedPhotoID && (
                                        <Grid container>
                                            {photoRows.map((photo) => {
                                                if (
                                                    photo.code &&
                                                    photo.purchaseOrderMapID === poMapID &&
                                                    (photo.code.startsWith('EXPIRATION_DATE') == true ||
                                                        caseCountPhotoCodes.includes(photo.code) == true)
                                                ) {
                                                    return (
                                                        <Grid item xs={4} key={photo.code}>
                                                            <div
                                                                style={{ position: 'relative', cursor: 'pointer' }}
                                                                onClick={() => setEnlargedPhotoID(photo.id)}
                                                            >
                                                                <ZoomInIcon
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        right: 20,
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                                <img src={photo.s3URL} alt={photo.code} width={'90%'} />
                                                            </div>
                                                            <div
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                }}
                                                            >
                                                                <Button
                                                                    onClick={() => {
                                                                        procurementApi.downloadPODocument(
                                                                            poID,
                                                                            photo.id,
                                                                            photo.documentURL.replace(/^.*[\\\/]/, '')
                                                                        );
                                                                    }}
                                                                >
                                                                    Download
                                                                </Button>
                                                            </div>
                                                        </Grid>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </Grid>
                                    )}
                                </TabPanel>
                            </Paper>
                        </div>
                    )}
                    {pageIdx == 1 && (
                        <div>
                            <Paper>
                                <Tabs
                                    onChange={handleExternalInspectionTabChange}
                                    value={selectedExternalInspectionTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Summary" value="0" />
                                    <Tab label="Photos" value="1" />
                                </Tabs>
                                <TabPanel selectedTab={selectedExternalInspectionTab} index={'0'}>
                                    <div>
                                        <h3>% ITEMS DAMAGED: {(getExternalUnitsDamagedPercent() * 100).toFixed(2)}</h3>
                                    </div>
                                    <DataTable
                                        data={inspectionTable}
                                        columns={inspectionCols}
                                        editable={editEnabled}
                                        showPagination={false}
                                        saveEdit={async ({ id, field, value }) => {
                                            if (id == 'cases') {
                                                let response = await qualityApi.recordCasesInspected(
                                                    qualityReceivingData.id,
                                                    value,
                                                    'external'
                                                );
                                                if (response.status === false) {
                                                    let message = 'Error';
                                                    if (response) {
                                                        message += ': ' + response.msg;
                                                    }
                                                    setNotification({ text: message, level: 'error' });
                                                } else {
                                                    setNotification({
                                                        text: 'Cases Inspected recorded',
                                                        level: 'success',
                                                    });
                                                    setQualityReceivingData(response.data.itemInspected);
                                                    return;
                                                }
                                            } else if (id == 'pallets') {
                                                let response = await qualityApi.recordPalletsInspected(
                                                    qualityReceivingData.id,
                                                    value,
                                                    'external'
                                                );
                                                if (response.status === false) {
                                                    let message = 'Error';
                                                    if (response) {
                                                        message += ': ' + response.msg;
                                                    }
                                                    setNotification({ text: message, level: 'error' });
                                                } else {
                                                    setNotification({
                                                        text: 'Pallets Inspected recorded',
                                                        level: 'success',
                                                    });
                                                    setQualityReceivingData(response.data.itemInspected);
                                                    return;
                                                }
                                            }
                                        }}
                                    />
                                    <DataTable
                                        data={getExternalSummaryTableData()}
                                        columns={summaryDataTableCols}
                                        editable={true}
                                        showPagination={false}
                                        saveEdit={async ({ id, field, value }) => {
                                            let updates = {
                                                key: field,
                                                value: value,
                                            };
                                            if (id == '') {
                                                id = 0;
                                            }
                                            let response = await qualityApi.recordQualityReceivingIssueV2(
                                                id,
                                                updates,
                                                poMapID,
                                                'external'
                                            );
                                            if (response.status === false) {
                                                let message = 'Error';
                                                if (response) {
                                                    message += ': ' + response.msg;
                                                }
                                                setNotification({ text: message, level: 'error' });
                                            } else {
                                                setNotification({ text: 'External issue recorded!', level: 'success' });
                                                setQualityReceivingData(response.data.itemInspected);
                                            }
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel selectedTab={selectedExternalInspectionTab} index={'1'}>
                                    {enlargedPhotoID && (
                                        <div>
                                            <div
                                                style={{ position: 'relative', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setEnlargedPhotoID(null);
                                                }}
                                            >
                                                <ZoomOutIcon
                                                    style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}
                                                />
                                                <img
                                                    src={photoRows.find((photo) => photo.id === enlargedPhotoID).s3URL}
                                                    alt={photoRows.find((photo) => photo.id === enlargedPhotoID).code}
                                                    width={'100%'}
                                                />
                                            </div>

                                            <div
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        procurementApi.downloadPODocument(
                                                            poID,
                                                            enlargedPhotoID,
                                                            photoRows
                                                                .find((photo) => photo.id === enlargedPhotoID)
                                                                .documentURL.replace(/^.*[\\\/]/, '')
                                                        );
                                                    }}
                                                >
                                                    Download
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                    {!enlargedPhotoID && (
                                        <Grid container>
                                            {photoRows.map((photo) => {
                                                if (
                                                    photo.code &&
                                                    photo.purchaseOrderMapID === poMapID &&
                                                    externalPhotoCodes.includes(photo.code) == true
                                                ) {
                                                    return (
                                                        <Grid item xs={4} key={photo.code}>
                                                            <div
                                                                style={{ position: 'relative', cursor: 'pointer' }}
                                                                onClick={() => setEnlargedPhotoID(photo.id)}
                                                            >
                                                                <ZoomInIcon
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        right: 20,
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                                <img src={photo.s3URL} alt={photo.code} width={'90%'} />
                                                            </div>
                                                            <div
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                }}
                                                            >
                                                                <Button
                                                                    onClick={() => {
                                                                        procurementApi.downloadPODocument(
                                                                            poID,
                                                                            photo.id,
                                                                            photo.documentURL.replace(/^.*[\\\/]/, '')
                                                                        );
                                                                    }}
                                                                >
                                                                    Download
                                                                </Button>
                                                            </div>
                                                        </Grid>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </Grid>
                                    )}
                                </TabPanel>
                            </Paper>
                        </div>
                    )}
                    {pageIdx == 2 && (
                        <div>
                            <Paper>
                                <Tabs
                                    onChange={handleInternalInspectionTabChange}
                                    value={selectedInternalInspectionTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Summary" value="0" />
                                    <Tab label="Unit Inspection" value="1" />
                                    <Tab label="Photos" value="2" />
                                </Tabs>
                                <TabPanel selectedTab={selectedInternalInspectionTab} index={'0'}>
                                    <div>
                                        <h3>% ITEMS DAMAGED: {(getInternalUnitsDamagedPercent() * 100).toFixed(2)}</h3>
                                    </div>
                                    <DataTable
                                        data={inspectionTableInternal}
                                        columns={inspectionCols}
                                        editable={editEnabled}
                                        showPagination={false}
                                        saveEdit={async ({ id, field, value }) => {
                                            if (id == 'cases') {
                                                let response = await qualityApi.recordCasesInspected(
                                                    qualityReceivingData.id,
                                                    value,
                                                    'internal'
                                                );
                                                if (response.status === false) {
                                                    let message = 'Error';
                                                    if (response) {
                                                        message += ': ' + response.msg;
                                                    }
                                                    setNotification({ text: message, level: 'error' });
                                                } else {
                                                    setNotification({
                                                        text: 'Cases Inspected recorded',
                                                        level: 'success',
                                                    });
                                                    setQualityReceivingData(response.data.itemInspected);
                                                    return;
                                                }
                                            } else if (id == 'pallets') {
                                                let response = await qualityApi.recordPalletsInspected(
                                                    qualityReceivingData.id,
                                                    value,
                                                    'internal'
                                                );
                                                if (response.status === false) {
                                                    let message = 'Error';
                                                    if (response) {
                                                        message += ': ' + response.msg;
                                                    }
                                                    setNotification({ text: message, level: 'error' });
                                                } else {
                                                    setNotification({
                                                        text: 'Pallets Inspected recorded',
                                                        level: 'success',
                                                    });
                                                    setQualityReceivingData(response.data.itemInspected);
                                                    return;
                                                }
                                            } else if (id == 'pulpTemp') {
                                                let updates = {
                                                    key: 'levelTwoPulpTemp',
                                                    value: value,
                                                };
                                                let response = await qualityApi.recordQualityReceiving(id, updates);
                                                if (response.status === false) {
                                                    let message = 'Error';
                                                    if (response) {
                                                        message += ': ' + response.msg;
                                                    }
                                                    setNotification({ text: message, level: 'error' });
                                                } else {
                                                    setNotification({ text: 'Pulp temp recorded', level: 'success' });
                                                    setQualityReceivingData(response.data.itemInspected);
                                                }
                                            }
                                        }}
                                    />
                                    <DataTable
                                        data={getInternalSummaryTableData()}
                                        columns={summaryDataTableCols}
                                        editable={true}
                                        showPagination={false}
                                        saveEdit={async ({ id, field, value }) => {
                                            let updates = {
                                                key: field,
                                                value: value,
                                            };
                                            if (id == '') {
                                                id = 0;
                                            }
                                            let response = await qualityApi.recordQualityReceivingIssueV2(
                                                id,
                                                updates,
                                                poMapID,
                                                'external'
                                            );
                                            if (response.status === false) {
                                                let message = 'Error';
                                                if (response) {
                                                    message += ': ' + response.msg;
                                                }
                                                setNotification({ text: message, level: 'error' });
                                            } else {
                                                setNotification({ text: 'External issue recorded!', level: 'success' });
                                                setQualityReceivingData(response.data.itemInspected);
                                            }
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel selectedTab={selectedInternalInspectionTab} index={'1'}>
                                    <Grid container>
                                        {qualityReceivingData.internalUnitInspection &&
                                            Object.keys(qualityReceivingData.internalUnitInspection).map((key) => {
                                                return (
                                                    <Grid item xs={3} key={key}>
                                                        <FormControl>
                                                            <InputLabel>{`Unit ${key}`}</InputLabel>
                                                            <Select
                                                                value={qualityReceivingData.internalUnitInspection[key]}
                                                                disabled={!editEnabled}
                                                                onChange={async (e) => {
                                                                    if (!editEnabled) {
                                                                        return;
                                                                    }
                                                                    let res =
                                                                        await qualityApi.recordQualityUnitInspection(
                                                                            qualityReceivingData.id,
                                                                            { index: key, value: e.target.value }
                                                                        );
                                                                    if (!res.status) {
                                                                        let message = 'Error';
                                                                        if (res) {
                                                                            message += ': ' + res.msg;
                                                                        }
                                                                        setNotification({
                                                                            text: message,
                                                                            level: 'error',
                                                                        });
                                                                        return;
                                                                    }
                                                                    setQualityReceivingData(res.data.itemInspected);
                                                                    setNotification({
                                                                        text: 'Unit Inspection recorded',
                                                                        level: 'success',
                                                                    });
                                                                }}
                                                            >
                                                                {issueCodes.map((code) => {
                                                                    return (
                                                                        <MenuItem
                                                                            disabled={!editEnabled}
                                                                            value={code}
                                                                            key={code}
                                                                        >
                                                                            {code}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                </TabPanel>
                                <TabPanel selectedTab={selectedInternalInspectionTab} index={'2'}>
                                    {enlargedPhotoID && (
                                        <div>
                                            <div
                                                style={{ position: 'relative', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setEnlargedPhotoID(null);
                                                }}
                                            >
                                                <ZoomOutIcon
                                                    style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}
                                                />
                                                <img
                                                    src={photoRows.find((photo) => photo.id === enlargedPhotoID).s3URL}
                                                    alt={photoRows.find((photo) => photo.id === enlargedPhotoID).code}
                                                    width={'100%'}
                                                />
                                            </div>

                                            <div
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        procurementApi.downloadPODocument(
                                                            poID,
                                                            enlargedPhotoID,
                                                            photoRows
                                                                .find((photo) => photo.id === enlargedPhotoID)
                                                                .documentURL.replace(/^.*[\\\/]/, '')
                                                        );
                                                    }}
                                                >
                                                    Download
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                    {!enlargedPhotoID && (
                                        <Grid container>
                                            {photoRows.map((photo) => {
                                                if (
                                                    photo.code &&
                                                    photo.purchaseOrderMapID === poMapID &&
                                                    internalPhotoCodes.includes(photo.code) == true
                                                ) {
                                                    return (
                                                        <Grid item xs={4} key={photo.code}>
                                                            <div
                                                                style={{ position: 'relative', cursor: 'pointer' }}
                                                                onClick={() => setEnlargedPhotoID(photo.id)}
                                                            >
                                                                <ZoomInIcon
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        right: 20,
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                                <img src={photo.s3URL} alt={photo.code} width={'90%'} />
                                                            </div>
                                                            <div
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                }}
                                                            >
                                                                <Button
                                                                    onClick={() => {
                                                                        procurementApi.downloadPODocument(
                                                                            poID,
                                                                            photo.id,
                                                                            photo.documentURL.replace(/^.*[\\\/]/, '')
                                                                        );
                                                                    }}
                                                                >
                                                                    Download
                                                                </Button>
                                                            </div>
                                                        </Grid>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </Grid>
                                    )}
                                </TabPanel>
                            </Paper>
                        </div>
                    )}
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                    {}
                </div>
            </DialogContent>
        </Dialog>
    );
};

// The table to show items v2
const ViewItemsTableV2 = ({ parentRow, setExpandedRow, issueCodes, setUpdatedPurchaseOrderStatus, showReceived }) => {
    let [poID, setPOID] = useState(parentRow.original.id);
    let [rows, setRows] = useState([]);
    let [photoRows, setPhotoRows] = useState([]);
    const [printLabelModalDetails, setPrintLabelModalDetails] = useState(null);
    let [modalLaunch, setModalLaunch] = useState({
        poID: parentRow.original.id,
        poMapID: null,
        qualityReceivingData: null,
        initialPageIdx: 0,
    });

    let [itemDocumentationModalOpen, setItemDocumentationModalOpen] = useState(false);

    let [notification, setNotification] = useState({ text: null, level: null });
    let [editEnabled, setEditEnabled] = useState(false);

    let [receiveLineItemWithPalletsModalOpen, setReceiveLineItemWithPalletsModalOpen] = useState(false);
    let [selectedRow, setSelectedRow] = useState(null);

    const [showDidNotArriveModal, setShowDidNotArriveModal] = useState(false);
    let [currentPurchaseOrderMap, setCurrentPurchaseOrderMap] = useState(null);

    const { roles } = useContext(UserContext);
    let allowDidNotArrive = roles?.filter((role) => role.name === 'EDIT_QUALITY_CONTROL_DESKTOP').length > 0;

    const [showQualityReceivingModuleResetModal, setShowQualityReceivingModuleResetModal] = useState(false);

    const [multiTruckPO, setMultiTruckPO] = useState(false);

    let columns = [];
    if (parentRow.original.poType == 'PACKAGING') {
        columns = [
            { accessor: 'poMapID', Header: 'ID' },
            { accessor: 'masterSku', Header: 'Master SKU' },
            { accessor: 'description', Header: 'Name' },
            {
                accessor: 'progressStatus',
                Header: 'Progress Status',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <span
                            style={
                                row.original.progressStatus
                                    ? {
                                          backgroundColor: statusColors[row.original.progressStatus.replace(' ', '_')],
                                          color: statusTextColors[row.original.progressStatus.replace(' ', '_')],
                                      }
                                    : {}
                            }
                        >
                            {row.original.progressStatus}
                        </span>
                    );
                },
            },
            {
                accessor: 'issueStatus',
                Header: 'Issue Status',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <span style={{ backgroundColor: colors.red, color: 'white' }}>{row.original.issueStatus}</span>
                    );
                },
            },
            { accessor: 'issueTypes', Header: 'Issue Type Status' },
            { accessor: 'qty', Header: 'Expected Case Qty' },
        ];
    } else {
        columns = [
            { accessor: 'id', Header: 'ID' },
            { accessor: 'itemMasterSKU', Header: 'Item Master SKU' },
            { accessor: 'itemMasterName', Header: 'Name' },
            {
                accessor: 'progressStatus',
                Header: 'Progress Status',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <span
                            style={
                                row.original.progressStatus
                                    ? {
                                          backgroundColor: statusColors[row.original.progressStatus.replace(' ', '_')],
                                          color: statusTextColors[row.original.progressStatus.replace(' ', '_')],
                                      }
                                    : {}
                            }
                        >
                            {row.original.progressStatus}
                        </span>
                    );
                },
            },
            { accessor: 'truckID', Header: 'Truck ID', hide: !multiTruckPO },
            { accessor: 'itemNetSuiteID', Header: 'NetSuite Item ID' },
            {
                accessor: 'issueStatus',
                Header: 'Issue Status',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <span style={{ backgroundColor: colors.red, color: 'white' }}>{row.original.issueStatus}</span>
                    );
                },
            },
            { accessor: 'issueTypes', Header: 'Issue Type Status' },
            { accessor: 'qty', Header: 'Expected Cases' },
            { accessor: 'receivedCases', Header: 'Actual Cases', hide: !showReceived },
            { accessor: 'baseUnitsPerCase', Header: 'Expected Units/Case', hide: !showReceived },
            { accessor: 'receivedUnitCount', Header: 'Actual Units/Case', hide: !showReceived },
            { accessor: 'statusChangeAuthUser', Header: 'Auditor' },
            {
                accessor: 'auditCounts',
                Header: 'Units Per Case',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setModalLaunch({
                                        poID: parentRow.original.id,
                                        poMapID: row.original.id,
                                        qualityReceivingData: row.original,
                                        initialPageIdx: 0,
                                    });
                                    setItemDocumentationModalOpen(true);
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                },
            },
            {
                accessor: 'externalIssues',
                Header: 'External Issues',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setModalLaunch({
                                        poID: parentRow.original.id,
                                        poMapID: row.original.id,
                                        qualityReceivingData: row.original,
                                        initialPageIdx: 1,
                                    });
                                    setItemDocumentationModalOpen(true);
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                },
            },
            {
                accessor: 'internalIssues',
                Header: 'Internal Issues',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setModalLaunch({
                                        poID: parentRow.original.id,
                                        poMapID: row.original.id,
                                        qualityReceivingData: row.original,
                                        initialPageIdx: 2,
                                    });
                                    setItemDocumentationModalOpen(true);
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                },
            },
        ];
    }

    if (allowDidNotArrive) {
        columns.push({
            accessor: 'didNotArrive',
            Header: '',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            disabled={
                                !(
                                    row.original.progressStatus === 'PLACED' ||
                                    row.original.progressStatus === 'OPEN' ||
                                    row.original.progressStatus === 'IN PROGRESS'
                                )
                            }
                            onClick={() => {
                                setCurrentPurchaseOrderMap(row.original);
                                setShowDidNotArriveModal(true);
                            }}
                        >
                            Product Didn't Arrive
                        </Button>
                    </div>
                );
            },
        });
    }

    if (editEnabled && !showReceived) {
        columns.push({
            accessor: 'resetQc',
            Header: '',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            disabled={
                                row.original.progressStatus !== 'IN PROGRESS' &&
                                row.original.progressStatus !== 'QC COMPLETE'
                            }
                            onClick={async () => {
                                setCurrentPurchaseOrderMap(row.original);
                                setShowQualityReceivingModuleResetModal(true);
                            }}
                        >
                            Reset
                        </Button>
                    </div>
                );
            },
        });
    }

    if (!showReceived) {
        columns.push({
            accessor: 'receive',
            Header: '',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div>
                        <Button
                            disabled={
                                (parentRow.original.requiresQualityControl &&
                                    row.original.progressStatus !== 'QC COMPLETE') ||
                                (!parentRow.original.requiresQualityControl &&
                                    row.original.progressStatus == 'RECEIVED') ||
                                (!parentRow.original.requiresQualityControl &&
                                    row.original.progressStatus == 'CONSUMED')
                            }
                            onClick={async () => {
                                setSelectedRow(row);
                                setReceiveLineItemWithPalletsModalOpen(true);
                            }}
                        >
                            Receive
                        </Button>
                    </div>
                );
            },
        });
    }

    columns.push({
        accessor: 'printLabels',
        Header: 'Print Labels',
        Cell: ({ row }) => {
            if (row.original.progressStatus !== 'RECEIVED') {
                return null;
            }
            return <Button onClick={() => openPrintLabelsModal(parentRow.original.poType, row.original)}>Print</Button>;
        },
    });

    const openPrintLabelsModal = (poType, row) => {
        // for non packaging rows, po map id is "id"
        // for packaging rows, packaging po map id is poMapID
        const poMapId = poType === 'PACKAGING' ? row.poMapID : row.id;
        setPrintLabelModalDetails({ poType, poMapId });
    };
    const closePrintLabelsModal = () => {
        setPrintLabelModalDetails(null);
    };

    let getRows = async () => {
        let response = await qualityApi.getQualityPODetailsV2(poID, parentRow.original.poType === 'PACKAGING');
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
        setEditEnabled(response.data.jalapenoReceivingEditEnabled);
    };

    let getPhotoRows = async () => {
        let response = await procurementApi.getPurchaseOrderDocuments(poID);

        if (response.status === false) {
            setNotification({ text: "Can't access photos: " + response.msg, level: 'error' });
            return;
        }

        setPhotoRows(response.data.rows);
    };

    let photoCols = [
        { accessor: 'id', Header: 'Document ID' },
        { accessor: 'sku', Header: 'SKU' },
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'name', Header: 'Item Name' },
        { accessor: 'notes', Header: 'Photo Label' },
        {
            accessor: 'documentURL',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                if (!row.original.documentURL) {
                    return '';
                }
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                procurementApi.downloadPODocument(
                                    poID,
                                    row.original.id,
                                    row.original.documentURL.replace(/^.*[\\\/]/, '')
                                );
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                procurementApi.removePODocument(poID, row.original.id);
                            }}
                        >
                            Remove
                        </Button>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        getRows();
        getPhotoRows();
        if (parentRow.original.truckID === 'PARTIAL-PO') {
            setMultiTruckPO(true);
        }
    }, []);

    return (
        <div className="batch-table">
            <Notifications options={notification} />
            <ItemDidNotArriveModal
                open={showDidNotArriveModal}
                handleClose={() => {
                    setShowDidNotArriveModal(false);
                    getRows();
                }}
                purchaseOrderId={poID}
                purchaseOrderMap={currentPurchaseOrderMap}
            />
            <QualityReceivingModuleResetModal
                open={showQualityReceivingModuleResetModal}
                handleClose={(moduleName) => {
                    setShowQualityReceivingModuleResetModal(false);
                    getRows();
                    getPhotoRows();
                }}
                setNotification={setNotification}
                purchaseOrderMap={currentPurchaseOrderMap}
            />
            {itemDocumentationModalOpen && (
                <DirectedReceivingItemModal
                    rowData={modalLaunch}
                    setNotification={setNotification}
                    closeModal={() => {
                        setItemDocumentationModalOpen(false);
                        getRows();
                    }}
                    issueCodes={issueCodes}
                    editEnabled={editEnabled}
                    photoRows={photoRows}
                />
            )}
            {receiveLineItemWithPalletsModalOpen && (
                <ReceiveLineItemWithPalletsModal
                    open={receiveLineItemWithPalletsModalOpen}
                    close={() => {
                        setSelectedRow(null);
                        setReceiveLineItemWithPalletsModalOpen(false);
                        getRows();
                    }}
                    setNotification={setNotification}
                    rowData={selectedRow}
                    poID={poID}
                    poType={parentRow.original.poType}
                    setUpdatedPurchaseOrderStatus={setUpdatedPurchaseOrderStatus}
                    internalTransfer={parentRow.original.purchaseOrderType === 'INTERNAL_TRANSFER_ASN'}
                    thirdPartyASN={parentRow.original.isThirdParty}
                />
            )}

            <PrintReceivedPalletAndCaseLabelsModal
                type={printLabelModalDetails?.poType}
                poMapId={printLabelModalDetails?.poMapId}
                onClose={closePrintLabelsModal}
                open={printLabelModalDetails !== null}
            />

            <DataTable
                columns={columns}
                data={rows}
                editable={true}
                saveEdit={async ({ id, field, value }) => {
                    let updates = {
                        key: field,
                        value: value,
                    };
                    let response = await qualityApi.recordQualityReceiving(id, updates);
                    if (response.status === false) {
                        let message = 'Error';
                        if (response) {
                            message += ': ' + response.msg;
                        }
                        setNotification({ text: message, level: 'error' });
                    } else {
                        setNotification({ text: 'Issue recorded!', level: 'success' });
                        getRows();
                    }
                }}
            />
            <DataTable columns={photoCols} data={photoRows} />
        </div>
    );
};

let DirectedReceivablePurchaseOrders = ({
    showReceived = false,
    showPendingActions = false,
    showPendingIssues = false,
}) => {
    const [rows, setRows] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [receiveWithPalletsModalOpen, setReceiveWithPalletsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const user = useContext(UserContext);
    const [issueCodes, setIssueCodes] = useState([]);
    const [truckTempModalOpen, setTruckTempModalOpen] = useState(false);
    const [showModifyPOModal, setShowModifyPOModal] = useState(false);

    const [modalLaunch, setModalLaunch] = useState({});
    const [itemDocumentationModalOpen, setItemDocumentationModalOpen] = useState(false);
    const [photoRows, setPhotoRows] = useState([]);
    const [editEnabled, setEditEnabled] = useState(false);

    let getPhotoRows = async (poID) => {
        let response = await procurementApi.getPurchaseOrderDocuments(poID);

        if (response.status === false) {
            setNotification({ text: "Can't access photos: " + response.msg, level: 'error' });
            return;
        }

        setPhotoRows(response.data.rows);
    };

    let columns = [];
    if (showReceived == true) {
        columns = [
            { accessor: 'id', Header: 'Purchase Order' },
            { accessor: 'truckID', Header: 'Truck ID' },
            { accessor: 'poType', Header: 'Type' },
            { accessor: 'deliveryType', Header: 'Delivery Type' },
            { accessor: 'vendorName', Header: 'Vendor' },
            { accessor: 'shipperName', Header: 'Shipper' },
            { accessor: 'buyer', Header: 'Buyer' },
            {
                accessor: 'status',
                Header: 'Progress Status',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <span
                            style={
                                row.original.status
                                    ? {
                                          backgroundColor: statusColors[row.original.status.replace(' ', '_')],
                                          color: statusTextColors[row.original.status.replace(' ', '_')],
                                      }
                                    : {}
                            }
                        >
                            {row.original.status}
                        </span>
                    );
                },
            },
            { accessor: 'receivedDate', Header: 'Received Date' },
            {
                accessor: 'issueStatus',
                Header: 'Issue Status',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <span style={{ backgroundColor: colors.red, color: 'white' }}>{row.original.issueStatus}</span>
                    );
                },
            },
            {
                accessor: 'download',
                Header: 'Download',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Button
                                id="ShipList"
                                onClick={() => {
                                    if (row.original.poType === 'PACKAGING') {
                                        packagingApi.downloadPackagingPOShipList(row.original.id);
                                    } else {
                                        procurementApi.downloadPOShipList(row.original.id);
                                    }
                                }}
                            >
                                Ship List
                            </Button>
                        </div>
                    );
                },
            },
        ];
    } else {
        columns = [
            { accessor: 'id', Header: 'Purchase Order' },
            { accessor: 'truckID', Header: 'Truck ID' },
            { accessor: 'deliveryDateLocal', Header: 'Scheduled Delivery Date' },
            { accessor: 'poType', Header: 'Type' },
            { accessor: 'deliveryType', Header: 'Delivery Type' },
            { accessor: 'vendorName', Header: 'Vendor' },
            { accessor: 'shipperName', Header: 'Shipper' },
            { accessor: 'buyer', Header: 'Buyer' },
            {
                accessor: 'status',
                Header: 'Progress Status',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <span
                            style={
                                row.original.status
                                    ? {
                                          backgroundColor: statusColors[row.original.status.replace(' ', '_')],
                                          color: statusTextColors[row.original.status.replace(' ', '_')],
                                      }
                                    : {}
                            }
                        >
                            {row.original.status}
                        </span>
                    );
                },
            },
            {
                accessor: 'timeInStatus',
                Header: 'Time in Status',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <span
                            style={
                                row.original.timeInStatus > 90 ? { backgroundColor: colors.red, color: 'white' } : {}
                            }
                        >
                            {row.original.timeInStatus}
                        </span>
                    );
                },
            },

            {
                accessor: 'issueStatus',
                Header: 'Issue Status',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <span style={{ backgroundColor: colors.red, color: 'white' }}>{row.original.issueStatus}</span>
                    );
                },
            },
            {
                accessor: 'download',
                Header: 'Download',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Button
                                id="ShipList"
                                onClick={() => {
                                    if (row.original.poType === 'PACKAGING') {
                                        packagingApi.downloadPackagingPOShipList(row.original.id);
                                    } else {
                                        procurementApi.downloadPOShipList(row.original.id);
                                    }
                                }}
                            >
                                Ship List
                            </Button>
                        </div>
                    );
                },
            },
            {
                accessor: 'modifyPO',
                Header: 'Modify PO',
                Cell: ({ cell: { row } }) => {
                    return (
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Button
                                id="Modify"
                                onClick={async () => {
                                    setSelectedRow(row.original);
                                    setShowModifyPOModal(true);
                                }}
                            >
                                Modify PO
                            </Button>
                        </div>
                    );
                },
            },
        ];
    }

    let getPurchaseOrdersForReceiving = async () => {
        let response;
        response = await receivingApi.getReceivableGroceryAndPackagingPurchaseOrders(user.getFacilityID());

        if (response.status === false) {
            setNotification({ text: 'No POs found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.deliveryDate = moment(row.deliveryDateLocal.date).format('MM/DD/YYYY HH:mm:ss');
                if (row.truckID === 0 && row.fobPoType === 'partial') {
                    row.truckID = 'PARTIAL-PO';
                }
                if (row.truckID === 0 && row.fobPoType !== 'partial') {
                    row.truckID = null;
                }
                return row;
            });
        }

        // Need to add id which will populate in saveEdit
        setRows(response.data.rows);
    };

    let setUpdatedPurchaseOrderStatus = async (poID, newStatus) => {
        let rowsForUpdate = JSON.parse(JSON.stringify(rows));

        rowsForUpdate.forEach((row) => {
            if (row.id === poID) {
                row.status = newStatus;
            }
        });

        setRows(rowsForUpdate);
    };

    let getPurchaseOrdersAlreadyReceived = async () => {
        let response = await receivingApi.getReceivedGroceryAndPackagingPurchaseOrders(user.getFacilityID());

        if (response.status === false) {
            setNotification({ text: 'No POs found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.deliveryDate = moment(row.deliveryDateLocal.date).format('MM/DD/YYYY HH:mm:ss');
                return row;
            });
        }

        // Need to add id which will populate in saveEdit
        setRows(response.data.rows);
    };

    let getPendingIssues = async () => {
        let response = await qualityApi.getPendingIssues(user.getFacilityID());

        if (response.status === false) {
            setNotification({ text: 'No line items found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
        setEditEnabled(response.data.jalapenoReceivingEditEnabled);
    };

    let getQualityIssueCodes = async () => {
        let response = await qualityApi.getQualityIssueCodes();
        if (response.status === false) {
            setNotification({ text: 'No Issue codes found ' + response.msg, level: 'error' });
            setIssueCodes([]);
        }
        setIssueCodes(response.data.issueCodes);
    };

    let showTruckTempModal = (row) => {
        setSelectedRow(row);
        setTruckTempModalOpen(true);
    };

    useEffect(() => {
        if (showPendingActions == true) {
        } else if (showPendingIssues == true) {
            getPendingIssues();
        } else if (showReceived == true) {
            getPurchaseOrdersAlreadyReceived();
        } else {
            getPurchaseOrdersForReceiving();
        }
        getQualityIssueCodes();
    }, []);

    if (showPendingActions == true || showPendingIssues == true) {
        let pendingActionsColumns = [
            { accessor: 'poID', Header: 'Purchase Order ID' },
            { accessor: 'id', Header: 'ID' },
            { accessor: 'itemMasterSKU', Header: 'Item Master SKU' },
            { accessor: 'itemMasterName', Header: 'Name' },
            { accessor: 'vendorName', Header: 'Vendor' },
            {
                accessor: 'progressStatus',
                Header: 'Progress Status',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <span
                            style={
                                row.original.progressStatus
                                    ? {
                                          backgroundColor: statusColors[row.original.progressStatus.replace(' ', '_')],
                                          color: statusTextColors[row.original.progressStatus.replace(' ', '_')],
                                      }
                                    : {}
                            }
                        >
                            {row.original.progressStatus}
                        </span>
                    );
                },
            },
            {
                accessor: 'issueStatus',
                Header: 'Issue Status',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <span style={{ backgroundColor: colors.red, color: 'white' }}>{row.original.issueStatus}</span>
                    );
                },
            },
            {
                accessor: 'timeInStatus',
                Header: 'Time in Status',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <span
                            style={
                                row.original.timeInStatus > 90 ? { backgroundColor: colors.red, color: 'white' } : {}
                            }
                        >
                            {row.original.timeInStatus}
                        </span>
                    );
                },
            },
            { accessor: 'issueTypes', Header: 'Issue Type Status' },
            { accessor: 'qty', Header: 'Expected Cases' },
            { accessor: 'receivedCases', Header: 'Actual Cases' },
            { accessor: 'baseUnitsPerCase', Header: 'Expected Units/Case' },
            { accessor: 'receivedUnitCount', Header: 'Actual Units/Case' },
            { accessor: 'statusChangeAuthUser', Header: 'Auditor' },
            {
                accessor: 'externalIssues',
                Header: 'External Issues',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Button
                                onClick={async () => {
                                    await getPhotoRows(row.original.poID);
                                    setModalLaunch({
                                        poID: row.original.poID,
                                        poMapID: row.original.id,
                                        qualityReceivingData: row.original,
                                        initialPageIdx: 1,
                                    });
                                    setItemDocumentationModalOpen(true);
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                },
            },
            {
                accessor: 'internalIssues',
                Header: 'Internal Issues',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Button
                                onClick={async () => {
                                    await getPhotoRows(row.original.poID);
                                    setModalLaunch({
                                        poID: row.original.poID,
                                        poMapID: row.original.id,
                                        qualityReceivingData: row.original,
                                        initialPageIdx: 2,
                                    });
                                    setItemDocumentationModalOpen(true);
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                },
            },
            {
                accessor: 'auditCounts',
                Header: 'Units Per Case',
                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                    return (
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Button
                                onClick={async () => {
                                    await getPhotoRows(row.original.poID);
                                    setModalLaunch({
                                        poID: row.original.poID,
                                        poMapID: row.original.id,
                                        qualityReceivingData: row.original,
                                        initialPageIdx: 0,
                                    });
                                    setItemDocumentationModalOpen(true);
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                },
            },
        ];

        if (showPendingIssues == true) {
            return (
                <div>
                    <hi>Issue Lines</hi>
                    {itemDocumentationModalOpen && (
                        <DirectedReceivingItemModal
                            rowData={modalLaunch}
                            setNotification={setNotification}
                            closeModal={() => {
                                setItemDocumentationModalOpen(false);
                                getPendingIssues();
                            }}
                            issueCodes={issueCodes}
                            editEnabled={editEnabled}
                            photoRows={photoRows}
                        />
                    )}

                    <Notifications options={notification} />
                    <DataTable columns={pendingActionsColumns} data={rows} expandable={false} />
                </div>
            );
        } else {
            return (
                <div>
                    <hi>Pending Actions</hi>
                    {itemDocumentationModalOpen && (
                        <DirectedReceivingItemModal
                            rowData={modalLaunch}
                            setNotification={setNotification}
                            closeModal={() => {
                                setItemDocumentationModalOpen(false);
                            }}
                            issueCodes={issueCodes}
                            editEnabled={editEnabled}
                            photoRows={photoRows}
                        />
                    )}

                    <Notifications options={notification} />
                    <DataTable columns={pendingActionsColumns} data={rows} expandable={false} />
                </div>
            );
        }
    } else {
        return (
            <div>
                <h1>{showReceived ? 'Completed Purchase Orders' : 'Receivable Purchase Orders'}</h1>
                <Notifications options={notification} />
                <div>
                    {showModifyPOModal && (
                        <ModifyPOModal
                            open={showModifyPOModal}
                            close={() => setShowModifyPOModal(false)}
                            rowData={selectedRow}
                        />
                    )}
                    <DataTable
                        columns={columns}
                        data={rows}
                        expandable={true}
                        ExpansionComponent={
                            <ViewItemsTableV2
                                issueCodes={issueCodes}
                                setUpdatedPurchaseOrderStatus={setUpdatedPurchaseOrderStatus}
                                showReceived={showReceived}
                            />
                        }
                    />
                </div>
            </div>
        );
    }
};

export { DirectedReceivablePurchaseOrders };
