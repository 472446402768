import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { Notifications } from '../../../shared';
import { customerApi as api } from '../../../api/customer';
import { CustomerAccount } from './CustomerAccount';
import { CustomerSearchBar } from './CustomerSearchBar';
import { SearchResults } from './SearchResults';
import { ThirdPartyOrder } from './ThirdPartyOrder';

const CustManage = () => {
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [searchResults, setSearchResults] = useState([]);
    const [searchingCustomers, setSearchingCustomers] = useState(false);
    const [customerID, setCustomerID] = useState('');
    const [v2Plans, setV2Plans] = useState([]);
    const [isThirdParty, setIsThirdParty] = useState(false);
    const [thirdPartySource, setThirdPartySource] = useState('');

    const urlParams = new URLSearchParams(window.location.search);
    const searchType = urlParams.get('searchType');
    const searchValue = urlParams.get('value');

    const getV2Plans = async () => {
        let res = await api.getV2Plans();
        if (!res.status) {
            setNotification({ text: res.msg, level: 'error' });
            return false;
        }

        setV2Plans(res.data.plans);
    };

    const searchCustomers = async ({ searchBy, searchTerm }) => {
        setSearchingCustomers(true);
        setCustomerID(0);
        setSearchResults([]);
        setIsThirdParty(false);
        setThirdPartySource('');

        if (searchBy !== searchType) {
            urlParams.set('searchType', searchBy);
        }

        if (searchTerm !== searchValue) {
            urlParams.set('value', searchTerm);
        }

        const newURL = `${window.location.pathname}?${urlParams}`;
        window.history.replaceState(null, null, newURL);

        let results = await api.searchCustomers(searchTerm, searchBy);
        setSearchingCustomers(false);
        if (!results.status || !results.data) {
            setNotification({ text: 'Error searching customers', level: 'error' });
            return;
        }

        if (results.data.isThirdParty) {
            setIsThirdParty(true);
            setThirdPartySource(results.data.thirdPartySource);
            return;
        }

        if (!results.data.customers) {
            setNotification({ text: 'Error searching customers', level: 'error' });
            return;
        }

        if (results.data.customers.length === 1) {
            setCustomerID(results.data.customers[0].customerID);
            return;
        }
        setSearchResults(results.data.customers);
    };

    useEffect(() => {
        getV2Plans();

        if (searchType && searchValue) {
            searchCustomers({
                searchBy: searchType,
                searchTerm: searchValue,
            });
        }
    }, []);

    return (
        <Paper style={{ minHeight: '80vh', padding: '10px', backgroundColor: '#FFF9EA' }}>
            <Notifications options={notification} />
            <h1>Customer Management Portal</h1>
            <CustomerSearchBar searchCustomers={searchCustomers} searchType={searchType} searchValue={searchValue} />

            {customerID > 0 && (
                <CustomerAccount setNotification={setNotification} customerID={customerID} v2Plans={v2Plans} />
            )}
            {customerID === 0 &&
                (!searchingCustomers ? (
                    isThirdParty ? (
                        <ThirdPartyOrder source={thirdPartySource} searchTerm={searchValue} />
                    ) : (
                        <SearchResults searchResults={searchResults} setCustomerID={setCustomerID} />
                    )
                ) : (
                    <div>Searching</div>
                ))}
        </Paper>
    );
};

export { CustManage };
