import { mmFetchWithResponse } from './mmFetchWithResponse';

let productApi = {
    assignCustomersToPlusMembershipOffer: async (offerID, customerFile, removeCustomers) => {
        let formData = new FormData();
        formData.append('offerID', offerID);
        formData.append('removeCustomers', removeCustomers);
        formData.append('customerFile', customerFile);

        let url = `api/plus-membership-offers-customer-map/v1/customers`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    bulkUploadDriverTipsSettings: async (data) => {
        let formData = new FormData();
        formData.append('driverTipsSettingsFile', data.file);

        let url = `api/product/v1/driverTipsSettings`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    createFeatureFlag: async (item) => {
        let url = `api/product/v1/featureFlag`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    confirmUploadDriverTipsSettings: async (key) => {
        let url = 'api/product/v1/driverTipsSettings/confirmUpload';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    getAssignableFeatureFlags: async () => {
        let url = `api/product/v1/featureFlag/assignable`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDriverTipsSettings: async () => {
        let url = `api/product/v1/driverTipsSettings`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getFeatureFlagCustomers: async () => {
        let url = `api/product/v1/featureFlag/customers`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getFeatureFlags: async () => {
        let url = `api/product/v1/featureFlag`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getFacilityInFeature: async (feature, facilityID) => {
        let url = `api/product/v1/featureFlag/facilityInFeature?feature=${feature}&facilityId=${facilityID}`;
        return await mmFetchWithResponse(url, { method: 'GET' });
    },
    getIsFeatureOn: async (feature) => {
        const url = `api/product/v1/featureFlag/isFeatureOn?feature=${feature}`;
        return await mmFetchWithResponse(url, { method: 'GET' });
    },
    getPlusMembershipOffers: async () => {
        let url = `api/plus-membership-offers/v1/offers`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    createPlusMembershipOffer: async (formData) => {
        let sendParams = {
            credits: formData.creditsValue,
            creditsDurationDays: formData.creditsDurationDays,
            name: formData.name,
        };
        let url = `api/plus-membership-offers/v1/offers`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            processData: false,
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    updateDriverTipsSettings: async (driverTipsSettingsID, updates) => {
        let url = `api/product/v1/driverTipsSettings/${driverTipsSettingsID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(updates),
        });
        return response;
    },
    updateFeatureFlag: async (featureFlagID, updates) => {
        let url = `api/product/v1/featureFlag/${featureFlagID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(updates),
        });
        return response;
    },
    uploadFeatureFlag: async (featureFlag, wipeList, customerFile) => {
        let formData = new FormData();
        formData.append('featureFlagID', featureFlag);
        formData.append('wipeList', wipeList);
        formData.append('customerFile', customerFile);

        let url = `api/product/v1/featureFlag/customers`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    uploadLineItem: async (customerFile) => {
        let formData = new FormData();
        formData.append('customerFile', customerFile);

        let url = `api/product/v1/lineItem/customers`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
};

export { productApi };
