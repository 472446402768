import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { DataTable, FormComponent } from '../../../../shared';
import { FAMAlert } from '../../../shared';
import { partnershipsApi as api } from '../../../../api/partnerships';
import '../orders.css';
import { stateOptions } from '../../../../utils/globalUtils';

const SkuEditModal = ({ row, saveEdit, getData, open, onClose, extSkuOptions }) => {
    const [editing, setEditing] = useState(false);

    const skuCols = [
        { Header: 'Slot', accessor: 'slot' },
        {
            Header: 'Sku',
            accessor: 'sku',
            editable: !row.success,
            editProps: { type: 'autocomplete', options: extSkuOptions },
        },
        {
            Header: 'Quantity',
            accessor: 'quantity',
            editable: !row.success,
            editProps: { type: 'input', inputType: 'number' },
        },
    ];

    if (!row.success) {
        skuCols.push({
            Header: 'Remove Sku',
            Cell: ({ row: skuRow }) => {
                return (
                    <Button
                        disabled={editing}
                        onClick={() => {
                            removeSkuAtIndex(skuRow.original.index);
                        }}
                    >
                        Remove Sku
                    </Button>
                );
            },
        });
    }

    const skuFormFields = [
        {
            label: 'Slot',
            name: 'slot',
            gridValue: 4,
            inputProps: { required: false, type: 'number' },
        },
        {
            label: 'Sku #',
            name: 'sku',
            gridValue: 4,
            inputElement: 'autocomplete',
            inputProps: { required: true, opts: extSkuOptions },
        },
        { label: 'Quantity', name: 'quantity', gridValue: 4, inputProps: { type: 'number', required: true } },
    ];
    const title = row.success ? `Imported skus for order ${row.orderID}` : `Edit skus for row`;

    const addSku = async (formData, resetForm) => {
        setEditing(true);
        let skus = [...row.skus];
        let maxSlot = Math.max(...skus.map((item) => item.slot));
        console.log('max slot', maxSlot, formData.slot);
        skus.push({ slot: formData.slot || maxSlot + 1, sku: formData.sku, quantity: formData.quantity });
        if (skus.length === 0) {
            console.log('cant edit to zero skus');
            setEditing(false);
            return;
        }
        await saveEdit(row.id, { skus: skus });
        getData();
        setEditing(false);
        resetForm();
    };

    const removeSkuAtIndex = async (ind) => {
        setEditing(true);
        let filteredSkus = [...row.skus].filter((item) => item.index !== ind);
        if (filteredSkus.length === 0) {
            console.log('cant edit to zero skus');
            setEditing(false);
            return;
        }
        await saveEdit(row.id, { skus: filteredSkus });
        getData();
        setEditing(false);
    };

    const updateSku = async ({ id, field, value }) => {
        setEditing(true);
        let skus = [...row.skus].map((item) => {
            if (item.id === id) {
                item[field] = value;
            }
            return item;
        });
        if (skus.length === 0) {
            console.log('cant edit to zero skus');
            setEditing(false);
            return;
        }
        await saveEdit(row.id, { skus: skus });
        getData();
        setEditing(false);
    };

    if (!Object.keys(row).length) {
        return null;
    }

    return (
        <>
            <Dialog open={open} fullWidth={true} maxWidth="lg" onClose={onClose}>
                <DialogContent>
                    <DataTable
                        title={title}
                        data={row.skus.map((item) => {
                            item.id = item.index;
                            return item;
                        })}
                        columns={skuCols}
                        editable={!row.success}
                        saveEdit={updateSku}
                    />
                    {!row.success && (
                        <FormComponent
                            formFields={skuFormFields}
                            onSubmit={addSku}
                            button={{ text: 'Add Sku', disabled: editing }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

const ImportCard = ({ item, getData, source, listOptions, allergenOptions, extSkuOptions, taxonomyOptions }) => {
    const [expanded, setExpanded] = useState(false);
    const [rows, setRows] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [editingSkusForRow, setEditingSkusForRow] = useState({});

    const openSkuEditModal = (row) => {
        setEditingSkusForRow(row);
    };

    const columns = [
        { Header: 'Original Row', accessor: 'rowNumber' },
        { Header: 'Error', accessor: 'error' },
        { Header: 'Order ID', accessor: 'orderID' },

        { Header: 'Box Total', accessor: 'boxTotal' },
        { Header: 'List Min', accessor: 'listMin' },
        { Header: 'List Max', accessor: 'listMax' },
        { Header: 'Slots In List', accessor: 'totalSlotsInList' },
        { Header: 'Slots Filled', accessor: 'totalSlotsFilled' },
        { Header: 'Slots Not Filled', accessor: 'slotNumbersNotFilled' },

        {
            Header: 'Lists',
            accessor: 'curatedListInfoIDs',
            editable: (row) => !row.original.success && !row.original.skus.length,
            editProps: { type: 'autocomplete-multiple', options: listOptions },
        },
        {
            Header: 'Skus',
            accessor: 'skus',
            Cell: ({ row }) => {
                if (!row.original.skus.length) {
                    return null;
                }
                return (
                    <>
                        <Button
                            onClick={() => {
                                openSkuEditModal(row.original);
                            }}
                        >
                            View{!row.success && ' and Edit'}
                        </Button>
                    </>
                );
            },
        },
        { Header: 'First Name', accessor: 'firstName', editable: (row) => !row.original.success },
        { Header: 'Last Name', accessor: 'lastName', editable: (row) => !row.original.success },
        { Header: 'Address 1', accessor: 'lineOne', editable: (row) => !row.original.success },
        { Header: 'Address 2', accessor: 'lineTwo', editable: (row) => !row.original.success },
        { Header: 'City', accessor: 'city', editable: (row) => !row.original.success },
        {
            Header: 'State',
            accessor: 'state',
            editable: (row) => !row.original.success,
            width: 200,
            editProps: { type: 'autocomplete', options: stateOptions },
        },
        { Header: 'Zip', accessor: 'zip', editable: (row) => !row.original.success },
        { Header: 'Email', accessor: 'email', editable: (row) => !row.original.success },
        {
            Header: 'Delivery Day',
            accessor: 'day',
            editable: (row) => !row.original.success,
            editProps: {
                type: 'select',
                options: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
            },
        },
        {
            Header: 'Allergens',
            accessor: 'allergens',
            editable: (row) => !row.original.success,
            editProps: {
                type: 'autocomplete-multiple',
                options: allergenOptions,
            },
        },
        {
            Header: 'Dislikes',
            accessor: 'dislikedTaxonomyIDs',
            editable: (row) => !row.original.success,
            editProps: {
                type: 'autocomplete-multiple',
                options: taxonomyOptions,
            },
        },
        { Header: 'Notes', accessor: 'notes', editable: (row) => !row.original.success },
        { Header: 'Phone', accessor: 'phone', editable: (row) => !row.original.success },
    ];

    const editRow = async (id, params) => {
        let result = await api.updateImportedRow(id, source, params);
        if (!result.status) {
            setErrorMessage(result.msg);
            return false;
        }

        return true;
    };

    const getRows = async () => {
        let result = await api.getImportByID(source, item.id);
        if (!result.status) {
            return;
        }
        let formattedRows = result.data.rows.map((item) => {
            item.slotNumbersNotFilled = item.slotNumbersNotFilled.join(', ');
            item.allergens = item.allergens.filter((a) => a !== '');
            // item.skus = item.skus.map(item => item.externalSku).join(", ");
            item.canUserSelectBulkAction = !item.success && !item.retryInProgress;
            if (item.retryInProgress) {
                item.error = 'RETRY IN PROGRESS';
            }
            return item;
        });
        setRows(formattedRows);
        return formattedRows;
    };

    const getRowsAndUpdateEditingSkuRow = async () => {
        let updatedRows = await getRows();
        if (!!editingSkusForRow.id) {
            let updatedRow = updatedRows.find((item) => item.id === editingSkusForRow.id);
            if (!updatedRow) {
                setEditingSkusForRow({});
            }
            setEditingSkusForRow(updatedRow);
        }
    };

    useEffect(() => {
        if (expanded && !rows.length) {
            getRows();
        }
    }, [expanded]);

    const bulkActions = [
        {
            name: 'Retry',
            action: async (ids) => {
                setErrorMessage('');
                setSuccessMessage('');

                const result = await api.retryImportedRows(source, ids, false);
                if (!result.status) {
                    setErrorMessage(result.msg);
                    return;
                }
                setSuccessMessage(
                    `Retrying order creation for ${ids.length} imported rows. Use the refresh button above to see results.`
                );
                getRows();
            },
        },
        {
            name: 'Force Create (Ignore min/max and required slots)',
            action: async (ids) => {
                setErrorMessage('');
                setSuccessMessage('');

                const result = await api.retryImportedRows(source, ids, true);
                if (!result.status) {
                    setErrorMessage(result.msg);
                    return;
                }
                setSuccessMessage(
                    `Retrying order creation, ignoring list min/max and required slots, for ${ids.length} imported rows. Use the refresh button above to see results.`
                );
                getRows();
            },
        },
    ];

    return (
        <div className="curated-list-container">
            <FAMAlert severity="error" message={errorMessage} alignTop={true} />
            <FAMAlert severity="success" message={successMessage} alignTop={true} />

            <SkuEditModal
                saveEdit={editRow}
                getData={getRowsAndUpdateEditingSkuRow}
                row={editingSkusForRow}
                open={Object.keys(editingSkusForRow).length > 0}
                onClose={() => {
                    setEditingSkusForRow({});
                }}
                extSkuOptions={extSkuOptions}
            />
            <div className="orders-header-container">
                <div>
                    <span className="grotesk-bold small-header-text">Import ID</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="grotesk-regular small-header-text">
                        #{item.id} {moment(item.timestamp.date).local().format('MM/DD/YYYY h:mm a')}
                    </span>
                </div>

                <div>
                    <button
                        onClick={() => {
                            getData();
                            getRows();
                        }}
                        className="fam-button fam-button-white grotesk-bold"
                    >
                        Refresh
                    </button>
                </div>
            </div>

            <div className="card-details-container">
                <span className="grotesk-bold">Imported By</span>
                <span className="grotesk-regular">{item.authUser}</span>
                <span className="grotesk-bold">Number of Rows</span>
                <span className="grotesk-regular">{item.numRows}</span>
                <span className="grotesk-bold">Number of Success</span>
                <span className="grotesk-regular">{item.numSuccess}</span>
                <span className="grotesk-bold">Number of Failures</span>
                <span className="grotesk-regular">{item.numFailures}</span>
            </div>

            {expanded && (
                <div className="order-import-results-container">
                    <DataTable
                        selectMultiple={true}
                        tableContainerClass="fam-data-table"
                        data={rows}
                        columns={columns}
                        editable={true}
                        saveEdit={async ({ id, field, value }) => {
                            return editRow(id, { [field]: value });
                        }}
                        bulkActions={bulkActions}
                        csvExport={true}
                    />
                </div>
            )}
            <div className="curated-list-divider"></div>

            <div
                className="fam-warning-expand-button grotesk-regular align-center"
                onClick={() => setExpanded(!expanded)}
            >
                {expanded ? 'Collapse' : 'View Details'}
            </div>
        </div>
    );
};

const PartnershipImports = ({ partnershipSource }) => {
    const [imports, setImports] = useState([]);
    const [listOptions, setListOptions] = useState([]);
    const [allergenOptions, setAllergenOptions] = useState([]);
    const [extSkuOptions, setExtSkuOptions] = useState([]);
    const [taxonomyOptions, setTaxonomyOptions] = useState([]);

    const getImports = async () => {
        let result = await api.getImports(partnershipSource);
        if (!result.status) {
            return;
        }
        setImports(result.data.imports);
    };

    useEffect(() => {
        getImports();
    }, [partnershipSource]);

    useEffect(() => {
        const getListOptions = async () => {
            let result = await api.getSubscriptionOptions(partnershipSource);
            if (!result.status) {
                return;
            }
            setListOptions(result.data.lists);
            setAllergenOptions(result.data.allergens);
            setTaxonomyOptions(result.data.taxonomy);
        };

        const getExtSkuOptions = async () => {
            const res = await api.getItemsWithTaxonomy(partnershipSource);
            if (!res.status) {
                return;
            }

            let extSkuNameMap = {};

            res.data.rows.forEach((row) => {
                row.items.forEach((item) => {
                    extSkuNameMap[item.externalSku] = item.name;
                });
            });
            let opts = Object.keys(extSkuNameMap).map((key) => ({
                value: key,
                text: `Sku ${key}: ${extSkuNameMap[key]}`,
            }));
            setExtSkuOptions(opts);
        };

        getImports();
        getListOptions();
        getExtSkuOptions();
    }, [partnershipSource]);

    return (
        <div>
            {imports.map((item) => (
                <ImportCard
                    source={partnershipSource}
                    key={item.id}
                    item={item}
                    getData={getImports}
                    listOptions={listOptions}
                    allergenOptions={allergenOptions}
                    extSkuOptions={extSkuOptions}
                    taxonomyOptions={taxonomyOptions}
                />
            ))}
        </div>
    );
};

export { PartnershipImports };
