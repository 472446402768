import { mmFetchWithResponse } from './mmFetchWithResponse';

class FBMOrdersV2Api {
    // Temporary base url for testing
    constructor() {
        this.baseUrl = 'api/fbm/v2';
    }

    async getOrders(fbmAccountName, facilityID) {
        if (fbmAccountName == undefined || facilityID == undefined) {
            return { status: false, msg: 'fbmAccountName or facilityID is undefined' };
        }
        const url = `${this.baseUrl}/orders?fbmAccount=${fbmAccountName}&facilityID=${facilityID}`;
        return mmFetchWithResponse(url, { method: 'GET' });
    }

    async getShipments(fbmOrderNum, fbmAccountName) {
        if (fbmOrderNum == undefined || fbmAccountName == undefined) {
            return { status: false, msg: 'fbmOrderNum or fbmAccountName is undefined' };
        }
        // Encode fbmOrderNum to ensure special characters are correctly handled in the URL
        const encodedFbmOrderNum = encodeURIComponent(fbmOrderNum);
        const url = `${this.baseUrl}/orders/shipments?fbmOrderNum=${encodedFbmOrderNum}&fbmAccount=${fbmAccountName}`;
        return mmFetchWithResponse(url, { method: 'GET' });
    }

    async cancelShipment(shipmentId, fbmAccount, reason) {
        const url = `${this.baseUrl}/orders/shipments/cancel`;
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ shipmentId, fbmAccount, reason }),
        });
    }

    async changeShipmentCarrierService(shipmentId, fbmAccount, carrierServiceId, facilityID) {
        const url = `${this.baseUrl}/orders/shipments/changeService`;
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ shipmentId, fbmAccount, carrierServiceId, facilityID }),
        });
    }

    async getCarrierServices(carrierContains, fbmAccount) {
        const url = `${this.baseUrl}/carrierServices?carrierContains=${carrierContains}&fbmAccount=${fbmAccount}`;
        return mmFetchWithResponse(url, {
            method: 'GET',
        });
    }

    async resetShipmentStatus(shipmentId, fbmAccount) {
        const url = `${this.baseUrl}/orders/shipments/resetStatus`;
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ shipmentId, fbmAccount }),
        });
    }
}

export { FBMOrdersV2Api };
