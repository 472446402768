import React, { useState } from 'react';
import {
    makeStyles,
    Box,
    Chip,
    Typography,
    Tooltip
} from '@material-ui/core';

const MAX_VISIBLE_CHIPS = 4;

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
        minHeight: '32px',
        alignItems: 'center'
    },
    chip: {
        margin: theme.spacing(0.25),
        height: '24px'  // Make chips smaller
    },
    moreChip: {
        margin: theme.spacing(0.25),
        height: '24px',
        cursor: 'pointer'
    },
    clearChip: {
        margin: theme.spacing(0.25),
        height: '24px',
        cursor: 'pointer',
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        }
    },
    noFiltersText: {
        color: theme.palette.text.secondary
    }
}));

const FilterChipsContainer = ({
    activeFilters = [], // Array of { filter: string, value: string, deletable: boolean }
    filterConfigs = [], // Array of filter configurations to determine order
    onDelete
}) => {
    const classes = useStyles();
    const [showLess, setShowLess] = useState(false);

    if (!activeFilters.length) {
        return (
            <Box className={classes.container}>
                <Typography variant="body2" className={classes.noFiltersText}>
                    No active filters
                </Typography>
            </Box>
        );
    }

    // Create a map of filter types to their order in filterConfigs
    const filterOrder = filterConfigs.reduce((acc, config, index) => {
        acc[config.type] = index;
        return acc;
    }, {});

    // Sort activeFilters based on the order in filterConfigs
    const sortedFilters = [...activeFilters].sort((a, b) => {
        const orderA = filterOrder[a.filter] ?? Number.MAX_VALUE;
        const orderB = filterOrder[b.filter] ?? Number.MAX_VALUE;
        return orderA - orderB;
    });

    const visibleFilters = showLess ? sortedFilters.slice(0, MAX_VISIBLE_CHIPS) : sortedFilters;
    const remainingCount = sortedFilters.length - MAX_VISIBLE_CHIPS;

    const handleShowMore = () => {
        setShowLess(false);
    };

    const handleShowLess = () => {
        setShowLess(true);
    };

    const handleClearAll = () => {
        // Send all deletable filters at once to onDelete
        const deletableFilters = sortedFilters
            .filter(filter => filter.deletable)
            .map(filter => ({ filter: filter.filter, value: filter.value }));
        
        if (deletableFilters.length > 0) {
            onDelete(deletableFilters);
        }
    };

    return (
        <Box className={classes.container}>
            {visibleFilters.map(({ filter, value, deletable }) => (
                <Chip
                    key={`${value}`}
                    label={`${value}`}
                    onDelete={deletable ? () => onDelete({ filter, value }) : undefined}
                    color="primary"
                    size="small"
                    className={classes.chip}
                />
            ))}
            {showLess && remainingCount > 0 && (
                <Tooltip title={sortedFilters.slice(MAX_VISIBLE_CHIPS).map(f => f.value).join(', ')}>
                    <Chip
                        label={`+${remainingCount} more`}
                        onClick={handleShowMore}
                        color="default"
                        size="small"
                        className={classes.moreChip}
                    />
                </Tooltip>
            )}
            {!showLess && sortedFilters.length > MAX_VISIBLE_CHIPS && (
                <Chip
                    label="Show less"
                    onClick={handleShowLess}
                    color="default"
                    size="small"
                    className={classes.moreChip}
                />
            )}
            {sortedFilters.some(filter => filter.deletable) && (
                <Chip
                    label="Clear"
                    onClick={handleClearAll}
                    size="small"
                    className={classes.clearChip}
                />
            )}
        </Box>
    );
};

export { FilterChipsContainer }; 