import React, { useState, useContext } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { UserContext } from '../../../../contexts/UserContext';
import { SubscriptionForm } from './subscriptionForm';

const CreateSubscription = ({ listOptions, allergenOptions, adminEmailOptions, dislikeOptions }) => {
    const [open, setOpen] = useState(false);
    const { roles } = useContext(UserContext);
    const canCreateSubscriptions = roles.map((role) => role.name).includes('CREATE_FAM_SUBSCRIPTION');

    if (!canCreateSubscriptions) {
        return null;
    }
    return (
        <>
            <button
                onClick={() => {
                    setOpen(true);
                }}
                className="fam-button fam-button-yellow grotesk-bold"
            >
                Create Subscription
            </button>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogContent>
                    <SubscriptionForm
                        listOptions={listOptions}
                        allergenOptions={allergenOptions}
                        adminEmailOptions={adminEmailOptions}
                        dislikeOptions={dislikeOptions}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export { CreateSubscription };
