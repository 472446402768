import React, { useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';

const ExpirationDateForm = ({ formData, setFormField, expiresOnDefault, expirationType }) => {
    useEffect(() => {
        let expirationDatesTmp = [];
        let i = 0;
        while (i < Number(formData.numPallets)) {
            expirationDatesTmp.push(formData.expirationDates[i] || expiresOnDefault);
            i++;
        }

        if (expirationDatesTmp.length > 0 && expirationDatesTmp.length != formData.expirationDates.length) {
            setFormField(expirationDatesTmp);
        }
    }, [formData]);

    let getPalletName = (ind) => {
        let firstLetterInd = Math.floor(ind / 26);
        let firstLetter = String.fromCharCode('A'.charCodeAt() + firstLetterInd);
        let secondLetterInd = ind - 26 * firstLetterInd;
        let secondLetter = String.fromCharCode('A'.charCodeAt() + secondLetterInd);
        return `${firstLetter}${secondLetter}`;
    };

    return (
        <>
            <h4>{expirationType === 'PRODUCTION_DATE' ? 'Production Dates' : 'Expiration Dates'}</h4>
            {formData.expirationDates.map((formField, formFieldIndex) => {
                return (
                    <Grid item xs={12} key={formFieldIndex}>
                        <TextField
                            type="date"
                            style={{ width: '100%', padding: 15 }}
                            label={`Pallet ${getPalletName(formFieldIndex)}`}
                            value={formData.expirationDates[formFieldIndex] || ''}
                            onChange={(event) => {
                                let palletFormDataTmp = JSON.parse(JSON.stringify(formData.expirationDates));
                                palletFormDataTmp[formFieldIndex] = event.target.value;
                                setFormField(palletFormDataTmp);
                            }}
                        />
                    </Grid>
                );
            })}
        </>
    );
};

export { ExpirationDateForm };
