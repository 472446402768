import React, { useState, useEffect, useContext } from 'react';
import { FAMAlert } from '../../../shared/index';
import { partnershipsApi } from '../../../../api/partnerships';
import { UserContext } from '../../../../contexts/UserContext';
import { SubscriptionFailedOrdersDataTable } from './subscriptionFailedOrdersDataTable';

const FailedSubscriptionOrders = ({ listOptions }) => {
    console.log('list options', listOptions);
    const { famAccount, roles } = useContext(UserContext);

    const canEditSubs = roles.map((role) => role.name).includes('EDIT_FAM_SUBSCRIPTIONS');
    const [failedOrders, setFailedOrders] = useState([]);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const getData = async () => {
        const res = await partnershipsApi.getFailedSubscriptionOrders(famAccount.partnershipSource);

        if (!res.status || !res.data) {
            return false;
        }

        setFailedOrders(res.data.rows);

        return true;
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            <FAMAlert severity="success" message={successMessage} alignTop={true} />
            <FAMAlert severity="error" message={errorMessage} alignTop={true} />

            <SubscriptionFailedOrdersDataTable
                failedOrders={failedOrders}
                includeSubColumns={true}
                source={famAccount.partnershipSource}
                getData={getData}
                listOptions={listOptions}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
            />
        </div>
    );
};

export { FailedSubscriptionOrders };
