import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { TabPanel } from '../../../shared';
import { DriverTipsDriverReport } from './driverTipsDriverReport';
import { DriverTipsCostCenterReport } from './driverTipsCostCenterReport';
import { DriverTipsBackChargeReport } from './driverTipsBackChargeReport';

let DriverTipsReports = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="driverReport_Tab" label="Driver" value="0" />
                <Tab id="costCenterReport_Tab" label="Cost Center" value="1" />
                <Tab id="backChargeReport_Tab" label="Back Charge" value="2" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <DriverTipsDriverReport />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <DriverTipsCostCenterReport />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <DriverTipsBackChargeReport />
            </TabPanel>
        </Paper>
    );
};

export { DriverTipsReports };
