import React, { useEffect, useState } from 'react';
import { Notifications } from '../../../../shared';
import printJS from 'print-js';
import { WarehouseLabelApi } from '../../../../api/warehouseLabels';
import { receivingApi } from '../../../../api/receiving';
import { PrintLabelsForLot } from './printPalletAndCaseTagsForLot';

const labelApi = new WarehouseLabelApi();

const PrintReceivedPalletAndCaseLabels = ({ poMapId, type }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [lots, setLots] = useState([]);

    useEffect(() => {
        const getLots = async () => {
            const res = await receivingApi.getLotsFromPoMapId(poMapId, type);
            if (!res.status) {
                setNotification({ text: res.msg, level: 'error' });
                return;
            }

            setLots(res.data.lots);
        };
        getLots();
    }, []);

    const fileReaderCheck = (fr, tmpPDF) => {
        if (tmpPDF instanceof Blob) {
            fr.readAsDataURL(tmpPDF);
        } else {
            setNotification({ text: 'There was an issue printing the pdf', level: 'error' });
        }
    };

    const printCaseLabels = async (lotId, qty) => {
        const result = await labelApi.getCaseLabels({ lotID: lotId, qty });
        if (result) {
            var fr = new FileReader();
            fr.addEventListener(
                'load',
                () => {
                    const tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                    printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                },
                false
            );
            fileReaderCheck(fr, result);
        } else {
            setNotification({ text: 'There was an issue printing the pdf', level: 'error' });
        }
    };

    const printPalletLabels = async (lotId, qty) => {
        const result = await labelApi.getPalletLabelsByLotId({ lotId, qty });
        if (result) {
            var fr = new FileReader();
            fr.addEventListener(
                'load',
                () => {
                    const tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                    printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                },
                false
            );
            fileReaderCheck(fr, result);
        } else {
            setNotification({ text: 'There was an issue printing the pdf', level: 'error' });
        }
    };

    return (
        <div style={{ textAlign: 'center', margin: '10px' }}>
            <Notifications options={notification} />
            {lots.map((lot) => (
                <PrintLabelsForLot lot={lot} printPallets={printPalletLabels} printCases={printCaseLabels} />
            ))}
        </div>
    );
};

export { PrintReceivedPalletAndCaseLabels };
