import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { ViewAllOrders } from './orders/viewAllOrders';
import { ViewOrderDeepDive } from './orders/viewOrderDeepDive';
import { TabPanel, FBMAccountPicker } from '../../../shared';
import { productApi } from '../../../api/product';
import { ViewAllOrdersV2 } from './ordersV2/viewAllOrdersV2';

const FBMViewOrders = () => {
    const [selectedTab, setSelectedTab] = useState('0');
    const [isV2, setIsV2] = useState(false);
    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const source = queryParameters.get('source');
        if (source !== null) {
            setSelectedTab('1');
        }
        setFeatureFlag();
    }, []);

    const setFeatureFlag = async () => {
        const response = await productApi.getIsFeatureOn('FBM_ORDERS_V2');
        if (response.status) {
            if (response.data.isFeatureOn) {
                setIsV2(true);
            }
        }
    };
    return (
        <Paper>
            <FBMAccountPicker />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="viewAllOrders_Tab" label="View All Orders" value="0" />
                <Tab id="viewOrderDeepDive_Tab" label="Order Deep Dive" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                {!isV2 ? <ViewAllOrders /> : <ViewAllOrdersV2 />}
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <ViewOrderDeepDive />
            </TabPanel>
        </Paper>
    );
};

export { FBMViewOrders };
