import React, { useState, useEffect } from 'react';
import { productionApi as api } from '../../../api/production';
import { DataTable, FormComponent, Notifications, TabPanel, UploadCSVAndReviewComponent } from '../../../shared';
import { Dialog, DialogContent, Tabs, Tab } from '@material-ui/core';

const SellableItemMisfitsPlusDiscounts = function () {
    const [year, setYear] = useState('');
    const [week, setWeek] = useState('');
    const [data, setData] = useState([]);
    const [editable, setEditable] = useState(false);
    const [pgForEdit, setPgForEdit] = useState({});

    const [selectedTab, setSelectedTab] = useState('');
    const [notification, setNotification] = useState({ text: null, level: null });

    let getData = async ({ newWeek, newYear }) => {
        let result = await api.getSellableItemMisfitsPlusDiscounts({
            week: newWeek || week,
            year: newYear || year,
        });
        if (!result.status) {
            setNotification({ text: 'Failed to get data for week and year', level: 'error' });
            return;
        }

        setWeek(result.data.week);
        setYear(result.data.year);
        setEditable(result.data.editable);
        setData(result.data.packGroups);
        setSelectedTab('0');
    };

    useEffect(() => {
        getData({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let formFields = [
        {
            name: 'week',
            initialValue: week,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Week',
            inputProps: { required: true, type: 'number', max: '53' },
        },
        {
            name: 'year',
            initialValue: year,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Year',
            inputProps: { required: true, type: 'number', maxLength: '4' },
        },
    ];

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <div>
            <Notifications options={notification} />
            {pgForEdit?.packGroupID > 0 && (
                <UploadModal
                    packGroup={pgForEdit}
                    closeModal={() => {
                        setPgForEdit({});
                        getData({});
                    }}
                    week={week}
                    year={year}
                    getData={getData}
                    setNotification={setNotification}
                />
            )}
            <h2>
                Sellable Item M+ Discounts - Week {week}, {year}
            </h2>
            {week && year && (
                <FormComponent
                    formFields={formFields}
                    button={{ gridValue: 2, text: 'Change Week/Year' }}
                    onSubmit={async (formData) => {
                        getData({ newWeek: formData.week, newYear: formData.year });
                    }}
                />
            )}
            <br />
            <br />
            {week && year && (
                <div>
                    {data.length > 0 && selectedTab ? (
                        <div>
                            <Tabs
                                onChange={handleChange}
                                value={selectedTab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                {data.map((group, index) => (
                                    <Tab
                                        key={group.packGroupID}
                                        label={`Pack Group ${group.packGroupName}`}
                                        value={`${index}`}
                                    />
                                ))}
                            </Tabs>
                            {data.map((group, index) => {
                                return (
                                    <TabPanel selectedTab={selectedTab} key={group.packGroupID} index={`${index}`}>
                                        <div>
                                            {group.items.length > 0 ? (
                                                <SellableItemsTable
                                                    week={week}
                                                    year={year}
                                                    packGroupID={group.packGroupID}
                                                    tableData={group.items}
                                                    editable={editable}
                                                    setNotification={setNotification}
                                                    setPackGroupForEdit={(packGroupID) => {
                                                        const pg = data.find(
                                                            (item) => item.packGroupID === packGroupID
                                                        );
                                                        setPgForEdit(pg);
                                                    }}
                                                />
                                            ) : (
                                                <div>No sellable items found for this pack group.</div>
                                            )}
                                        </div>
                                    </TabPanel>
                                );
                            })}
                        </div>
                    ) : (
                        <div>No sellable item data found for this week.</div>
                    )}
                </div>
            )}
        </div>
    );
};

const SellableItemsTable = function ({
    tableData,
    packGroupID,
    setPackGroupForEdit,
    setNotification,
    week,
    year,
    editable,
    getData,
}) {
    let toolbarActions = [];
    toolbarActions = [
        {
            name: 'Export',
            action: async () => {
                api.getSellableItemDiscountCSV({ week, year, packGroupID });
            },
        },
    ];
    if (editable) {
        toolbarActions.push({
            name: 'Bulk Update',
            action: () => {
                setPackGroupForEdit(packGroupID);
            },
        });
    }

    let columns = [
        { accessor: 'id', Header: 'Sellable Item ID' },
        { accessor: 'externalSku', Header: 'External Sku' },
        { accessor: 'name', Header: 'Name' },
        {
            accessor: 'plusMemberDiscount',
            Header: 'Member Discount Percent Off',
            editable: editable,
            editProps: {
                type: 'input',
                inputType: 'number',
                min: '0',
                max: '99',
                required: true,
            },
        },
    ];

    let onSaveEdit = async ({ value, row }) => {
        let res = await api.updateMisfitsPlusDiscount(row.id, value);
        if (res.status !== true) {
            setNotification({ text: res.msg, level: 'error' });
        }
        return res.status;
    };

    return (
        <div>
            <DataTable
                key={`packGroupItems${packGroupID}`}
                columns={columns}
                data={tableData}
                editable={true}
                toolbarActions={toolbarActions}
                saveEdit={onSaveEdit}
            />
        </div>
    );
};

const DiscountCell = ({ row }) => {
    let green = '#ecfdf0';
    let red = '#fbe9eb';
    let prevValue = row.original.previousValue;
    let newValue = row.original.percentOff;
    if (!!prevValue) {
        if (prevValue === newValue) {
            return (
                <div>
                    <div>{newValue}</div>
                </div>
            );
        } else {
            return (
                <div>
                    <div style={{ backgroundColor: red }}>
                        <s>{prevValue}</s>
                    </div>
                    <div style={{ backgroundColor: green }}>{newValue}</div>
                </div>
            );
        }
    } else {
        if (!newValue) {
            return (
                <div>
                    <div>{newValue}</div>
                </div>
            );
        } else {
            return (
                <div>
                    <div style={{ backgroundColor: green }}>{newValue}</div>
                </div>
            );
        }
    }
};

const UploadModal = ({ packGroup, closeModal }) => {
    const columns = [
        { Header: 'Row', accessor: 'rowNumber' },
        { Header: 'Sellable Item ID', accessor: 'sellableItemID' },
        { Header: 'External sku', accessor: 'externalSku' },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Percent Off', id: 'percentOff', Cell: DiscountCell },
        { Header: 'Errors', accessor: 'errors' },
    ];

    const upload = async (formData) => {
        const res = await api.uploadSellableItemMisfitsPlusDiscounts(formData.file, packGroup.packGroupID);
        if (!res.status) {
            return res;
        }

        /**
         * Sort the results for display -
         * Rows that were uploaded (have a row number) come first and are sorted by row number
         * Rows that were not uploaded and currently have a non-zero percent off (that will be cleared) come next
         * Rows that were not uploaded and currently have a non-zero percent off come last (no changes for these)
         */
        res.data.results = res.data?.results?.sort((a, b) => {
            if (!!a.rowNumber && !!b.rowNumber) {
                return a.rowNumber - b.rowNumber;
            } else if (!!a.rowNumber) {
                return -1;
            } else if (!!b.rowNumber) {
                return 1;
            }

            if (a.previousValue !== 0 && b.previousValue !== 0) {
                return a.previousValue - b.previousValue;
            } else if (a.previousValue !== 0) {
                return -1;
            } else if (b.previousValue !== 0) {
                return 1;
            }

            return 0;
        });

        return res;
    };

    const confirm = async (key) => {
        return api.confirmSellableItemMisfitsPlusDiscounts(key);
    };

    const downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,External Sku,Percent Off';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'misfits_plus_discounts_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <Dialog open={true} onClose={closeModal} fullWidth={true} maxWidth="lg">
            <DialogContent style={{ paddingTop: '0px' }}>
                <div>
                    <UploadCSVAndReviewComponent
                        fileLabel="Item File"
                        columns={columns}
                        title={`Upload Member Discounts - Pack Group ${packGroup.packGroupName}`}
                        subtitle="Skus that are not included in the uploaded csv will have their discount reset to 0%"
                        upload={upload}
                        confirm={confirm}
                        downloadTemplate={downloadTemplate}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export { SellableItemMisfitsPlusDiscounts };
