import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Notifications, DataTable, FormComponent, TabPanel } from '../../../shared';
import { financeApi as api } from '../../../api/finance';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Tabs,
    Tab,
    Grid,
    FormControlLabel,
    Checkbox,
    TextField,
} from '@material-ui/core';

const DriverTipsCostCenterReport = () => {
    let [rows, setRows] = useState([]);
    let [hasTipsOnly, setHasTipsOnly] = useState(false);
    let [reportIsReady, setReportIsReady] = useState(false);
    let [reportButtonText, setReportButtonText] = useState('Loading...');
    const [notification, setNotification] = useState({ text: '', level: '' });

    const handleCheckboxChange = (event) => {
        setHasTipsOnly(event.target.checked);
    };

    const [reportStartDate, setReportStartDate] = React.useState(moment().subtract(14, 'days').format('YYYY-MM-DD'));
    let handleReportStartDateChange = (event) => {
        setReportStartDate(event.target.value);
    };

    const [reportEndDate, setReportEndDate] = React.useState(moment().format('YYYY-MM-DD'));
    let handleReportEndDateChange = (event) => {
        setReportEndDate(event.target.value);
    };

    let handleRunReportClick = (event) => {
        getAndRenderReportData();
    };

    let getAndRenderReportData = async () => {
        setReportIsReady(false);

        setReportButtonText('Loading...');
        setRows([]);

        let response = await api.getDriverTipsCostCenterReport(hasTipsOnly, reportStartDate, reportEndDate);

        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setReportButtonText('Run Report');
            setReportIsReady(true);
            return;
        }

        setRows(response.data.rows);
        setReportButtonText('Run Report');
        setReportIsReady(true);
    };

    useEffect(() => {
        getAndRenderReportData();
    }, []);

    let handleAuditPressed = async () => {
        let response = await api.downloadTipAudit(reportStartDate, reportEndDate);

        if (response.status === false) {
            setNotification({ text: response.msg, level: 'error' });
        }
    };

    let columns = [
        { accessor: 'costCenter', Header: 'Cost Center (Location)' },
        { accessor: 'date', Header: 'Date' },
        { accessor: 'netTip', Header: 'Total Tips (P) (Net)' },
        { accessor: 'totalTips', Header: 'Total Tips (P) (Gross)' },
        { accessor: 'totalTipsRefunded', Header: 'Refunded Tips' },
        { accessor: 'totalHoursWorked', Header: 'Hours Worked' },
        { accessor: 'hourlyRate', Header: 'Tip Rate / hour (P)' },
        { accessor: 'tipsEarned', Header: 'Tips Earned (I) (Net)' },
        { accessor: 'surplusPool', Header: 'Surplus Pool' },
    ];

    return (
        <div>
            <Notifications options={notification} />
            <Grid item md={12}>
                <TextField
                    id="reportStartDate"
                    label="Start Date"
                    type="date"
                    value={reportStartDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={!reportIsReady}
                    onChange={handleReportStartDateChange}
                    style={{ marginRight: '50px' }}
                />

                <TextField
                    id="reportEndDate"
                    label="End Date"
                    type="date"
                    value={reportEndDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={!reportIsReady}
                    onChange={handleReportEndDateChange}
                    style={{ marginRight: '50px' }}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            id="HasTipsOnly_checkbox"
                            onChange={handleCheckboxChange}
                            color="primary"
                            defaultChecked={hasTipsOnly}
                        />
                    }
                    label="Has Tips Only"
                />

                <Button id="runReport" onClick={handleRunReportClick} color="secondary" disabled={!reportIsReady}>
                    {reportButtonText}
                </Button>

                <Button
                    id="downloadAuditButton"
                    onClick={handleAuditPressed}
                    color="secondary"
                    disabled={!reportIsReady}
                >
                    Audit file
                </Button>
            </Grid>
            <DataTable
                columns={columns}
                data={rows}
                csvExport={true}
                csvExportFileName="driver-cost-center"
                csvExportHeadersUseAccessor={true}
            />
        </div>
    );
};

export { DriverTipsCostCenterReport };
