import React, { useState } from 'react';
import {
    makeStyles,
    Box,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    Paper,
    Divider
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1)
    },
    selectedCount: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1)
    },
    checkboxGroup: {
        maxHeight: '300px',
        overflowY: 'auto',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius
    },
    divider: {
        margin: theme.spacing(1, 0)
    }
}));

const MultiCheckFilter = ({ 
    type,
    name,
    deletable,
    options,
    onFilterChange,
    label = 'Select Items'
}) => {
    const classes = useStyles();
    const [selectedItems, setSelectedItems] = useState([]);

    const handleChange = (event) => {
        const { value, checked } = event.target;
        let newSelectedItems;

        if (value === 'all') {
            newSelectedItems = checked ? [...options] : [];
        } else {
            newSelectedItems = checked 
                ? [...selectedItems, value]
                : selectedItems.filter(item => item !== value);
        }
        
        setSelectedItems(newSelectedItems);

        // Create individual chips for each selected item
        const filterData = newSelectedItems.map(item => ({
            type,
            data: [item],
            chipLabel: `${name}: ${item}`,
            deletable: true
        }));

        onFilterChange(filterData);
    };

    const isAllSelected = selectedItems.length === options.length;
    const someSelected = selectedItems.length > 0 && !isAllSelected;

    return (
        <Box className={classes.container}>
            <Typography variant="caption" className={classes.selectedCount}>
                {selectedItems.length} selected
            </Typography>

            <Paper variant="outlined" className={classes.checkboxGroup}>
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAllSelected}
                                    indeterminate={someSelected}
                                    onChange={handleChange}
                                    value="all"
                                />
                            }
                            label="All"
                        />
                        <Divider className={classes.divider} />
                        {options.map((option) => (
                            <FormControlLabel
                                key={option}
                                control={
                                    <Checkbox
                                        checked={selectedItems.includes(option)}
                                        onChange={handleChange}
                                        value={option}
                                    />
                                }
                                label={option}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </Paper>
        </Box>
    );
};

export { MultiCheckFilter }; 