import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { Notifications, DataTable, TabPanel, FormComponent } from '../../../shared/index';
import { partnershipsApi as api } from '../../../api/partnerships';
import { warehouseApi } from '../../../api/warehouse';

const ViewImportedOrderSources = ({ setNotification, facilities }) => {
    const [rows, setRows] = useState([]);

    const getImportedOrderSources = async () => {
        const res = await api.getOrderImportSources();
        if (!res.status) {
            setNotification({ text: 'Failed to get FAM partners', level: 'error' });
            return;
        }
        setRows(res.data.rows);
    };

    useEffect(() => {
        getImportedOrderSources();
    }, []);

    const columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'name', Header: 'Name' },
        { accessor: 'contactName', Header: 'Contact Name', editable: true },
        { accessor: 'email', Header: 'Email', editable: true },
        {
            accessor: 'facilityIDs',
            Header: 'Facilities',
            editable: true,
            editProps: {
                type: 'autocomplete-multiple',
                options: (row) => facilities.filter((fac) => !row.facilityIDs.includes(fac.id) && fac.active),
                multiple: true,
            },
        },
        { accessor: 'active', Header: 'Active', editable: true, type: 'checkbox', editProps: { type: 'checkbox' } },
    ];

    return (
        <div>
            <div>
                Active partners with facilities and an email address will have inventory sent to them on Wednesdays at
                12 pm
            </div>
            <DataTable
                columns={columns}
                data={rows}
                editable={true}
                saveEdit={async ({ id, field, value }) => {
                    const res = await api.updateOrderImportSource(id, { [field]: value });
                    if (!res.status) {
                        setNotification({ level: 'error', text: `Failed to edit: ${res.msg}` });
                        return false;
                    }
                    getImportedOrderSources();
                    return true;
                }}
            />
        </div>
    );
};

const CreateImportedOrderSource = ({ setNotification, facilities }) => {
    const formFields = [
        { name: 'name', label: 'Name', inputElement: 'textField', inputProps: { required: true } },
        { name: 'contactName', label: 'Contact Name', inputElement: 'textField' },
        { name: 'email', inputElement: 'textField', label: 'Email' },
        {
            name: 'facilityIDs',
            label: 'Facilities',
            inputElement: 'autocomplete',
            inputProps: { opts: facilities.filter((item) => item.active), multiple: true },
        },
    ];

    const createOrderSource = async (formData) => {
        const response = await api.createImportedOrderSource(formData);
        if (!response.status) {
            setNotification({ text: response.msg, level: 'error' });
            return;
        }

        setNotification({ text: 'FAM partner created', level: 'success' });
    };

    return (
        <FormComponent
            formFields={formFields}
            onSubmit={async (formData, resetForm) => {
                formData.facilityIDs = formData.facilityIDs.map((item) => item.value);
                createOrderSource(formData);
                resetForm();
            }}
        />
    );
};

const FAMPartners = () => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [selectedTab, setSelectedTab] = useState('0');
    const [facilities, setFacilities] = useState([]);

    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    useEffect(() => {
        async function getFacilities() {
            const res = await warehouseApi.getFacilities();
            if (!res.status || !res.data) {
                return;
            }
            setFacilities(res.data.rows.map((item) => ({ text: item.name, value: item.id, active: item.active })));
        }
        getFacilities();
    }, []);

    return (
        <div>
            <Notifications options={notification} />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab key="viewSources" label="View FAM Partners" value="0" />
                <Tab key="createSource" label="Create FAM Partner" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} key="viewSources" index="0">
                <ViewImportedOrderSources setNotification={setNotification} facilities={facilities} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} key="createSource" index="1">
                <CreateImportedOrderSource setNotification={setNotification} facilities={facilities} />
            </TabPanel>
        </div>
    );
};

export { FAMPartners };
