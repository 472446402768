import React, { useState, useContext } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { ViewV2Discounts } from '../components/viewV2Discounts';
import { CreateV2Discount } from '../components/createV2Discount';
import { TabPanel } from '../../../shared';
import { UserContext } from '../../../contexts/UserContext';

const Discounts = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    const { roles } = useContext(UserContext);
    const canEditDiscounts = roles?.some((role) => role.name.endsWith('CREATE_PROMO_CODE'));

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewDiscounts_Tab" label="View Discounts" value="0" />
                {canEditDiscounts && <Tab id="CreateDiscount_Tab" label="Create Discount" value="1" />}
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewV2Discounts canEditDiscounts={!!canEditDiscounts} />
            </TabPanel>
            {canEditDiscounts && (
                <TabPanel selectedTab={selectedTab} index={'1'}>
                    <CreateV2Discount />
                </TabPanel>
            )}
        </Paper>
    );
};

export { Discounts };
