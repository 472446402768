import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { ViewAutoAssignPackaging } from './viewAutoAssignPackaging';
import { CreateAutoAssignPackaging } from './createAutoAssignPackaging';
import { TabPanel } from '../../../shared';

const AutoAssignPackaging = ({ baseUrl }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewConfig_Tab" label="View Config" value="0" />
                <Tab id="CreateConfig_Tab" label="Create Config" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewAutoAssignPackaging />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateAutoAssignPackaging />
            </TabPanel>
        </Paper>
    );
};

export { AutoAssignPackaging };
