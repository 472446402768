import React, { useContext, useEffect, useRef, useState } from 'react';
import { DataTable, Notifications } from '../../../../shared';
import { ActionsCell } from './ActionsCell';
import { ActionModal } from './ActionModal';
import './locationInventoryV2Styles.css';
import { Button, CircularProgress } from '@material-ui/core';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';
import { UserContext } from '../../../../contexts/UserContext';
import { MoveCasesAndLooseUnitsFromKnownOriginAction } from './actions/moveCasesAndLooseUnitsAction';
import { AdjustCasesAndLooseUnitsAction } from './actions/adjustCasesAndLooseUnitsAction';
import { LotNotInLocationAction } from './actions/lotNotInLocationAction';
import { EntireLocationEmptyAction } from './actions/entireLocationEmptyAction';
import { ViewPalletsModal } from './ViewPalletsModal';
import { EmptyPalletCreator } from './emptyPalletCreator';

const warehouseInventoryV2Api = new WarehouseInventoryV2Api();

const LotTabExpansion = ({ onLinkClicked, lotActive, selectedLotId, onSuccess, parentRow }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [rows, setRows] = useState([]);
    const [viewActions, setViewActions] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [actions, setActions] = useState([]);
    const [locationsLoading, setLocationsLoading] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState(null);
    const [selectedLocationName, setSelectedLocationName] = useState('');
    const { user } = useContext(UserContext);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        getLocationDataForLot();
    }, [selectedLotId]);

    const handleMovePalletSuccess = () => {
        getLocationDataForLot();
    };

    //filter actions based on selectedRow.type
    //TODO more actions filtering for inactive lot?
    useEffect(() => {
        if (selectedRow.type !== 'PREP') {
            setActions(actionDefinitions.filter((action) => action.value !== 'prep'));
        } else {
            setActions(actionDefinitions);
        }
    }, [selectedRow]);

    const showPalletModal = async (row) => {
        setSelectedLocationName(row.locationName);
        setSelectedLocationId(row.locationId);
    };

    const columns = [
        {
            Header: 'Location',
            accessor: 'locationName',
            Cell: ({ row }) => {
                return (
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => handleLocationLinkClick(row.original.locationName)}
                    >
                        {row.original.locationName}
                    </Button>
                );
            },
        },
        {
            Header: 'Pallets',
            accessor: 'pallets',
            Cell: ({ row }) => {
                const numPallets = row.original.pallets;

                return (
                    <Button
                        disabled={!numPallets}
                        onClick={() => {
                            showPalletModal(row.original);
                        }}
                    >
                        {!numPallets ? <span>0</span> : <strong>{numPallets}</strong>}
                    </Button>
                );
            },
        },
        { Header: 'Cases', accessor: 'cases' },
        { Header: 'Loose Units', accessor: 'looseUnits' },
        { Header: 'Total Units', accessor: 'totalUnits' },
        {
            Header: 'Actions',
            Cell: ({ row }) => (
                <ActionsCell row={row} setSelectedRow={setSelectedRow} setViewActions={setViewActions} />
            ),
        },
    ];

    const handleLocationLinkClick = (locationName) => {
        onLinkClicked({ tabIndex: 1, searchBy: 'locationName', searchTerm: locationName });
    };

    const handleModalClose = () => {
        setViewActions(false);
        setSelectedRow({});
    };

    const handleViewPalletModalClose = () => {
        setSelectedLocationId('');
        setSelectedLocationName('');
    };

    const handleSuccess = (message) => {
        onSuccess(message);
    };

    //API call to GET data
    const getLocationDataForLot = async () => {
        if (!locationsLoading && isMounted.current) {
            setLocationsLoading(true);
            const searchValues = [{ searchBy: 'lotId', searchTerm: selectedLotId }];

            const result = await warehouseInventoryV2Api.getLocations(user.facilityID, searchValues);
            if (result.status) {
                setRows(
                    result.data.locations.map((location) => {
                        return {
                            locationId: location.locationId,
                            locationName: location.locationName,
                            active: location.numLots > 0,
                            uniqueSkus: location.numSku,
                            lots: location.numLots,
                            pallets: location.numPallets,
                            cases: location.numCases,
                            looseUnits: location.numLooseUnits,
                            totalUnits: location.numTotalUnits,
                            locInvType: location.inventoryType,
                        };
                    })
                );
            } else {
                setNotification({ text: result.msg, level: 'error' });
            }
            setLocationsLoading(false);
        }
    };

    //Action Definitions
    const actionDefinitions = [
        {
            text: 'Prep',
            value: 'prep',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Prep</span>;
            },
        },
        {
            text: 'Move Cases and Loose Units',
            value: 'moveCaseAndLooseUnits',
            componentJSX: function () {
                return (
                    <MoveCasesAndLooseUnitsFromKnownOriginAction
                        lotId={selectedLotId}
                        locationName={selectedRow?.locationName}
                        locationId={selectedRow?.locationId}
                        locInvType={selectedRow?.locInvType}
                        numPallets={selectedRow?.pallets}
                        onSuccess={onSuccess}
                    />
                );
            },
        },
        {
            text: 'Lot Not in Location',
            value: 'lotNotInLocation',
            componentJSX: function () {
                return (
                    <LotNotInLocationAction
                        lotId={selectedLotId}
                        locationId={selectedRow?.locationId}
                        locInvType={selectedRow?.locInvType}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
        {
            text: 'Entire Location Empty',
            value: 'entireLocationEmpty',
            componentJSX: function () {
                return (
                    <EntireLocationEmptyAction
                        locationId={selectedRow?.locationId}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
        {
            text: 'Create Empty Pallet',
            value: 'createEmptyPallet',
            componentJSX: function () {
                return <EmptyPalletCreator locationId={selectedRow?.locationId} />;
            },
        },
        {
            text: 'Adjust Cases and Loose Units',
            value: 'adjustCasesAndLooseUnits',
            componentJSX: function () {
                return (
                    <AdjustCasesAndLooseUnitsAction
                        locationId={selectedRow?.locationId}
                        lotId={selectedLotId}
                        caseNum={selectedRow?.cases}
                        looseUnitNum={selectedRow?.looseUnits}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                        locInvType={selectedRow?.locInvType}
                        palletNum={selectedRow?.pallets}
                        unitsPerCase={parentRow.original?.unitsPerCase}
                    />
                );
            },
        },
    ];

    return (
        lotActive && (
            <div className="expanded-table-div">
                <Notifications options={notification} />
                {locationsLoading && <CircularProgress color="inherit" size={48} />}
                {!locationsLoading && (
                    <DataTable columns={columns} data={rows} editable={false} tableContainerMaxHeight={600} />
                )}
                <ActionModal
                    title={`Actions for Location ${selectedRow.locationName}`}
                    open={viewActions}
                    row={selectedRow}
                    actions={actions}
                    closeModal={handleModalClose}
                />
                <ViewPalletsModal
                    open={selectedLocationId > 0}
                    closeModal={handleViewPalletModalClose}
                    lotId={selectedLotId}
                    locationName={selectedLocationName}
                    locationId={selectedLocationId}
                    setNotification={setNotification}
                    onMovePalletSuccess={handleMovePalletSuccess}
                />
            </div>
        )
    );
};

export { LotTabExpansion };
