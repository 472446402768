import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { ViewPO } from './viewPurchaseOrder';
import { CreatePO } from './createPurchaseOrder';
import { BulkCreatePurchaseOrders } from './bulkCreatePurchaseOrders';
import { ViewShipper } from './viewShippers';
import { CreateShipper } from './createShipper';
import { TabPanel } from '../../../shared';

const PurchaseOrders = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewPurchaseOrders_Tab" label="View Purchase Orders" value="0" />
                <Tab id="CreatePurchaseOrder_Tab" label="Create Purchase Order" value="1" />
                <Tab id="BulkCreatePurchaseOrders_Tab" label="Bulk Create Purchase Orders" value="2" />
                <Tab id="ViewShippers_Tab" label="View Shippers" value="3" />
                <Tab id="CreateShipper_Tab" label="Create Shipper" value="4" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewPO />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreatePO />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <BulkCreatePurchaseOrders />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'3'}>
                <ViewShipper />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'4'}>
                <CreateShipper />
            </TabPanel>
        </Paper>
    );
};

export { PurchaseOrders };
