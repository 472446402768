import React, { useState, useContext } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { Button, Grid } from '@material-ui/core';
import { Notifications, FormComponent } from '../../../../shared';
import { fbmApi as api } from '../../../../api/fbm';
import { DeepDiveResults } from './deepDiveResults';

const ViewOrderDeepDive = () => {
    const [deepDiveResults, setDeepDiveResults] = useState({});
    const [notification, setNotification] = useState({ text: '', level: '' });
    const { fbmAccount } = useContext(UserContext);
    const [isIngesting, setIsIngesting] = useState(false);

    // Load the page
    const loadData = async (id) => {
        const response = await api.getOrderDetails(id, fbmAccount.fbmAccountName);
        if (response.status === false) {
            setNotification({ text: 'Error retrieving order ' + response.msg, level: 'error' });
            return;
        }

        setDeepDiveResults(response.data.data);

        setNotification({ text: 'Success', level: 'success' });
    };

    const formFields = [
        {
            name: 'lsOrderNum',
            label: `${fbmAccount.companyName} Order Number`,
            inputElement: 'textField',
            gridValue: 8,
        },
    ];

    return (
        <div>
            <h1>{fbmAccount.companyName} - Order Deep Dive</h1>
            <Notifications options={notification} />
            {!!fbmAccount.id && (
                <div>
                    <Grid container>
                        {Object.keys(deepDiveResults).length > 0 && (
                            <Grid item xs={4}>
                                <Button
                                    id={`MarkShippedButton`}
                                    onClick={async () => {
                                        const response = await api.markOrderShipped(
                                            deepDiveResults.shipmentID,
                                            fbmAccount.fbmAccountName
                                        );
                                        if (response.status === false) {
                                            setNotification({
                                                text: 'Error marking shipped ' + response.msg,
                                                level: 'error',
                                            });
                                            return;
                                        }

                                        setNotification({ text: 'Success', level: 'success' });
                                    }}
                                >
                                    Mark Order Shipped
                                </Button>
                            </Grid>
                        )}
                        {Object.keys(deepDiveResults).length > 0 && (
                            <Grid item xs={4}>
                                <Button
                                    id={`IngestButton`}
                                    disabled={isIngesting}
                                    onClick={async () => {
                                        setIsIngesting(true);
                                        const response = await api.ingestSingleOrder(
                                            deepDiveResults.orderNum,
                                            fbmAccount.fbmAccountName
                                        );
                                        setIsIngesting(false);
                                        if (response.status === false) {
                                            setNotification({
                                                text: 'Unable to ingest ' + response.msg,
                                                level: 'error',
                                            });
                                            return;
                                        }

                                        setNotification({ text: 'Success', level: 'success' });
                                    }}
                                >
                                    Ingest
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <br />
                    <FormComponent
                        formFields={formFields}
                        onSubmit={async (formData) => {
                            loadData(formData.lsOrderNum);
                        }}
                    />
                    <br />
                    <br />
                    {Object.keys(deepDiveResults).length > 0 && (
                        <DeepDiveResults companyName={fbmAccount.companyName} deepDiveResults={deepDiveResults} />
                    )}
                </div>
            )}
        </div>
    );
};

export { ViewOrderDeepDive };
