import React, { useState, useEffect, useContext } from 'react';
import { UploadCSVAndReviewComponent } from '../../../shared';
import { productionApi as api } from '../../../api/production';
import { warehouseApi } from '../../../api/warehouse';
import { UserContext } from '../../../contexts/UserContext';

let LSMCell = ({ row, column }) => {
    let green = '#ecfdf0';
    let red = '#fbe9eb';
    let currentValue = row.original.currentSlot;
    if (currentValue && currentValue !== row.original.slot) {
        return (
            <div>
                <div style={{ backgroundColor: red }}>
                    <s>{currentValue}</s>
                </div>
                <div style={{ backgroundColor: green }}>{row.original.slot}</div>
            </div>
        );
    }
    if (!currentValue) {
        return (
            <div>
                <div style={{ backgroundColor: green }}>{row.original.slot}</div>
            </div>
        );
    }
    return row.original.slot;
};

let LineSlotMapUpload = ({ packGroupID, onSuccess }) => {
    const [showDummySlotsButton, setShowDummySlotsButton] = useState(false);
    const user = useContext(UserContext);

    useEffect(() => {
        async function getFacilities() {
            const res = await warehouseApi.getFacilities(true);
            if (!res.status || !res.data) {
                return;
            }
            let facility = res.data.rows.find((item) => item.id === user.getFacilityID());
            if (!!facility) {
                setShowDummySlotsButton(facility.printBatchelorPDF);
            }
        }
        getFacilities();
    }, []);

    let columns = [
        { Header: 'Row', accessor: 'rowNumber' },
        { Header: 'Sellable Item ID', accessor: 'sellableItemID' },
        { Header: 'External sku', accessor: 'externalSku' },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Slot', accessor: 'slot', Cell: LSMCell },
        { Header: 'Errors', accessor: 'errors' },
    ];

    let confirmUpload = async (key) => {
        let res = await api.setLineSlotMap(key);
        return res;
    };

    let upload = async (formData) => {
        let res = await api.uploadLineSlotMap(packGroupID, formData);
        return res;
    };

    let downloadTemplate = async () => {
        api.downloadLineSlotMapCSV(packGroupID);
    };

    let downloadTemplateWithDummyData = async () => {
        api.downloadLineSlotMapCSV(packGroupID, true);
    };

    return (
        <div>
            <UploadCSVAndReviewComponent
                title="Line Slot Map Upload"
                fileLabel="Line Slot Map File"
                columns={columns}
                upload={upload}
                confirm={confirmUpload}
                downloadTemplate={downloadTemplate}
                downloadTemplateText="Download CSV"
                downloadTemplateWithDummyData={showDummySlotsButton ? downloadTemplateWithDummyData : null}
                downloadTemplateWithDummyDataText="Download CSV with dummy slots"
                onSuccess={onSuccess}
            />
        </div>
    );
};

export { LineSlotMapUpload };
