import React, { useEffect, useState } from 'react';

import { FormComponent, Notifications } from '../../../../shared';
import { productApi as api } from '../../../../api/product';

import './AssignCustomersToOffer.css';

const buildFormFields = ({ plusMembershipOfferOptions }) => [
    {
        name: 'plusMembershipOffer',
        inputElement: 'select',
        label: 'Plus Membership Offer',
        gridValue: 12,
        inputProps: { required: true, opts: plusMembershipOfferOptions },
        formControlId: 'plus-membership-offer-select-input',
    },
    {
        name: 'customerFile',
        inputElement: 'file',
        label: 'Upload Customer IDs',
        gridValue: 12,
        inputProps: { required: true },
        formControlId: 'customer-ids-file-input',
    },
    {
        name: 'removeCustomers',
        inputElement: 'checkbox',
        label: 'Remove customers',
        gridValue: 12,
        formControlId: 'remove-customers-checkbox-input',
    },
];

function AssignCustomersToOffer() {
    const [offerOptions, setOfferOptions] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });

    useEffect(() => {
        const fetchPlusMembershipOffers = async () => {
            const response = await api.getPlusMembershipOffers();

            if (response.status === false) {
                setNotification({ text: response.msg, level: 'error' });
            } else {
                const options = [
                    { text: 'Please Select', value: 0 },
                    ...response.data.offers.map((offer) => ({
                        text: offer.name,
                        value: offer.id,
                    })),
                ];
                setOfferOptions(options);
            }
        };

        fetchPlusMembershipOffers();
    }, []);

    const handleSubmit = async (formData, resetForm) => {
        let response = await api.assignCustomersToPlusMembershipOffer(
            formData.plusMembershipOffer,
            formData.customerFile,
            formData.removeCustomers
        );

        if (response.status === false) {
            setNotification({
                text: `Unable to update plus membership offers customer map. ${response.msg}`,
                level: 'error',
            });
            return;
        }

        setNotification({ text: 'Success!', level: 'success' });
        resetForm();
    };

    const formFields = buildFormFields({ plusMembershipOfferOptions: offerOptions });

    return (
        <div className="assign-customers-to-offer-form">
            <Notifications options={notification} />

            <h1 className="form-heading">Assign Customers to Offer</h1>
            <p className="field-description">
                Assign a list of customers to a plus membership offer by selecting the desired offer and uploading a CSV
                containing a single column of customer IDs.
                <br />
                <br />
                Marking the "Remove customers" checkbox will instead remove all customers contained in the CSV from the
                selected offer.
                <br />
                <br />
                For more information, visit the{' '}
                <a
                    href="https://misfitsmarket.atlassian.net/wiki/spaces/TECH/pages/3916595247/Plus+Membership+Offers"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Confluence doc
                </a>
                .
            </p>
            <div className="form-wrapper">
                <FormComponent
                    formFields={formFields}
                    onSubmit={(formData, resetForm) => handleSubmit(formData, resetForm)}
                />
            </div>
        </div>
    );
}

export { AssignCustomersToOffer };
