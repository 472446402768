import React, { useState, useEffect, useContext } from 'react';
import { partnershipsApi } from '../../../../api/partnerships';
import { ItemDetails } from '../../createList/components/itemDropdownDetails';
import '../subscriptions.css';
import { DropdownMultipleCombobox, Input, FAMAlert } from '../../../shared/index';
import { Divider, Dialog, DialogContent } from '@material-ui/core';
import { DataTable } from '../../../../shared/index';
import { UserContext } from '../../../../contexts/UserContext';

const SupplementalItems = ({ taxonomyOptions = [] }) => {
    const { famAccount, roles } = useContext(UserContext);
    const [items, setItems] = useState([]);

    const [externalSkuOptions, setExternalSkuOptions] = useState([]);

    const getSupplementalItems = async () => {
        const res = await partnershipsApi.getSupplementalItemsForPartner(famAccount.partnershipSource);
        if (!res.status) {
            return;
        }
        let tmpItems = {};
        res.data.items.forEach((item) => {
            if (!tmpItems.hasOwnProperty(item.boxNumber)) {
                tmpItems[item.boxNumber] = [];
            }
            tmpItems[item.boxNumber].push(item);
        });
        setItems(tmpItems);
    };

    useEffect(() => {
        async function getItemsInCurrentPackGroups() {
            const res = await partnershipsApi.getItemsWithTaxonomy(famAccount.partnershipSource);
            if (!res.status) {
                return;
            }
            let extSkuMap = {};

            res.data.rows.forEach((row) => {
                row.items.forEach((item) => {
                    if (!extSkuMap[item.externalSku]) {
                        extSkuMap[item.externalSku] = item;
                    }
                });
            });

            setExternalSkuOptions(Object.values(extSkuMap));
        }

        getSupplementalItems();
        getItemsInCurrentPackGroups();
    }, []);

    return (
        <div className="grotesk-regular">
            {Object.keys(items).map((key) => (
                <SupplementalItemBoxNumber
                    key={key}
                    boxNumber={key}
                    items={items[key]}
                    externalSkuOptions={externalSkuOptions}
                    refreshData={getSupplementalItems}
                    taxonomyOptions={taxonomyOptions}
                />
            ))}
            <AddScheduleForm refreshData={getSupplementalItems} />
        </div>
    );
};

const AddScheduleForm = ({ refreshData }) => {
    const { famAccount } = useContext(UserContext);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [boxNumber, setBoxNumber] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async () => {
        setSubmitting(true);

        const res = await partnershipsApi.addSupplementalItemSchedule(famAccount.partnershipSource, {
            boxNumber,
            quantity,
        });
        setSubmitting(false);
        if (!res.status) {
            setErrorMessage(`Failed to add. ${res.msg}`);
        } else {
            setSuccessMessage('Success!');
            setBoxNumber('');
        }
        refreshData();
    };
    return (
        <div style={{ margin: '50px 0px' }}>
            <h4>Add new scheduled supplemental item</h4>
            <FAMAlert severity="success" message={successMessage} />
            <FAMAlert severity="error" message={errorMessage} />

            <div className="fam-add-supplemental-item-row">
                <Input
                    label="Order Number"
                    value={boxNumber}
                    onChange={(value) => {
                        setBoxNumber(value);
                    }}
                    type="number"
                />
                <Input
                    label="Quantity"
                    value={quantity}
                    onChange={(value) => {
                        setQuantity(value);
                    }}
                    type="number"
                />
                <button
                    style={{ marginTop: '10px' }}
                    className="fam-button fam-button-yellow grotesk-bold"
                    disabled={!boxNumber || !quantity || submitting}
                    onClick={handleSubmit}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

const SupplementalItemBoxNumber = ({
    boxNumber,
    items,
    externalSkuOptions,
    refreshData,
    taxonomyOptions = { taxonomyOptions },
}) => {
    return (
        <div style={{ marginBottom: '0px' }}>
            <div className="grotesk-bold" style={{ paddingBottom: '5px' }}>
                Order Number {boxNumber}
            </div>
            {items.map((item, ind) => {
                return (
                    <SupplementalItemOptions
                        key={ind}
                        item={item}
                        taxonomyOptions={taxonomyOptions}
                        externalSkuOptions={externalSkuOptions}
                        refreshData={refreshData}
                    />
                );
            })}
            <Divider style={{ margin: '20px 0px 40px' }} />
        </div>
    );
};

const AddItemsModal = ({
    externalSkuOptions = [],
    taxonomyOptions = [],
    open,
    handleClose,
    item,
    refreshData,
    type,
}) => {
    const { famAccount } = useContext(UserContext);

    const [selectedItemsToAdd, setSelectedItemsToAdd] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [submitting, setSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = () => {
        if (type === 'taxonomy') {
            addTaxonomy();
        } else {
            addItems();
        }
    };

    const addItems = async () => {
        setSubmitting(true);

        const promises = selectedItemsToAdd.map((extSku) =>
            partnershipsApi.addSupplementalItemOption(famAccount.partnershipSource, {
                externalSku: extSku,
                quantity: quantity,
                scheduleID: item.scheduleID,
            })
        );
        const res = await Promise.all(promises);
        if (res.every((item) => item.status)) {
            setSuccessMessage(`Successfully added ${promises.length} items`);
            setSelectedItemsToAdd([]);
            setQuantity(1);
        } else {
            setErrorMessage('Failed to add one or more items');
        }
        setSubmitting(false);
        refreshData();
    };

    const addTaxonomy = async () => {
        setSubmitting(true);

        const promises = selectedItemsToAdd.map((taxonomyID) =>
            partnershipsApi.addSupplementalItemOption(famAccount.partnershipSource, {
                taxonomyID: taxonomyID,
                quantity: quantity,
                scheduleID: item.scheduleID,
            })
        );
        const res = await Promise.all(promises);
        if (res.every((item) => item.status)) {
            setSuccessMessage(`Successfully added ${promises.length} items`);
            setSelectedItemsToAdd([]);
            setQuantity(1);
        } else {
            setErrorMessage('Failed to add one or more categories');
        }
        setSubmitting(false);
        refreshData();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
            <DialogContent style={{ height: '500px' }}>
                <div>
                    <FAMAlert severity="success" message={successMessage} />
                    <FAMAlert severity="error" message={errorMessage} />

                    <h4>
                        Add Additional {type === 'taxonomy' ? 'Taxonomy' : 'Item'} Options to Box {item.boxNumber}
                    </h4>

                    {type === 'externalSku' && (
                        <DropdownMultipleCombobox
                            label="Items"
                            OptionComponent={<ItemDetails />}
                            options={externalSkuOptions
                                .filter((opt) => {
                                    let currentItemsInSlot = item.itemOptions.map((i) => i.externalSku);
                                    return !currentItemsInSlot.includes(opt.externalSku);
                                })
                                .map((item) => ({
                                    value: item.externalSku,
                                    text: item.name,
                                    ...item,
                                }))}
                            value={selectedItemsToAdd}
                            onChange={({ selectedItems }) => {
                                setSelectedItemsToAdd(selectedItems.filter((item) => !!item).map((item) => item.value));
                            }}
                        />
                    )}

                    {type === 'taxonomy' && (
                        <DropdownMultipleCombobox
                            label="Categories"
                            options={taxonomyOptions
                                .filter((opt) => {
                                    let parentExcluded = false;
                                    let taxonomyIDsAlreadySelected = item.taxonomyOptions.map((i) => i.id);
                                    taxonomyIDsAlreadySelected = [...taxonomyIDsAlreadySelected, ...selectedItemsToAdd];
                                    opt.parentIDs.forEach((id) => {
                                        if (
                                            taxonomyIDsAlreadySelected.includes(id) &&
                                            !taxonomyIDsAlreadySelected.includes(opt.value)
                                        ) {
                                            parentExcluded = true;
                                        }
                                    });
                                    return !parentExcluded;
                                })
                                .filter((opt) => {
                                    let currentItemsInSlot = item.taxonomyOptions.map((i) => i.id);
                                    return !currentItemsInSlot.includes(opt.value);
                                })}
                            value={selectedItemsToAdd}
                            onChange={({ selectedItems }) => {
                                setSelectedItemsToAdd(selectedItems.filter((item) => !!item).map((item) => item.value));
                            }}
                        />
                    )}

                    <Input
                        label="Quantity"
                        value={quantity}
                        onChange={(value) => {
                            setQuantity(value);
                        }}
                        type="number"
                    />

                    <button
                        style={{ marginTop: '10px' }}
                        className="fam-button fam-button-yellow grotesk-bold"
                        disabled={submitting || !selectedItemsToAdd.length}
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

const SupplementalItemOptions = ({ item, externalSkuOptions, taxonomyOptions, refreshData }) => {
    const { famAccount } = useContext(UserContext);

    const [itemModalOpen, setItemModalOpen] = useState(false);
    const [taxonomyModalOpen, setTaxonomyModalOpen] = useState(false);
    const [removingItem, setRemovingItem] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleRemove = async (optionID) => {
        setRemovingItem(true);

        const res = await partnershipsApi.removeSupplementalItemOption(famAccount.partnershipSource, optionID);
        setRemovingItem(false);
        if (!res.status) {
            setErrorMessage(`Failed to remove: ${res.msg}`);
            return;
        }
        setSuccessMessage('Success');
        refreshData();
    };

    const handleRemoveSchedule = async (scheduleID) => {
        const res = await partnershipsApi.removeSupplementalItemSchedule(famAccount.partnershipSource, scheduleID);
        if (!res.status) {
            setErrorMessage(`Failed to remove: ${res.msg}`);
            return;
        }
        setSuccessMessage('Success');
        refreshData();
    };

    const itemColumns = [
        { Header: 'Quantity', accessor: 'quantity' },
        { Header: 'Brand', accessor: 'brand' },
        { Header: 'Item Name', accessor: 'name' },
        {
            Header: 'Remove',
            Cell: ({ row }) => {
                return (
                    <button
                        style={{ margin: '10px' }}
                        className="fam-button fam-button-red grotesk-bold"
                        onClick={() => {
                            handleRemove(row.original.optionID);
                        }}
                        disabled={removingItem}
                    >
                        Remove
                    </button>
                );
            },
        },
    ];

    const taxonomyColumns = [
        { Header: 'Category', accessor: 'name' },
        { Header: 'Quantity', accessor: 'quantity' },
        {
            Header: 'Remove',
            Cell: ({ row }) => {
                return (
                    <button
                        style={{ margin: '10px' }}
                        className="fam-button fam-button-red grotesk-bold"
                        onClick={() => {
                            handleRemove(row.original.optionID);
                        }}
                        disabled={removingItem}
                    >
                        Remove
                    </button>
                );
            },
        },
    ];

    return (
        <div className="data-table-hide-toolbar">
            {item.type === 'externalSku' && <div> Add {item.quantity} of the following items: </div>}
            {item.type === 'taxonomy' && <div>Add {item.quantity} item(s) from the following categories: </div>}
            {!item.type && <div>Add {item.quantity} items</div>}

            <FAMAlert severity="success" message={successMessage} />
            <FAMAlert severity="error" message={errorMessage} />

            {item.type === 'externalSku' && (
                <DataTable
                    tableContainerClass="data-table-hide-toolbar"
                    data={item.itemOptions}
                    columns={itemColumns}
                />
            )}
            {item.type === 'taxonomy' && (
                <DataTable
                    tableContainerClass="data-table-hide-toolbar"
                    data={item.taxonomyOptions}
                    columns={taxonomyColumns}
                />
            )}

            {(!item.type || item.type === 'externalSku') && (
                <button
                    style={{ margin: '0px 5px' }}
                    className="fam-button fam-button-yellow grotesk-bold"
                    onClick={() => {
                        setItemModalOpen(true);
                    }}
                >
                    Add Item Options
                </button>
            )}
            {(!item.type || item.type === 'taxonomy') && (
                <button
                    style={{ margin: '0px 5px' }}
                    className="fam-button fam-button-yellow grotesk-bold"
                    onClick={() => {
                        setTaxonomyModalOpen(true);
                    }}
                >
                    Add Taxonomy Options
                </button>
            )}
            {
                <button
                    style={{ margin: '0px 5px' }}
                    className="fam-button grotesk-bold"
                    onClick={() => {
                        const msg = `This will remove all options for order number ${item.boxNumber}. Confirm?`;
                        if (window.confirm(msg)) {
                            handleRemoveSchedule(item.scheduleID);
                        }
                    }}
                >
                    Delete All Order {item.boxNumber} Options
                </button>
            }

            <AddItemsModal
                type="externalSku"
                item={item}
                open={itemModalOpen}
                handleClose={() => {
                    setItemModalOpen(false);
                }}
                externalSkuOptions={externalSkuOptions}
                refreshData={refreshData}
            />

            <AddItemsModal
                type="taxonomy"
                item={item}
                open={taxonomyModalOpen}
                handleClose={() => {
                    setTaxonomyModalOpen(false);
                }}
                taxonomyOptions={taxonomyOptions}
                refreshData={refreshData}
            />
        </div>
    );
};

export { SupplementalItems };
