import React, { useEffect, useState, useContext } from 'react';
import { DataTable } from '../../../../shared';
import { Notifications } from '../../../../shared';
import { Button } from '@material-ui/core';
import { UserContext } from '../../../../contexts/UserContext';
import { CycleCountApi } from '../../../../api/cycleCount';
import { ActionModal } from '../locationInventoryV2Components/ActionModal';
import { UnitDiscrepancyDetailCell } from './cycleCountIssues/unitDiscrepancyDetailCell';
import { getActionDefinitions } from './cycleCountIssues/cycleCountIssueActionDefinitions';
import { MissingPalletDetailCell } from './cycleCountIssues/missingPalletDetailCell';
import { MissingLotDetailCell } from './cycleCountIssues/missingLotDetailCell';
import { PalletMovedToLocationDetailCell } from './cycleCountIssues/palletMovedToLocationDetailCell';
const cycleCountApi = new CycleCountApi();

const LocationIssuesTab = () => {
    const [rows, setRows] = useState([]);
    const [currentIssue, setCurrentIssue] = useState(null);
    const [notification, setNotification] = useState({ text: '', level: '' });
    const user = useContext(UserContext);
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [missingLocationId, setMissingLocationId] = useState(null);

    useEffect(() => {
        getLocationIssues();
    }, []);

    const handleActionClick = (issue) => {
        setActionModalOpen(true);
        setCurrentIssue(issue);
    };

    const getLocationIssues = async () => {
        const res = await cycleCountApi.getLocationIssues(user.getFacilityID());
        if (!res.status) {
            setNotification({ text: res.msg, level: 'error' });
            return;
        }

        setMissingLocationId(res.data.missingLocationId);
        setRows(res.data.results);
    };

    const handleCloseModal = () => {
        setCurrentIssue(null);
        setActionModalOpen(false);
    };

    const handleSuccess = () => {
        handleCloseModal();
        getLocationIssues();
        setNotification({ text: 'Issue resolved', level: 'success' });
    };

    const handleError = (message) => {
        setNotification({ text: message, level: 'error' });
    };

    const columns = [
        { Header: 'Time', accessor: 'timestamp' },
        { Header: 'User', accessor: 'authUser' },
        { Header: 'Issue', accessor: 'issueType' },
        { Header: 'Location', accessor: 'locationName' },
        {
            Header: 'Details',
            Cell: ({ row }) => {
                if (
                    (row.original.issueType === 'Unit Discrepancy' || row.original.issueType === 'Additional Sku') &&
                    row.original.expectedResults?.length === 1
                ) {
                    return <UnitDiscrepancyDetailCell row={row.original} />;
                } else if (row.original.issueType === 'Missing Pallet' || row.original.issueType === 'Pallet Empty') {
                    return <MissingPalletDetailCell row={row.original} />;
                } else if (row.original.issueType === 'Missing Lot') {
                    return <MissingLotDetailCell row={row.original} />;
                } else if (row.original.issueType === 'Moved Pallet To Location') {
                    return <PalletMovedToLocationDetailCell row={row.original} />;
                }
                return <div>{row.original.details}</div>;
            },
        },
        {
            Header: 'Actions',
            Cell: ({ row }) => {
                return (
                    <Button
                        style={{ margin: '5px', textTransform: 'none' }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => handleActionClick(row.original)}
                    >
                        Actions
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <Notifications options={notification} />
            <DataTable
                csvExport={true}
                csvExportFileName="cycle_count_issues"
                columns={columns}
                data={rows}
                editable={false}
                tableContainerMaxHeight={800}
            />
            <ActionModal
                actions={getActionDefinitions(
                    currentIssue,
                    handleCloseModal,
                    handleSuccess,
                    missingLocationId,
                    handleError
                )}
                closeModal={() => {
                    setActionModalOpen(false);
                }}
                title="Resolve Cycle Count Issue"
                open={actionModalOpen}
                row={currentIssue}
            />
        </>
    );
};

export { LocationIssuesTab };
