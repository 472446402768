import React, { useState, useEffect } from 'react';
import { Nav, Expandable } from '../../shared';
import { partnershipsApi } from '../../../api/partnerships';

const FAQs = () => {
    const [allergenString, setAllergenString] = useState('');
    const [lastUpdated, setLastUpdated] = useState('');

    useEffect(() => {
        const getFaQs = async () => {
            const resp = await partnershipsApi.getFAQs();
            if (!resp.status) {
                return;
            }
            let allergens = resp.data.allergens;
            allergens = allergens.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
            const lastAllergen = allergens.pop();
            const allergenStr = allergens.join(', ') + ', and ' + lastAllergen;

            setAllergenString(allergenStr);
            setLastUpdated(resp.data.lastZipMapChange);
        };
        getFaQs();
    }, []);

    return (
        <div className="page-container">
            <Nav />
            <div>
                <span className="grotesk-bold header-text">Frequently Asked Questions</span>
                <Expandable
                    header="What allergens do you support?"
                    content={`${allergenString}.
                        Please note that we will only support these exact allergens, which appear in the allergens field of the product. 
                        We will not support excluding items listed in cross allergens.`}
                />

                <Expandable
                    header="What is your delivery schedule?"
                    content={<DeliverySchedule lastUpdated={lastUpdated} />}
                />
            </div>
        </div>
    );
};

const DeliverySchedule = ({ lastUpdated }) => {
    const downloadDeliveryScheduleCSV = () => {
        partnershipsApi.getDeliveryScheduleCSV();
    };

    return (
        <div>
            Download a csv of our delivery options for every supported zip code
            <span className="clickable" onClick={downloadDeliveryScheduleCSV}>
                {' '}
                <a>
                    <strong>here</strong>
                </a>
            </span>
            . The delivery schedule can change, so you should reference the latest version of this file. This data was
            last updated on {lastUpdated}. Upload times in csv are all EST.
        </div>
    );
};

export { FAQs };
