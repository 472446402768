import React, { useContext, useState } from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { TabPanel } from '../../../shared';
import { LocationAccuracyTab } from './cycleCount/locationAccuracyTab';
import { LocationIssuesTab } from './cycleCount/locationIssuesTab';
import { SkuCycleCountTab } from './cycleCount/skuCycleCountTab';
import { UserContext } from '../../../contexts/UserContext';

const CycleCount = () => {
    const [selectedTab, setSelectedTab] = useState('0');
    const { user } = useContext(UserContext);

    const handleTabChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper style={{ minHeight: '80vh' }}>
            <Tabs onChange={handleTabChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="Location_Accuracy_Tab" label="Location Accuracy" value="0" />
                {user.cycleCountManager && <Tab id="Location_Issues_Tab" label="Issue Locations" value="1" />}
                <Tab id="Sku_Cycle_Count_Tab" label="Sku Cycle Count" value="2" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <LocationAccuracyTab />
            </TabPanel>
            {user.cycleCountManager && (
                <TabPanel selectedTab={selectedTab} index={'1'}>
                    <LocationIssuesTab />
                </TabPanel>
            )}
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <SkuCycleCountTab />
            </TabPanel>
        </Paper>
    );
};

export { CycleCount };
