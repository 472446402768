import React, { useState } from 'react';
import { Tabs, Tab, Paper, Typography, Box } from '@material-ui/core';
import { ViewEmployeeBox } from '../components/viewEmployeeBox';
import { CreateEmployeeBox } from '../components/createEmployeeBox';
import { TabPanel } from '../../../shared';

const EmployeeBox = ({ baseUrl }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="View employee box" value="0" />
                <Tab label="Create employee box" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewEmployeeBox />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateEmployeeBox />
            </TabPanel>
        </Paper>
    );
};

export { EmployeeBox };
