import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { ViewInboundTrucks, POsNotOnTrucks } from './viewInboundTrucks';
import { CreateInboundTruck } from './createInboundTruck';
import { CreateInboundCarrier } from './createInboundCarrier';
import { InboundTrucksSchedule } from './inboundTrucksSchedule';
import { TabPanel } from '../../../shared';

const InboundTrucks = ({ baseUrl }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewTrucks_Tab" label="Trucks Booked" value="0" />
                <Tab id="CreateTruck_Tab" label="Create Truck" value="1" />
                <Tab id="CreateCarrier_Tab" label="Create Carrier" value="2" />
                <Tab id="POsNotOnTruck_Tab" label="POs Not on Trucks" value="3" />
                <Tab id="Schedule" label="Schedule" value="4" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewInboundTrucks />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateInboundTruck />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <CreateInboundCarrier />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'3'}>
                <POsNotOnTrucks />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'4'}>
                <InboundTrucksSchedule />
            </TabPanel>
        </Paper>
    );
};

export { InboundTrucks };
