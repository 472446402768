import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Notifications, DataTable, WholesaleAccountPicker } from '../../../shared';
import { goPuffApi } from '../../../api/goPuff';
import { doorDashApi } from '../../../api/doorDash';
import { Button, RadioGroup, FormControlLabel, Radio, CircularProgress } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';
import moment from 'moment';

let ViewGoPuffBatches = () => {
    let [printableRows, setPrintableRows] = useState([]);
    let [printedRows, setPrintedRows] = useState([]);
    let [allocationRows, setAllocationRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const [printableRowsGroupBy, setPrintableRowsGroupBy] = useState('lineHaul');
    const [lineHaulGroupedRows, setLineHaulGroupedRows] = useState([]);
    const [gpMarketGroupedRows, setGpMarketGroupedRows] = useState([]);
    const user = useContext(UserContext);
    const { wholesaleAccount } = useContext(UserContext);
    let [api, setApi] = useState(wholesaleAccount.fbmAccountName === 'doordash' ? doorDashApi : goPuffApi);
    let [accountAbbr, setAccountAbbr] = useState(wholesaleAccount.fbmAccountName === 'doordash' ? 'DD' : 'GP');

    let getPrintableRows = async () => {
        let response = await api.getPrintableBatches(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setPrintableRows([]);
            return;
        }

        setPrintableRows(response.data.rows.lineHaulGroupedArr ? response.data.rows.lineHaulGroupedArr : []);
        setLineHaulGroupedRows(response.data.rows.lineHaulGroupedArr ? response.data.rows.lineHaulGroupedArr : []);
        setGpMarketGroupedRows(
            response.data.rows.gpPackMarketGroupedArr ? response.data.rows.gpPackMarketGroupedArr : []
        );
    };

    let getPrintedRows = async () => {
        let response = await api.getPrintedBatches(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setPrintableRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.createdAt = moment(row.createdAt.date).format('MM/DD/YYYY HH:mm:ss');
                return row;
            });
        }

        setPrintedRows(response.data.rows ? response.data.rows : []);
    };

    let getAllocationRows = async () => {
        let response = await api.getAllocationRollup(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setPrintableRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.deliveryDate = moment(row.deliveryDate.date).format('MM/DD/YYYY');
                return row;
            });
        }

        setAllocationRows(response.data.rows ? response.data.rows : []);
    };

    useEffect(() => {
        if (wholesaleAccount.fbmAccountName === 'doordash') {
            setApi(doorDashApi);
            setAccountAbbr('DD');
        } else {
            setApi(goPuffApi);
            setAccountAbbr('GP');
        }
    }, [wholesaleAccount]);

    useEffect(() => {
        getPrintableRows();
        getPrintedRows();
        getAllocationRows();
    }, [api]);

    const togglePrintableRows = (e) => {
        setPrintableRowsGroupBy(e.target.value);
        if (e.target.value === 'gpMarket') {
            setPrintableRows(gpMarketGroupedRows);
        } else {
            setPrintableRows(lineHaulGroupedRows);
        }
    };

    let allocationColumns = [
        { accessor: 'packingFC', Header: 'Packing FC' },
        { accessor: 'deliveryDate', Header: 'Delivery Date' },
        { accessor: 'numShipments', Header: 'Num Shipments' },
        {
            accessor: 'download',
            Header: 'Download Template',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Download`}
                        onClick={async () => {
                            api.downloadAllocationCsv(user.getFacilityID(), row.original.deliveryDate);
                        }}
                    >
                        Download Template
                    </Button>
                );
            },
        },
    ];

    let printableColumns = [
        { accessor: 'lineHaulName', Header: 'Line Haul', hide: printableRowsGroupBy === 'gpMarket' },
        { accessor: 'gpPackMarket', Header: `${accountAbbr} Pack Market`, hide: printableRowsGroupBy === 'lineHaul' },
        { accessor: 'numMFCs', Header: 'Num MFCs' },
        { accessor: 'deliveryDate', Header: 'Delivery Date' },
        {
            accessor: 'print',
            Header: 'Print',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                const [isLoading, setIsLoading] = useState(false);
                return (
                    <Button
                        id={`Print`}
                        disabled={isLoading}
                        onClick={async (e) => {
                            e.stopPropagation();
                            setIsLoading(true);
                            let value = printableRowsGroupBy === 'gpMarket' 
                                ? row.original.gpPackMarket 
                                : row.original.id;

                            await api.printBatch(
                                user.getFacilityID(),
                                printableRowsGroupBy,
                                value,
                                row.original.deliveryDate
                            );
                            getPrintableRows();
                            setIsLoading(false);
                        }}
                    >
                        {isLoading ? <CircularProgress size={24} /> : 'Print'}
                    </Button>
                );
            },
        },
    ];

    let printedColumns = [
        { accessor: 'batchID', Header: 'Batch ID' },
        { accessor: 'createdAt', Header: 'Printed On' },
        { accessor: 'numItems', Header: 'Num Shipments' },
        { accessor: 'creator', Header: 'Printed By' },
        {
            accessor: 'download',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                const [isLoading, setIsLoading] = useState(false);
                return (
                    <Button
                        id={`Download`}
                        disabled={isLoading}
                        onClick={async () => {
                            setIsLoading(true);
                            await api.reprintBatch(row.original.batchID);
                            setIsLoading(false);
                        }}
                    >
                        {isLoading ? <CircularProgress size={24} /> : 'Download PDF'}
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <WholesaleAccountPicker />
            {!!wholesaleAccount.id && (
                <>
                    <h1>{wholesaleAccount.companyName} Batches</h1>
                    <Notifications options={notification} />
                    <div>
                        <h2>Available for Allocation Adjustment</h2>
                        <Link to={'/gopuff/adjustments-upload'}>Upload Adjustments</Link>
                        <DataTable columns={allocationColumns} data={allocationRows} />
                        <h2>Available to Print</h2>
                        <div>
                            <RadioGroup
                                style={{ width: 'auto' }}
                                defaultValue={'lineHaul'}
                                value={printableRowsGroupBy}
                                onChange={togglePrintableRows}
                                row
                            >
                                <FormControlLabel value={'lineHaul'} control={<Radio />} label="Group By Line Haul" />
                                <FormControlLabel
                                    value={'gpMarket'}
                                    control={<Radio />}
                                    label={`Group By ${accountAbbr} Pack Market`}
                                />
                            </RadioGroup>
                        </div>
                        <DataTable
                            columns={printableColumns}
                            data={printableRows}
                            expandable={true}
                            ExpansionComponent={
                                <ViewLineItems
                                    setNotification={setNotification}
                                    printableRowsGroupBy={printableRowsGroupBy}
                                    wholesaleAccount={wholesaleAccount}
                                    api={api}
                                />
                            }
                        />
                        <h2>Printed</h2>
                        <DataTable columns={printedColumns} data={printedRows} />
                    </div>
                </>
            )}
        </div>
    );
};

const ViewLineItems = ({ parentRow, setNotification, printableRowsGroupBy, wholesaleAccount, api }) => {
    const [lineData, setLineData] = useState([]);
    const [searchVal, setSearchVal] = useState(
        printableRowsGroupBy === 'lineHaul' ? parentRow.original.id : parentRow.original.gpPackMarket
    );
    const user = useContext(UserContext);

    const getLineData = async () => {
        let res = await api.getPrintableBatchesDetails(
            printableRowsGroupBy,
            searchVal,
            parentRow.original.deliveryDate
        );
        if (res.status === false) {
            setNotification({ text: 'No printable batches Micro FC shipments found ' + res.msg, level: 'error' });
            setLineData([]);
            return;
        }

        setLineData(res.data.rows ? res.data.rows : []);
    };

    useEffect(() => {
        if (!!wholesaleAccount.id) {
            getLineData();
        }
    }, [wholesaleAccount]);
    let columns = [
        { accessor: 'microFC', Header: 'Micro FC' },
        { accessor: 'poNum', Header: 'PO ID' },
        { accessor: 'numShipments', Header: 'Num Shipments' },
        {
            accessor: 'print',
            Header: 'Print',
            Cell: ({ cell: { value: initialValue }, row }) => {
                return (
                    <Button
                        id={`Print`}
                        onClick={async () => {
                            await api.printBatch(
                                user.getFacilityID(),
                                'microFC',
                                row.original.microFC,
                                parentRow.original.deliveryDate
                            );

                            getLineData();
                        }}
                    >
                        Print
                    </Button>
                );
            },
        },
    ];

    return <DataTable columns={columns} data={lineData} />;
};

export { ViewGoPuffBatches };
