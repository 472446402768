import React, { useState } from 'react';
import { InputLabel } from '@material-ui/core';
import { DataTable } from '../../../../shared';
import { RefundModalLineItems } from '../RefundModalLineItems';
import { OrderDetailsModal } from './OrderDetailsModal';
import { CancelShipmentModal } from './CancelShipmentModal';
import { CancelModal } from './CancelModal';

const Orders = ({ classes, orders, getSubscription, setNotification, getOrders }) => {
    const [orderDetailsModalOpen, setOrderDetailsModalOpen] = useState(false);
    const [rowInfo, setRowInfo] = useState(null);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [cancelShipmentModalOpen, setCancelShipmentModalOpen] = useState(false);
    const [selectedShipmentID, setSelectedShipmentID] = useState(null);
    const [selectedChargeID, setSelectedChargeID] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [refundModalLineItemsOpen, setRefundModalLineItemsOpen] = useState(false);

    let columns = [
        {
            Header: 'Order #',
            Cell: ({ row }) => {
                return (
                    <div>
                        <span
                            onClick={() => {
                                setSelectedOrder(row.original.shopOrderNum);
                                setOrderDetailsModalOpen(true);
                            }}
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        >
                            {row.original.shopOrderNum}
                        </span>
                    </div>
                );
            },
        },
        { Header: 'Date', accessor: 'createdOn' },
        { Header: 'Source', accessor: 'source' },
        { Header: 'Status', accessor: 'shippingStatus' },
        { Header: 'Charge Status', accessor: 'charge.status' },
        {
            Header: 'Customization Status',
            Cell: ({ row }) => {
                const status = row.original.charge.customizationStatus ?? 'FULLY_CUSTOMIZED';

                return <div>{status}</div>;
            },
        },
        { Header: 'Facility', accessor: 'facility' },
        {
            Header: 'Refund',
            Cell: ({ row }) => {
                if (row.original.charge.status === 'HOLD') {
                    return null;
                }

                if (row.original.refundLink != null && row.original.refundLink.trim().length !== 0) {
                    return (
                        <div>
                            <a href={row.original.refundLink}>Refund</a>
                        </div>
                    );
                }

                return (
                    <div>
                        <span
                            onClick={() => {
                                setRowInfo(row.original);
                                setSelectedOrder(row.original.shopOrderNum);
                                setRefundModalLineItemsOpen(true);
                            }}
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        >
                            Refund
                        </span>
                    </div>
                );
            },
        },
        {
            Header: 'Cancel Order',
            Cell: ({ row }) => {
                if (!row.original.canCancel && !row.original.canCancelHoldCharge) {
                    return null;
                }

                return (
                    <div>
                        <span
                            onClick={() => {
                                setRowInfo(row.original);
                                setSelectedOrder(row.original.shopOrderNum);
                                setCancelModalOpen(true);
                            }}
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        >
                            CANCEL
                        </span>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <OrderDetailsModal
                orderNumber={selectedOrder}
                open={orderDetailsModalOpen}
                setOpen={setOrderDetailsModalOpen}
                setSelectedShipmentID={setSelectedShipmentID}
                setSelectedChargeID={setSelectedChargeID}
                setCancelShipmentModalOpen={setCancelShipmentModalOpen}
                setNotification={setNotification}
            />
            <RefundModalLineItems
                getSubscription={getSubscription}
                getOrders={getOrders}
                setNotification={setNotification}
                orderNumber={selectedOrder}
                rowInfo={rowInfo}
                open={refundModalLineItemsOpen}
                setOpen={setRefundModalLineItemsOpen}
            />
            <InputLabel className={classes.inputLabel}>Previous Orders</InputLabel>
            <DataTable columns={columns} data={orders} />
            <CancelModal
                setOpen={setCancelModalOpen}
                open={cancelModalOpen}
                rowInfo={rowInfo}
                getSubscription={getSubscription}
                getOrders={getOrders}
                setNotification={setNotification}
            />
            <CancelShipmentModal
                setOpen={setCancelShipmentModalOpen}
                open={cancelShipmentModalOpen}
                getSubscription={getSubscription}
                getOrders={getOrders}
                setNotification={setNotification}
                selectedShipmentID={selectedShipmentID}
                selectedChargeID={selectedChargeID}
            />
        </>
    );
};

export { Orders };
