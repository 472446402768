import React, { useState, useEffect } from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { Notifications, DataTable, TabPanel, UploadCSVAndReviewComponent } from '../../../shared';
import { logisticsApi as api } from '../../../api/logistics';
import moment from 'moment';

let ShippingRateUploadCSV = ({ onSuccess }) => {
    let columns = [
        { Header: 'Row', accessor: 'rowNumber' },
        { Header: 'Zip Code', accessor: 'zipCode' },
        { Header: 'Old Rate', accessor: 'prevValue' },
        { Header: 'Shipping Rate', accessor: 'shippingRate' },
        { Header: 'Errors', accessor: 'errors' },
        { Header: 'Warnings', accessor: 'warnings' },
    ];

    const confirm = async (key) => {
        const res = await api.confirmShippingRates(key);
        return res;
    };

    const upload = async (formData) => {
        const res = await api.uploadShippingRates(formData);
        return res;
    };

    const downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,Zip Code,Shipping Rate,';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'shipping_rate_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <div>
            <UploadCSVAndReviewComponent
                fileLabel="Shipping Rate File"
                title="Upload Shipping Rates"
                columns={columns}
                upload={upload}
                confirm={confirm}
                downloadTemplate={downloadTemplate}
                exportUploadResults={true}
            />
        </div>
    );
};

const ViewCurrentRates = () => {
    const [rates, setRates] = useState([]);
    const [notification, setNotification] = useState({ text: '', level: '' });

    const getRates = async () => {
        const res = await api.getCurrentShippingRates();
        if (!res.status) {
            setNotification({ text: 'Failed to get current rates', level: 'error' });
            return;
        }

        setRates(
            res.data.rates.map((item) => {
                item.uploadDate = item.uploadTimestamp ? moment(item.uploadTimestamp.date).format('MM/DD/YYYY') : null;
                return item;
            })
        );
    };

    useEffect(() => {
        getRates();
    }, []);

    const columns = [
        { Header: 'Zip Code', accessor: 'zipCode' },
        { Header: 'Shipping Rate', accessor: 'shippingRate' },
        { Header: 'Updated On', accessor: 'uploadDate' },
    ];

    return (
        <div>
            <Notifications options={notification} />

            <DataTable columns={columns} data={rates} csvExport={true} />
        </div>
    );
};

const ShippingRateUpload = () => {
    const [selectedTab, setSelectedTab] = useState('0');

    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="Upload Shipping Rates" value="0" />
                <Tab label="View Shipping Rates" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ShippingRateUploadCSV />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <ViewCurrentRates />
            </TabPanel>
        </Paper>
    );
};

export { ShippingRateUpload };
