import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { Notifications } from '../../../../../shared';
import { UserContext } from '../../../../../contexts/UserContext';
import { warehouseApi } from '../../../../../api/warehouse';
import { WarehouseInventoryV2Api } from '../../../../../api/warehouseInventoryV2';
import { Button, Typography, TextField, Grid, Box } from '@material-ui/core';
import { SearchableSelect } from '../../../../../shared/components/SearchableSelect';
import { EmptyPalletCreator } from '../emptyPalletCreator';

const warehouseApiV2 = new WarehouseInventoryV2Api();

const MoveCasesAndLooseUnitsFromKnownOriginAction = ({
    lotId,
    locationName,
    locationId,
    locInvType,
    onSuccess,
    fromPalletId = null,
    numPallets = 0,
}) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const { user } = useContext(UserContext);
    const [toLocations, setToLocations] = useState([]);
    const [fromPallets, setFromPallets] = useState([]);
    const [loadingFlags, setLoadingFlags] = useState({ fromPallet: false, toLocation: false, toPallet: false });
    const [toPallets, setToPallets] = useState([]);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showFromPalletField, setShowFromPalletField] = useState(false);
    const [showToPalletField, setShowToPalletField] = useState(false);
    const [selectedToLocation, setSelectedToLocation] = useState();
    const [formData, setFormData] = useState({
        numCases: 0,
        numUnits: 0,
        fromLocationId: locationId,
        toLocationId: '',
        fromPalletId: '',
        toPalletId: '',
    });

    const fromPalletOptions = useMemo(
        () => fromPallets.map((pallet) => ({ label: pallet.palletId.toString(), value: pallet.palletId })),
        [fromPallets]
    );

    const toLocationOptions = useMemo(
        () => toLocations.map((location) => ({ label: location.name, value: location })),
        [toLocations]
    );

    const toPalletOptions = useMemo(
        () => toPallets.map((pallet) => ({ label: pallet.palletId.toString(), value: pallet.palletId })),
        [toPallets]
    );

    useEffect(() => {
        if (fromPalletId != null) {
            setFormData((prevData) => ({
                ...prevData,
                fromPalletId: fromPalletId,
            }));
        } else {
            setShowFromPalletField(locInvType === 'Pallet' || (locInvType === 'Both' && numPallets > 0));
        }
        queryToLocations();
    }, [fromPalletId]);

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('ChangeExpirationDateAction intialized incorrectly onSuccess is not a function');
        }
    }, [onSuccess]);

    useEffect(() => {
        if (selectedToLocation) {
            //if type is Pallet or Both
            if (selectedToLocation.inventoryType === 'Pallet') {
                setShowToPalletField(true);
            } else {
                setShowToPalletField(false);
            }
        } else {
            setShowToPalletField(false);
        }
    }, [selectedToLocation]);

    useEffect(() => {
        if (showFromPalletField) {
            queryFromPallets(locationId);
        }
    }, [showFromPalletField]);

    useEffect(() => {
        if (showToPalletField && selectedToLocation) {
            queryToPallets(selectedToLocation.id);
        }
    }, [showToPalletField]);

    useEffect(() => {
        if (showToPalletField && toPallets.length === 0) {
            setShowError(true);
            setErrorMessage('Destination location requires a destination pallet, but no pallets found in location.');
        } else {
            setShowError(false);
            setErrorMessage('');
        }
    }, [toPallets]);

    const queryToLocations = async () => {
        setLoadingFlags((flags) => ({
            ...flags,
            toLocation: true,
        }));
        const result = await warehouseApi.getLocations(user.facilityID, true);
        setLoadingFlags((flags) => ({
            ...flags,
            toLocation: false,
        }));
        if (result.status) {
            setToLocations(result.data.rows ?? []);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const queryFromPallets = async (locationId) => {
        setLoadingFlags((flags) => ({
            ...flags,
            fromPallet: true,
        }));

        const result = await warehouseApiV2.getPalletsByLocationIdandLotId(locationId, lotId, user.facilityID);
        if (result?.status) {
            setFromPallets(result.data.pallets ?? []);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }

        setLoadingFlags((flags) => ({
            ...flags,
            fromPallet: false,
        }));
    };

    const queryToPallets = async () => {
        setLoadingFlags((flags) => ({
            ...flags,
            toPallet: true,
        }));

        const result = await warehouseApiV2.getPalletsByLocationId(selectedToLocation.id, user.facilityID);
        if (result?.status) {
            setToPallets(result.data.pallets ?? []);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }

        setLoadingFlags((flags) => ({
            ...flags,
            toPallet: false,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formData.numCases === '' || formData.numUnits === '') {
            setShowError(true);
            setErrorMessage('Number of Cases and Number of Loose Units fields cannot be blank.');
            return;
        }
        if (formData.numCases == 0 && formData.numUnits == 0) {
            setShowError(true);
            setErrorMessage('Number of Cases and Number of Loose Units cannot both be 0.');
            return;
        }
        if (!formData.toLocationId) {
            setShowError(true);
            setErrorMessage('You must select a destination location.');
            return;
        }
        if (showToPalletField && !formData.toPalletId) {
            setShowError(true);
            setErrorMessage('The selected location requires a pallet. Please select a destination pallet.');
            return;
        }
        setShowError(false);
        setErrorMessage('');

        const result = await warehouseApi.moveCasesAndLooseUnits({
            originLocationID: locationId,
            originPalletID: formData.fromPalletId,
            destinationLocationID: formData.toLocationId,
            destinationPalletID: formData.toPalletId,
            lotID: lotId,
            numCases: formData.numCases,
            numUnits: formData.numUnits,
        });

        if (result?.status) {
            onSuccess('Success', result.data);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const handleCreatePallet = async () => {
        queryToPallets(selectedToLocation.id);
    };

    const handleFormDataChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }, []);

    const handleToLocationChange = useCallback((newValue) => {
        setFormData((prevData) => ({
            ...prevData,
            toLocationId: newValue?.value.id,
        }));
        setSelectedToLocation(newValue?.value);
    }, []);

    const handleToPalletChange = useCallback((newValue) => {
        //set form data
        setFormData((prevData) => ({
            ...prevData,
            toPalletId: newValue?.value,
        }));
    }, []);

    const handleFromPalletChange = useCallback((newValue) => {
        setFormData((prevData) => ({
            ...prevData,
            fromPalletId: newValue?.value,
        }));
    }, []);

    return (
        <>
            <Notifications options={notification} />
            <h3>
                Move Cases and Loose Units from {locationName}
                {fromPalletId ? `, pallet ${fromPalletId}` : ''}
            </h3>
            <Box>
                {showError && <Typography style={{ color: 'red', marginBottom: 10 }}>{errorMessage}</Typography>}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {showFromPalletField && (
                                <>
                                    <SearchableSelect
                                        onChange={handleFromPalletChange}
                                        options={fromPalletOptions}
                                        loading={loadingFlags.fromPallet}
                                        label="Move From Pallet"
                                    />
                                </>
                            )}
                            <TextField
                                label="Number of cases to move"
                                name="numCases"
                                type="number"
                                value={formData.numCases}
                                onChange={handleFormDataChange}
                                variant="outlined"
                                fullWidth
                                margin="dense" // Reduced spacing
                                inputProps={{ min: 0, step: '1' }}
                                error={formData.numCases === ''}
                                helperText={formData.numCases === '' ? 'This field cannot be blank' : ''}
                            />
                            <TextField
                                label="Number of loose units to move"
                                name="numUnits"
                                type="number"
                                value={formData.numUnits}
                                onChange={handleFormDataChange}
                                variant="outlined"
                                fullWidth
                                margin="dense" // Reduced spacing
                                inputProps={{ min: 0, step: '1' }}
                                error={formData.numUnits === ''}
                                helperText={formData.numUnits === '' ? 'This field cannot be blank' : ''}
                            />
                            {showToPalletField && (
                                <EmptyPalletCreator locationId={selectedToLocation.id} onSuccess={handleCreatePallet} />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <SearchableSelect
                                onChange={handleToLocationChange}
                                options={toLocationOptions}
                                loading={loadingFlags.toLocation}
                                label="Move To Location"
                            />
                            {showToPalletField && (
                                <>
                                    <SearchableSelect
                                        onChange={handleToPalletChange}
                                        options={toPalletOptions}
                                        loading={loadingFlags.toPallet}
                                        label="Move To Pallet"
                                    />
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <Button type="submit" variant="outlined">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    );
};

export { MoveCasesAndLooseUnitsFromKnownOriginAction };
