import React, { useEffect, useState, useContext } from 'react';
import { DataTable, Notifications } from '../../../../shared';
import { LocationAccuracyRangeAndPartnerSelection } from './locationAccuracyRangeAndPartnerSelection';
import moment from 'moment';
import { LocationAccuracySummary } from './locationAccuracySummary';
import { UserContext } from '../../../../contexts/UserContext';
import { CycleCountApi } from '../../../../api/cycleCount';
import { CircularProgress, Button } from '@material-ui/core';
import { LocationAccuracyResultExclusionModal } from './locationAccuracyResultExclusionModal';
const cycleCountApi = new CycleCountApi();

const LocationAccuracyTab = () => {
    const [loading, setLoading] = useState(false);
    const initialStartDate = moment().subtract(1, 'w').format('YYYY-MM-DD');
    const initialEndDate = moment().format('YYYY-MM-DD');
    const user = useContext(UserContext);
    const [rows, setRows] = useState([]);
    const [summaryRow, setSummaryRow] = useState({});
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [locationAccuracyFilters, setLocationAccuracyFilters] = useState({
        start: initialStartDate,
        end: initialEndDate,
        selectedPartner: null,
        showIncomplete: false,
    });
    const [pendingExclusionId, setPendingExclusionId] = useState(null);

    useEffect(() => {
        getLocationAccuracy(
            locationAccuracyFilters.start,
            locationAccuracyFilters.end,
            locationAccuracyFilters.selectedPartner,
            locationAccuracyFilters.showIncomplete
        );
    }, []);

    const handleDelete = async (inventoryCycleCountRequestEntityID) => {
        setPendingExclusionId(inventoryCycleCountRequestEntityID);
    };

    const handleCloseConfirmation = () => {
        setPendingExclusionId(null);
    };

    const handleDeleteConfirmation = async () => {
        const res = await cycleCountApi.excludeFromCycleCountResults(pendingExclusionId);
        if (!res.status) {
            setNotification({ text: res.msg, level: 'error' });
            return;
        }

        setPendingExclusionId(null);
        setNotification({ text: 'Cycle count excluded from results', level: 'success' });
        getLocationAccuracy(
            locationAccuracyFilters.start,
            locationAccuracyFilters.end,
            locationAccuracyFilters.selectedPartner,
            locationAccuracyFilters.showIncomplete
        );
    };

    const columns = [
        { Header: 'Location', accessor: 'locationName' },
        { Header: 'Status', accessor: 'status' },
        { Header: 'Datetime Started', accessor: 'dateStarted' },
        { Header: 'Datetime Ended', accessor: 'dateEnded' },
        { Header: 'User', accessor: 'authUser' },
        { Header: 'Skus Expected', accessor: 'skusExpected' },
        { Header: 'Skus Accurate', accessor: 'skusCounted' },
        { Header: 'Units Expected', accessor: 'unitsExpected' },
        { Header: 'Units Counted', accessor: 'unitsCounted' },
        { Header: 'Lots Expected', accessor: 'lotsExpected' },
        { Header: 'Lots Accurate', accessor: 'lotsCounted' },
        { Header: 'Pallets Expected', accessor: 'palletsExpected' },
        { Header: 'Pallets Accurate', accessor: 'palletsCounted' },
        { Header: 'Sku Accuracy', accessor: 'skuAccuracy' },
        { Header: 'Unit Accuracy', accessor: 'unitAccuracy' },
        {
            Header: 'Delete',
            Cell: ({ row }) => (
                <Button onClick={() => handleDelete(row?.original?.inventoryCycleCountRequestEntityID)}>Delete</Button>
            ),
        },
    ];

    const getLocationAccuracy = async (start, end, partner, showIncomplete) => {
        setLoading(true);
        const res = await cycleCountApi.getLocationCycleCountResults(
            user.getFacilityID(),
            start,
            end,
            partner,
            showIncomplete
        );
        setLoading(false);
        if (!res.status) {
            setNotification({ text: res.msg, level: 'error' });
            return;
        }

        setRows(res.data.results);
        const summaryRow = { ...res.data.summary, totalExcluded: res.data.totalExcluded };
        setSummaryRow(summaryRow);
    };

    const handleRangeAndPartnerSelection = async (formData) => {
        setLocationAccuracyFilters(formData);
        getLocationAccuracy(formData.start, formData.end, formData.selectedPartner, formData.showIncomplete);
    };

    return (
        <>
            <Notifications options={notification} />

            <LocationAccuracyRangeAndPartnerSelection
                initialStartDate={initialStartDate}
                initialEndDate={initialEndDate}
                onSubmit={handleRangeAndPartnerSelection}
            />

            <LocationAccuracyResultExclusionModal
                open={pendingExclusionId !== null}
                handleConfirm={handleDeleteConfirmation}
                handleClose={handleCloseConfirmation}
            />

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', margin: '2rem' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <LocationAccuracySummary summaryRow={summaryRow} />

                    <DataTable
                        csvExport={true}
                        csvExportFileName="location_accuracy"
                        columns={columns}
                        data={rows}
                        editable={false}
                        tableContainerMaxHeight={800}
                        stickyHeader={true}
                    />
                </>
            )}
        </>
    );
};

export { LocationAccuracyTab };
