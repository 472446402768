import React, { useState, useEffect } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { infoTechApi as api } from '../../../api/infoTech';
import { Modal, Button, Select, NativeSelect } from '@material-ui/core';
import { warehouseApi } from '../../../api/warehouse';
import { logisticsApi } from '../../../api/logistics';

let ViewEmployeeBox = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [facilities, setFacilities] = useState([]);
    let [crossDocks, setCrossDocks] = useState([]);
    let [allOpts, setAllOpts] = useState([{ id: 0, name: 'N/A', pickupType: 'NO_PICKUP' }]);

    // Load  table
    let getRows = async () => {
        let response = await api.getDiscountFriendFamily();
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities(true);
        if (!response.status) {
            setNotification({ text: 'Failed to get facilities', level: 'error' });
            return;
        }

        let facilitiesAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            row.pickupType = 'FACILITY';
            return row;
        });

        setFacilities(facilitiesAsOpts);
        setAllOpts((prevOpts) => prevOpts.concat(facilitiesAsOpts));
    };

    let getCrossDocks = async () => {
        let response = await logisticsApi.getDBICrossDocks(true);
        if (!response.status) {
            setNotification({ text: 'Failed to get cross docks', level: 'error' });
            return;
        }

        let crossDocksAsOpts = response.data.rows.map((row) => {
            row.text = row.identifier;
            row.value = row.id;
            row.pickupType = 'CROSS_DOCK';
            return row;
        });

        setCrossDocks(crossDocksAsOpts);
        setAllOpts((prevOpts) => prevOpts.concat(crossDocksAsOpts));
    };

    useEffect(() => {
        getFacilities();
        getCrossDocks();
    }, []);

    useEffect(() => {
        if (allOpts.length > 61) {
            //let nullOpt = [{id: 0, value: "N/A", pickupType: "NO_PICKUP"}];
            //setAllOpts(prevOpts => [...prevOpts, { id: 0, text: 'N/A', pickupType: 'NO_PICKUP' }]);
            //setAllOpts(prevOpts => prevOpts.concat(nullOpt));
            getRows();
        }
    }, [allOpts]);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'firstName', Header: 'First Name' },
        { accessor: 'lastName', Header: 'Last Name' },
        { accessor: 'email', Header: 'Email' },
        { accessor: 'employeeID', Header: 'Employee ID', editable: true },
        {
            accessor: 'pickupLocationType',
            Header: 'Pickup Type',
            editable: true,
            editProps: {
                type: 'select',
                options: [
                    { value: 'NO_PICKUP', text: 'No pickup' },
                    { value: 'FACILITY', text: 'Facility' },
                    { value: 'CROSS_DOCK', text: 'Cross Dock' },
                ],
            },
        },
        {
            accessor: 'pickupLocationID',
            Header: 'Pickup Location',
            editable: true,
            editProps: {
                type: 'select',
                options: (row) =>
                    allOpts
                        .filter((item) => item.pickupType === row.pickupLocationType)
                        .map((item) => ({ value: item.id, text: item.name ? item.name : item.hub })),
            },
        },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        onClick={async () => {
                            let response = await api.revokeEmployeeBox(row.original.id);

                            if (response.status === false) {
                                setNotification({ text: "Can't remove box " + response.msg, level: 'error' });
                                return;
                            }

                            getRows();
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <h1>Employee Box</h1>
            <Notifications options={notification} />
            {}
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ id, field, value, row }) => {
                        // Map frontend field names to backend field names
                        const fieldMapping = {
                            employeeID: 'employeeID',
                            pickupLocationType: 'pickupLocationType',
                            pickupLocationID: 'pickupLocationID',
                        };

                        // Only proceed if it's a field we can update
                        if (!fieldMapping[field]) {
                            setNotification({ text: 'Cannot update this field', level: 'error' });
                            return;
                        }

                        let item = {};
                        item[fieldMapping[field]] = value;

                        let response = await api.updateEmployeeBox(id, item);

                        if (response.status === false) {
                            setNotification({ text: 'Failed: ' + response.msg, level: 'error' });
                            return;
                        }

                        getRows();
                    }}
                />
            </div>
        </div>
    );
};

export { ViewEmployeeBox };
