import React, { useState, useEffect, useContext, useRef } from 'react';
import { Input, Select, Combobox, MultiSelect, DropdownMultipleCombobox, FAMAlert } from '../../../shared';
import { partnershipsApi } from '../../../../api/partnerships';
import '../subscriptions.css';
import { UserContext } from '../../../../contexts/UserContext';
import { stateOptions } from '../../../../utils/globalUtils';
import moment from 'moment';

const SubscriptionForm = ({
    initialSub = {},
    onSuccess = () => {},
    listOptions = [],
    allergenOptions = [],
    adminEmailOptions = [],
    dislikeOptions = [],
}) => {
    const { famAccount, roles } = useContext(UserContext);
    const defaultInitialSub = {
        id: '',
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        deliveryDay: '',
        facility: '',
        city: '',
        province: '',
        zipCode: '',
        memberID: '',
        phoneNumber: '',
        email: '',
        numOrders: '',
        frequency: 'weekly',
        listID1: '',
        numBoxes1: '',
        listID2: '',
        numBoxes2: '',
        listID3: '',
        numBoxes3: '',
        listID4: '',
        numBoxes4: '',
        allergens: [],
        dislikes: [],
        adminEmails: [],
        deliveryInstructions: '',
        orderIDs: [],
    };
    const formEl = useRef(null);

    const [subscription, setSubscription] = useState(Object.keys(initialSub).length ? initialSub : defaultInitialSub);
    const frequencyOptions = [
        { value: 'weekly', text: 'Weekly' },
        { value: 'biweekly', text: 'Biweekly' },
        { value: 'monthly', text: 'Monthly' },
    ];
    const [listOptionsForSelectedDay, setListOptionsForSelectedDay] = useState([]);
    const boxPerOrderOptions = [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
    ];
    const [validDays, setValidDays] = useState([]);
    const [deliverySchedule, setDeliverySchedule] = useState([]);

    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [changedKeys, setChangedKeys] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');

    const updateSub = (obj) => {
        if (Object.keys(initialSub).length) {
            let tmp = JSON.parse(JSON.stringify(changedKeys));
            Object.keys(obj).forEach((key) => {
                if (!tmp.includes(key) && JSON.stringify(initialSub[key]) !== JSON.stringify(obj[key])) {
                    console.log(
                        `edit sub ${key} value changed from ${JSON.stringify(initialSub[key])} to ${JSON.stringify(
                            obj[key]
                        )}`
                    );
                    tmp.push(key);
                }
            });
            setChangedKeys(tmp);
        }
        setSubscription({ ...subscription, ...obj });
    };

    const handleCreateOrEditSub = async (e) => {
        setError('');
        e.preventDefault();

        setSubmitting(true);
        const validity = formEl.current.checkValidity();
        if (validity === false) {
            formEl.current.reportValidity();
            setSubmitting(false);
            return;
        }

        if (!!subscription.id) {
            // We are editing a subscription
            let submitObj = {};
            changedKeys.forEach((key) => {
                submitObj[key] = subscription[key];
            });
            if (Object.keys(submitObj).length === 0) {
                setSubmitting(false);
                setError('No values have been changed');
                return;
            }

            let res = await partnershipsApi.editSubscription(subscription.id, submitObj, famAccount.partnershipSource);
            setSubmitting(false);
            if (!res.status) {
                setError(`Failed to edit subscription. ${res.msg}`);
                return;
            }
            onSuccess('Subscription Edited!');
        } else {
            // We are creating a subscription
            let res = await partnershipsApi.createSubscription(famAccount.partnershipSource, subscription);
            setSubmitting(false);
            if (!res.status) {
                setError(`Failed to create subscription. ${res.msg}`);
                return;
            }
            setSuccessMessage('Subscription created!');
            resetForm();
        }
    };

    const resetForm = () => {
        setSubscription(defaultInitialSub);
    };

    const getValidDaysForZip = async (zip) => {
        const res = await partnershipsApi.getValidDeliveryDaysForZip(zip);
        if (!res.status || !res.data) {
            return;
        }

        const dayOptions = res.data?.deliveryDays?.map((item) => {
            const formattedDay = item.deliveryDay.charAt(0).toUpperCase() + item.deliveryDay.slice(1);
            return {
                text: `${formattedDay} (ships from ${item.facility})`,
                value: item.deliveryDay,
                facility: item.facility,
            };
        });
        setValidDays(dayOptions);
    };

    useEffect(() => {
        console.log('zip code changed', subscription.zipCode);
        if (/^[0-9]*$/.test(subscription.zipCode) && subscription.zipCode.length === 5) {
            getValidDaysForZip(subscription.zipCode);
        } else {
            updateSub({ deliveryDay: '', facility: '' });
            setValidDays([]);
        }
    }, [subscription.zipCode]);

    useEffect(() => {
        /**
         * Filter list options to what's available in the selected facility
         */
        const filteredOptions = listOptions.filter((item) =>
            item.facilities.map((f) => f.name).includes(subscription.facility)
        );
        setListOptionsForSelectedDay(filteredOptions);
    }, [subscription.facility, listOptions]);

    useEffect(() => {
        const listIDs = {
            listID1: initialSub?.listID1 || '',
            listID2: initialSub?.listID2 || '',
            listID3: initialSub?.listID3 || '',
            listID4: initialSub?.listID4 || '',
        };
        updateSub(listIDs);

        if (!subscription.facility) {
            setListOptionsForSelectedDay([]);
            return;
        }
    }, [subscription.facility]);

    useEffect(() => {
        const { deliveryDay, numOrders, frequency, zipCode, id } = subscription;
        const getDeliverySchedulePreview = async (deliveryDay, numOrders, frequency, zipCode, id) => {
            const res = await partnershipsApi.getDeliverySchedulePreview(
                deliveryDay,
                numOrders,
                frequency,
                zipCode,
                id
            );
            if (!res.status) {
                setDeliverySchedule([]);
                return;
            }

            setDeliverySchedule(res.data.schedule);
        };

        if (!!deliveryDay && !!numOrders && !!frequency && /^[0-9]*$/.test(zipCode) && zipCode.length === 5) {
            getDeliverySchedulePreview(deliveryDay, numOrders, frequency, zipCode, id);
        } else {
            setDeliverySchedule([]);
        }
    }, [subscription.deliveryDay, subscription.numOrders, subscription.frequency, subscription.zipCode]);

    const handleNumOrdersChange = (value) => {
        if (value === '') {
            updateSub({ numOrders: value });
            return;
        }

        const num = parseInt(value, 10);
        const emptyOrderIDs = !isNaN(num)
            ? Array.from({ length: num }, (_, i) => subscription.orderIDs[i] || initialSub?.orderIDs?.[i] || '')
            : [];
        updateSub({ numOrders: value, orderIDs: emptyOrderIDs });
    };

    return (
        <div className="fam-create-sub-container">
            <FAMAlert severity="success" message={successMessage} alignTop={true} />

            <form ref={formEl}>
                <div className="header-text grotesk-bold fam-create-sub-header">Delivery Information</div>

                <div className="fam-create-sub-two-item">
                    <Input
                        label="First Name"
                        required={true}
                        value={subscription.firstName}
                        onChange={(value) => {
                            updateSub({ firstName: value });
                        }}
                    />
                    <Input
                        label="Last Name"
                        required={true}
                        value={subscription.lastName}
                        onChange={(value) => {
                            updateSub({ lastName: value });
                        }}
                    />
                </div>
                <div className="fam-create-sub-two-item">
                    <Input
                        label="Street Address"
                        required={true}
                        value={subscription.address1}
                        onChange={(value) => {
                            updateSub({ address1: value });
                        }}
                    />
                    <Input
                        label="Apt/Unit"
                        required={false}
                        value={subscription.address2}
                        onChange={(value) => {
                            updateSub({ address2: value });
                        }}
                    />
                </div>
                <div className="fam-create-sub-three-item">
                    <Input
                        label="City"
                        required={true}
                        value={subscription.city}
                        onChange={(value) => {
                            updateSub({ city: value });
                        }}
                    />
                    <Combobox
                        label="State"
                        value={subscription.province}
                        onChange={({ selectedItem }) => {
                            updateSub({ province: selectedItem.value });
                        }}
                        required={true}
                        options={stateOptions}
                    />
                    <Input
                        label="Zip Code"
                        required={true}
                        value={subscription.zipCode}
                        onChange={(value) => {
                            updateSub({ zipCode: value });
                        }}
                    />
                </div>
                <div>
                    <Select
                        label="Delivery Day"
                        required={true}
                        options={validDays}
                        value={subscription.deliveryDay}
                        noOptionsText="Enter a valid zip code to view delivery day options"
                        onChange={({ selectedItem }) => {
                            updateSub({
                                deliveryDay: selectedItem.value,
                                facility: selectedItem.facility,
                            });
                        }}
                    />
                </div>
                <div>
                    <Input
                        label="Member ID"
                        required={false}
                        value={subscription.memberID}
                        onChange={(value) => {
                            updateSub({ memberID: value });
                        }}
                    />
                </div>
                <div>
                    <Input
                        label="Phone Number"
                        required={false}
                        value={subscription.phoneNumber}
                        onChange={(value) => {
                            updateSub({ phoneNumber: value });
                        }}
                    />
                </div>
                <div>
                    <Input
                        label="Email Address"
                        required={false}
                        value={subscription.email}
                        onChange={(value) => {
                            updateSub({ email: value });
                        }}
                    />
                </div>
                <div className="header-text grotesk-bold fam-create-sub-header">Order Information</div>
                <div className="fam-create-sub-two-item">
                    <Input
                        label="Number of Orders"
                        required={true}
                        value={subscription.numOrders}
                        onChange={handleNumOrdersChange}
                    />
                    <Select
                        label="Frequency"
                        required={true}
                        options={frequencyOptions}
                        value={subscription.frequency}
                        onChange={({ selectedItem }) => {
                            updateSub({ frequency: selectedItem.value });
                        }}
                    />
                </div>
                {subscription.orderIDs.map((orderID, index) => (
                    <Input
                        key={`orderID-${index}`}
                        label={`Order ID ${index + 1}`}
                        required={false}
                        value={orderID}
                        onChange={(value) => {
                            const newOrderIDs = [...subscription.orderIDs];
                            newOrderIDs[index] = value;
                            updateSub({ orderIDs: newOrderIDs });
                        }}
                    />
                ))}
                {deliverySchedule.length > 0 && (
                    <div>
                        <div className="grotesk-bold">Future Delivery Dates</div>
                        {deliverySchedule.map((item) => moment(item.deliveryDate.date).format('MM/DD/YYYY')).join(', ')}
                    </div>
                )}
                {[1, 2, 3, 4].map((number) => {
                    const listKey = `listID${number}`;
                    const boxesKey = `numBoxes${number}`;
                    const required = number === 1;
                    return (
                        <div className="fam-create-sub-two-item" key={`list${number}`}>
                            <Combobox
                                label={`List ${number}`}
                                required={required}
                                options={listOptionsForSelectedDay}
                                value={subscription[listKey]}
                                onChange={({ selectedItem }) => {
                                    let valuesToChange = { [listKey]: selectedItem.value };

                                    if (!selectedItem.value) {
                                        valuesToChange[boxesKey] = '';
                                    } else {
                                        if (!subscription[boxesKey]) {
                                            valuesToChange[boxesKey] = 1;
                                        }
                                    }
                                    // if (!!selectedItem.value && subscription[boxesKey] === '') {
                                    //     valuesToChange[boxesKey] = 1;
                                    // }
                                    updateSub(valuesToChange);
                                }}
                                noOptionsText="Enter an address and delivery day to view list options"
                            />
                            <Select
                                label="Boxes Per Order"
                                required={required}
                                options={boxPerOrderOptions}
                                value={subscription[boxesKey]}
                                onChange={({ selectedItem }) => {
                                    updateSub({ [boxesKey]: selectedItem.value });
                                }}
                            />
                        </div>
                    );
                })}
                <div>
                    <MultiSelect
                        label="Allergens"
                        required={false}
                        options={allergenOptions}
                        value={subscription.allergens}
                        onChange={({ selectedItems }) => {
                            updateSub({ allergens: selectedItems.map((item) => item?.value) });
                        }}
                    />
                </div>
                <div>
                    <DropdownMultipleCombobox
                        label="Item Dislikes"
                        required={false}
                        options={dislikeOptions.filter((item) => {
                            let parentExcluded = false;
                            item.parentIDs.forEach((id) => {
                                if (subscription.dislikes.includes(id) && !subscription.dislikes.includes(item.value)) {
                                    parentExcluded = true;
                                }
                            });
                            return !parentExcluded;
                        })}
                        value={subscription?.dislikes}
                        onChange={({ selectedItems, resetInputValue }) => {
                            updateSub({ dislikes: selectedItems.map((item) => item?.value) });
                            resetInputValue();
                        }}
                    />
                </div>
                <div>
                    <DropdownMultipleCombobox
                        label="Admin Emails"
                        required={false}
                        options={adminEmailOptions}
                        value={subscription?.adminEmails}
                        onChange={({ selectedItems, resetInputValue }) => {
                            updateSub({ adminEmails: selectedItems.map((item) => item?.value) });
                            resetInputValue();
                        }}
                    />
                </div>
                <div>
                    <Input
                        label="Delivery Instructions"
                        required={false}
                        value={subscription.deliveryInstructions}
                        onChange={(value) => {
                            updateSub({ deliveryInstructions: value });
                        }}
                    />
                    <div>* Not all carriers accommodate delivery instructions.</div>
                </div>
            </form>
            <FAMAlert severity="error" message={error} autohide={false} />
            <div className="fam-create-sub-button-container">
                <button
                    disabled={submitting}
                    type="submit"
                    className="fam-button fam-button-yellow grotesk-bold"
                    onClick={handleCreateOrEditSub}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export { SubscriptionForm };
