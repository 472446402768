import React, { useState, useEffect } from 'react';
import { Paper, Tabs, Tab, Button } from '@material-ui/core';
import { Notifications } from '../../../shared';
import { TabPanel } from '../../../shared';
import { ViewAisleBanners } from './viewAisleBanners';
import { CreateAisleBanner } from './createAisleBanner';
import { catalogApi } from '../../../api/catalog';
import { productionApi } from '../../../api/production';

let PhotoCell = ({ row, column, setSelectedPhoto, setPhotoModalOpen, setCurrentBanner }) => {
    let key = column.id;

    return (
        <div style={{ textAlign: 'center' }}>
            {row.original.id && (
                <div style={{ textAlign: 'center' }}>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            setSelectedPhoto({ item: row.original, key });
                            setPhotoModalOpen(true);
                            setCurrentBanner(row.original);
                        }}
                    >
                        <img
                            style={{ height: '100px', width: key === 'largeImage' ? '300px' : '100px' }}
                            src={`https://static.misfitsmarket.com/${row.original[key]}`}
                            alt="Preview"
                        />
                    </Button>
                </div>
            )}
        </div>
    );
};

const BannerCreation = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    const [notification, setNotification] = useState({ text: null, level: null });
    const [aisles, setAisles] = useState([]);
    const [aisleBanners, setAisleBanners] = useState([]);
    const [brands, setBrands] = useState([]);
    const [packGroups, setPackGroups] = useState([]);

    const [photoModalOpen, setPhotoModalOpen] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState({});

    const [colorModalOpen, setColorModalOpen] = useState(false);
    const [currentBanner, setCurrentBanner] = useState();
    const [clickThroughModalOpen, setClickThroughModalOpen] = useState(false);

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    let getPackGroups = async () => {
        let res = await productionApi.getPackGroupsRecent();
        if (!res.status) {
            setNotification({ text: 'Failed to get recent pack groups', level: 'error' });
            return;
        }

        let data = res.data.rows.map((row) => {
            let item = {};
            item.text = '' + row.id;
            item.value = '' + row.id;
            return item;
        });

        setPackGroups(res.data.rows);
    };

    let getAisles = async () => {
        let res = await productionApi.getAisles();
        if (!res.status) {
            setNotification({ text: `Failed to get categories`, level: 'error' });
            return;
        }

        setAisles(res.data.categories);
    };

    let getBrands = async () => {
        let res = await catalogApi.getBrands();
        if (!res.status) {
            setNotification({ text: 'Failed to get brands', level: 'error' });
            return;
        }
        setBrands(res.data.brands);
    };

    let getAisleBanners = async () => {
        let res = await productionApi.getAisleBanners();
        if (!res.status) {
            setNotification({ text: 'Error getting aisle banners: ' + res.msg, level: 'error' });
            return;
        }

        let newArr = res.data.rows.map((row) => {
            row.sponsored = row.sponsored === true ? 'Yes' : 'No';
            row.active = row.active === true ? 'Yes' : 'No';
            if (row.taxonomyID === 0) {
                row.aisleID = 'STOREFRONT';
            } else {
                row.aisleID = aisles.find((aisle) => aisle.id === row.taxonomyID).id;
            }
            row.sponsoredBrands = row.sponsoredBrands.map((item) => ({ ...item, value: item.brand, text: item.brand }));
            return row;
        });

        setAisleBanners(newArr);
    };

    const handleClickThroughModal = async (row) => {
        if (!!row.id && row.mobileApp === false) {
            setClickThroughModalOpen(true);
            setCurrentBanner(row);
        } else {
            let res = await productionApi.updateAisleBanner({
                id: row.id,
                mobileApp: false,
                appClickThrough: '',
            });
            if (!res.status) {
                setNotification({ text: 'Error getting aisle banners: ' + res.msg, level: 'error' });
                return;
            }
            getAisleBanners();
            return res.status;
        }
    };

    useEffect(() => {
        getAisles();
        getBrands();
        getPackGroups();
    }, []);

    useEffect(() => {
        if (aisles.length > 0) {
            getAisleBanners();
        }
    }, [aisles]);

    let aisleBannerColumns = [
        {
            accessor: 'aisleID',
            Header: 'Aisle',
            editProps: {
                type: 'select',
                options: aisles.map((val) => ({ text: `L${val.level} - ${val.name}`, value: val.id })),
            },
        },
        {
            accessor: 'sponsoredBrands',
            Header: 'Sponsored Brands',
            editable: true,
            editProps: {
                type: 'autocomplete-multiple-chips',
                options: brands,
                modalHeader: 'Add brands to aisle banner',
                addButtonText: 'Add Brands',
                addItem: async ({ values, row }) => {
                    let res = await catalogApi.createSponsoredBrandMapping('aisle_banner', row.id, values);
                    if (res.status === true) {
                        getAisleBanners();
                        getAisles();
                        return true;
                    } else {
                        setNotification({ level: 'error', text: res.msg });
                        return false;
                    }
                },
                removeItem: async ({ item }) => {
                    if (window.confirm('Remove brand sponsor from aisle banner?')) {
                        let res = await catalogApi.removeSponsoredBrandMapping(item.id);
                        if (res.status === true) {
                            getAisleBanners();
                            getAisles();
                        }
                    }
                },
            },
        },
        {
            accessor: 'packGroupIDs',
            Header: 'Pack Groups',
            filterValues: (row) => row.original.packGroupIDs.map((item) => item.value),
            editProps: {
                type: 'autocomplete-multiple',
                options: packGroups.map((item) => ({ text: '' + item.id, value: '' + item.id })),
                multiple: true,
            },
        },
        { accessor: 'active', Header: 'Active', editable: true, editProps: { type: 'select', options: ['Yes', 'No'] } },
        {
            accessor: 'largeImage',
            Header: 'Large Image',
            width: '300px',
            Cell: ({ row, column }) => {
                return PhotoCell({
                    row,
                    column,
                    setSelectedPhoto,
                    setPhotoModalOpen,
                    setCurrentBanner,
                });
            },
        },
        {
            accessor: 'smallImage',
            Header: 'Small Image',
            width: '100px',
            Cell: ({ row, column }) => {
                return PhotoCell({
                    row,
                    column,
                    setSelectedPhoto,
                    setPhotoModalOpen,
                    setCurrentBanner,
                });
            },
        },
        {
            accessor: 'mobileApp',
            Header: 'Mobile App',
            type: 'checkbox',
            editable: true,
            editProps: { type: 'checkbox' },
            customEdit: handleClickThroughModal,
        },
        { accessor: 'webApp', Header: 'Web App', type: 'checkbox', editable: true, editProps: { type: 'checkbox' } },
        {
            accessor: 'appClickThrough',
            Header: 'App Click Through',
            editable: (row) => row.original.mobileApp,
            editProps: {
                type: 'select',
                options: [
                    { text: 'Recipe Inspiration', value: '/shop/recipe-inspiration' },
                    { text: 'All Items', value: '/shop/aisle/all' },
                ],
            },
        },
        {
            accessor: 'copy',
            Header: 'Copy',
            editable: true,
            editProps: { type: 'input', multiline: true },
            width: '300px',
        },
        {
            accessor: 'modalCopy',
            Header: 'Modal Copy',
            editable: true,
            editProps: { type: 'input', multiline: true },
            width: '200px',
        },
        { accessor: 'bannerName', Header: 'Banner Name' },
        {
            accessor: 'backgroundColor',
            Header: 'Background Color',
            Cell: ({ row }) => {
                let textColor;
                let bgColor = row.original.backgroundColor;

                let hexColor = bgColor.slice(1);

                var r = parseInt(hexColor.substr(0, 2), 16);
                var g = parseInt(hexColor.substr(2, 2), 16);
                var b = parseInt(hexColor.substr(4, 2), 16);

                //Determine text color based on background color
                if ((r * 299 + g * 587 + b * 114) / 1000 >= 128) {
                    textColor = '#000000';
                } else {
                    textColor = '#ffffff';
                }
                return (
                    <div style={{ textAlign: 'center' }}>
                        {row.original.id ? (
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setColorModalOpen(true);
                                    setCurrentBanner(row.original);
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: bgColor,
                                        width: '100px',
                                        height: '100px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: textColor,
                                    }}
                                >
                                    {bgColor}
                                </div>
                            </Button>
                        ) : (
                            <div
                                style={{
                                    backgroundColor: bgColor,
                                    width: '100px',
                                    height: '100px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: textColor,
                                }}
                            >
                                {bgColor}
                            </div>
                        )}
                    </div>
                );
            },
        },
        { accessor: 'linkURL', Header: 'Link URL', editable: true },
        { accessor: 'linkTitle', Header: 'Link Title', editable: true },
    ];

    return (
        <Paper>
            <Notifications options={notification} />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewAisleBanners_Tab" label="View Aisle Banners" value="0" />
                <Tab id="CreateAisleBanners_Tab" label="Create Aisle Banner" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewAisleBanners
                    notification={notification}
                    setNotification={setNotification}
                    aisleBannerColumns={aisleBannerColumns}
                    aisleBanners={aisleBanners}
                    aisles={aisles}
                    getAisleBanners={getAisleBanners}
                    getAisles={getAisles}
                    photoModalOpen={photoModalOpen}
                    setPhotoModalOpen={setPhotoModalOpen}
                    selectedPhoto={selectedPhoto}
                    currentBanner={currentBanner}
                    colorModalOpen={colorModalOpen}
                    setColorModalOpen={setColorModalOpen}
                    clickThroughModalOpen={clickThroughModalOpen}
                    setClickThroughModalOpen={setClickThroughModalOpen}
                />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateAisleBanner
                    setNotification={setNotification}
                    brands={brands}
                    aisles={aisles}
                    getAisleBanners={getAisleBanners}
                    setCurrentBanner={setCurrentBanner}
                    currentBanner={currentBanner}
                    packGroups={packGroups}
                />
            </TabPanel>
        </Paper>
    );
};

export { BannerCreation };
