import React from 'react';

const UnitDiscrepancyDetailCell = ({ row }) => {
    const result = row?.expectedResults?.[0];

    return (
        <div key={result?.expectedResultID} style={{ margin: '10px 0px' }}>
            <div>
                <strong>Item: </strong>
                {result?.itemName}
            </div>
            <div>
                <strong>Lot: </strong>
                {result?.expectedLotID}
            </div>
            {result?.expectedPalletID && (
                <div>
                    <strong>Pallet: </strong>
                    {result?.expectedPalletID}
                </div>
            )}
            <div>
                <strong>Expected: </strong>
                {result?.totalExpectedUnits} units ({result?.expectedCaseCount} cases, {result?.expectedLooseUnitCount}{' '}
                loose units)
            </div>
            <div>
                <strong>Actual: </strong>
                {result?.totalActualUnits} units ({result?.actualCaseCount} cases, {result?.actualLooseUnitCount} loose
                units)
            </div>
        </div>
    );
};

export { UnitDiscrepancyDetailCell };
