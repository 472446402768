import React, { useContext, useEffect, useState } from 'react';
import { Notifications } from '../../../../../shared';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { warehouseApi } from '../../../../../api/warehouse';

const QCInspectionAction = ({ requestInspection = false, lotId = null, palletId = null, onSuccess, onCancel }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState();
    const [headerLabel, setHeaderLabel] = useState();

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('InspectionPassedAction intialized incorrectly onSuccess is not a function');
        }
        if (!(onCancel instanceof Function)) {
            console.error('InspectionPassedAction intialized incorrectly onCancel is not a function');
        }
    }, [onSuccess]);

    useEffect(() => {
        if (requestInspection) {
            setStatus('QC_AUDIT');
            setHeaderLabel('Should the lot be put on QC AUDIT?');
        } else {
            setStatus('ATS');
            setHeaderLabel('Did the lot pass QC inspection?');
        }
    }, [requestInspection]);

    const handleSubmit = async () => {
        setLoading(true);
        const result = palletId
            ? await warehouseApi.updatePalletStatus(palletId, status)
            : await warehouseApi.updateLotQCStatus(lotId, status);

        setLoading(false);
        if (result.status) {
            onSuccess();
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <div>
            <Notifications options={notification} />
            <Typography style={{ marginBottom: 10 }}> {headerLabel} </Typography>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSubmit} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Confirm'}
            </Button>
        </div>
    );
};

export { QCInspectionAction };
