import React, { useEffect, useState } from 'react';
import { Notifications } from '../../../../../shared';
import { warehouseApi } from '../../../../../api/warehouse';
import printJS from 'print-js';
import { CircularProgress, TextField, Button, Box } from '@material-ui/core';
import { WarehouseLabelApi } from '../../../../../api/warehouseLabels';

const labelApi = new WarehouseLabelApi();

const PrintCaseLabelAction = ({ lotId, onSuccess }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [numLabels, setNumLabels] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('PrintAllCaseLabelsAction intialized incorrectly onSuccess is not a function');
        }
    }, [onSuccess]);

    const fileReaderCheck = (fr, tmpPDF) => {
        if (tmpPDF instanceof Blob) {
            fr.readAsDataURL(tmpPDF);
        } else {
            setNotification({ text: 'There was an issue printing the pdf', level: 'error' });
        }
    };

    const handleFormDataChange = (e) => {
        let value = e.target.value;
        value = parseInt(value, 10);

        if (isNaN(value) || value <= 0) {
            setNumLabels(0); // Prevent 0 or invalid values
        } else {
            setNumLabels(value); // Accept valid values
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        const result = await labelApi.getCaseLabels({ lotID: lotId, qty: numLabels });
        setLoading(false);
        if (result) {
            var fr = new FileReader();
            fr.addEventListener(
                'load',
                () => {
                    const tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                    printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                },
                false
            );
            fileReaderCheck(fr, result);
        } else {
            setNotification({ text: 'There was an issue printing the pdf', level: 'error' });
        }
    };

    return (
        <div>
            <Notifications options={notification} />
            {loading ? (
                <CircularProgress size={24} />
            ) : (
                <form onSubmit={handleSubmit}>
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ gap: 4 }}>
                        <TextField
                            label="Number of Labels"
                            name="numLabels"
                            type="number"
                            value={numLabels}
                            onChange={handleFormDataChange}
                            variant="outlined"
                            margin="dense"
                            inputProps={{ min: 1, step: '1' }}
                        />
                        <Button type="submit" variant="outlined">
                            Print
                        </Button>
                    </Box>
                </form>
            )}
        </div>
    );
};

export { PrintCaseLabelAction };
