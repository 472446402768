import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { CycleCountApi } from '../../../../../api/cycleCount';
import { Notifications } from '../../../../../shared';

const cycleCountApi = new CycleCountApi();

const AcknowledgeLocationIssueAction = ({ cycleCountEventId, onSuccess, onCancel, locationName, issueType }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [loading, setLoading] = useState(false);

    const handleConfirm = async () => {
        setLoading(true);
        const res = await cycleCountApi.resolveIssue(cycleCountEventId);
        setLoading(false);
        if (!res.status) {
            setNotification({ text: res.msg || 'Failed to resolve issue', level: 'error' });
            return;
        }

        onSuccess();
    };

    return (
        <>
            <Notifications options={notification} />

            <div style={{ margin: '10px 0px' }}>
                <div>
                    <span>
                        <strong>Issue Type: </strong>
                    </span>
                    <span>{issueType}</span>
                </div>

                <div>
                    <span>
                        <strong>Location: </strong>
                    </span>
                    <span>{locationName}</span>
                </div>
            </div>

            <div style={{ marginBottom: 10 }}> Acknowledge and clear the issue from the system? </div>

            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={handleConfirm} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Confirm'}
            </Button>
        </>
    );
};

export { AcknowledgeLocationIssueAction };
