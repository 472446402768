import React, { useEffect } from 'react';
import { UploadCSVAndReviewComponent } from '../../../shared';
import { goPuffApi as api } from '../../../api/goPuff';

const UploadTMSData = ({ columns, setNotification, getData }) => {
    const uploadData = async (formData) => {
        return await api.uploadTMSPalletData(formData);
    };

    const confirmUpload = async (key) => {
        return await api.confirmTMSPalletUpload(key);
    };

    useEffect(() => {
        columns.push({ accessor: 'errors', Header: 'Errors' });
    }, []);

    return (
        <UploadCSVAndReviewComponent
            title="Upload TMS Data"
            fileLabel="TMS Data File"
            columns={columns}
            upload={uploadData}
            confirm={confirmUpload}
        />
    );
};

export { UploadTMSData };
