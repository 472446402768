import React, { useState } from 'react';
import { DataTable } from '../../../../shared';
import { Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const LocationAccuracySummary = ({ summaryRow }) => {
    const [accuracyType, setAccuracyType] = useState('unit');

    const useStyles = makeStyles(() => ({
        hideToolbar: {
            '& .MuiToolbar-root': {
                display: 'none',
            },
        },
        summaryToggle: {
            padding: '20px 0px',
        },
    }));
    const classes = useStyles();

    const columns = [
        { Header: 'Locations Counted', accessor: 'numLocations' },
        {
            Header: 'Locations Accurate',
            Cell: ({ row }) => {
                let count = 0;
                if (accuracyType === 'unit') {
                    count = row.original.locationsAccurateByUnit;
                } else if (accuracyType === 'sku') {
                    count = row.original.locationsAccurateBySku;
                }

                return <div>{count}</div>;
            },
        },
        { Header: 'Skus Expected', accessor: 'skusExpected' },
        { Header: 'Skus Accurate', accessor: 'skusCounted' },
        { Header: 'Units Expected', accessor: 'unitsExpected' },
        { Header: 'Units Counted', accessor: 'unitsCounted' },
        { Header: 'Lots Expected', accessor: 'lotsExpected' },
        { Header: 'Lots Accurate', accessor: 'lotsCounted' },
        { Header: 'Pallets Expected', accessor: 'palletsExpected' },
        { Header: 'Pallets Accurate', accessor: 'palletsCounted' },
        {
            Header: 'Location Accuracy',
            Cell: ({ row }) => {
                let accuracy = '0%';
                if (accuracyType === 'unit') {
                    accuracy = row.original.locationAccuracyPercentByUnit;
                } else if (accuracyType === 'sku') {
                    accuracy = row.original.locationAccuracyPercentBySku;
                }

                return <div>{accuracy}</div>;
            },
        },

        { Header: 'Sku Accuracy', accessor: 'skuAccuracy' },
        { Header: 'Unit Accuracy', accessor: 'unitAccuracy' },
        { Header: 'Total Excluded', accessor: 'totalExcluded' },
    ];

    return (
        <div className={classes.hideToolbar}>
            <div className={classes.summaryToggle}>
                <strong>Summary By: </strong>
                <Select
                    defaultValue="unit"
                    native
                    onChange={(event) => {
                        setAccuracyType(event.target.value);
                    }}
                >
                    <option value="unit">Unit Accuracy</option>
                    <option value="sku">Sku Accuracy</option>
                </Select>
            </div>

            <DataTable data={[summaryRow]} columns={columns} tableContainerMaxHeight={700} showPagination={false} />
        </div>
    );
};

export { LocationAccuracySummary };
