import React, { useEffect } from 'react';
import { DataTable } from '../../../shared';
import { goPuffApi as api } from '../../../api/goPuff';

const ViewTMSUploads = ({ columns, data, setBulkIDs, setModalOpen, getData, setNotification }) => {
    const bulkActions = [
        {
            name: 'Bulk update',
            action: (orderIDs) => {
                setBulkIDs(orderIDs);
                setModalOpen(true);
            },
        },
    ];

    const toolbarActions = [
        {
            name: 'Export',
            action: async () => {
                const res = await api.downloadTMSPalletDataCsv();
                if (!res || !res.status) {
                    setNotification({ text: 'Failed to download csv export', level: 'error' });
                    return;
                }
            },
        },
    ];

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            <DataTable
                data={data}
                columns={columns}
                bulkActions={bulkActions}
                selectMultiple={true}
                editable={true}
                toolbarActions={toolbarActions}
                saveEdit={async ({ id, field, value }) => {
                    const item = {};
                    item[field] = value;

                    const res = await api.updateTMSPalletData(id, item);
                    if (res.status === false) {
                        setNotification({ text: 'Failed: ' + res.msg, level: 'error' });
                        return;
                    }

                    setNotification({ text: 'Success!', level: 'success' });
                    getData();
                }}
            />
        </div>
    );
};

export { ViewTMSUploads };
