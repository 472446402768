import React, { useContext, useEffect, useState } from 'react';
import { DataTable, Notifications } from '../../../../shared';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';
import { UserContext } from '../../../../contexts/UserContext';
import { CircularProgress } from '@material-ui/core';

const warehouseInventoryV2Api = new WarehouseInventoryV2Api();

const NegativeVarianceHistoryExpansion = ({ parentRow }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const { user } = useContext(UserContext);

    useEffect(() => {
        const getHistory = async (sku, start, end) => {
            setLoading(true);
            const result = await warehouseInventoryV2Api.getNegativeVarianceHistoryForSku(
                user.facilityID,
                sku,
                start,
                end
            );
            setLoading(false);
            if (result.status) {
                setRows(result.data.items);
            } else {
                setNotification({ text: result.msg, level: 'error' });
            }
        };

        const { mmSku, endTimestamp, startTimestamp } = parentRow.original;
        getHistory(mmSku, startTimestamp, endTimestamp);
    }, []);

    const columns = [
        { Header: 'Start Time', accessor: 'startingTimestamp' },
        { Header: 'End Time', accessor: 'endingTimestamp' },
        { Header: 'Message', accessor: 'message' },
        { Header: 'Starting Negative Variance', accessor: 'startingNegativeVariance' },
        { Header: 'Ending Negative Variance', accessor: 'endingNegativeVariance' },
    ];

    return (
        <div className="expanded-table-div">
            <Notifications options={notification} />
            {loading ? <CircularProgress color="inherit" size={48} /> : <DataTable columns={columns} data={rows} />}
        </div>
    );
};

export { NegativeVarianceHistoryExpansion };
