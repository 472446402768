import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { ViewItemMaster } from './components/viewItemMaster';
import { CreateItemMaster } from './components/createItemMaster';
import { ViewItemLevelTwo } from './components/viewItemLevelTwo';
import { CreateItemLevelTwo } from './components/createItemLevelTwo';
import { TabPanel, Notifications } from '../../shared/';
import { catalogApi as api } from '../../api/catalog';
import { warehouseApi } from '../../api/warehouse';

const Catalog = ({ baseUrl }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    let [notification, setNotification] = useState({ text: null, level: null });
    let [taxonomy, setTaxonomy] = useState({ l1: [], l2: [], l3: [], l4: [] });
    let [tempZones, setTempZones] = useState({ shipping: [], storage: [], receiving: [] });
    let [facilities, setFacilities] = useState([]);
    let [rows, setRows] = useState([]);
    let [items, setItems] = useState([]);

    let getTaxonomy = async () => {
        let promises = [1, 2, 3, 4].map((lvl) => api.getTaxonomyByLevel(lvl, 'TAXONOMY'));
        let res = await Promise.all(promises);
        if (res.every((item) => item.status)) {
            setTaxonomy({
                l1: res[0].data.categories,
                l2: res[1].data.categories,
                l3: res[2].data.categories,
                l4: res[3].data.categories,
            });
        }
    };

    const getTempZones = async () => {
        let res = await api.getItemTempZones();
        if (!res.status) {
            setNotification({ text: 'Failed to get item temp zones', level: 'error' });
            return;
        }

        setTempZones(res.data);
    };

    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities(true);
        if (response.status === false) {
            return;
        }

        setFacilities(
            response.data.rows.map((item) => {
                return { name: item.name, id: item.id };
            })
        );
    };

    let getItems = async (activeOnly) => {
        let response = await warehouseApi.getItemMaster(activeOnly);
        if (response.status === false) {
            setNotification({ text: 'No items found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }
        setRows(response.data.rows);
    };

    let getItemMasters = async () => {
        let itemMasterRes = await api.getItemInformation(true, 'itemMaster');
        if (itemMasterRes.status === true) {
            let items = itemMasterRes.data.items.map((item) => {
                return {
                    text: `${item.externalSku} (${item.name})`,
                    value: item,
                };
            });
            setItems(items);
        }
    };

    useEffect(() => {
        getTaxonomy();
        getTempZones();
        getFacilities();
        getItems(true);
        getItemMasters();
    }, []);

    return (
        <Paper>
            <Notifications options={notification} />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewItemMaster_Tab" label="View Item Master" value="0" />
                <Tab id="CreateItemMaster_Tab" label="Create Item Master" value="1" />
                <Tab id="ViewItemLevel2_Tab" label="View Item Level 2" value="2" />
                <Tab id="CreateItemLevel2_Tab" label="Create Item Level 2" value="3" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewItemMaster
                    taxonomy={taxonomy}
                    tempZones={tempZones}
                    facilities={facilities}
                    rows={rows}
                    getItems={getItems}
                    items={items}
                    getItemMasters={getItemMasters}
                />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateItemMaster
                    taxonomy={taxonomy}
                    tempZones={tempZones}
                    facilities={facilities}
                    items={items}
                    getItemMasters={getItemMasters}
                />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <ViewItemLevelTwo />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'3'}>
                <CreateItemLevelTwo />
            </TabPanel>
        </Paper>
    );
};

export { Catalog };
