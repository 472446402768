import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { DataTable, Notifications } from '../../../../shared';
import { ActionsCell } from './ActionsCell';
import { PalletTabExpansion } from './palletTabExpansion';
import { ActionModal } from './ActionModal';
import { Button, CircularProgress, FormControlLabel, Checkbox } from '@material-ui/core';
import { MovePalletAction } from './actions/movePalletAction';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';
import { PrintAllCaseLabelsAction } from './actions/printAllCaseLabelsAction';
import { PrintPalletLabelAction } from './actions/printPalletLabelAction';
import { QCInspectionAction } from './actions/qcInspectionAction';
import { PalletEmptyAction } from './actions/palletEmptyAction';
import { EmptyPalletCreator } from './emptyPalletCreator';

const warehouseInventoryV2Api = new WarehouseInventoryV2Api();

const PalletTab = ({ onLinkClicked, queryParams }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [loadingPallets, setLoadingPallets] = useState(false);
    const [rows, setRows] = useState([]);
    const [viewActions, setViewActions] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const { user } = useContext(UserContext);
    const [displayedType, setDisplayedType] = useState({ items: true, packaging: true });
    const [filteredRows, setFilteredRows] = useState([]);

    //search bar queryParams effect
    useEffect(() => {
        handleSearch();
    }, [queryParams]);

    useEffect(() => {
        setFilteredRows(rows);
    }, [rows]);

    useEffect(() => {
        if (displayedType.items && !displayedType.packaging) {
            setFilteredRows(rows.filter((row) => row.type !== 'PACKAGING'));
        } else if (!displayedType.items && displayedType.packaging) {
            setFilteredRows(rows.filter((row) => row.type !== 'ITEM'));
        } else if (!displayedType.items && !displayedType.packaging) {
            setFilteredRows([]);
        } else {
            setFilteredRows(rows);
        }
    }, [displayedType]);

    const handleSearch = async () => {
        //TODO handle the filtering of the row data to match queryPrams
        getPallets();
    };

    //Column Definitions
    const columns = [
        { Header: 'Pallet Id', accessor: 'palletId' },
        { Header: 'Active', accessor: 'active', type: 'checkbox' },
        { Header: 'Unique Skus', accessor: 'uniqueSkus' },
        { Header: 'Num Of Lots', accessor: 'numOfLots' },
        { Header: 'Cases', accessor: 'cases' },
        { Header: 'Loose Units', accessor: 'looseUnits' },
        { Header: 'Total Units', accessor: 'totalUnits' },
        {
            Header: 'Location',
            accessor: 'locationName',
            Cell: ({ row }) => {
                return (
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => handleLocationLinkClick(row.original.locationName)}
                    >
                        {row.original.locationName}
                    </Button>
                );
            },
        },
        { Header: 'Status', accessor: 'status' },
        {
            accessor: 'actions',
            Header: 'Actions',
            Cell: ({ row }) => (
                <ActionsCell row={row} setSelectedRow={setSelectedRow} setViewActions={setViewActions} />
            ),
        },
    ];

    //API call to GET data
    const getPallets = async () => {
        setLoadingPallets(true);
        const searchValues = [{ searchBy: queryParams.searchBy, searchTerm: queryParams.searchTerm }];

        if (queryParams.type) {
            searchValues.push({ searchBy: 'type', searchTerm: queryParams.type });
        }
        const results = await warehouseInventoryV2Api.getPallets(user.facilityID, searchValues);
        if (results.status) {
            setRows(
                results.data.pallets.map((pallet) => {
                    return {
                        palletId: pallet.palletId,
                        active: pallet.numLots > 0,
                        uniqueSkus: pallet.numSku,
                        numOfLots: pallet.numLots,
                        cases: pallet.numCases,
                        looseUnits: pallet.numLooseUnits,
                        totalUnits: pallet.totalUnits,
                        locationName: pallet.locationName,
                        locationId: pallet.locationId,
                        locInvType: pallet.inventoryType,
                        locationType: pallet.locationType,
                        status: pallet.status,
                        type: pallet.type,
                    };
                })
            );
        } else {
            setNotification({ text: results.msg, level: 'error' });
        }
        setLoadingPallets(false);
    };

    const handleLocationLinkClick = (locationName) => {
        onLinkClicked({ tabIndex: 1, searchBy: 'locationName', searchTerm: locationName });
    };

    const handleOnRowClicked = (row) => {
        setSelectedRow(row.original);
    };

    const handleModalClose = () => {
        setViewActions(false);
    };

    const handleSuccess = (message = 'Success') => {
        setNotification({ text: message, level: 'success' });
        handleModalClose();
        getPallets();
    };

    const handleDisplayTypeChange = (event) => {
        setDisplayedType({ ...displayedType, [event.target.name]: event.target.checked });
    };

    //Action Definitions
    const actions = [
        {
            text: 'Move Pallet',
            value: 'movePallet',
            componentJSX: function () {
                return <MovePalletAction palletId={selectedRow?.palletId} onSuccess={handleSuccess} />;
            },
        },
        {
            text: 'Print Pallet Label',
            value: 'printPalletLabel',
            componentJSX: function () {
                return <PrintPalletLabelAction palletId={selectedRow?.palletId} onSuccess={handleSuccess} />;
            },
        },
        {
            text: 'Create Empty Pallet',
            value: 'createEmptyPallet',
            componentJSX: function () {
                return <EmptyPalletCreator locationId={selectedRow?.locationId} />;
            },
        },
        {
            text: 'Pallet Empty',
            value: 'palletEmpty',
            componentJSX: function () {
                return (
                    <PalletEmptyAction
                        palletId={selectedRow?.palletId}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
        {
            text: 'Print All Case Labels',
            value: 'printAllCaseLabels',
            componentJSX: function () {
                return <PrintAllCaseLabelsAction palletId={selectedRow?.palletId} onSuccess={handleSuccess} />;
            },
        },
        {
            text: 'Request Inspection',
            value: 'requestInspection',
            componentJSX: function () {
                return (
                    <QCInspectionAction
                        requestInspection={true}
                        palletId={selectedRow?.palletId}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
        {
            text: 'Inspection Passed',
            value: 'inspectionPassed',
            componentJSX: function () {
                return (
                    <QCInspectionAction
                        requestInspection={false}
                        palletId={selectedRow?.palletId}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
    ];

    return (
        <>
            <Notifications options={notification} />
            {loadingPallets && <CircularProgress color="inherit" size={48} />}
            {!loadingPallets && (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox checked={displayedType.items} onChange={handleDisplayTypeChange} name="items" />
                        }
                        label="Finished Goods"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={displayedType.packaging}
                                onChange={handleDisplayTypeChange}
                                name="packaging"
                            />
                        }
                        label="Packaging"
                    />
                    <DataTable
                        csvExport={true}
                        csvExportFileName="pallet_inventory"
                        columns={columns}
                        data={filteredRows}
                        editable={false}
                        tableContainerMaxHeight={800}
                        stickyHeader={true}
                        expandable={true}
                        ExpansionComponent={
                            <PalletTabExpansion
                                palletId={selectedRow?.palletId}
                                onSuccess={handleSuccess}
                                locationType={selectedRow?.locationType}
                                locationName={selectedRow?.locationName}
                                locationId={selectedRow?.locationId}
                                locInvType={selectedRow?.inventoryType}
                                onLinkClicked={onLinkClicked}
                            />
                        }
                        onRowClicked={handleOnRowClicked}
                        toolbarEnabled={false}
                    />
                    <ActionModal
                        title={`Actions for Pallet ${selectedRow.palletId}`}
                        open={viewActions}
                        row={selectedRow}
                        actions={actions}
                        closeModal={handleModalClose}
                    />
                </>
            )}
        </>
    );
};

export { PalletTab };
