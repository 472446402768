import React, { useState, useEffect, useContext } from 'react';
import { Notifications, DataTable, FacilityPicker, FBMAccountPicker } from '../../../shared';
import { fbmApi as api } from '../../../api/fbm';
import { UserContext } from '../../../contexts/UserContext';
import { Grid, CircularProgress } from '@material-ui/core';
import { productApi } from '../../../api/product';
import { FBMItemLotDetails } from './FBMInventoryLotDetails';

const InventoryDetails = ({ parentRow }) => {
    let cols = [
        { Header: 'Lot Number', accessor: 'lotID' },
        { Header: 'Partner Lot', accessor: 'partnerLotNumber' },
        { Header: 'Received On', accessor: 'receivedAt' },
        { Header: 'Expires On', accessor: 'expiresOn' },
        { Header: 'Num Units', accessor: 'numUnits' },
    ];
    return <DataTable columns={cols} data={parentRow.original.lotDetails} />;
};

const FBMInventory = () => {
    const [rows, setRows] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [facilityID, setFacilityID] = useState(null);
    const { user, fbmAccount } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [featureFlagEnabled, setFeatureFlagEnabled] = useState(false);
    const [loadingUnits, setLoadingUnits] = useState(false);

    //This feature flag is used to only show the new FBMInventoryV2 page for facilities that have jalapop enabled.
    //This feature flag can be removed when all facilities are on Jalapop.
    const getFeatureFlag = async () => {
        setLoading(true);
        const response = await productApi.getFacilityInFeature('LOCATION_INVENTORY_PAGE_V2', facilityID);
        setLoading(false);
        if (response.status) {
            setFeatureFlagEnabled(response.data.isFacilityInFeature);
            return response.data.isFacilityInFeature;
        } else {
            setNotification({ text: response.msg, level: 'error' });
            setFeatureFlagEnabled(false);
            return false;
        }
    };

    // Load rows
    const getRows = async () => {
        setLoading(true);
        const response = await api.getInventory(facilityID, fbmAccount.fbmAccountName);
        setLoading(false);
        if (response.status === false) {
            setNotification({ text: 'Failed to get inventory ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(
            response.data.rows.map((row) => {
                return { ...row, risk: handleAtRisk(row.numUnits, row.inventoryRiskThreshold) };
            })
        );
    };

    // Load rows V2
    const getRowsV2 = async () => {
        setLoading(true);
        setLoadingUnits(true);
        try {
            const inventoryResponse = await api.getInventory(facilityID, fbmAccount.fbmAccountName);
            if (!inventoryResponse.status) {
                setNotification({ text: 'Failed to get inventory ' + inventoryResponse.msg, level: 'error' });
                setRows([]);
                setLoading(false);
                return;
            }

            // Set the initial inventory data
            const mappedRows = inventoryResponse.data.rows.map((row) => {
                return { ...row, risk: handleAtRisk(row.availableUnits, row.inventoryRiskThreshold) };
            });
            setRows(mappedRows);
            setLoading(false);

            // Get externalSkus and packagingIds based on row type
            const externalSkus = [];
            const packagingIds = [];
            mappedRows.forEach((row) => {
                if (row.type === 'Item' && row.externalSku) {
                    externalSkus.push(row.externalSku);
                } else if (row.type === 'Packaging' && row.packagingId) {
                    packagingIds.push(row.packagingId);
                }
            });

            // Make the second API call to get units information
            const unitsResponse = await api.getInventoryUnitsandLotDetails(
                facilityID,
                fbmAccount.fbmAccountName,
                externalSkus,
                packagingIds
            );

            if (!unitsResponse.status) {
                setNotification({ text: 'Failed to get inventory units ' + unitsResponse.msg, level: 'error' });
            } else {
                // Update the rows with the units information using the mappedRows from the first API call
                const updatedRows = mappedRows.map((row) => {
                    // Find matching unit data based on ID
                    const unitData = unitsResponse.data.units.find((unit) => unit.id === row.id);

                    // If we found matching unit data, merge it with the row
                    if (unitData) {
                        const updatedRow = {
                            ...row,
                            onHandUnits: unitData.onHandUnits,
                            onOrderUnits: unitData.onOrderUnits,
                            qcUnits: unitData.qcUnits,
                            availableUnits: unitData.availableUnits,
                            lotDetails: unitData.lotDetails || [],
                            risk: handleAtRisk(unitData.availableUnits, row.inventoryRiskThreshold),
                        };
                        return updatedRow;
                    }
                    return { ...row, lotDetails: [] };
                });

                setRows(updatedRows);
            }
        } catch (error) {
            setNotification({ text: 'Error fetching inventory data: ' + error.message, level: 'error' });
            setRows([]);
        } finally {
            setLoading(false);
            setLoadingUnits(false);
        }
    };

    const getFlagAndGetRows = async () => {
        const featureFlag = await getFeatureFlag();
        if (featureFlag) {
            await getRowsV2();
        } else {
            await getRows();
        }
    };

    useEffect(() => {
        if (!!user.id && !user.fbm) {
            setFacilityID(user.facilityID);
        }
    }, [user]);

    useEffect(() => {
        if (!!facilityID && !!fbmAccount.id) {
            getFlagAndGetRows();
        }
    }, [facilityID, fbmAccount]);

    const handleAtRisk = (numUnits, inventoryRiskThreshold) => {
        if (!inventoryRiskThreshold) {
            return '';
        }

        if (numUnits <= inventoryRiskThreshold) {
            return 'AT RISK';
        }

        return '';
    };

    const exportData = (rows) => {
        let csvContent =
            'Item ID,Name,Lot Number,Partner Lot,Received On,Expires On,Num Units,Risk Threshold,Risk, Status';
        csvContent += '\r\n';

        for (let row of rows) {
            const lotDetails = row.original?.lotDetails ?? [];
            if (!lotDetails || !Array.isArray(lotDetails)) {
                continue;
            }

            for (let lotDetailsRow of lotDetails) {
                const partnerLotNumber = lotDetailsRow.partnerLotNumber ?? '';

                csvContent += `"${row.original.id}","${row.original.name}","${
                    lotDetailsRow.lotID
                }","${partnerLotNumber}","${lotDetailsRow.receivedAt}","${lotDetailsRow.expiresOn}","${
                    lotDetailsRow.numUnits
                }","${row.original.inventoryRiskThreshold ?? ''}", ${handleAtRisk(
                    row.original.numUnits,
                    row.original.inventoryRiskThreshold
                )}, ${row.original.status}`;
                csvContent += '\r\n';
            }
        }

        let filename = `inventory.csv`;
        var file = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (window.navigator.msSaveOrOpenBlob)
            // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else {
            // Others
            var a = document.createElement('a'),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    };

    const cols = [
        { accessor: 'id', Header: 'Item ID' },
        { accessor: 'type', Header: 'Type' },
        { accessor: 'name', Header: 'Name' },
        { accessor: 'numUnits', Header: 'Units' },
        {
            accessor: 'inventoryRiskThreshold',
            Header: 'Risk Threshold',
            editable: true,
            editProps: { type: 'input', inputType: 'number', min: 0, integer: true },
        },
        { accessor: 'risk', Header: 'Risk' },
        { accessor: 'status', Header: 'Status' },
    ];

    const colsV2 = [
        { accessor: 'id', Header: 'Item ID' },
        { accessor: 'type', Header: 'Type' },
        { accessor: 'name', Header: 'Name' },
        {
            accessor: 'inventoryRiskThreshold',
            Header: 'Risk Threshold',
            editable: true,
            editProps: { type: 'input', inputType: 'number', min: 0, integer: true },
        },
        {
            accessor: 'risk',
            Header: 'Risk',
            Cell: ({ value, row }) =>
                loadingUnits ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={20} />
                    </div>
                ) : (
                    row.original.risk ?? ''
                ),
        },
        {
            Header: 'On-Hand Units',
            accessor: 'onHandUnits',
            Cell: ({ value, row }) =>
                loadingUnits ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={20} />
                    </div>
                ) : (
                    row.original.onHandUnits ?? 0
                ),
        },
        {
            Header: 'Order Allocated Units',
            accessor: 'onOrderUnits',
            Cell: ({ value, row }) =>
                loadingUnits ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={20} />
                    </div>
                ) : (
                    row.original.onOrderUnits ?? 0
                ),
        },
        {
            Header: 'QC Audit Units',
            accessor: 'qcUnits',
            Cell: ({ value, row }) =>
                loadingUnits ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={20} />
                    </div>
                ) : (
                    row.original.qcUnits ?? 0
                ),
        },
        {
            Header: 'Available Units',
            accessor: 'availableUnits',
            Cell: ({ value, row }) =>
                loadingUnits ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={20} />
                    </div>
                ) : (
                    row.original.availableUnits ?? 0
                ),
        },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item xs={12}>
                <FBMAccountPicker />
            </Grid>
            {!!fbmAccount.id && (
                <>
                    <Grid item md={6} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                        <h1>{fbmAccount.companyName} Inventory</h1>
                    </Grid>
                    <Grid item md={6} style={{ textAlign: 'right', paddingRight: '35px' }}>
                        {user.fbm && (
                            <FacilityPicker
                                defaultValue={fbmAccount.facilityIDs[0]}
                                whitelistedFacilities={fbmAccount.facilityIDs}
                                activeOnly={true}
                                onChange={(facilityID, text, facility) => {
                                    setFacilityID(facilityID);
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <DataTable
                                editable={true}
                                expandable={true}
                                ExpansionComponent={featureFlagEnabled ? <FBMItemLotDetails /> : <InventoryDetails />}
                                csvExport={true}
                                columns={featureFlagEnabled ? colsV2 : cols}
                                data={rows}
                                customExport={exportData}
                                saveEdit={async ({ field, value, row }) => {
                                    const res = await api.updateItemInventoryRiskThreshold(
                                        fbmAccount.fbmAccountName,
                                        row.id,
                                        value,
                                        facilityID,
                                        row.type
                                    );
                                    if (res.status === false) {
                                        setNotification({ text: 'Error updating Inventory', level: 'error' });
                                    } else {
                                        setNotification({ text: 'Inventory Updated!', level: 'success' });
                                        featureFlagEnabled ? await getRowsV2() : await getRows();
                                    }
                                    return res.status;
                                }}
                            />
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export { FBMInventory };
