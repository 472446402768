import React, { useState } from 'react';
import {
    Box,
    makeStyles
} from '@material-ui/core';
import { SearchableSelect } from '../../../../../../shared/components/SearchableSelect';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1)
    }
}));

const SearchableSelectFilter = ({
    type,
    name,
    deletable = true,
    onFilterChange,
    options = [],
    placeholder = 'Select...',
    loading = false
}) => {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState(null);

    // Transform options to match SearchableSelect format
    const formattedOptions = options.map(option => ({
        label: option,
        value: option
    }));

    const handleChange = (newValue) => {
        setSelectedValue(newValue);
        
        if (!newValue) {
            onFilterChange(null);
            return;
        }

        onFilterChange({
            type,
            data: newValue.value,
            chipLabel: `${name}: ${newValue.value}`,
            deletable
        });
    };

    return (
        <Box className={classes.container}>
            <SearchableSelect
                options={formattedOptions}
                onChange={handleChange}
                loading={loading}
                label={placeholder}
                value={selectedValue}
            />
        </Box>
    );
};

export { SearchableSelectFilter }; 