import { Button } from '@material-ui/core';
import React from 'react';

const CustomerBulkTemplateDownload = ({ csvColumns, fileName }) => {
    return (
        <Button
            onClick={() => {
                let csvContent = `data:text/csv;charset=utf-8,${csvColumns}`;
                var encodedUri = encodeURI(csvContent);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', `${fileName}_template.csv`);
                document.body.appendChild(link);

                link.click();
            }}
        >
            Download Template
        </Button>
    );
};

export { CustomerBulkTemplateDownload };
