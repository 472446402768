import React, { useState } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { csvUtil as util } from '../../../shared/utils/csvUtil';
import { customerApi as api } from '../../../api/customer';
import { CustomerBulkTemplateDownload } from './CustomerBulkTemplateDownload';

const MassRefund = () => {
    const [notification, setNotification] = useState({ text: null, level: null });
    let [reasonOps, setReasonOps] = useState([
        'BULK_DAMAGE',
        'BULK_DOUBLE_DELAY',
        'BULK_MISSING_ORDER',
        'BULK_SUBSTITUTION',
        'BULK_WEATHER',
        'OTHER',
    ]);
    const formFields = [
        {
            name: 'reason',
            inputElement: 'select',
            label: 'Reason',
            gridValue: 8,
            inputProps: { required: true, opts: reasonOps },
        },
        {
            name: 'refundIDFile',
            inputElement: 'file',
            label: 'Order Number File',
            gridValue: 12,
            inputProps: { required: true },
        },
    ];

    return (
        <div>
            <h1>Mass Refund</h1>
            <Notifications options={notification} />
            <CustomerBulkTemplateDownload csvColumns={'Order Number'} fileName={'mass_refund'} />
            <div>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        if (formData?.refundIDFile === null) {
                            setNotification({
                                text: 'Please Upload a CSV File containing a list of Order Numbers.',
                                level: 'warning',
                            });
                            return;
                        }
                        const fileReader = new FileReader();
                        fileReader.readAsText(formData?.refundIDFile, 'UTF-8');
                        fileReader.onload = async (event) => {
                            const csvData = event.target.result;

                            const cleanCsvResults = await util.cleanCsv(csvData);
                            if (cleanCsvResults?.text && cleanCsvResults?.level) {
                                setNotification(cleanCsvResults);
                                return;
                            }

                            const orderIDValidationResults = await util.csvColumnIntegerValidation(
                                cleanCsvResults,
                                'Order Number'
                            );
                            if (orderIDValidationResults?.text && orderIDValidationResults?.level) {
                                setNotification(orderIDValidationResults);
                                return;
                            }

                            const response = await api.massFullRefund(formData?.refundIDFile, formData?.reason);
                            if (response.status === false) {
                                setNotification({ text: 'Unable to refund ' + response.msg, level: 'error' });
                                return;
                            }

                            setNotification({ text: 'Success!', level: 'success' });
                            resetForm();
                        };
                    }}
                />
            </div>
        </div>
    );
};

export { MassRefund };
