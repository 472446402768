import React, { useState } from 'react';
import { Paper, Tab, Tabs } from '@material-ui/core';

import { TabPanel } from '../../../../shared';

import { AssignCustomersToOffer } from './AssignCustomersToOffer';
import { ViewPlusMembershipOffers } from './ViewPlusMembershipOffers';
import { CreatePlusMembershipOffer } from './CreatePlusMembershipOffer';

const TabValues = Object.freeze({
    VIEW_OFFERS: 'view-offers',
    ASSIGN_CUSTOMERS_TO_OFFER: 'assign-customers-to-offer',
    CREATE_OFFER: 'create-offer',
});

function PlusMembershipOffers() {
    let [selectedTab, setSelectedTab] = useState(TabValues.VIEW_OFFERS);

    return (
        <Paper>
            <Tabs
                onChange={(_, value) => setSelectedTab(value)}
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="View Offers" value={TabValues.VIEW_OFFERS} />
                <Tab label="Create Offer" value={TabValues.CREATE_OFFER} />
                <Tab label="Assign Customers" value={TabValues.ASSIGN_CUSTOMERS_TO_OFFER} />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={TabValues.VIEW_OFFERS}>
                <ViewPlusMembershipOffers />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={TabValues.CREATE_OFFER}>
                <CreatePlusMembershipOffer />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={TabValues.ASSIGN_CUSTOMERS_TO_OFFER}>
                <AssignCustomersToOffer />
            </TabPanel>
        </Paper>
    );
}

export { PlusMembershipOffers };
