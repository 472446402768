import { LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState, useRef, useEffect, useCallback } from 'react';

const ExpandableDataGrid = ({
    rows,
    columns,
    childRows,
    childColumns,
    onSelectedRowsChange,
    isLoading,
    isChildrenLoading,
    childGridTitle = '',
    selectedRow,
    onSelectedRowChange,
}) => {
    const [childGridPosition, setChildGridPosition] = useState(null);
    const mainGridRef = useRef(null);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (!(onSelectedRowsChange instanceof Function)) {
            console.error('ExpandableDataGrid initialized incorrectly onSelectedRowsChange is not a function');
        }
        onSelectedRowsChange(selectionModel);
    }, [selectionModel]);

    const updateChildGridPosition = useCallback((params) => {
        const rowElement = document.querySelector(`[data-id="${params.id}"]`);
        if (rowElement && mainGridRef.current) {
            const rowRect = rowElement.getBoundingClientRect();
            const gridRect = mainGridRef.current.getBoundingClientRect();

            setChildGridPosition({
                top: rowRect.bottom - gridRect.top,
                left: 0,
                width: gridRect.width,
            });
        }
    }, []);

    const handleRowClick = useCallback(
        (params, event) => {
            // If clicked on the checkbox column or the event is from checkbox click, do nothing
            if (params.field === '__check__' || event.target.type === 'checkbox') {
                return;
            }

            // Stop default grid behavior
            event.stopPropagation();
            event.preventDefault();

            if (childRows && childColumns) {
                // If clicking the same row that's already selected, close it
                if (selectedRow?.id === params.row.id) {
                    onSelectedRowChange(undefined);
                    setChildGridPosition(null);
                    return;
                }

                // Otherwise, select the new row
                onSelectedRowChange(params.row);
                updateChildGridPosition(params);
            }
        },
        [childRows, childColumns, selectedRow, onSelectedRowChange, updateChildGridPosition]
    );

    // Reset child grid position when selected row becomes undefined
    useEffect(() => {
        if (!selectedRow) {
            setChildGridPosition(null);
        }
    }, [selectedRow]);

    // Modify action columns to prevent row expansion
    const columnsWithModifiedActions = columns.map((column) => {
        if (column.field === 'actions') {
            return {
                ...column,
                renderCell: (params) => {
                    // Use the original renderCell
                    const originalRenderCell = column.renderCell;
                    if (originalRenderCell) {
                        const originalContent = originalRenderCell(params);
                        // Clone the button and add stopPropagation
                        return React.cloneElement(originalContent, {
                            onClick: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                // Call the original onClick if it exists
                                if (originalContent.props.onClick) {
                                    originalContent.props.onClick(e);
                                }
                            },
                        });
                    }
                    return null;
                },
            };
        }
        return column;
    });

    // Pagination options for correct menu display
    const paginationOptions = {
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        pageSize: 10,
        pagination: true,
    };

    return (
        <div style={{ position: 'relative' }}>
            <div ref={mainGridRef}>
                {isLoading && <LinearProgress />}
                <DataGrid
                    rows={rows || []}
                    columns={columnsWithModifiedActions}
                    checkboxSelection
                    autoHeight
                    onRowClick={handleRowClick}
                    checkboxSelectionVisibleOnly={true}
                    disableRowSelectionOnClick={true}
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    pageSizeOptions={paginationOptions.rowsPerPageOptions}
                    initialState={{
                        pagination: {
                            pageSize: paginationOptions.pageSize,
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-menu': {
                            flexDirection: 'column',
                        },
                    }}
                    disableColumnSelector={true}
                />
            </div>

            {selectedRow && childGridPosition && (
                <div
                    style={{
                        position: 'absolute',
                        top: `${childGridPosition.top}px`,
                        left: `${childGridPosition.left}px`,
                        width: `${childGridPosition.width}px`,
                        zIndex: 1000,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '4px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #ddd',
                        padding: '8px',
                        marginTop: '4px',
                    }}
                >
                    <div style={{ fontWeight: 'bold', marginBottom: '8px', padding: '4px' }}>{childGridTitle}</div>
                    {isChildrenLoading && <LinearProgress />}
                    {!isChildrenLoading && (
                        <DataGrid
                            rows={childRows || []}
                            columns={childColumns}
                            autoHeight
                            hideFooter
                            disableColumnMenu
                            disableColumnSelector
                            disableSelectionOnClick
                            sx={{
                                '& .MuiDataGrid-menu': {
                                    flexDirection: 'column',
                                },
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default ExpandableDataGrid;
