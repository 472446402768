import React from 'react';

const MissingLotDetailCell = ({ row }) => {
    const result = row?.expectedResults?.[0];
    const fromLocationEmptyModal = row?.metadata?.fromLocationEmpty;

    return (
        <div style={{ margin: '10px 0px' }}>
            <div style={{ textDecoration: 'underline', textAlign: 'center', fontWeight: 'bold' }}>
                Lot {result?.expectedLotID} Moved To Missing
            </div>
            {fromLocationEmptyModal && <div style={{ fontWeight: 'bold' }}>From Location Empty</div>}
            <div>
                <strong>Item: </strong>
                {result?.itemName}
            </div>
            <div key={result.expectedResultID}>
                {result?.expectedPalletID && (
                    <div>
                        <strong>From Pallet: </strong>
                        {result?.expectedPalletID}
                    </div>
                )}
                <div>
                    <strong>Units Moved: </strong>
                    {result?.totalExpectedUnits} units ({result?.expectedCaseCount} cases,{' '}
                    {result?.expectedLooseUnitCount} loose units)
                </div>
            </div>
        </div>
    );
};

export { MissingLotDetailCell };
