import React, { useEffect, useState } from 'react';
import { Notifications } from '../../../../shared';
import { warehouseApi } from '../../../../api/warehouse';
import { Button, CircularProgress, Typography, Box } from '@material-ui/core';
import { WarehouseLabelApi } from '../../../../api/warehouseLabels';
import printJS from 'print-js';
import { CopyToClipboard } from '../../../../shared/components/copyToClipboard';

const labelApi = new WarehouseLabelApi();

const EmptyPalletCreator = ({ locationId, onSuccess = null }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [creatingPallet, setCreatingPallet] = useState(false);
    const [createdPalletId, setCreatedPalletId] = useState(null);
    const [printingPallet, setPrintingPallet] = useState(false);

    useEffect(() => {
        setCreatedPalletId(null);
    }, [locationId]);

    useEffect(() => {
        if (createdPalletId && onSuccess instanceof Function) {
            onSuccess();
        }
    }, [createdPalletId]);

    const fileReaderCheck = (fr, tmpPDF) => {
        if (tmpPDF instanceof Blob) {
            fr.readAsDataURL(tmpPDF);
        } else {
            setNotification({ text: 'There was an issue printing the pdf', level: 'error' });
        }
    };

    const printPalletTag = async () => {
        setPrintingPallet(true);
        const result = await labelApi.getPalletLabels({ palletID: createdPalletId, qty: 1 });
        setPrintingPallet(false);
        if (result) {
            var fr = new FileReader();
            fr.addEventListener(
                'load',
                () => {
                    const tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                    printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                },
                false
            );
            fileReaderCheck(fr, result);
        } else {
            setNotification({ text: 'There was an issue printing the pdf', level: 'error' });
        }
    };

    const handleSubmit = async () => {
        setCreatingPallet(true);
        const result = await warehouseApi.createNewPalletForLocation(locationId);
        setCreatingPallet(false);
        if (result?.status) {
            setCreatedPalletId(result.data.palletId ?? null);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    return (
        <div>
            <Notifications options={notification} />
            {createdPalletId ? (
                <Box>
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                        <Typography style={{ color: 'green' }}>Empty pallet {createdPalletId} created.</Typography>
                        <CopyToClipboard value={createdPalletId} />
                    </Box>
                    <Button onClick={printPalletTag}>
                        {printingPallet ? <CircularProgress size={24} /> : 'Print Pallet Tag'}
                    </Button>
                </Box>
            ) : (
                <Button onClick={handleSubmit} disabled={creatingPallet}>
                    {creatingPallet ? <CircularProgress size={24} /> : 'Create Empty Pallet'}
                </Button>
            )}
        </div>
    );
};

export { EmptyPalletCreator };
