import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { Button } from '@material-ui/core';
import { fbmApi as api } from '../../../api/fbm';
import { warehouseApi } from '../../../api/warehouse';
import { procurementApi as procureApi } from '../../../api/procurement';
import { UserContext } from '../../../contexts/UserContext';

import moment from 'moment';

let CreateASN = ({ initialValues = {}, onSuccess }) => {
    let [error, setError] = useState(null);
    let [shippers, setShippers] = useState([]);
    let [facilities, setFacilities] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [schedDeliveryDate, setSchedDeliveryDate] = useState();
    let { user, fbmAccount } = useContext(UserContext);

    useEffect(() => {
        if (fbmAccount?.id) {
            getShippers();
        }
    }, [fbmAccount]);

    // Master  form submit function
    let createPurchaseOrder = async (formData, resetForm) => {
        if (formData.itemsInPO.length === 0) {
            setNotification({ text: 'Must provide at least 1 item', level: 'error' });
            return;
        }

        for (let item of formData.itemsInPO) {
            if (!item.qty) {
                setNotification({ text: 'Please complete all required item fields', level: 'error' });
                return;
            }

            if (!item.expectedExpirationDate) {
                setNotification({ text: 'Please fill in expected expiration date', level: 'error' });
                return;
            }
        }

        let sendParams = {
            deliverDate: formData.scheduledDeliveryDate,
            deliverTime: formData.scheduledTime,
            specialInstructions: formData.specialInstr,
            inboundShipperID: formData.shipper,
            facilityID: formData.facility,
            items: formData.itemsInPO,
            minTempReq: formData.minTempReq,
            maxTempReq: formData.maxTempReq,
            shippingReference: formData.shippingReference
        };

        if (!user.fbm) {
            sendParams.fbmAccount = fbmAccount.fbmAccountName;
        }

        let response = await api.createPurchaseOrder(sendParams);
        if (response.status === false) {
            let message = 'Error creating ASN';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        } else {
            setNotification({ text: 'ASN created!', level: 'success' });
            resetForm();

            if (onSuccess && onSuccess instanceof Function) {
                onSuccess();
            }
        }
    };

    useEffect(() => {
        getShippers();
        getFacilities();
    }, []);

    // Load shippers
    let getShippers = async () => {
        let response = await procureApi.getShippers();
        if (response.status === false) {
            setNotification({ text: 'No shippers found ' + response.msg, level: 'error' });
            setShippers([]);
            return;
        }

        let allowedShipperIDs = fbmAccount.inboundShipperIDs;

        let shippersAsOpts = response.data.rows
            .map((row) => {
                row.text = row.name;
                row.value = row.id;
                return row;
            })
            .filter((item) => {
                return allowedShipperIDs.includes(item.value);
            });

        setShippers(shippersAsOpts);
    };

    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities(true);
        if (!response.status) {
            setNotification({ text: 'Failed to get facilities', level: 'error' });
            return;
        }

        let allowedFacilityIDs = fbmAccount.facilityIDs;

        let facilitiesAsOpts = response.data.rows
            .map((row) => {
                row.text = row.name;
                row.value = row.id;
                return row;
            })
            .filter((facility) => {
                return allowedFacilityIDs.includes(facility.value);
            });

        setFacilities(facilitiesAsOpts);
    };

    // Define the form
    let formFields = [
        {
            name: 'scheduledDeliveryDate',
            inputElement: 'date',
            label: 'Scheduled Delivery Date',
            gridValue: 3,
            inputProps: { required: true, onChange: (e) => setSchedDeliveryDate(e.target.value) },
        },
        {
            name: 'scheduledTime',
            inputElement: 'time',
            label: 'Scheduled delivery time',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'minTempReq',
            inputElement: 'textField',
            label: 'Min Temperature',
            gridValue: 3,
            initialValue: initialValues.minTempReq || undefined,
            inputProps: { type: 'number' },
        },
        {
            name: 'maxTempReq',
            inputElement: 'textField',
            label: 'Max Temperature',
            gridValue: 3,
            initialValue: initialValues.maxTempReq || undefined,
            inputProps: { type: 'number' },
        },
        {
            name: 'shipper',
            inputElement: 'autocomplete',
            label: 'Shipper',
            gridValue: 2,
            initialValue: initialValues.shipperID
                ? { text: initialValues.shipperName, value: initialValues.shipperID }
                : undefined,
            inputProps: { required: true, opts: shippers },
        },
        {
            name: 'facility',
            inputElement: 'select',
            label: 'Facility',
            gridValue: 2,
            inputProps: { required: true, opts: facilities },
        },
        { name: 'specialInstr', inputElement: 'textField', label: 'Special Instructions', gridValue: 8 },
        { name: 'shippingReference', inputElement: 'textField', label: 'Reference Number', gridValue: 8 },
        {
            name: 'itemsInPO',
            label: 'Items',
            inputElement: ({ formData, setFormField }) => (
                <AddItemMasters
                    setNotification={setNotification}
                    initialValue={initialValues}
                    formData={formData}
                    setFormField={setFormField}
                    schedDeliveryDate={schedDeliveryDate}
                />
            ),
        },
    ];

    return (
        <div>
            <h1>Create {fbmAccount.companyName} ASN</h1>
            <Notifications options={notification} />
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        createPurchaseOrder(formData, resetForm);
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </div>
    );
};

let AddItemMasters = ({ setNotification, setFormField, formData, initialValue, schedDeliveryDate }) => {
    let [itemOptions, setItemOptions] = useState([]);
    let [itemMaster, setItemMaster] = useState([]);
    const [currentItem, setCurrentItem] = useState();
    const { fbmAccount } = useContext(UserContext);

    let initValue = [];
    if (initialValue && initialValue.additionalData) {
        initValue = initialValue.additionalData.map((item) => {
            item.week = '';
            item.expectedExpirationDate = '';
            item.itemMasterName = item.itemName;
            return item;
        });
    }
    let [itemsInPO, setItemsInPO] = useState(initValue);

    let clearItems = () => {
        setItemsInPO([]);
    };

    useEffect(() => {
        // reset form (after submit) clears formData.itemsInPO, but does not reset internal itemsInPO
        // we need to do this manually when the form data has been cleared and there is no initial value
        if (itemsInPO.length && !formData.itemsInPO && !initValue.length) {
            clearItems();
        }
    }, [formData]);

    let getItemMaster = async () => {
        let itemMasterRes = await api.getItems(fbmAccount.fbmAccountName);
        if (itemMasterRes.status === false) {
            return;
        } else {
            let items = itemMasterRes.data.rows.map((item) => {
                return { text: `SKU ${item.thirdPartyItemID}: ${item.name}`, value: item.thirdPartyItemID };
            });
            setItemMaster(itemMasterRes.data.rows);
            setItemOptions(items);
        }
    };
    let getCsPalletFromSku = async (sku) => {
        let itemFind = itemMaster.find((item) => item.thirdPartyItemID == sku);
        return itemFind.csPallet;
    };

    let handleCheckActiveAndPrintedExpDate = async (event, value) => {
        if (!value) {
            return;
        }
        let itemSku = value.value;
        let findItem = itemMaster.find((item) => item.sku === itemSku);
        setCurrentItem(findItem);

        let schedDate = moment(schedDeliveryDate);
    };

    // Add item submit function
    let addItemSubmit = async (formData) => {
        // Add to data table
        let sellItem = {};
        sellItem.sku = formData.sku;
        sellItem.itemMasterName = formData.sku;
        sellItem.qty = formData.itemQty;
        sellItem.unitsPerCase = formData.unitsPerCase;
        sellItem.csPallet = (await getCsPalletFromSku(formData.sku)) || 80;
        sellItem.expectedExpirationDate = formData.expectedExpirationDate;
        sellItem.numOfPallets = formData.numOfPallets;
        sellItem.partnerLotNumber = formData.partnerLotNumber;
        sellItem.requireInspectionUponReceipt = formData.requireInspectionUponReceipt;

        let items = itemsInPO.concat(sellItem);
        setItemsInPO(items);
    };

    // Remove an item from the PO
    let removeItemPressed = async (row) => {
        var array = [...itemsInPO];
        array.splice(row.index, 1);
        setItemsInPO(array);
    };

    useEffect(() => {
        getItemMaster();
        if (initialValue.length) {
            setFormField(initialValue);
        }
    }, []);

    useEffect(() => {
        setFormField(itemsInPO);
    }, [itemsInPO]);

    let itemMasterFormFields = [
        {
            name: 'sku',
            inputElement: 'autocomplete',
            label: 'SKU',
            gridValue: 4,
            inputProps: { required: true, opts: itemOptions, onChange: handleCheckActiveAndPrintedExpDate },
        },
        { name: 'itemQty', inputElement: 'textField', label: 'Quantity (cases)', gridValue: 2 },
        {
            name: 'unitsPerCase',
            inputElement: 'textField',
            label: 'Units Per Case',
            gridValue: 1,
            inputProps: { type: 'number' },
        },
        {
            name: 'numOfPallets',
            inputElement: 'textField',
            label: 'Num Of Pallets',
            gridValue: 1,
            inputProps: { type: 'number', integer: true },
        },
        { name: 'expectedExpirationDate', inputElement: 'date', label: 'Expected Expiration Date', gridValue: 2 },
        {
            name: 'partnerLotNumber',
            inputElement: 'textField',
            label: 'Partner Lot Number',
            gridValue: 1,
        },
        {
            name: 'requireInspectionUponReceipt',
            inputElement: 'checkbox',
            label: 'Require Inspection',
            gridValue: 2,
        },
    ];

    let sellableItemColumns = [
        { accessor: 'sku', Header: 'SKU' },
        { accessor: 'itemMasterName', Header: 'Name' },
        {
            accessor: 'qty',
            Header: 'Quantity (cases)',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: true },
        },
        {
            accessor: 'unitsPerCase',
            Header: 'Units Per Case',
            editable: true,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'csPallet',
            Header: 'Cases per pallet',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: true },
        },
        {
            accessor: 'numOfPallets',
            Header: 'Num Of Pallets',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'expectedExpirationDate',
            Header: 'Expected Expiration Date',
            editable: true,
            editProps: { type: 'input', inputType: 'date' },
        },
        {
            accessor: 'partnerLotNumber',
            Header: 'Partner Lot Number',
            editable: true,
            editProps: { type: 'input' },
        },
        {
            accessor: 'requireInspectionUponReceipt',
            Header: 'Require Inspection',
            editable: true,
            type: 'checkbox',
        },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Remove_${row.id}`}
                        onClick={() => {
                            removeItemPressed(row);
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <h3>Items In ASN</h3>
            <FormComponent
                formFields={itemMasterFormFields}
                onSubmit={async (formData, resetForm) => {
                    addItemSubmit(formData);
                    resetForm();
                }}
                button={{ text: 'Add', gridValue: 1 }}
            />
            <DataTable
                columns={sellableItemColumns}
                data={itemsInPO}
                editable={true}
                saveEdit={({ id, field, value, row }) => {
                    let items = JSON.parse(JSON.stringify(itemsInPO)).map((item) => {
                        if (item.sku === row.sku) {
                            item[field] = value;
                        }
                        return item;
                    });
                    setItemsInPO(items);
                }}
            />
        </div>
    );
};

export { CreateASN };
