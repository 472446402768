import React, { useState } from 'react';
import {
    makeStyles,
    TextField,
    IconButton,
    Box,
    Tooltip,
    Chip
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1)
    },
    inputRow: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center'
    },
    input: {
        flex: 1
    },
    chipsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(0.5),
        marginTop: theme.spacing(1)
    },
    chip: {
        height: '24px'  // Make chips smaller
    }
}));

const TextInputFilter = ({
    type,
    name,
    deletable = true,
    onFilterChange,
    placeholder = 'Enter value...',
    validateInput = (value) => true,
    formatValue = (value) => value,
    errorMessage = 'Invalid input',
    multiple = false
}) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        setError(false);

        if (!multiple) {
            const trimmedValue = value.trim();
            if (!trimmedValue) {
                onFilterChange(null);
                return;
            }

            if (!validateInput(trimmedValue)) {
                setError(true);
                return;
            }

            const formattedValue = formatValue(trimmedValue);
            onFilterChange({
                type,
                data: formattedValue,
                chipLabel: `${name}: ${formattedValue}`,
                deletable
            });
        }
    };

    const handleSubmit = () => {
        const trimmedValue = inputValue.trim();
        if (!trimmedValue) return;

        if (!validateInput(trimmedValue)) {
            setError(true);
            return;
        }

        const formattedValue = formatValue(trimmedValue);
        
        // Don't add duplicates
        if (!selectedValues.includes(formattedValue)) {
            const newValues = [...selectedValues, formattedValue];
            setSelectedValues(newValues);
            
            // Send all values to parent
            onFilterChange(newValues.map(value => ({
                type,
                data: value,
                chipLabel: `${name}: ${value}`,
                deletable
            })));
        }
        
        setInputValue('');
        setError(false);
    };

    const handleDeleteChip = (valueToDelete) => {
        const newValues = selectedValues.filter(value => value !== valueToDelete);
        setSelectedValues(newValues);
        
        if (newValues.length === 0) {
            onFilterChange(null);
        } else {
            onFilterChange(newValues.map(value => ({
                type,
                data: value,
                chipLabel: `${name}: ${value}`,
                deletable
            })));
        }
    };

    const handleClear = () => {
        setInputValue('');
        setError(false);
        if (!multiple) {
            onFilterChange(null);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && multiple) {
            event.preventDefault();
            handleSubmit();
        }
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.inputRow}>
                <TextField
                    className={classes.input}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    placeholder={placeholder}
                    error={error}
                    helperText={error ? errorMessage : ''}
                    size="small"
                    variant="outlined"
                />
                {multiple ? (
                    <Tooltip title="Add">
                        <IconButton
                            size="small"
                            onClick={handleSubmit}
                            disabled={!inputValue.trim() || error}
                            color="primary"
                        >
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                ) : inputValue && (
                    <Tooltip title="Clear">
                        <IconButton
                            size="small"
                            onClick={handleClear}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            {multiple && selectedValues.length > 0 && (
                <Box className={classes.chipsContainer}>
                    {selectedValues.map((value) => (
                        <Chip
                            key={value}
                            label={value}
                            onDelete={() => handleDeleteChip(value)}
                            size="small"
                            className={classes.chip}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export { TextInputFilter }; 