import { mmFetch } from './mmFetch';

class WarehouseLabelApi {
    constructor() {
        this.baseUrl = 'api/warehouse/v1/label/';
    }

    async getCaseLabels({ lotID, palletID, qty }) {
        let url = `${this.baseUrl}case`;
        if (lotID) {
            url += `?lotID=${lotID}`;
            if (qty) {
                url += `&qty=${qty}`;
            }
        } else if (palletID) {
            url += `?palletID=${palletID}`;
        }

        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                return blob;
            }
        } catch (e) {
            console.log('[getCaseLabels] error', e.toString());
        }
    }

    async getPalletLabelsByLotId({ lotId, qty }) {
        const url = `${this.baseUrl}pallet?lotId=${lotId}&qty=${qty}`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                return blob;
            }
        } catch (e) {
            console.log('[getPalletLabels] error', e.toString());
        }
    }

    async getPalletLabels({ palletID, qty = 1 }) {
        const url = `${this.baseUrl}pallet?id=${palletID}&qty=${qty}`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                return blob;
            }
        } catch (e) {
            console.log('[getPalletLabels] error', e.toString());
        }
    }

    async getMultiplePalletLabels(palletIds) {
        const palletIdString = palletIds.join(',');
        const url = `${this.baseUrl}pallet?ids=${palletIdString}`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                return blob;
            }
        } catch (e) {
            console.log('[getPalletLabels] error', e.toString());
        }
    }
}

export { WarehouseLabelApi };
