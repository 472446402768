import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { ViewVendor } from './viewVendor';
import { IncompleteVendor } from './incompleteVendor';
import { TabPanel } from '../../../shared';

const Vendor = ({ baseUrl }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewVendor_Tab" label="View Vendor" value="0" />
                <Tab id="IncompleteVendors_Tab" label="Incomplete Vendors" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewVendor />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <IncompleteVendor />
            </TabPanel>
        </Paper>
    );
};

export { Vendor };
