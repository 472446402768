import React from 'react';
import { AdjustCasesAndLooseUnitsAction } from '../../locationInventoryV2Components/actions/adjustCasesAndLooseUnitsAction';
import { AcknowledgeLocationIssueAction } from '../../locationInventoryV2Components/actions/cycleCountAcknowledgeIssueAction';
import { MoveCasesAndLooseUnitsFromKnownOriginAction } from '../../locationInventoryV2Components/actions/moveCasesAndLooseUnitsAction';
import { MoveCasesAndLooseUnitsToKnownDestinationAction } from '../../locationInventoryV2Components/actions/moveCasesAndLooseUnitsToKnownDestinationAction';
import { AdjustFromZeroAction } from '../../locationInventoryV2Components/actions/adjustFromZeroAction';
import { MovePalletAction } from '../../locationInventoryV2Components/actions/movePalletAction';
import { CycleCountApi } from '../../../../../api/cycleCount';

const cycleCountApi = new CycleCountApi();

const getMovePalletActionDefinition = (currentIssue, handleCancel, handleSuccess) => {
    return {
        text: `Move Pallet ${currentIssue?.expectedResults[0]?.expectedPalletID} from MISSING`,
        value: 'movePallet',
        componentJSX: function () {
            return (
                <MovePalletAction
                    palletId={currentIssue?.expectedResults[0]?.expectedPalletID}
                    onSuccess={handleSuccess}
                />
            );
        },
    };
};

const getMoveLotFromActionDefinition = (
    currentIssue,
    handleCancel,
    handleSuccess,
    fromLocationId,
    fromLocationName,
    fromPalletId
) => {
    return {
        text: `Move Lot ${currentIssue?.expectedResults[0]?.expectedLotID} FROM ${fromLocationName}`,
        value: 'moveFromLocation',
        componentJSX: function () {
            return (
                <MoveCasesAndLooseUnitsFromKnownOriginAction
                    lotId={currentIssue?.expectedResults[0]?.expectedLotID}
                    locationId={fromLocationId}
                    locationName={fromLocationName}
                    fromPalletId={fromPalletId}
                    locInvType={fromPalletId ? 'Pallet' : 'Case'}
                    onSuccess={handleSuccess}
                    onCancel={handleCancel}
                />
            );
        },
    };
};

const getAdjustFromZeroActionDefinition = (currentIssue, handleCancel, handleSuccess) => {
    return {
        text: 'Adjust From Zero',
        value: 'adjustFromZero',
        componentJSX: function () {
            return (
                <AdjustFromZeroAction
                    lotId={currentIssue?.expectedResults[0]?.expectedLotID}
                    locationId={currentIssue?.locationID}
                    locationName={currentIssue?.locationName}
                    palletId={currentIssue?.expectedResults[0]?.expectedPalletID}
                    onSuccess={handleSuccess}
                    onCancel={handleCancel}
                    reason={'Cycle Count Adjustment'}
                    initialNumCases={currentIssue?.expectedResults[0]?.actualCaseCount}
                    initialNumUnits={currentIssue?.expectedResults[0]?.actualLooseUnitCount}
                />
            );
        },
    };
};

const getAdjustActionDefinition = (currentIssue, handleCancel, handleSuccess) => {
    return {
        text: 'Adjust',
        value: 'adjust',
        componentJSX: function () {
            return (
                <AdjustCasesAndLooseUnitsAction
                    locationId={currentIssue?.locationID}
                    palletId={currentIssue?.expectedResults[0]?.expectedPalletID}
                    lotId={currentIssue?.expectedResults[0]?.expectedLotID}
                    caseNum={currentIssue?.expectedResults[0]?.actualCaseCount}
                    looseUnitNum={currentIssue?.expectedResults[0]?.actualLooseUnitCount}
                    onSuccess={handleSuccess}
                    onCancel={handleCancel}
                    initialReason={'Cycle Count Adjustment'}
                />
            );
        },
    };
};

const getMoveLotToActionDefinition = (currentIssue, handleCancel, handleSuccess) => {
    return {
        text: `Move Lot ${currentIssue?.expectedResults[0]?.expectedLotID} TO ${currentIssue.locationName}`,
        value: 'moveToLocation',
        componentJSX: function () {
            return (
                <MoveCasesAndLooseUnitsToKnownDestinationAction
                    lotId={currentIssue?.expectedResults[0]?.expectedLotID}
                    toLocationName={currentIssue?.locationName}
                    toLocationId={currentIssue?.locationID}
                    toPalletId={currentIssue?.expectedResults[0]?.expectedPalletID}
                    onSuccess={handleSuccess}
                />
            );
        },
    };
};

const getActionDefinitions = (currentIssue, handleCancel, handleSuccess, missingLocationId, handleError) => {
    if (!currentIssue) {
        return [];
    }

    const actionDefinitions = [
        {
            text: 'Acknowledge',
            value: 'acknowledge',
            componentJSX: function () {
                return (
                    <AcknowledgeLocationIssueAction
                        cycleCountEventId={currentIssue.eventID}
                        locationName={currentIssue.locationName}
                        issueType={currentIssue.issueType}
                        onSuccess={handleSuccess}
                        onCancel={handleCancel}
                    />
                );
            },
        },
    ];

    const handleMissingLotMovementSuccess = async (successMessage, resultData) => {
        const { movementSummaries } = resultData;
        const { summaryEventID } = movementSummaries[0];

        const res = await cycleCountApi.resolveIssue(currentIssue.eventID, summaryEventID);
        if (!res.status) {
            const message = `Movement was successful, but failed to resolve issue. Please resolve by acknowledging the issue.`;
            handleError(message);
            return;
        }

        handleSuccess(successMessage);
    };

    if (currentIssue.issueType === 'Unit Discrepancy') {
        actionDefinitions.push(getMoveLotToActionDefinition(currentIssue, handleCancel, handleSuccess));
        actionDefinitions.push(
            getMoveLotFromActionDefinition(
                currentIssue,
                handleCancel,
                handleSuccess,
                currentIssue.locationID,
                currentIssue.locationName,
                currentIssue?.expectedResults[0]?.expectedPalletID
            )
        );
        actionDefinitions.push(getAdjustActionDefinition(currentIssue, handleCancel, handleSuccess));
    } else if (currentIssue.issueType === 'Additional Sku') {
        actionDefinitions.push(getMoveLotToActionDefinition(currentIssue, handleCancel, handleSuccess));
        actionDefinitions.push(getAdjustFromZeroActionDefinition(currentIssue, handleCancel, handleSuccess));
    } else if (currentIssue.issueType === 'Missing Pallet' || currentIssue.issueType === 'Pallet Empty') {
        actionDefinitions.push(getMovePalletActionDefinition(currentIssue, handleCancel, handleSuccess));
    } else if (currentIssue.issueType === 'Missing Lot') {
        actionDefinitions.push(
            getMoveLotFromActionDefinition(
                currentIssue,
                handleCancel,
                handleMissingLotMovementSuccess,
                missingLocationId,
                'MISSING',
                null
            )
        );
    }

    return actionDefinitions;
};

export { getActionDefinitions };
