import React, { useState, useEffect } from 'react';
import { Paper, Tabs, Tab, Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { Notifications, FormComponent, DataTable, TabPanel, UploadCSVAndReviewComponent } from '../../../shared';
import { shopperMarketingApi as api } from '../../../api/shopperMarketing';
import { procurementApi } from '../../../api/procurement';
import { productionApi } from '../../../api/production';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

const CreateVendorPromotion = ({ vendors, banners, setNotification, vendorPromotionOptions }) => {
    const [createdPromotion, setCreatedPromotion] = useState(null);
    const [fundingTypes, setFundingTypes] = useState([]);

    let pleaseSelect = { text: 'Please Select', value: 0 };
    let bannerOpts = [pleaseSelect].concat(banners);

    const handlePromoTypeChange = (e, val) => {
        if (val.value === 'Accrual') {
            setFundingTypes(vendorPromotionOptions.accrualFundingTypes);
        } else {
            setFundingTypes(vendorPromotionOptions.fundingTypes);
        }
    };

    const formFields = [
        {
            name: 'vendorID',
            label: 'Vendor',
            inputElement: 'autocomplete',
            gridValue: 4,
            inputProps: { required: true, opts: vendors },
        },
        {
            name: 'type',
            label: 'Promotion Type',
            inputElement: 'autocomplete',
            gridValue: 4,
            inputProps: {
                required: true,
                opts: vendorPromotionOptions.promotionTypes,
                onChange: handlePromoTypeChange,
            },
        },
        {
            name: 'bannerID',
            label: 'Banner Name',
            inputElement: 'select',
            gridValue: 4,
            inputProps: { opts: bannerOpts },
        },
        {
            name: 'startWeek',
            label: 'Start Week',
            gridValue: 3,
            inputProps: { type: 'number', integer: true, required: true },
        },
        {
            name: 'startYear',
            label: 'Start Year',
            gridValue: 3,
            inputProps: { type: 'number', integer: true, required: true },
        },
        {
            name: 'endWeek',
            label: 'End Week',
            gridValue: 3,
            inputProps: { type: 'number', integer: true, required: true },
        },
        {
            name: 'endYear',
            label: 'End Year',
            gridValue: 3,
            inputProps: { type: 'number', integer: true, required: true },
        },
        {
            name: 'status',
            label: 'Status',
            gridValue: 4,
            inputElement: 'autocomplete',
            inputProps: { required: true, opts: vendorPromotionOptions.statuses },
        },
        {
            name: 'fundingType',
            label: 'Funding Type',
            gridValue: 4,
            inputElement: 'select',
            inputProps: { required: true, opts: fundingTypes },
        },
        { name: 'fundedAmount', label: 'Vendor Funded Amount', gridValue: 4, inputProps: { type: 'number' } },
        { name: 'paymentReceiptTerms', label: 'Payment Receipt Terms', gridValue: 6, inputProps: { multiline: true } },
        { name: 'notes', label: 'Notes', gridValue: 6, inputProps: { multiline: true } },
    ];

    const createVendorPromotion = async (formData) => {
        const res = await api.createVendorPromotion(formData);
        if (!res.status) {
            setNotification({ text: 'Failed to create promotion ' + res.msg, level: 'error' });
            return;
        }

        const createdPromotion = res.data.promotion;
        setCreatedPromotion(createdPromotion);
    };

    return (
        <>
            {!createdPromotion ? (
                <FormComponent formFields={formFields} onSubmit={createVendorPromotion} />
            ) : (
                <>
                    <Alert severity="success">Vendor Promotion Created. Upload skus to add to promotion.</Alert>
                    <UploadPromoDetails
                        vendorPromotionID={createdPromotion.id}
                        vendorPromotionType={createdPromotion.type}
                    />
                </>
            )}
        </>
    );
};

const UploadPromoDetails = ({ vendorPromotionID, vendorPromotionType, getVendorPromotionSkus }) => {
    const columns = [
        { Header: 'External Sku', accessor: 'externalSku' },
        { Header: 'Pre Promotion Price Override', accessor: 'prePromotionPriceOverride' },
        { Header: 'Price Override', accessor: 'priceOverride' },
        { Header: 'Funding Type', accessor: 'fundingType' },
        { Header: 'Vendor Funded Amount', accessor: 'fundedAmount' },
        { Header: 'Unit Cap', accessor: 'unitCap' },
        { Header: 'Errors', accessor: 'errors' },
    ];

    const confirmVendorPromotions = async (key) => {
        const res = await api.confirmVendorPromotions(key);
        if (!!getVendorPromotionSkus && getVendorPromotionSkus instanceof Function) {
            getVendorPromotionSkus();
        }
        return res;
    };

    const uploadVendorPromotions = async (formData) => {
        const res = await api.uploadVendorPromotions(vendorPromotionID, formData);
        return res;
    };

    const downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,External Sku,Funding Type,';
        csvContent += 'Vendor Funded Amount (Funding Type Cost per Unit Sold Only),Pre Promotion Price Override';
        if (
            vendorPromotionType === 'Trade Funding/TPR' ||
            vendorPromotionType === 'Unpaid Price Override' ||
            vendorPromotionType === 'M+ Trade Funding/TPR'
        ) {
            csvContent += ',Price Override';
        }
        if (vendorPromotionType === 'Autoship') {
            csvContent += ',Unit Cap';
        }
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'vendor_promotion_sku_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <div>
            <UploadCSVAndReviewComponent
                fileLabel="Vendor Promotion Sku File"
                title="Upload Vendor Promotion Skus"
                columns={columns}
                upload={uploadVendorPromotions}
                confirm={confirmVendorPromotions}
                downloadTemplate={downloadTemplate}
            />
        </div>
    );
};

const DuplicateVendorPromotionModal = ({ vendorPromotionID, setOpen, setNotification, getVendorPromotions }) => {
    const [error, setError] = useState('');

    const formFields = [
        {
            name: 'startWeek',
            label: 'Start Week',
            gridValue: 3,
            inputProps: { type: 'number', integer: true, required: true },
        },
        {
            name: 'startYear',
            label: 'Start Year',
            gridValue: 3,
            inputProps: { type: 'number', integer: true, required: true },
        },
        {
            name: 'endWeek',
            label: 'End Week',
            gridValue: 3,
            inputProps: { type: 'number', integer: true, required: true },
        },
        {
            name: 'endYear',
            label: 'End Year',
            gridValue: 3,
            inputProps: { type: 'number', integer: true, required: true },
        },
    ];

    const cloneVendorPromotion = async (formData) => {
        const res = await api.cloneVendorPromotion(vendorPromotionID, formData);
        if (!res.status) {
            setError(`Failed to duplicate. ${res.msg}`);
            return false;
        }

        setNotification({ text: 'Vendor promotion duplicated', level: 'success' });
        getVendorPromotions();
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={true}
            onClose={() => {
                setOpen(false);
            }}
        >
            <DialogTitle>Duplicate Vendor Promotion</DialogTitle>
            <DialogContent>
                <FormComponent formFields={formFields} onSubmit={cloneVendorPromotion} />
                {!!error && <div style={{ color: 'red' }}>{error}</div>}
            </DialogContent>
        </Dialog>
    );
};

const GenericModal = ({
    setOpen,
    requestAfterUpate,
    selectedVendorPromotionID,
    setNotification,
    editing,
    formFields,
    title,
}) => {
    const update = async (formData) => {
        let res;
        if (editing === 'endYear') {
            res = await api.updateVendorPromotion(selectedVendorPromotionID, formData);
        }
        if (!res.status) {
            setNotification({ level: 'error', text: `Failed to update promotion: ${res.msg}` });
            return false;
        }
        requestAfterUpate();
        setOpen(false);
        return true;
    };

    return (
        <Dialog fullWidth={true} maxWidth="md" open={true} onClose={() => setOpen(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <FormComponent formFields={formFields} onSubmit={update} />
            </DialogContent>
        </Dialog>
    );
};

const ViewVendorPromotions = ({ vendors, banners, vendorPromotionOptions, setNotification }) => {
    const [vendorPromotions, setVendorPromotions] = useState([]);
    const [selectedVendorPromotionID, setSelectedVendorPromotionID] = useState(null);
    const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
    const [endYearModalOpen, setEndYearModalOpen] = useState(false);

    const getVendorPromotions = async () => {
        const res = await api.getVendorPromotions();
        if (!res.status) {
            setNotification({ text: 'Failed to get vendor promotions', level: 'error' });
            return;
        }

        let data = res.data.rows.map((row) => {
            if (row.aisleBannerID === 0) {
                row.bannerID = '';
            } else {
                row.bannerID = banners.filter((banner) => banner.value === row.aisleBannerID)[0].value || undefined;
            }
            return row;
        });
        setVendorPromotions(data);
    };

    const updateVendorPromotion = async ({ id, field, value }) => {
        let res = await api.updateVendorPromotion(id, { [field]: value });
        if (!res.status) {
            setNotification({ level: 'error', text: `Failed to update promotion: ${res.msg}` });
            return false;
        }
        getVendorPromotions();
        return true;
    };

    const showEndYearModal = (row) => {
        setEndYearModalOpen(true);
        setSelectedVendorPromotionID(row.id);
    };

    useEffect(() => {
        getVendorPromotions();
    }, [banners]);

    const columns = [
        { Header: 'ID', accessor: 'id' },
        { Header: 'Vendor', accessor: 'vendorID', editProps: { type: 'select', options: vendors } },
        {
            Header: 'Promotion Type',
            accessor: 'type',
            editProps: { type: 'select', required: true, options: vendorPromotionOptions.promotionTypes },
        },
        {
            Header: 'Start Week',
            accessor: 'startWeek',
            editable: (row) => row.original.editableParameters.includes('startWeek'),
        },
        {
            Header: 'Start Year',
            accessor: 'startYear',
            editable: (row) => row.original.editableParameters.includes('startYear'),
        },
        {
            Header: 'End Week',
            accessor: 'endWeek',
            editable: (row) => row.original.editableParameters.includes('endWeek'),
        },
        {
            Header: 'End Year',
            accessor: 'endYear',
            editable: (row) => row.original.editableParameters.includes('endYear'),
            customEdit: (row) => showEndYearModal(row),
        },
        {
            Header: 'Status',
            accessor: 'status',
            editable: (row) => row.original.editableParameters.includes('status'),
            editProps: { type: 'select', required: true, options: vendorPromotionOptions.statuses },
        },
        {
            Header: 'Funding Type',
            accessor: 'fundingType',
            editable: (row) => row.original.editableParameters.includes('fundingType'),
            editProps: { type: 'select', required: true, options: vendorPromotionOptions.fundingTypes },
        },
        {
            Header: 'Vendor Funded Amount',
            accessor: 'fundedAmount',
            editable: (row) => row.original.editableParameters.includes('fundedAmount'),
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            Header: 'Payment Receipt Terms',
            accessor: 'paymentReceiptTerms',
            editable: (row) => row.original.editableParameters.includes('paymentReceiptTerms'),
            editProps: { type: 'input', multiline: true },
        },
        { Header: 'Notes', accessor: 'notes', editable: true, editProps: { type: 'input', multiline: true } },
        {
            Header: 'Banner Name',
            accessor: 'bannerID',
            editable: true,
            editProps: { type: 'select', options: banners },
        },
        { Header: 'Voided', accessor: 'voided', editable: (row) => !row.original.voided, type: 'checkbox' },
        {
            Header: 'Duplicate',
            Cell: ({ row }) => {
                return (
                    <Button
                        onClick={async () => {
                            setSelectedVendorPromotionID(row.original.id);
                            setDuplicateModalOpen(true);
                        }}
                    >
                        Duplicate
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            {endYearModalOpen && (
                <GenericModal
                    setOpen={setEndYearModalOpen}
                    setNotification={setNotification}
                    requestAfterUpate={getVendorPromotions}
                    selectedVendorPromotionID={selectedVendorPromotionID}
                    editing={'endYear'}
                    title={'Editing End Week and Year'}
                    formFields={[
                        {
                            name: 'endWeek',
                            label: 'End Week',
                            gridValue: 3,
                            inputProps: { type: 'number', integer: true, required: true },
                        },
                        {
                            name: 'endYear',
                            label: 'End Year',
                            gridValue: 3,
                            inputProps: { type: 'number', integer: true, required: true },
                        },
                    ]}
                />
            )}
            {duplicateModalOpen && (
                <DuplicateVendorPromotionModal
                    vendorPromotionID={selectedVendorPromotionID}
                    setOpen={setDuplicateModalOpen}
                    setNotification={setNotification}
                    getVendorPromotions={getVendorPromotions}
                />
            )}
            <DataTable
                columns={columns}
                data={vendorPromotions}
                editable={true}
                saveEdit={updateVendorPromotion}
                expandable={true}
                ExpansionComponent={
                    <ViewVendorPromotionSkus
                        setNotification={setNotification}
                        vendorPromotionOptions={vendorPromotionOptions}
                        setSelectedVendorPromotionID={setSelectedVendorPromotionID}
                        selectedVendorPromotionID={selectedVendorPromotionID}
                    />
                }
            />
        </div>
    );
};

let EditPriceOverridesModal = ({ selectedRow, setOpen, setNotification, getVendorPromotionSkus }) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={true}
            onClose={() => {
                setOpen(false);
            }}
        >
            <DialogTitle>
                Edit {selectedRow.priceOverrideType === 'prePromotionPriceOverride' && 'Pre Promotion '}
                Price Overrides - External Sku: {selectedRow.externalSku}
            </DialogTitle>
            <DialogContent>
                <DataTable
                    editable={true}
                    columns={[
                        { accessor: 'facility', Header: 'Facility', editable: false },
                        {
                            accessor: selectedRow.priceOverrideType,
                            Header: `${
                                selectedRow.priceOverrideType === 'prePromotionPriceOverride' ? 'Pre Promotion ' : ''
                            }Price Override`,
                            editable: true,
                            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
                        },
                    ]}
                    data={selectedRow.priceOverrides}
                    saveEdit={async ({ row, value }) => {
                        let sendParams = {
                            facilityID: row.facilityID,
                            [selectedRow.priceOverrideType]: value,
                        };
                        let res = await api.updateVendorPromotionSku(
                            selectedRow.vendorPromotionID,
                            selectedRow.id,
                            sendParams
                        );
                        if (!res.status) {
                            setNotification({ level: 'error', text: `Failed to update: ${res.msg}` });
                            return false;
                        }
                        getVendorPromotionSkus();
                        return true;
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

const PriceOverridesCell = ({ row, type, setSelectedRow, setEditPriceOverridesModalOpen }) => {
    let priceOverrides = row.original.priceOverrides;

    return (
        <div>
            {priceOverrides.map((item, index) => {
                if (!item[type]) {
                    return null;
                }
                return (
                    <div key={`${index}`}>
                        <span>
                            {item.facility} - ${item[type].toFixed(2)}
                        </span>
                    </div>
                );
            })}
            {row.original.editableParameters.includes(type) && (
                <div>
                    <Button
                        onClick={() => {
                            setSelectedRow({ ...row.original, priceOverrideType: type });
                            setEditPriceOverridesModalOpen(true);
                        }}
                    >
                        EDIT
                    </Button>
                </div>
            )}
        </div>
    );
};
const ViewVendorPromotionSkus = ({
    parentRow,
    setNotification,
    vendorPromotionOptions,
    setSelectedVendorPromotionID,
}) => {
    const [vendorPromotionSkus, setVendorPromotionSkus] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [editPriceOverridesModalOpen, setEditPriceOverridesModalOpen] = useState(false);
    const [fundingTypeOptions, setFundingTypeOptions] = useState([]);

    const handleFundingTypeOptions = () => {
        if (parentRow.original.fundingType === 'Accrual') {
            setFundingTypeOptions(vendorPromotionOptions.skuAccrualFundingTypes);
        } else {
            setFundingTypeOptions(vendorPromotionOptions.skuFundingTypes);
        }
    };

    const columns = [
        { Header: 'External Sku', accessor: 'externalSku' },
        { Header: 'Name', accessor: 'name' },
        {
            Header: 'Pre Promotion Price Override',
            accessor: 'prePromotionPriceOverride',
            filterValues: (row) =>
                row.original.priceOverrides.map((item) => `${item.facility} - ${item.prePromotionPriceOverride}`),
            Cell: ({ row }) =>
                PriceOverridesCell({
                    row,
                    type: 'prePromotionPriceOverride',
                    setSelectedRow,
                    setEditPriceOverridesModalOpen,
                }),
        },
        {
            Header: 'Price Override',
            accessor: 'priceOverride',
            filterValues: (row) =>
                row.original.priceOverrides.map((item) => `${item.facility} - ${item.priceOverride}`),
            Cell: ({ row }) =>
                PriceOverridesCell({ row, type: 'priceOverride', setSelectedRow, setEditPriceOverridesModalOpen }),
        },
        {
            Header: 'Funding Type',
            accessor: 'fundingType',
            editable: (row) => row.original.editableParameters.includes('fundingType'),
            editProps: { type: 'select', required: true, options: fundingTypeOptions },
        },
        {
            Header: 'Vendor Funded Amount',
            accessor: 'fundedAmount',
            editable: (row) => row.original.editableParameters.includes('fundedAmount'),
        },
        {
            Header: 'Unit Cap',
            accessor: 'unitCap',
            Cell: ({ row }) => {
                const unitCapAmount = row.original.unitCap;
                return unitCapAmount > 0 ? unitCapAmount : 'N/A';
            },
        },
        {
            Header: 'Remove',
            Cell: ({ row }) => {
                if (!parentRow.original.editableParameters.includes('startWeek')) {
                    return null;
                }
                return (
                    <Button
                        onClick={async () => {
                            let res = await api.removeVendorPromotionSku(parentRow.original.id, row.original.id);
                            if (!res.status) {
                                setNotification({ level: 'error', text: `Failed to remove aisle mapping: ${res.msg}` });
                                return;
                            }
                            getVendorPromotionSkus();
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    const getVendorPromotionSkus = async () => {
        const res = await api.getVendorPromotionSkus(parentRow.original.id);
        if (!res.status) {
            setNotification({ text: 'Failed to get vendor promotion skus', level: 'error' });
            return;
        }
        setVendorPromotionSkus(res.data.rows);
    };

    const updateVendorPromotionSku = async ({ id, field, value }) => {
        const sendParams = { [field]: value };

        const res = await api.updateVendorPromotionSku(parentRow.original.id, id, sendParams);
        if (!res.status) {
            setNotification({ text: `Failed to update vendor promotion sku - ${res.msg}`, level: 'error' });
            return false;
        }
        setNotification({ text: 'Vendor promotion sku updated', level: 'success' });
        getVendorPromotionSkus();
    };

    useEffect(() => {
        getVendorPromotionSkus();
        handleFundingTypeOptions();
        setSelectedVendorPromotionID(parentRow.original.id);
    }, []);

    return (
        <>
            {editPriceOverridesModalOpen && (
                <EditPriceOverridesModal
                    setOpen={setEditPriceOverridesModalOpen}
                    setNotification={setNotification}
                    selectedRow={selectedRow}
                    getVendorPromotionSkus={getVendorPromotionSkus}
                />
            )}
            <DataTable
                editable={true}
                columns={columns}
                data={vendorPromotionSkus}
                saveEdit={updateVendorPromotionSku}
            />
            {!parentRow.original.voided && parentRow.original.editableParameters.includes('startWeek') && (
                <UploadPromoDetails
                    vendorPromotionID={parentRow.original.id}
                    vendorPromotionType={parentRow.original.type}
                    getVendorPromotionSkus={getVendorPromotionSkus}
                />
            )}
        </>
    );
};

const VendorPromotions = () => {
    const [selectedTab, setSelectedTab] = useState('0');
    const [vendorPromotionOptions, setVendorPromotionOptions] = useState({});
    const [vendors, setVendors] = useState([]);
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [banners, setBanners] = useState([]);

    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    const getBanners = async () => {
        let res = await productionApi.getAisleBanners();
        if (!res.status) {
            setNotification({ text: 'Failed to get aisle banners ' + res.msg, level: 'error' });
            return;
        }

        let bannerRows = res.data.rows.map((row) => {
            let item = {};
            item.text = row.bannerName;
            item.value = row.id;
            return item;
        });

        setBanners(bannerRows);
    };

    const getVendors = async () => {
        const res = await procurementApi.getVendors();
        if (!res.status) {
            setNotification({ text: 'Failed to get vendors', level: 'error' });
            return;
        }

        setVendors(res.data.rows.map((item) => ({ text: item.name, value: item.id })));
    };

    const getVendorPromotionOptions = async () => {
        const res = await api.getVendorPromotionOptions();
        if (!res.status) {
            setNotification({ text: 'Failed to get options for vendor promotions', level: 'error' });
            return;
        }

        const options = {
            promotionTypes: res.data.promotionTypes,
            statuses: res.data.statuses,
            fundingTypes: res.data.fundingTypes,
            skuFundingTypes: res.data.skuFundingTypes,
            accrualFundingTypes: res.data.accrualFundingTypes,
            skuAccrualFundingTypes: res.data.skuAccrualFundingTypes,
        };

        setVendorPromotionOptions(options);
    };

    useEffect(() => {
        getBanners();
        getVendors();
        getVendorPromotionOptions();
    }, []);

    return (
        <Paper>
            <Notifications options={notification} />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="View Vendor Promotions" value="0" />
                <Tab label="Create Vendor Promotion" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewVendorPromotions
                    vendors={vendors}
                    banners={banners}
                    vendorPromotionOptions={vendorPromotionOptions}
                    setNotification={setNotification}
                />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateVendorPromotion
                    vendors={vendors}
                    banners={banners}
                    vendorPromotionOptions={vendorPromotionOptions}
                    setNotification={setNotification}
                />
            </TabPanel>
        </Paper>
    );
};

export { VendorPromotions };
