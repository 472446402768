import React, { useEffect, useState } from 'react';
import { FormComponent, Notifications } from '../../../../../shared';
import { warehouseApi } from '../../../../../api/warehouse';
import { Button, Typography, TextField, Box, CircularProgress } from '@material-ui/core';

const BulkAdjustUnitsPerCaseAction = ({ lotId, unitsPerCase = 0, onSuccess, onCancel }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [units, setUnits] = useState(unitsPerCase);
    const reason = 'DESKTOP_ADJUSTMENT';
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('BulkAdjustUnitsPerCaseAction intialized incorrectly onSuccess is not a function');
        }
        if (!(onCancel instanceof Function)) {
            console.error('BulkAdjustUnitsPerCaseAction initialized incorrectly onCancel is not a function');
        }
    }, [onSuccess, onCancel]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isNaN(units) || units <= 0) {
            setShowError(true);
            setErrorMessage('Please enter a valid number.');
            return;
        }
        setShowError(false);
        setErrorMessage('');
        setLoading(true);

        const requestParams = {
            lotID: lotId,
            numUnits: units,
            reason: reason,
        };

        const result = await warehouseApi.bulkAdjustNumUnits(requestParams);
        setLoading(false);
        if (result.status) {
            onSuccess();
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const handleFormDataChange = (e) => {
        setUnits(e.target.value);
    };

    return (
        <>
            <Notifications options={notification} />
            <h3>{`Adjust each case within lot ${lotId} to new units per case`}</h3>
            {showError && <Typography style={{ color: 'red', marginBottom: 10 }}>{errorMessage}</Typography>}
            {loading ? (
                <CircularProgress size={24} />
            ) : (
                <form onSubmit={handleSubmit}>
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ gap: 4 }}>
                        <TextField
                            label="Units Per Case"
                            name="unitsPerCase"
                            type="number"
                            value={units}
                            onChange={handleFormDataChange}
                            variant="outlined"
                            margin="dense"
                            inputProps={{ min: 1, step: '1' }}
                            error={units === ''}
                            helperText={units === '' ? 'This field cannot be blank' : ''}
                        />
                        <Button type="submit" variant="outlined">
                            Submit
                        </Button>
                    </Box>
                </form>
            )}
        </>
    );
};

export { BulkAdjustUnitsPerCaseAction };
