import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Modal,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    TextField,
} from '@material-ui/core';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { receivingApi as api } from '../../../api/receiving';
import { procurementApi as procureApi } from '../../../api/procurement';
import { packagingApi as packagingApi } from '../../../api/packaging';
import { ViewItemsTable } from '../../../components/procurement/components/viewPurchaseOrder';
import { UserContext } from '../../../contexts/UserContext';

const useStyles = makeStyles((theme) => {
    return {
        panelContainer: {
            borderColor: theme.palette.ivy.main,
            borderWidth: '3px',
            borderStyle: 'solid',
            borderRadius: '5px',
            margin: '10px 10px',
            padding: '0px',
        },
        panelSummary: {
            height: '70px',
            fontSize: '16px',
            padding: '25px',
        },
    };
});

let PalletCountForm = ({ formData, setFormField, item }) => {
    useEffect(() => {
        let palletCountsTmp = [];
        let i = 0;
        while (i < Number(formData.numPallets)) {
            palletCountsTmp.push(formData.palletCounts[i] || '');
            i++;
        }

        if (palletCountsTmp.length > 0 && palletCountsTmp.length != formData.palletCounts.length) {
            setFormField(palletCountsTmp);
        }
    }, [formData]);

    let getPalletName = (ind) => {
        let firstLetterInd = Math.floor(ind / 26);
        let firstLetter = String.fromCharCode('A'.charCodeAt() + firstLetterInd);
        let secondLetterInd = ind - 26 * firstLetterInd;
        let secondLetter = String.fromCharCode('A'.charCodeAt() + secondLetterInd);
        return `${firstLetter}${secondLetter}`;
    };

    return (
        <>
            <h4>Pallet Counts</h4>
            {formData.palletCounts.map((formField, formFieldIndex) => {
                return (
                    <Grid item xs={12} key={formFieldIndex}>
                        <TextField
                            type="number"
                            style={{ width: '100%', padding: 15 }}
                            label={`Pallet ${getPalletName(formFieldIndex)}`}
                            value={formData.palletCounts[formFieldIndex] || ''}
                            onChange={(event) => {
                                let palletFormDataTmp = JSON.parse(JSON.stringify(formData.palletCounts));
                                palletFormDataTmp[formFieldIndex] = event.target.value;
                                setFormField(palletFormDataTmp);
                            }}
                            onKeyPress={(event) => {
                                if ((event.charCode >= 48 && event.charCode <= 57) === false) {
                                    event.preventDefault();
                                    return false;
                                }
                            }}
                        />
                    </Grid>
                );
            })}
        </>
    );
};

let ReceiveWithPalletsModal = ({ rowData, close, open, setNotification, user }) => {
    const [varianceAttemptCount, setVarianceAttemptCount] = useState(0);

    let receivePO = async () => {
        setReceivingPO(true);
        let sendData = [];

        for (let i = 0; i < poItems.length; i++) {
            let item = poItems[i];
            let formData = poFormData[i];

            let palletCounts = poFormData[i].palletCounts;
            palletCounts.forEach((count) => {
                if (Number(count) <= 0) {
                    setNotification({ text: 'Cases on pallet must be greater than 0', level: 'error' });
                    setReceivingPO(false);
                    return;
                }
            });
            if (palletCounts.length != formData.numPallets) {
                setNotification({ text: 'Number of pallets does not match specified count', level: 'error' });
                setReceivingPO(false);
                return;
            }

            sendData.push({
                poMapID: item.poMapID,
                expiresOn: rowData.poType === 'GROCERY' ? formData.expiration : undefined,
                pallets: palletCounts,
                receiveQty0: formData.receiveQty0,
            });
        }

        let res;

        if (rowData.poType === 'GROCERY') {
            res = await api.receivePurchaseOrderWithPalletCounts(rowData.id, {
                items: sendData,
                varianceAttemptCount: varianceAttemptCount,
            });
        } else if (rowData.poType === 'PACKAGING') {
            res = await api.receivePackagingPurchaseOrderWithPalletCounts(rowData.id, {
                items: sendData,
                varianceAttemptCount: varianceAttemptCount,
            });
        }

        setReceivingPO(false);
        if (!res.status) {
            if (res.data.hasVarianceError && !res.data.hasFatalError) {
                if (res.data.varianceAttemptCount) {
                    setVarianceAttemptCount(parseInt(res.data.varianceAttemptCount));
                }
                if (varianceAttemptCount < 1) {
                    setNotification({
                        text: 'Case count entered with discrepancies. Please re-count and renter',
                        level: 'error',
                    });
                } else {
                    setNotification({
                        text: 'Received with discrepancies, please escalate to leadership',
                        level: 'error',
                    });
                }
            } else {
                setNotification({ text: 'Failed to receive purchase order. ' + res.msg, level: 'error' });
            }
            getPurchaseOrderProgress(rowData.id);
            return;
        }

        if (res.data.hasWarning) {
            setNotification({ text: 'Purchase order received, with warnings: ' + res.data.warning, level: 'warning' });
        } else {
            setNotification({ text: 'Purchase order received', level: 'success' });
        }

        close();
    };

    const classes = useStyles();
    const theme = useTheme();
    let [expandedFormIndex, setExpandedFormIndex] = useState(null);
    let [receivingPO, setReceivingPO] = useState(false);
    let [showReceivingContent, setShowReceivingContent] = useState(true);

    let [poItems, setPoItems] = useState([]);
    let [poStatus, setPoStatus] = useState('');
    let [poFormData, setPoFormData] = useState([]);
    let [progressLoaded, setProgressLoaded] = useState(false);
    let [errors, setErrors] = useState({});

    let getPurchaseOrderProgress = async (poID) => {
        let res = await api.getPurchaseOrderReceivingProgress(poID, rowData.poType);
        if (!res.status) {
            setProgressLoaded(true);
            return;
        }

        let formDataTemp = [];
        if (res.data.output.items && res.data.output.items.length) {
            for (let i = 0; i < poItems.length; i++) {
                let item = res.data.output.items.find((it) => it.poMapID === poItems[i].poMapID);
                if (item) {
                    let totalCases = 0;
                    for (let num of item.palletCaseCounts) {
                        totalCases += Number(num);
                    }
                    formDataTemp[i] = {
                        poMapID: item.poMapID,
                        palletCounts: item.palletCaseCounts,
                        numPallets: item.palletCaseCounts.length,
                        expiration: item.expiresOn,
                        receiveQty0: item.receiveQty0,
                        totalCases: totalCases,
                    };
                } else {
                    formDataTemp[i] = {};
                }
            }
            setPoFormData(formDataTemp);
        }

        if (res.data.output.errors) {
            setErrors(res.data.output.errors);
        }

        setProgressLoaded(true);
    };

    useEffect(() => {
        if (!rowData) {
            return;
        }

        getPurchaseOrderDetails(rowData.id, rowData.poType);
    }, [rowData]);

    useEffect(() => {
        if (!rowData || !poItems.length) {
            return;
        }
        getPurchaseOrderProgress(rowData.id, poItems);
    }, [poItems]);

    let setInputPropBool = (inputPropName, item) => {
        if (inputPropName === 'required') {
            if (item.expirationType == 'EXPIRATION_DATE' || item.expirationType == 'PRODUCTION_DATE') {
                return true;
            }
            return false;
        } else if (inputPropName === 'disabled') {
            if (item.expirationType == 'DAYS_AFTER_RECEIPT' || item.expirationType == 'NO_EXPIRATION_DATE') {
                return true;
            }
            return false;
        } else if (inputPropName === 'label') {
            if (item.expirationType == 'PRODUCTION_DATE') {
                return 'Production date';
            }
            return 'Expiration';
        } else if (inputPropName === 'styleBackground') {
            if (item.expirationType == 'DAYS_AFTER_RECEIPT' || item.expirationType == 'NO_EXPIRATION_DATE') {
                return 'lightgrey';
            }
            return 'white';
        } else if (inputPropName === 'styleOpacity') {
            if (item.expirationType == 'DAYS_AFTER_RECEIPT' || item.expirationType == 'NO_EXPIRATION_DATE') {
                return '50%';
            }
            return '100%';
        }
    };

    // Load PO details
    let getPurchaseOrderDetails = async (receivePOID, type) => {
        let response = { status: false };
        if (type === 'GROCERY') {
            response = await procureApi.getPurchaseOrderDetails(receivePOID);
        } else if (type === 'PACKAGING') {
            response = await packagingApi.getPurchaseOrderDetails(receivePOID);
            if (response.status === false) {
                setNotification({ text: 'Can not receive Packaging PO ' + response.msg, level: 'error' });
                return;
            }
        } else {
            setNotification({ text: 'Unrecognized purchase order type ' + type, level: 'error' });
            return;
        }

        if (response.status === false) {
            setNotification({ text: 'Can not receive PO ' + response.msg, level: 'error' });
            return;
        }
        let poDetails = response.data.output;

        setPoItems(poDetails.items);
        setPoStatus(poDetails.status);
    };

    if (!rowData) {
        return null;
    }

    let getBackgroundColor = (poMapID, totalCases, expiration, receiveQty0, expirationType) => {
        if (errors[poMapID]) {
            return theme.palette.tomato.main;
        }

        if (receiveQty0) {
            return theme.palette.aloe.main;
        }

        if (rowData.poType === 'PACKAGING') {
            return Number(totalCases) > 0 ? theme.palette.aloe.main : theme.palette.mustard.main;
        } else {
            if (expirationType != 'NO_EXPIRATION_DATE') {
                return Number(totalCases) > 0 && !!expiration ? theme.palette.aloe.main : theme.palette.mustard.main;
            } else {
                return Number(totalCases) > 0 ? theme.palette.aloe.main : theme.palette.mustard.main;
            }
        }
    };

    let handleChange = (index) => {
        if (expandedFormIndex === index) {
            setExpandedFormIndex(null);
        } else {
            setExpandedFormIndex(index);
        }
    };

    return (
        <div>
            {showReceivingContent && (
                <Dialog open={open} onClose={close} fullWidth={true} maxWidth="lg" height="90%">
                    <DialogTitle>
                        Receive {rowData.poType} Purchase Order {rowData.id}
                    </DialogTitle>
                    <DialogContent>
                        {progressLoaded && (
                            <>
                                {poItems.map((item, poItemIndex) => {
                                    return (
                                        <ExpansionPanel
                                            className={classes.panelContainer}
                                            key={`${poItemIndex}`}
                                            expanded={expandedFormIndex === poItemIndex}
                                            onChange={() => handleChange(poItemIndex)}
                                        >
                                            <ExpansionPanelSummary
                                                className={classes.panelSummary}
                                                style={{
                                                    backgroundColor: getBackgroundColor(
                                                        item.poMapID,
                                                        poFormData[poItemIndex]?.totalCases,
                                                        poFormData[poItemIndex]?.expiration,
                                                        poFormData[poItemIndex]?.receiveQty0,
                                                        item.expirationType
                                                    ),
                                                }}
                                            >
                                                <Grid container>
                                                    <Grid id={`SkuExpansionComponent_${poItemIndex}`} item xs={6}>
                                                        <h3>
                                                            {rowData.poType === 'GROCERY'
                                                                ? `Sku ${item.sku} - ${item.itemName}`
                                                                : `Sku ${item.masterSku} - ${item.description} (${item.type})`}
                                                        </h3>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {poFormData[poItemIndex] &&
                                                            Number(poFormData[poItemIndex]?.totalCases) > 0 && (
                                                                <h3>
                                                                    <div>
                                                                        Num Pallets:{' '}
                                                                        {poFormData[poItemIndex].numPallets}
                                                                    </div>
                                                                    <div>
                                                                        Pallet Case Total:{' '}
                                                                        {poFormData[poItemIndex].totalCases}
                                                                    </div>
                                                                </h3>
                                                            )}
                                                    </Grid>
                                                </Grid>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        {errors[item.poMapID] && (
                                                            <div style={{ color: 'red' }}>
                                                                Errors:
                                                                {errors[item.poMapID].map((item) => (
                                                                    <div>{item}</div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <FormComponent
                                                            formFields={[
                                                                {
                                                                    name: 'receiveQty0',
                                                                    inputElement: 'checkbox',
                                                                    initialValue:
                                                                        poFormData[poItemIndex]?.receiveQty0 || false,
                                                                    label: 'Receive 0 Pallets',
                                                                },
                                                                {
                                                                    name: 'expiration',
                                                                    display: (formData) =>
                                                                        formData.receiveQty0 === false &&
                                                                        rowData.poType === 'GROCERY',
                                                                    initialValue:
                                                                        poFormData[poItemIndex]?.expiration ||
                                                                        item.expiresOn,
                                                                    inputElement: 'date',
                                                                    label: setInputPropBool('label', item),
                                                                    gridValue: 5,
                                                                    inputProps: {
                                                                        required: setInputPropBool('required', item),
                                                                        disabled: setInputPropBool('disabled', item),
                                                                        style: {
                                                                            background: setInputPropBool(
                                                                                'styleBackground',
                                                                                item
                                                                            ),
                                                                            opacity: setInputPropBool(
                                                                                'styleOpacity',
                                                                                item
                                                                            ),
                                                                        },
                                                                    },
                                                                },
                                                                {
                                                                    name: 'numPallets',
                                                                    inputElement: 'textField',
                                                                    initialValue:
                                                                        poFormData[poItemIndex]?.numPallets || '',
                                                                    inputProps: { type: 'number', required: true },
                                                                    label: 'Num Pallets',
                                                                    gridValue: 5,
                                                                    display: (formData) =>
                                                                        formData.receiveQty0 === false,
                                                                },
                                                                {
                                                                    name: 'palletCounts',
                                                                    display: (formData) =>
                                                                        formData.receiveQty0 === false,
                                                                    initialValue:
                                                                        poFormData[poItemIndex]?.palletCounts || [],
                                                                    inputElement: ({ formData, setFormField }) => (
                                                                        <PalletCountForm
                                                                            formData={formData}
                                                                            setFormField={setFormField}
                                                                            item={item}
                                                                        />
                                                                    ),
                                                                },
                                                                {
                                                                    name: 'totalCases',
                                                                    inputElement: ({ formData, setFormField }) => {
                                                                        let total = 0;
                                                                        for (let num of formData.palletCounts) {
                                                                            total += Number(num);
                                                                        }
                                                                        if (total !== formData.totalCases) {
                                                                            setFormField(total);
                                                                        }
                                                                        return (
                                                                            <div>
                                                                                Total Cases: {formData.totalCases}
                                                                            </div>
                                                                        );
                                                                    },
                                                                },
                                                            ]}
                                                            onFormDataChange={(formData) => {
                                                                let poFormDataTmp = JSON.parse(
                                                                    JSON.stringify(poFormData)
                                                                );
                                                                poFormDataTmp[poItemIndex] = formData;
                                                                setPoFormData(poFormDataTmp);
                                                            }}
                                                            onSubmit={() => {
                                                                setExpandedFormIndex(null);
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    );
                                })}
                            </>
                        )}
                        &nbsp;&nbsp;
                        <Button
                            id="ReceivePO"
                            disabled={
                                receivingPO ||
                                poFormData.length != poItems.length ||
                                !poFormData.every((item) => {
                                    if (!item || !item.palletCounts) {
                                        return false;
                                    }
                                    if (item.receiveQty0 === true) {
                                        return true;
                                    }
                                    let counts = item.palletCounts;
                                    let total = 0;
                                    counts.forEach((num) => {
                                        total += Number(num);
                                    });
                                    return total > 0;
                                })
                            }
                            onClick={receivePO}
                        >
                            Receive PO
                        </Button>
                        {rowData.poType === 'GROCERY' && (
                            <Button
                                id="ModifyPO"
                                onClick={async () => {
                                    setShowReceivingContent(false);
                                }}
                            >
                                Modify PO
                            </Button>
                        )}
                    </DialogContent>
                </Dialog>
            )}
            {!showReceivingContent && (
                <Dialog open={open} onClose={close} fullWidth={true} maxWidth="lg" height="90%">
                    <DialogTitle>Modify Purchase Order {rowData.id}</DialogTitle>
                    <DialogContent>
                        {<ViewItemsTable receivingPOID={rowData.id} />}
                        &nbsp;&nbsp;
                        <Button
                            id="Back"
                            onClick={async () => {
                                getPurchaseOrderDetails(rowData.id, rowData.poType);
                                setShowReceivingContent(true);
                            }}
                        >
                            Back
                        </Button>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
};

let ReceivablePurchaseOrders = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const [receiveWithPalletsModalOpen, setReceiveWithPalletsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const user = useContext(UserContext);
    const [directedReceivingEnabled, setDirectedReceivingEnabled] = useState(false);

    let columns = [
        { accessor: 'id', Header: 'Purchase Order' },
        { accessor: 'poType', Header: 'Type' },
        { accessor: 'shipperName', Header: 'Shipper' },
        { accessor: 'vendorName', Header: 'Vendor' },
        { accessor: 'deliveryDate', Header: 'Delivery Date' },
        { accessor: 'truckID', Header: 'Truck ID' },
        {
            accessor: 'download',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id="ShipList"
                            onClick={() => {
                                if (row.original.poType === 'PACKAGING') {
                                    packagingApi.downloadPackagingPOShipList(row.original.id);
                                } else {
                                    procureApi.downloadPOShipList(row.original.id);
                                }
                            }}
                        >
                            Ship List
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'receiveWithPalletCounts',
            Header: 'Receive With Pallets',
            Cell: ({ cell: { row } }) => {
                return (
                    <div>
                        <Button
                            id="Receive"
                            onClick={async () => {
                                setSelectedRow(row.original);
                                setReceiveWithPalletsModalOpen(true);
                            }}
                        >
                            Receive
                        </Button>
                    </div>
                );
            },
        },
    ];

    let getPurchaseOrdersForReceiving = async () => {
        let response;
        response = await api.getReceivableGroceryAndPackagingPurchaseOrders(user.getFacilityID());

        if (response.status === false) {
            setNotification({ text: 'No POs found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.deliveryDate = moment(row.deliveryDateLocal.date).format('MM/DD/YYYY HH:mm:ss');
                return row;
            });
        }

        // Need to add id which will populate in saveEdit
        setRows(response.data.rows);
        setDirectedReceivingEnabled(response.data.directedReceivingEnabled);
    };

    useEffect(() => {
        getPurchaseOrdersForReceiving();
    }, []);

    if (directedReceivingEnabled) {
        return (
            <div>This page is deprecated, please navigate to the Inbound Receiving page to receive purchase orders</div>
        );
    }
    return (
        <div>
            <h1>Receivable Purchase Orders</h1>
            <Notifications options={notification} />
            {receiveWithPalletsModalOpen && (
                <ReceiveWithPalletsModal
                    open={receiveWithPalletsModalOpen}
                    close={() => {
                        setSelectedRow(null);
                        setReceiveWithPalletsModalOpen(false);
                        getPurchaseOrdersForReceiving();
                    }}
                    setNotification={setNotification}
                    rowData={selectedRow}
                    user={user}
                />
            )}
            <div>
                <DataTable columns={columns} data={rows} />
            </div>
        </div>
    );
};

export { ReceivablePurchaseOrders };
