import React, { useState, useEffect } from 'react';
import { FormComponent, Notifications } from '../../../../shared';
import { productApi as api } from '../../../../api/product';

let formFields = [
    /**
     * Common Form Section, used for all the discount types
     */
    { name: 'name', inputElement: 'textField', label: 'Offer Name', gridValue: 12, inputProps: { required: true } },
    {
        name: 'creditsValue',
        inputElement: 'textField',
        label: 'Credits Value',
        gridValue: 4,
        inputProps: { required: true },
    },
    {
        name: 'creditsDurationDays',
        inputElement: 'textField',
        label: 'Credit Expiry Days',
        gridValue: 4,
        inputProps: { required: true },
    },
];

const CreatePlusMembershipOffer = () => {
    let [notification, setNotification] = useState({ text: null, level: null });

    return (
        <div>
            <h1>Create Plus Membership Offer </h1>
            <Notifications options={notification} />
            <div>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        let response = await api.createPlusMembershipOffer(formData);
                        if (response.status === false) {
                            setNotification({
                                text: 'Unable to create plus membership offer ' + response.msg,
                                level: 'error',
                            });
                            return;
                        }

                        setNotification({ text: 'Success!', level: 'success' });
                        resetForm();
                    }}
                />
            </div>
        </div>
    );
};

export { CreatePlusMembershipOffer };
