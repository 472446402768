import React from 'react';
import { Paper, Typography, Box, Link } from '@material-ui/core';

const ThirdPartyOrder = ({ source, searchTerm }) => {
    // Function to get the base URL dynamically
    const getBaseUrl = () => {
        const currentHost = window.location.hostname;
        const subdomain = currentHost.split('.')[0];
        return `https://${subdomain}.misfitsmarket.com`;
    };

    // Create the full order deep dive URL
    const orderDeepDiveUrl = `${getBaseUrl()}/lettuce/system/order-deep-dive?orderNumber=${searchTerm}`;

    // Function to format the source for display (convert snake_case to Title Case)
    const formatSource = (source) => {
        return source
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    return (
        <Box mt={3}>
            <Paper style={{ padding: '20px' }}>
                <Typography variant="h5" gutterBottom>
                    FBM/FaM Order
                </Typography>
                <Typography variant="body1" paragraph>
                    This order was placed through {formatSource(source)}.
                </Typography>
                <Typography variant="body1">
                    <Link href={orderDeepDiveUrl} target="_blank" rel="noopener noreferrer">
                        View Order Details
                    </Link>
                </Typography>
            </Paper>
        </Box>
    );
};

export { ThirdPartyOrder };
