import { mmFetchWithResponse } from './mmFetchWithResponse';
import { mmFetch } from './mmFetch';

let marketingApi = {
    createV2Discount: async (item) => {
        let url = 'api/discounts/v2';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    uploadCustomerIdCsvFileForDiscountCode: async (customerIdCsvFile, discountCode) => {
        let formData = new FormData();
        formData.append('customerIDList', customerIdCsvFile);

        let url = `api/discounts/v2/customerList/` + discountCode + `/append`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    downloadCustomerIdCsvFileForDiscountCode: async (discountID) => {
        let filename = `customerIdList-${discountID}.csv`;
        let url = `api/discounts/v2/customerList/` + discountID + `/csv`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadCustomerIdListCSV] error', e.toString());
        }
    },
    getAllReferralRewards: async () => {
        let url = `api/marketing/v1/referrals/list`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getReferralRewards: async () => {
        let url = `api/marketing/v1/referrals/credit`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDiscounts: async () => {
        let url = `api/discounts/v2?firstUseOnly=true&limitedReferral=true`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDiscount: async (discountID) => {
        let url = `api/discounts/v2/${discountID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    disableDiscount: async (discountID) => {
        let url = `api/discounts/v2/disable`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ discountID: discountID }),
        });
        return response;
    },
    updateV2Discount: async (discountId, claimBy, useBy, nanobarText, nanobarTerms) => {
        let url = `api/discounts/v2/modify`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({
                discountID: discountId,
                claimBy: claimBy,
                useBy: useBy,
                nanobarText: nanobarText,
                nanobarTerms: nanobarTerms,
            }),
        });
        return response;
    },
    updateReferralRewards: async (item) => {
        let url = `api/marketing/v1/referrals/credit`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    getProduceList: async () => {
        let url = `api/marketing/v1/produceList`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    createProduceListItem: async ({ itemName, image, countryOfOrigin, rankNumber }) => {
        let formData = new FormData();

        formData.append('itemName', itemName);
        formData.append('image', image);
        formData.append('countryOfOrigin', countryOfOrigin);
        formData.append('rankNumber', rankNumber);

        let url = `api/marketing/v1/produceList`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    updateProduceListItem: async (id, item) => {
        let formData = new FormData();
        Object.keys(item).forEach((key) => {
            formData.append(key, item[key]);
        });

        let url = `api/marketing/v1/produceList/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    deleteProduceListItem: async (id) => {
        let url = `api/marketing/v1/produceList/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    getVipCustomerList: async () => {
        let url = `api/marketing/v1/vipCustomerList`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    deleteVipCustomer: async (id) => {
        let url = `api/marketing/v1/vipCustomerList/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    uploadVipCustomers: async (customerFile) => {
        let formData = new FormData();
        formData.append('customerFile', customerFile);

        let url = `api/marketing/v1/vipCustomerList`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    getAcquisitionPDPs: async () => {
        const url = 'api/catalog/v1/acquisition-pdps';
        return mmFetchWithResponse(url);
    },
    generateAcquisitionPDPs: async () => {
        const url = 'api/catalog/v1/acquisition-pdps';
        return mmFetchWithResponse(url, { method: 'PUT' });
    },
};

export { marketingApi };
