import { mmFetchWithResponse } from './mmFetchWithResponse';

class CycleCountApi {
    constructor() {
        this.baseUrl = 'api/cycle-count/v1/';
    }

    async createSkuCycleCountRequest(externalSkus, facilityID) {
        const url = `${this.baseUrl}request/sku`;
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ externalSkus, facilityID }),
        });
    }

    async getSkuOptions(facilityID) {
        const url = `${this.baseUrl}skus?facilityID=${facilityID}`;
        return mmFetchWithResponse(url);
    }

    async getLocationCycleCountResults(facilityID, start, end, partner = null, showIncomplete = false) {
        let url = `${this.baseUrl}results/location?facilityID=${facilityID}&start=${start}&end=${end}`;
        if (partner) {
            url += `&partner=${partner}`;
        }
        if (showIncomplete) {
            url += `&includeIncomplete=${showIncomplete}`;
        }
        return mmFetchWithResponse(url);
    }

    async excludeFromCycleCountResults(requestEntityID) {
        const url = `${this.baseUrl}requestEntity/${requestEntityID}/exclude`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
        });
    }

    async getLocationIssues(facilityID) {
        const url = `${this.baseUrl}results/location/issues?facilityID=${facilityID}`;
        return mmFetchWithResponse(url);
    }

    async resolveIssue(eventID, inventorySummaryEventID = null) {
        const url = `${this.baseUrl}results/location/issues`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ eventID, inventorySummaryEventID }),
        });
    }

    async getSkuCycleCountResults(facilityID) {
        const url = `${this.baseUrl}results/sku?facilityID=${facilityID}`;
        return mmFetchWithResponse(url);
    }

    async getSkuCycleCountRequestEntityResults(requestEntityID) {
        const url = `${this.baseUrl}results/sku/${requestEntityID}`;
        return mmFetchWithResponse(url);
    }
}

export { CycleCountApi };
