import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { FormComponent } from '../../../shared';
import { goPuffApi as api } from '../../../api/goPuff';

const MarkTmsStatusModal = ({
    modalOpen,
    setModalOpen,
    status,
    action,
    row,
    setNotification,
    getData,
    bulkIDs,
    setBulkIDs,
    setAction,
}) => {
    const assignPalletStatus = async (id, formData) => {
        const res = await api.markTMSPalletUploadWithStatus(id, formData);

        if (!res.status) {
            setNotification({
                level: 'error',
                text: `Failed to assign pallet statuses: ${res.msg}`,
            });
        } else {
            setNotification({
                level: 'success',
                text: 'Pallet status successfully assigned!',
            });

            getData();
        }
    };

    const formFields = [
        {
            name: 'date',
            inputElement: 'date',
            label: 'Date',
            inputProps: { required: true },
        },
        {
            name: 'time',
            inputElement: 'time',
            label: 'Time',
            inputProps: { required: true },
        },
        {
            name: 'status',
            inputElement: 'select',
            label: 'Action',
            inputProps: {
                required: true,
                opts: [
                    { text: 'Arrived', value: 'ArrivedAtOrigin' },
                    { text: 'En Route', value: 'Enroute' },
                    { text: 'Delivered', value: 'CompletedShipment' },
                ],
                onChange: (e) => setAction(e.target.value),
            },
            display: () => bulkIDs.length > 0,
        },
    ];

    return (
        <Dialog
            open={modalOpen}
            onClose={() => {
                setModalOpen(false);
                setBulkIDs([]);
            }}
            maxWidth="md"
        >
            <DialogTitle>Mark shipment as {status}</DialogTitle>
            <DialogContent>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        if (bulkIDs && bulkIDs.length > 0) {
                            await Promise.all(bulkIDs.map((id) => assignPalletStatus(id, formData)));
                        } else {
                            formData.status = action;
                            await assignPalletStatus(row.original.id, formData);
                        }
                        setModalOpen(false);
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export { MarkTmsStatusModal };
