import React, { useState } from 'react';
import {
    FormControl,
    Select,
    MenuItem,
    Chip,
    Box,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1)
    },
    chipsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(0.5),
        marginTop: theme.spacing(1)
    },
    chip: {
        height: '24px'
    }
}));

const SelectFilter = ({
    type,
    name,
    deletable = true,
    onFilterChange,
    options = [],
    multiple = false,
    placeholder = 'Select...'
}) => {
    const classes = useStyles();
    const [selectedValues, setSelectedValues] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        
        if (multiple) {
            if (selectedValue.includes('select-all')) {
                const newValues = selectedValues.length === options.length ? [] : options;
                setSelectedValues(newValues);
                if (newValues.length === 0) {
                    onFilterChange(null);
                } else {
                    onFilterChange(newValues.map(item => ({
                        type,
                        data: item,
                        chipLabel: `${name}: ${item}`,
                        deletable: true
                    })));
                }
                return;
            }

            const newValues = Array.isArray(selectedValue) ? selectedValue : [selectedValue];
            setSelectedValues(newValues);
            if (newValues.length === 0) {
                onFilterChange(null);
            } else {
                onFilterChange(newValues.map(item => ({
                    type,
                    data: item,
                    chipLabel: `${name}: ${item}`,
                    deletable: true
                })));
            }
        } else {
            const newValue = selectedValue || '';
            setSelectedValues(newValue ? [newValue] : []);
            if (!newValue) {
                onFilterChange(null);
            } else {
                onFilterChange({
                    type,
                    data: newValue,
                    chipLabel: `${name}: ${newValue}`,
                    deletable
                });
            }
        }
    };

    const handleDeleteChip = (valueToDelete) => {
        if (multiple) {
            const newValues = selectedValues.filter(value => value !== valueToDelete);
            setSelectedValues(newValues);
            
            if (newValues.length === 0) {
                onFilterChange(null);
            } else {
                onFilterChange(newValues.map(value => ({
                    type,
                    data: value,
                    chipLabel: `${name}: ${value}`,
                    deletable: true
                })));
            }
        } else {
            setSelectedValues([]);
            onFilterChange(null);
        }
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Box className={classes.container}>
            <FormControl variant="outlined" size="small" fullWidth>
                <Select
                    value={multiple ? selectedValues : (selectedValues[0] || '')}
                    onChange={handleChange}
                    multiple={multiple}
                    open={isOpen}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    displayEmpty
                    renderValue={(selected) => {
                        if (!selected || (Array.isArray(selected) && selected.length === 0)) {
                            return placeholder;
                        }
                        if (!multiple) {
                            return selected;
                        }
                        return `${selected.length} selected`;
                    }}
                >
                    {multiple && (
                        <MenuItem 
                            key="select-all"
                            value="select-all"
                        >
                            {selectedValues.length === options.length ? 'Deselect All' : 'Select All'}
                        </MenuItem>
                    )}
                    {options.map((option) => (
                        <MenuItem 
                            key={option} 
                            value={option}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {multiple && selectedValues.length > 0 && (
                <Box className={classes.chipsContainer}>
                    {selectedValues.map((value) => (
                        <Chip
                            key={value}
                            label={value}
                            onDelete={() => handleDeleteChip(value)}
                            size="small"
                            className={classes.chip}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export { SelectFilter }; 