import React, { useEffect, useState } from 'react';
import { FormComponent } from '../../../../shared';
import {
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    Typography,
    makeStyles,
    Paper,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { fbmApi } from '../../../../api/fbm';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    formControl: {
        minWidth: 200,
    },
    filterSection: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[50],
        borderRadius: theme.shape.borderRadius,
        width: '60%',
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    filterGroup: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: theme.spacing(2),
        flexWrap: 'wrap',
        flex: 1,
    },
    dateGroup: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    timeframeLabel: {
        whiteSpace: 'nowrap',
    },
    okButton: {
        marginLeft: theme.spacing(2),
    },
}));

const LocationAccuracyRangeAndPartnerSelection = ({ onSubmit, initialStartDate, initialEndDate }) => {
    const classes = useStyles();
    const [partnerFilterEnabled, setPartnerFilterEnabled] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState('NONE');
    const [partnerOptions, setPartnerOptions] = useState([]);
    const [showIncomplete, setShowIncomplete] = useState(false);

    useEffect(() => {
        getFBMPartners();
    }, []);

    const handleToggleChange = (event) => {
        setPartnerFilterEnabled(event.target.checked);
        if (!event.target.checked) {
            setSelectedPartner('NONE');
        }
    };

    const handleShowIncompleteChange = (event) => {
        setShowIncomplete(event.target.checked);
    };

    const handlePartnerChange = (event) => {
        setSelectedPartner(event.target.value);
    };

    const getFBMPartners = async () => {
        const res = await fbmApi.getFBMAccounts();
        if (res.status) {
            const excludedNames = ['gopuff', 'doordash', 'imperfect'];
            const filteredAccounts = res.data.accounts.filter(
                (account) => !excludedNames.includes(account.fbmAccountName)
            );
            setPartnerOptions(filteredAccounts);
        }
    };

    const handleSubmit = async (formData) => {
        if (selectedPartner && selectedPartner !== 'NONE') {
            formData.selectedPartner = selectedPartner;
        } else {
            formData.selectedPartner = null;
        }
        formData.showIncomplete = showIncomplete;
        await onSubmit(formData);
    };

    const formFields = [
        {
            name: 'start',
            inputElement: 'date',
            initialValue: initialStartDate,
            gridValue: 5,
            inputProps: {
                required: true,
                size: 'small',
            },
        },
        {
            name: 'end',
            inputElement: 'date',
            initialValue: initialEndDate,
            gridValue: 5,
            inputProps: {
                required: true,
                size: 'small',
            },
        },
    ];

    const formButton = {
        text: 'OK',
        gridValue: 2,
        variant: 'contained',
        color: 'primary',
        className: classes.okButton,
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.filterSection}>
                <div className={classes.filtersContainer}>
                    <div className={classes.filterGroup}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="includeIncomplete"
                                    value={showIncomplete}
                                    onChange={handleShowIncompleteChange}
                                    color="primary"
                                />
                            }
                            label="Show Incomplete"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={partnerFilterEnabled}
                                    onChange={handleToggleChange}
                                    color="primary"
                                />
                            }
                            label="Partner Filter"
                        />
                        {partnerFilterEnabled && (
                            <FormControl variant="outlined" size="small" className={classes.formControl}>
                                <InputLabel id="partner-select-label">Select a Partner</InputLabel>
                                <Select
                                    labelId="partner-select-label"
                                    id="partner-select"
                                    value={selectedPartner}
                                    onChange={handlePartnerChange}
                                    label="Select a Partner"
                                >
                                    <MenuItem value="NONE">
                                        <em>Select a Partner</em>
                                    </MenuItem>
                                    {partnerOptions.map((partner) => (
                                        <MenuItem key={partner.fbmAccountName} value={partner.fbmAccountName}>
                                            {partner.companyName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </div>

                    <div className={classes.dateGroup}>
                        <Typography variant="subtitle1" className={classes.timeframeLabel}>
                            Timeframe:
                        </Typography>
                        <FormComponent formFields={formFields} button={formButton} onSubmit={handleSubmit} />
                    </div>
                </div>
            </div>
        </Paper>
    );
};

export { LocationAccuracyRangeAndPartnerSelection };
