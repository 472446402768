import React, { useEffect, useState } from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { TabPanel } from '../../../shared';
import { useLocation, useHistory } from 'react-router-dom';
import { ActiveNegativeVariance } from './negativeVariance/activeNegativeVariance';
import { NegativeVarianceHistory } from './negativeVariance/negativeVarianceHistory';

const NegativeVariance = () => {
    const location = useLocation();
    const history = useHistory();

    const getTabFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return Number(params.get('tab')) || 0;
    };

    const [selectedTab, setSelectedTab] = useState(getTabFromUrl());

    useEffect(() => {
        const updatedParams = { tab: selectedTab };
        const filteredParams = Object.fromEntries(
            Object.entries(updatedParams).filter(([_, value]) => value !== undefined && value !== '')
        );

        const searchParams = new URLSearchParams(filteredParams);
        history.replace(`?${searchParams.toString()}`);
    }, [selectedTab, history]);

    const tabChange = (event, value) => {
        setSelectedTab(value);
    };

    const handleLinkClicked = (value) => {
        tabChange(null, value.tabIndex);
    };

    return (
        <Paper style={{ minHeight: '80vh' }}>
            <Tabs onChange={tabChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ActiveNegativeVariance_Tab" label="Current Negative Variance" value={0} />
                <Tab id="NegativeVarianceHistory_Tab" label="Negative Variance History" value={1} />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={0}>
                <ActiveNegativeVariance onLinkClicked={handleLinkClicked} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={1}>
                <NegativeVarianceHistory onLinkClicked={handleLinkClicked} />
            </TabPanel>
        </Paper>
    );
};
export { NegativeVariance };
