import React, { useState } from 'react';
import { Grid, Button, TextField, Divider } from '@material-ui/core';

const PrintLabelsForLot = ({ lot, printCases, printPallets }) => {
    const [palletLabelQty, setPalletLabelQty] = useState(4);
    const [caseLabelQty, setCaseLabelQty] = useState(lot.caseCount);

    const [printingCaseLabels, setPrintingCaseLabels] = useState(false);
    const [printingPalletLabels, setPrintingPalletLabels] = useState(false);

    const printCaseLabels = async () => {
        setPrintingCaseLabels(true);
        await printCases(lot.id, caseLabelQty);
        setPrintingCaseLabels(false);
    };

    const printPalletLabels = async () => {
        setPrintingPalletLabels(true);
        await printPallets(lot.id, palletLabelQty);
        setPrintingPalletLabels(false);
    };

    const handlePalletFormChange = (e) => {
        let value = e.target.value;
        value = parseInt(value, 10);

        if (isNaN(value) || value <= 0) {
            setPalletLabelQty('');
        } else if (value > 16) {
            setPalletLabelQty(16); // Cap the value at 16 (API caps this at 16)
        } else {
            setPalletLabelQty(value); // Accept valid values
        }
    };

    const handleCaseFormChange = (e) => {
        let value = e.target.value;
        value = parseInt(value, 10);

        const limit = lot.caseCount * 2;
        if (value > limit) {
            setCaseLabelQty(limit);
        } else {
            setCaseLabelQty(value);
        }
    };

    return (
        <div key={lot.id} style={{ border: '1px solid black', borderRadius: '5px', margin: '5px 30px 15px' }}>
            <Grid container style={{ fontSize: '16px', padding: '10px', backgroundColor: 'lightgray' }}>
                <Grid item xs={4}>
                    <strong>Lot ID: </strong>
                    <span>{lot.id}</span>
                </Grid>
                <Grid item xs={4}>
                    <strong>Partner Lot Number: </strong>
                    <span>{lot.partnerLotNumber ?? 'N/A'}</span>
                </Grid>
                <Grid item xs={4}>
                    <strong>Expires On: </strong>
                    <span>{lot.expiresOn?.date ?? 'N/A'}</span>
                </Grid>
            </Grid>
            <Divider />
            <Grid container style={{ paddingBottom: '15px' }}>
                <Grid item xs={6}>
                    <h3>Print Pallet Labels</h3>
                    <TextField
                        id="print-pallet-labels"
                        label="Number of Labels Per Pallet"
                        value={palletLabelQty}
                        type="number"
                        inputProps={{ min: 1, step: '1' }}
                        onChange={handlePalletFormChange}
                        InputProps={{
                            endAdornment: (
                                <Button disabled={printingPalletLabels} onClick={printPalletLabels}>
                                    Print
                                </Button>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <h3>Print Case Labels</h3>
                    <TextField
                        id="print-case-labels"
                        label="Number of Case Labels"
                        value={caseLabelQty}
                        type="number"
                        inputProps={{ min: 1, step: '1' }}
                        onChange={handleCaseFormChange}
                        InputProps={{
                            endAdornment: (
                                <Button disabled={printingCaseLabels} onClick={printCaseLabels}>
                                    Print
                                </Button>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export { PrintLabelsForLot };
