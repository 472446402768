import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Modal,
    Paper,
    Tab,
    Tabs,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import { DataTable, FormComponent, Notifications, TabPanel } from '../../../shared';
import { logisticsApi } from '../../../api/logistics';
import { UserContext } from '../../../contexts/UserContext';

const CartExperience = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const user = useContext(UserContext);

    const getItemsInTable = async () => {
        const response = await logisticsApi.getCartExperiences();

        if (response.status === false) {
            setNotification({ text: 'No cart experiences found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        let cxDetails = response.data.cartExperiences;
        setRows(cxDetails);
    };
    useEffect(() => {
        getItemsInTable();
    }, []);

    const canChangeCartExperience = user.canChangeCartExperience();

    const columns = [
        { accessor: 'id', Header: 'ID', editable: false },
        { accessor: 'zipCode', Header: 'CX - Zip Code Override', editable: false },
        {
            accessor: 'freeShippingEligible',
            Header: 'CX - Free Shipping Eligible',
            editable: canChangeCartExperience,
            type: 'checkbox',
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'freeShippingThreshold',
            Header: 'CX - Free Shipping Threshold (USD Cents)',
            editable: canChangeCartExperience,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'orderMinimumOverridden',
            Header: 'CX - Order Minimum Overridden',
            editable: canChangeCartExperience,
            type: 'checkbox',
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'orderMinimum',
            Header: 'CX - Order Minimum (USD Cents)',
            editable: canChangeCartExperience,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'coldKeeperFeeEnabled',
            Header: 'CX - Cold Keeper Fee Enabled',
            editable: canChangeCartExperience,
            type: 'checkbox',
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'coldKeeperFee',
            Header: 'CX - Cold Keeper Fee (USD Cents)',
            editable: canChangeCartExperience,
            editProps: { type: 'input', inputType: 'number' },
        },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            <div style={{ marginBottom: '2em', textAlign: 'left' }}>
                <small>
                    Note: You can submit a file exported from the table below, but only the <mono>cartExperience</mono>{' '}
                    columns will be be used; the rest will be ignored.
                </small>
                <FormComponent
                    formFields={[
                        {
                            name: 'cartExperienceFile',
                            label: 'Upload Cart Experience',
                            inputElement: 'file',
                            accept: ['text/csv'],
                        },
                    ]}
                    onSubmit={async (formData, resetForm) => {
                        const result = await logisticsApi.uploadCartExperience(formData);
                        setNotification({ text: result.msg, level: result.status ? 'success' : 'error' });
                        if (result.status) {
                            resetForm();
                        }
                        getItemsInTable();
                    }}
                />
            </div>
            <DataTable editable={true} title="Zip Codes" columns={columns} data={rows} />
        </Grid>
    );
};

export { CartExperience };
