const { VITE_REACT_APP_MAGIC_TOKEN = '' } = import.meta.env;
let baseURL = '';

let { VITE_REACT_APP_BACKEND: apiBackend = '' } = import.meta.env;

apiBackend = apiBackend.trim();

if (apiBackend) {
    baseURL = `https://${apiBackend}-dev.misfitsmarket.com`;
}

const defaultOptions = {
    headers: {
        'Content-Type': 'application/json',
    },
};

if (VITE_REACT_APP_MAGIC_TOKEN) {
    defaultOptions.headers['x-mm-dev-lettuce'] = VITE_REACT_APP_MAGIC_TOKEN;
}

const mmFetch = (url, options) => fetch(`${baseURL}/${url}`, { ...defaultOptions, ...options });

export { mmFetch };
