import React, { useState, useContext, useEffect } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { TabPanel } from '../../../shared';
import { CreateReferral } from '../components/createReferrals';
import { ViewReferrals } from '../components/viewReferrals';
import { UserContext } from '../../../contexts/UserContext';

let ReferralProgram = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    const { roles } = useContext(UserContext);
    const canEditReferrals = roles?.some((role) => role.name.endsWith('UPDATE REFERRALS'));

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewReferrals_Tab" label="View Referrals" value="0" />
                {canEditReferrals && <Tab id="CreateReferral_Tab" label="Create Referrals" value="1" />}
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewReferrals />
            </TabPanel>
            {canEditReferrals && (
                <TabPanel selectedTab={selectedTab} index={'1'}>
                    <CreateReferral />
                </TabPanel>
            )}
        </Paper>
    );
};

export { ReferralProgram };
