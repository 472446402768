import React, { useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';

let PartnerLotNumbersDateForm = ({ formData, setFormField, partnerLotNumberOnDefault }) => {
    useEffect(() => {
        let partnerLotNumbersTmp = [];
        let i = 0;
        while (i < Number(formData.numPallets)) {
            partnerLotNumbersTmp.push(formData.partnerLotNumbers[i] || partnerLotNumberOnDefault);
            i++;
        }

        if (partnerLotNumbersTmp.length > 0 && partnerLotNumbersTmp.length != formData.partnerLotNumbers.length) {
            setFormField(partnerLotNumbersTmp);
        }
    }, [formData]);

    return (
        <>
            <h4>Partner Lot Numbers</h4>
            {formData.partnerLotNumbers.map((formField, formFieldIndex) => {
                return (
                    <Grid item xs={12} key={formFieldIndex}>
                        <TextField
                            type="string"
                            style={{ width: '100%', padding: 15 }}
                            label={`Partner Lot Number`}
                            value={formData.partnerLotNumbers[formFieldIndex] || ''}
                            onChange={(event) => {
                                let partnerLotNumbersTmp = JSON.parse(JSON.stringify(formData.partnerLotNumbers));
                                partnerLotNumbersTmp[formFieldIndex] = event.target.value;
                                setFormField(partnerLotNumbersTmp);
                            }}
                        />
                    </Grid>
                );
            })}
        </>
    );
};

export { PartnerLotNumbersDateForm };
