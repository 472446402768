import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { Grid, InputLabel } from '@material-ui/core';
import { DataTable } from '../../../../shared';

const DeepDiveResults = ({ deepDiveResults }) => {
    const [thirdPartyInfoColumns, setThirdPartyInfoColumns] = useState([]);
    const { fbmAccount } = useContext(UserContext);
    const [shipToCompare, setShipToCompare] = useState({});

    const summaryColumns = [
        { accessor: 'shipmentId', Header: 'Shipment Id' },
        { accessor: 'shipStatus', Header: 'ShipStatus' },
        { accessor: 'trackingUrl', Header: 'Tracking Url' },
        { accessor: 'hub', Header: 'Hub' },
        { accessor: 'carrier', Header: 'Carrier' },
    ];

    const ingestColumn = [
        { accessor: 'insertedAt', Header: 'Recorded At' },
        { accessor: 'type', Header: 'Type' },
        { accessor: 'details', Header: 'Details' },
    ];

    const eventColumn = [
        { accessor: 'shipmentId', Header: 'ShipmentId' },
        { accessor: 'timestamp', Header: 'Timestamp' },
        { accessor: 'type', Header: 'Event' },
        { accessor: 'details', Header: 'Details' },
    ];

    const itemMMCol = [
        { accessor: 'shipmentId', Header: 'Shipment Id' },
        { accessor: 'name', Header: 'Item Name' },
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'thirdPartyItemID', Header: `${fbmAccount.companyName} SKU` },
        { accessor: 'qty', Header: 'Amount' },
    ];

    const thirdPartyItemsColumns = [
        { accessor: 'name', Header: 'Item Name' },
        { accessor: 'thirdPartyItemID', Header: `${fbmAccount.companyName} SKU` },
        { accessor: 'qty', Header: 'Amount' },
    ];

    useEffect(() => {
        if (fbmAccount.fbmAccountName === 'littlespoon') {
            setThirdPartyInfoColumns([
                { accessor: 'createdOn', Header: 'Ordered At' },
                { accessor: 'ssOrderID', Header: 'ShipStation Order ID' },
                { accessor: 'status', Header: 'Status' },
                { accessor: 'warehouse', Header: 'Warehouse' },
                { accessor: 'isFulfilledByMisfits', Header: 'In Misfits System?' },
            ]);
        }
    }, [fbmAccount]);

    useEffect(() => {
        const shipToInternal = deepDiveResults.shipToInternal;
        const shipToExternal = deepDiveResults.shipToExternal;
        setShipToCompare({
            lineOne: shipToInternal.lineOne?.toLowerCase() === shipToExternal.lineOne?.toLowerCase(),
            lineTwo: shipToInternal.lineTwo?.toLowerCase() === shipToExternal.lineTwo?.toLowerCase(),
            city: shipToInternal.city?.toLowerCase() === shipToExternal.city?.toLowerCase(),
            province: shipToInternal.province?.toLowerCase() === shipToExternal.province?.toLowerCase(),
            zip: shipToInternal.zip === shipToExternal.zip,
        });
    }, [deepDiveResults]);

    const addressTextColor = (flag) => {
        return flag ? 'black' : 'red';
    };

    return (
        <>
            <h2>Summary Information</h2>
            <Grid container>
                <Grid item xs={6}>
                    <InputLabel>{fbmAccount.companyName} Order Number</InputLabel>
                    {deepDiveResults.orderNum}
                </Grid>
                <Grid item xs={6}>
                    <InputLabel>Misfits Order Number</InputLabel>
                    {deepDiveResults.mmOrderNum}
                </Grid>
            </Grid>
            <DataTable columns={summaryColumns} data={deepDiveResults.shipmentInfo} />
            <br />
            <h2>Misfits Address Information</h2>
            <Grid container>
                <Grid item xs={true}>
                    <InputLabel>Line One</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.lineOne) }}>
                        {deepDiveResults.shipToInternal.lineOne}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>Line Two</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.lineTwo) }}>
                        {deepDiveResults.shipToInternal.lineTwo}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>City</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.city) }}>
                        {deepDiveResults.shipToInternal.city}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>State</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.province) }}>
                        {deepDiveResults.shipToInternal.province}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>Zip</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.zip) }}>
                        {deepDiveResults.shipToInternal.zip}
                    </span>
                </Grid>
            </Grid>
            <br />
            <h2>{fbmAccount.companyName} Address Information</h2>
            <Grid container>
                <Grid item xs={true}>
                    <InputLabel>Line One</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.lineOne) }}>
                        {deepDiveResults.shipToExternal.lineOne}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>Line Two</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.lineTwo) }}>
                        {deepDiveResults.shipToExternal.lineTwo}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>City</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.city) }}>
                        {deepDiveResults.shipToExternal.city}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>State</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.province) }}>
                        {deepDiveResults.shipToExternal.province}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>Zip</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.zip) }}>
                        {deepDiveResults.shipToExternal.zip}
                    </span>
                </Grid>
            </Grid>
            <br />
            <h2>{deepDiveResults.thirdPartyName} Records</h2>
            <DataTable columns={thirdPartyInfoColumns} data={deepDiveResults.thirdPartyInfo} />
            <br />
            <h2>Ingest Log</h2>
            <DataTable columns={ingestColumn} data={deepDiveResults.digestEvents} />
            <br />
            <Grid container>
                <Grid item xs={6}>
                    <h2>Items ({deepDiveResults.thirdPartyName})</h2>
                    <DataTable columns={thirdPartyItemsColumns} data={deepDiveResults.thirdPartyItems} />
                </Grid>
                <Grid item xs={6}>
                    <h2>Items (Misfits)</h2>
                    <DataTable columns={itemMMCol} data={deepDiveResults.mmItems} />
                </Grid>
            </Grid>
            <h2>Event Log</h2>
            <DataTable columns={eventColumn} data={deepDiveResults.shipEvents} />
        </>
    );
};

export { DeepDiveResults };
