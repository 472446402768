import React from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    tooltip: {
        fontSize: '0.875rem',
    },
}));

const PaymentDetailTooltip = ({ item, isPlusMember, children }) => {
    const classes = useStyles();
    const hasRefundAmount = item.pointsRefunded > 0 || item.creditsRefunded > 0 || item.cashRefunded > 0;
    const itemWasCharged =
        item.status !== 'CANCELLED' && (item.pointsUsed > 0 || item.creditsUsed > 0 || item.totalForCard > 0);

    const itemInfo = (
        <div>
            {!!item.status && <div>Status: {item.status}</div>}
            <hr />
            {item.msrp > 0 && <div>MSRP: ${item.msrp.toFixed(2)}</div>}
            {(item.nonPlusMemberPrice > 0 || item.price > 0) && (
                <div>
                    Non-Member: ${item.nonPlusMemberPrice ? item.nonPlusMemberPrice.toFixed(2) : item.price.toFixed(2)}
                    {isPlusMember ? '' : '*'}
                </div>
            )}
            {item.plusMemberPrice > 0 && (
                <div>
                    Plus Member: ${item.plusMemberPrice ? item.plusMemberPrice.toFixed(2) : 'N/A'}
                    {isPlusMember ? '*' : ''}
                </div>
            )}
            <hr />
            {item.discountAmount > 0 && <div>Discount: -${item.discountAmount.toFixed(2)}</div>}
            <div>Tax Charged: ${(item.taxAmount || 0).toFixed(2)}</div>
            <div>
                Subtotal: $
                {(
                    parseFloat((item.priceForCustomer * item.qty).toFixed(2)) +
                    item.taxAmount -
                    item.discountAmount
                ).toFixed(2)}
            </div>
            <hr />
            {itemWasCharged ? (
                <>
                    {item.pointsUsed > 0 && <div>Points Used: {item.pointsUsed}</div>}
                    {item.creditsUsed > 0 && <div>Credits Used: ${item.creditsUsed.toFixed(2)}</div>}
                    {item.totalForCard > 0 && <div>Stripe Charge: ${item.totalForCard.toFixed(2)}</div>}
                </>
            ) : (
                <div>Not Charged</div>
            )}
            {hasRefundAmount && <hr />}
            {item.pointsRefunded > 0 && <div>Points Returned: {item.pointsRefunded}</div>}
            {item.creditsRefunded > 0 && <div>Credit Returned: ${item.creditsRefunded.toFixed(2)}</div>}
            {item.cashRefunded > 0 && <div>Stripe Refund: ${item.cashRefunded.toFixed(2)}</div>}
        </div>
    );

    return (
        <Tooltip title={itemInfo} classes={{ tooltip: classes.tooltip }} arrow>
            {children}
        </Tooltip>
    );
};

export { PaymentDetailTooltip };
