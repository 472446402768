import React, { useState } from 'react';
import { FormComponent } from '..';
import { InputAdornment, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const SearchBar = ({ onSubmit, searchByOptions, onClearSearch = null }) => {
    const [submitted, setSubmitted] = useState(false);
    const [resetFormData, setResetFormData] = useState(null);

    const clearSearch = () => {
        setSubmitted(false);
        if (resetFormData instanceof Function) {
            resetFormData();
        }
        onClearSearch();
    };

    const clearSearchAdornment = () => {
        if (!submitted) {
            return null;
        }
        return (
            <InputAdornment position="end">
                <IconButton onClick={clearSearch}>
                    <ClearIcon />
                </IconButton>
            </InputAdornment>
        );
    };

    let searchFormFields = [
        {
            name: 'searchBy',
            inputElement: 'select',
            initialValue: searchByOptions[0].value,
            gridValue: 3,
            inputProps: { required: true, opts: searchByOptions },
        },
        {
            name: 'searchTerm',
            label: ' ',
            inputElement: 'textField',
            gridValue: 8,
            inputProps: { required: true },
            InputProps: { endAdornment: onClearSearch instanceof Function ? clearSearchAdornment() : null },
        },
    ];

    return (
        <FormComponent
            formFields={searchFormFields}
            button={{ text: 'Search', gridValue: 1 }}
            onSubmit={async (formData, resetForm) => {
                setResetFormData(() => () => resetForm());
                setSubmitted(true);
                onSubmit(formData);
            }}
        />
    );
};

export { SearchBar };
