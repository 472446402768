import { mmFetchWithResponse } from './mmFetchWithResponse';
import { mmFetch } from './mmFetch';

let fbmApi = {
    addItemToASN: async (id, item) => {
        let url = `api/fbm/v1/asn/` + id + `/items`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    addItemToPackagingASN: async (id, item) => {
        let url = `api/fbm/v1/packagingASN/${id}/items`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    cancelASN: async (id, fbmAccount = '') => {
        let url = `api/fbm/v1/asn/${id}/cancel`;
        if (!!fbmAccount) {
            url += `?fbmAccount=${fbmAccount}`;
        }
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    cancelPackagingASN: async (id, fbmAccount = '') => {
        let url = `api/fbm/v1/packagingASN/${id}/cancel`;
        if (!!fbmAccount) {
            url += `?fbmAccount=${fbmAccount}`;
        }
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    createPackagingASN: async (item) => {
        let url = `api/fbm/v1/packagingASN`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    createPurchaseOrder: async (item) => {
        let url = `api/fbm/v1/asn`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    downloadIngestResults: async (fbmAccount = '') => {
        let url = `api/fbm/v1/orders/ingest/download`;
        if (!!fbmAccount) {
            url += `?fbmAccount=${fbmAccount}`;
        }
        let filename = `order_ingest_results.csv`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadIngestResults] error', e.toString());
        }
    },
    getASNs: async (fbmAccount) => {
        let url = `api/fbm/v1/asn?fbmAccount=${fbmAccount}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getASNDetails: async (id, fbmAccount) => {
        let url = `api/fbm/v1/asn/${id}?fbmAccount=${fbmAccount}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getFBMAccounts: async (accountName = '', fromReportScreen = false) => {
        let url = `api/fbm/v1/accounts?fromReportScreen=${fromReportScreen}`;
        if (!!accountName) {
            url += `&name=${accountName}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    updateFBMAccountConfig: async (accountId, values) => {
        let url = `api/fbm/v1/accounts/${accountId}/config`;

        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(values),
        });

        return response;
    },
    getHospitalShipments: async (facilityID, fbmAccount) => {
        let url = `api/fbm/v1/shipments/hospital?facilityID=${facilityID}&fbmAccount=${fbmAccount}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getBulkOrderIngestionDetails: async (fbmAccount) => {
        let url = `api/fbm/v1/orders/bulkIngestDetails?fbmAccount=${fbmAccount}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getInventory: async (facilityID, fbmAccount) => {
        let url = `api/fbm/v1/inventory?facilityID=${facilityID}&fbmAccount=${fbmAccount}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getInventoryUnitsandLotDetails: async (facilityID, fbmAccount, externalSkus, packagingIds) => {
        const url = `api/fbm/v1/inventory/units?facilityID=${facilityID}&fbmAccount=${fbmAccount}`;
        return await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({
                externalSkus: externalSkus,
                packagingIds: packagingIds,
            }),
        });
    },
    updateItemInventoryRiskThreshold: async (
        fbmAccountName,
        thirdPartyItemID,
        inventoryRiskThreshold,
        facilityID,
        type
    ) => {
        let url = 'api/fbm/v1/inventory';
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({
                inventoryRiskThreshold,
                thirdPartyItemID,
                fbmAccountName,
                facilityID,
                type,
            }),
        });
        return response;
    },
    getItems: async (fbmAccount = '') => {
        let url = `api/fbm/v1/items`;
        if (!!fbmAccount) {
            url += `?fbmAccount=${fbmAccount}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getViewAllOrders: async (fbmAccount, facilityID) => {
        let url = `api/fbm/v1/orders?fbmAccount=${fbmAccount}&facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getOrderDetails: async (orderNum, fbmAccount) => {
        let url = `api/fbm/v1/orders/details?orderNum=${encodeURIComponent(orderNum)}&fbmAccount=${fbmAccount}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackagingTypes: async (fbmAccount) => {
        return await mmFetchWithResponse(`api/fbm/v1/packaging/types?fbmAccount=${fbmAccount}`);
    },
    getPackaging: async (fbmAccount) => {
        let url = `api/fbm/v1/packaging?fbmAccount=${fbmAccount}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    createPackaging: async (packagingParams, fbmAccount) => {
        packagingParams.fbmAccountName = fbmAccount;
        return await mmFetchWithResponse(`api/fbm/v1/packaging`, {
            method: 'POST',
            body: JSON.stringify(packagingParams),
        });
    },
    getPackagingASNs: async (fbmAccount) => {
        let url = `api/fbm/v1/packagingASN?fbmAccount=${fbmAccount}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackagingASNDetails: async (asnID, fbmAccount) => {
        let url = `api/fbm/v1/packagingASN/${asnID}?fbmAccount=${fbmAccount}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    ingestSingleOrder: async (orderID, fbmAccount) => {
        let url = `api/fbm/v1/orders/ingestSingle`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ orderID: orderID, fbmAccount: fbmAccount }),
        });
        return response;
    },
    getShipmentStatus: async (facilityID, fbmAccount) => {
        let url = `api/fbm/v1/shipments/status?facilityID=${facilityID}&fbmAccount=${fbmAccount}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    markOrderShipped: async (mmShipID, fbmAccount) => {
        let url = `api/fbm/v1/orders/markShipped`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({
                shipmentID: mmShipID,
                fbmAccount: fbmAccount,
            }),
        });
        return response;
    },
    modifyASN: async (asnID, items) => {
        let url = `api/fbm/v1/asn/${asnID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(items),
        });
        return response;
    },
    modifyASNItem: async (asnID, rowID, items) => {
        let url = `api/fbm/v1/asn/${asnID}/items/${rowID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(items),
        });
        return response;
    },
    modifyPackagingASN: async (asnID, items) => {
        let url = `api/fbm/v1/packagingASN/${asnID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(items),
        });
        return response;
    },
    modifyPackagingASNItem: async (asnID, rowID, items) => {
        let url = `api/fbm/v1/packagingASN/${asnID}/items/${rowID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(items),
        });
        return response;
    },
    purchaseShipment: async (id, fbmAccount) => {
        let url = `api/fbm/v1/shipments/retry`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({
                shipmentID: id,
                fbmAccount: fbmAccount,
            }),
        });
        return response;
    },
    removeASNItem: async (asnID, rowID, fbmAccount) => {
        let url = `api/fbm/v1/asn/${asnID}/items/${rowID}/remove?fbmAccount=${fbmAccount}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    removePackagingASNItem: async (asnID, rowID, fbmAccount) => {
        let url = `api/fbm/v1/packagingASN/${asnID}/items/${rowID}/remove?fbmAccount=${fbmAccount}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },

    // Reports
    getReportReceivingDiscrepancyView: async (fbmAccount, reportStartDate, reportEndDate) => {
        let url = `api/fbm/v1/reports/receiving-discrepancy/view?fbmAccount=${fbmAccount}&reportStartDate=${reportStartDate}&reportEndDate=${reportEndDate}`;
        return await mmFetchWithResponse(url);
    },
    getReportPackagingInventoryView: async (fbmAccount) => {
        let url = `api/fbm/v1/reports/packaging-inventory/view?fbmAccount=${fbmAccount}`;
        return await mmFetchWithResponse(url);
    },
    getReportOnTimeDeliveryView: async (fbmAccount, reportStartDate, reportEndDate) => {
        let url = `api/fbm/v1/reports/on-time-delivery/view?fbmAccount=${fbmAccount}&reportStartDate=${reportStartDate}&reportEndDate=${reportEndDate}`;
        return await mmFetchWithResponse(url);
    },
    getReportExternalSkuUsageView: async (fbmAccount, facilityID) => {
        let url = `api/fbm/v1/reports/external-sku-usage/view?fbmAccount=${fbmAccount}&facilityID=${facilityID}`;
        return await mmFetchWithResponse(url);
    },
    getReportDailySkuPackView: async (fbmAccount, reportStartDate, reportEndDate, facilityID) => {
        let url = `api/fbm/v1/reports/daily-sku-pack/view?fbmAccount=${fbmAccount}&facilityID=${facilityID}&reportStartDate=${reportStartDate}&reportEndDate=${reportEndDate}`;
        return await mmFetchWithResponse(url);
    },

    // Little spoon specific endpoints
    createSub: async (sendParams) => {
        let url = `api/fbm/v1/littleSpoon/substitutions`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    removeSub: async (rowID) => {
        let url = `api/fbm/v1/littleSpoon/substitutions/${rowID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: '',
        });
        return response;
    },
    getSubList: async (week, year, facilityID) => {
        let url = `api/fbm/v1/littleSpoon/substitutions?facilityID=${facilityID}&week=${week}&year=${year}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
};

export { fbmApi };
