import React, { useState, useEffect, useCallback } from 'react';

function useGetTableData(apiMethod) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        setData([]);
        const response = await apiMethod();
        if (response.status) {
            setData(response.data.rows);
        } else {
            setError(response.msg);
        }
        setIsLoading(false);
    }, [apiMethod]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { data, isLoading, error, refetch: fetchData };
}

export { useGetTableData };
