import React from 'react';

import { FormWithDisplay } from './FormWithDisplay';
import { camelToTitleCase } from '../../../utils/globalUtils';
import { cartExperienceDollarAmountFields } from '../data/data';

const FIELDS_TO_EXCLUDE = ['orderMinimumOverridden', 'coldKeeperFeeEnabled'];

const CartExperience = ({ accountInfo }) => {
    const nextOrderCartExperienceDetails = Object.assign(
        {},
        {
            carrier: accountInfo?.nextOrder?.carrier ?? 'UNKNOWN',
            packagingReturnEligible: accountInfo?.isPRPEligible ?? false,
        },
        accountInfo?.nextOrder?.cartExperience ?? {}
    );

    const options = Object.entries(nextOrderCartExperienceDetails).map(([field, value]) => {
        const displayLabel = camelToTitleCase(field);
        let displayValue = value;

        if (field === 'coldKeeperFee' && value === 0) {
            displayValue = 'No Cold Keeper Fee';
        } else if (cartExperienceDollarAmountFields.includes(field)) {
            displayValue = `$${value}`;
        }

        return {
            name: field,
            label: displayLabel,
            initialValue: displayValue ?? '',
        };
    });

    const displayOptions = options.filter((item) => !FIELDS_TO_EXCLUDE.includes(item.name));

    return <FormWithDisplay editable={false} title="Cart Experience" formFields={displayOptions} />;
};

export { CartExperience };
