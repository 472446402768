import React, { useCallback, useEffect, useState, useContext } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { TextField, MenuItem, Button, Box } from '@material-ui/core';
import { Notifications } from '../../../../shared';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';

const warehouseInventoryV2 = new WarehouseInventoryV2Api();

const LocationInventorySearchBar = ({ queryParams, onSearch }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [companySourceOptions, setCompanySourceOptions] = useState([]);
    const [formData, setFormData] = useState({
        searchBy: queryParams.searchBy ?? '',
        searchTerm: queryParams.searchTerm ?? '',
        type: queryParams.type ?? '',
    });
    const { user } = useContext(UserContext);

    useEffect(() => {
        const getInventoryOptions = async () => {
            const res = await warehouseInventoryV2.getInventoryOptions(user.facilityID);
            if (!res.status) {
                setNotification({ text: res.message, level: 'error' });
                return;
            }
            setCompanySourceOptions(res.data.companySources);
        };

        getInventoryOptions();
    }, []);

    useEffect(() => {
        setFormData({
            searchBy: queryParams.searchBy ?? '',
            searchTerm: queryParams.searchTerm ?? '',
            type: queryParams.type ?? '',
        });
    }, [queryParams]);

    const onInputChange = (e) => {
        const { name, value } = e.target;
        if (['lotId', 'locationName', 'palletId'].includes(value)) {
            setFormData({
                ...formData,
                [name]: value,
                type: '',
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (needsType() & (formData.type === undefined || formData.type === '')) {
            setNotification({ text: 'Please select a type', level: 'error' });
        } else {
            onSearch(formData.searchBy, formData.searchTerm, formData.type);
        }
    };

    const handleClear = () => {
        setFormData({
            searchBy: '',
            searchTerm: '',
            type: '',
        });
        onSearch('', '', '');
    };

    const hasFormData = formData.searchBy !== '' || formData.searchTerm !== '';

    const needsType = useCallback(() => {
        if (['externalSku', 'purchaseOrderId', 'partnerSku'].includes(formData.searchBy)) {
            return true;
        }
        return false;
    }, [formData.searchBy]);

    return (
        <Box>
            <Notifications options={notification} />
            <form
                component="form"
                onSubmit={onSubmit}
                style={{
                    display: 'grid',
                    gridTemplateColumns: '200px 200px 200px 50px 50px',
                    gap: '16px',
                    justifyContent: 'center',
                }}
            >
                <TextField
                    select
                    label="Search By:"
                    name="searchBy"
                    value={formData.searchBy}
                    onChange={onInputChange}
                    variant="outlined"
                    sx={{ minWidth: 300 }}
                >
                    <MenuItem value="lotId">Lot Id</MenuItem>
                    <MenuItem value="externalSku">External Sku</MenuItem>
                    <MenuItem value="partnerSku">Partner Sku</MenuItem>
                    <MenuItem value="purchaseOrderId">Purchase Order Id</MenuItem>
                    <MenuItem value="locationName">Location Name</MenuItem>
                    <MenuItem value="palletId">Pallet Id</MenuItem>
                    <MenuItem value="partnerLotNumber">Partner Lot</MenuItem>
                    <MenuItem value="companySource">Company Source</MenuItem>
                </TextField>

                <TextField
                    name="searchTerm"
                    value={formData.searchTerm}
                    onChange={onInputChange}
                    variant="outlined"
                    sx={{ minWidth: 300 }}
                    {...(formData.searchBy === 'companySource'
                        ? {
                              select: true,
                              label: 'Company Source',
                              children: companySourceOptions.map((option) => (
                                  <MenuItem key={option} value={option}>
                                      {option}
                                  </MenuItem>
                              )),
                          }
                        : {
                              label: 'Search Term',
                          })}
                />

                {needsType() && (
                    <TextField
                        select
                        label="Type: "
                        name="type"
                        value={formData.type}
                        onChange={onInputChange}
                        variant="outlined"
                        sx={{ minWidth: 300 }}
                    >
                        <MenuItem value="ITEM">Item</MenuItem>
                        <MenuItem value="PACKAGING">Packaging</MenuItem>
                    </TextField>
                )}
                <Button type="submit" variant="text" color="primary">
                    Search
                </Button>
                {hasFormData && (
                    <Button onClick={handleClear} variant="text" color="secondary">
                        Clear
                    </Button>
                )}
            </form>
        </Box>
    );
};

export { LocationInventorySearchBar };
