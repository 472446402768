import React, { useState, useContext, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { productApi } from '../../../api/product';
import { UserContext } from '../../../contexts/UserContext';
import { FilterAndGroupByContainer } from './inventoryHistoryV2/filterAndGroupByContainer';
import { InventoryHistory } from '../../../components/warehouse';

//this will be removed when the component files are built out and can be imported
const InventoryHistoryTableComponent = () => {
    return (
        <div>
            {/* Placeholder for inventory history table */}
            <p>PLACEHOLDER Inventory History Table Component</p>
        </div>
    );
};

const InventoryHistoryV2 = () => {
    const user = useContext(UserContext);
    const [featureFlagEnabled, setFeatureFlagEnabled] = useState(false);

    const getFeatureFlag = async () => {
        // This can be removed when ALL FACILITIES are on Jalapop
        const jalapopFacilities = [9, 10, 11, 13];
        if (!jalapopFacilities.includes(user.getFacilityID())) {
            setFeatureFlagEnabled(false);
            return;
        }

        const response = await productApi.getFeatureFlags();
        const targetRow = response?.data?.rows?.find(row => row.feature === "INVENTORY_HISTORY_PAGE_V2");
        setFeatureFlagEnabled(targetRow ? targetRow.enabled : false);
    };

    useEffect(() => {
        getFeatureFlag();
    }, []);

    if (!featureFlagEnabled) {
        return <InventoryHistory />;
    }

    return (
        <Paper style={{ minHeight: '80vh', padding: '20px' }}>
            <h1 style={{ textAlign: 'left', marginBottom: '20px' }}>Inventory Event Log</h1>
            <FilterAndGroupByContainer facilityId={user.getFacilityID()} />
            <InventoryHistoryTableComponent />
        </Paper>
    );
};

export { InventoryHistoryV2 };
