import React, { useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';

let PalletCountForm = ({ formData, setFormField, item }) => {
    useEffect(() => {
        let palletCountsTmp = [];
        let i = 0;
        while (i < Number(formData.numPallets)) {
            palletCountsTmp.push(formData.palletCounts[i] || '');
            i++;
        }

        if (palletCountsTmp.length > 0 && palletCountsTmp.length != formData.palletCounts.length) {
            setFormField(palletCountsTmp);
        }
    }, [formData]);

    let getPalletName = (ind) => {
        let firstLetterInd = Math.floor(ind / 26);
        let firstLetter = String.fromCharCode('A'.charCodeAt() + firstLetterInd);
        let secondLetterInd = ind - 26 * firstLetterInd;
        let secondLetter = String.fromCharCode('A'.charCodeAt() + secondLetterInd);
        return `${firstLetter}${secondLetter}`;
    };

    return (
        <>
            <h4>Pallet Counts</h4>
            {formData.palletCounts.map((formField, formFieldIndex) => {
                return (
                    <Grid item xs={12} key={formFieldIndex}>
                        <TextField
                            type="number"
                            style={{ width: '100%', padding: 15 }}
                            label={`Pallet ${getPalletName(formFieldIndex)}`}
                            value={formData.palletCounts[formFieldIndex] || ''}
                            onChange={(event) => {
                                let palletFormDataTmp = JSON.parse(JSON.stringify(formData.palletCounts));
                                palletFormDataTmp[formFieldIndex] = event.target.value;
                                setFormField(palletFormDataTmp);
                            }}
                            onKeyPress={(event) => {
                                if ((event.charCode >= 48 && event.charCode <= 57) === false) {
                                    event.preventDefault();
                                    return false;
                                }
                            }}
                        />
                    </Grid>
                );
            })}
        </>
    );
};

export { PalletCountForm };
