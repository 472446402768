import { mmFetchWithResponse } from './mmFetchWithResponse';

class WarehouseInventoryV2Api {
    constructor() {
        this.baseUrl = 'api/warehouse/v2/inventory/';
    }

    /**
     *
     * @param {number} facilityId
     * @param {Array<{searchBy: string, searchTerm: string | number}>} searchValues If the searchBy value is externalSku a type value is required
     * @returns {Promise<{status: boolean, msg: string, data: any}>}
     */
    async getPallets(facilityId, searchValues = []) {
        let url = `${this.baseUrl}pallets?facilityId=${facilityId}`;
        for (const searchValue of searchValues) {
            if (searchValue.searchBy !== undefined && searchValue.searchTerm !== undefined) {
                url += `&${searchValue.searchBy}=${searchValue.searchTerm}`;
            }
        }
        return await mmFetchWithResponse(url, { method: 'GET' });
    }

    async getPalletsByLocationId(locationId, facilityId) {
        const url = `${this.baseUrl}pallets/location/${locationId}?facilityId=${facilityId}`;
        return await mmFetchWithResponse(url, { method: 'GET' });
    }

    /**
     *
     * @param {number} facilityId
     * @param {Array<{searchBy: string, searchTerm: string | number}>} searchValues If the searchBy value is externalSku a type value is required
     * @returns {Promise<{status: boolean, msg: string, data: any}>}
     */
    async getLots(facilityId, searchValues = []) {
        let url = `${this.baseUrl}lots?facilityId=${facilityId}`;
        for (const searchValue of searchValues) {
            if (searchValue.searchBy !== undefined && searchValue.searchTerm !== undefined) {
                url += `&${searchValue.searchBy}=${searchValue.searchTerm}`;
            }
        }
        return await mmFetchWithResponse(url, { method: 'GET' });
    }

    async getPalletsByLocationIdandLotId(locationId, lotId, facilityId) {
        const url = `${this.baseUrl}pallets/location/${locationId}/lot/${lotId}?facilityId=${facilityId}`;
        return await mmFetchWithResponse(url, { method: 'GET' });
    }

    /**
     *
     * @param {number} facilityId
     * @param {Array<{searchBy: string, searchTerm: string | number}>} searchValues If the searchBy value is externalSku a type value is required
     * @returns {Promise<{status: boolean, msg: string, data: any}>}
     */
    async getLocations(facilityId, searchValues = []) {
        let url = `${this.baseUrl}locations?facilityId=${facilityId}`;
        for (const searchValue of searchValues) {
            if (searchValue.searchBy !== undefined && searchValue.searchTerm !== undefined) {
                url += `&${searchValue.searchBy}=${searchValue.searchTerm}`;
            }
        }
        return await mmFetchWithResponse(url, { method: 'GET' });
    }

    /**
     *
     * @param {number} facilityId
     * @returns {Promise<{status: boolean, msg: string, data: any}>}
     */
    async getEmptyLocations(facilityId) {
        let url = `${this.baseUrl}empty-locations?facilityId=${facilityId}`;
        return mmFetchWithResponse(url, { method: 'GET' });
    }

    /**
     *
     * @param {number} facilityId
     * @param {Array<{searchBy: string, searchTerm: string | number}>} searchValues If the searchBy value is externalSku a type value is required
     * @returns {Promise<{status: boolean, msg: string, data: any}>}
     */
    async getSkus(facilityId, searchValues = []) {
        let url = `${this.baseUrl}skus?facilityId=${facilityId}`;
        for (const searchValue of searchValues) {
            if (searchValue.searchBy !== undefined && searchValue.searchTerm !== undefined) {
                url += `&${searchValue.searchBy}=${searchValue.searchTerm}`;
            }
        }
        return await mmFetchWithResponse(url, { method: 'GET' });
    }

    async getInventoryHistory(facilityId) {
        const url = `${this.baseUrl}summary-events?facilityId=${facilityId}`;
        return mmFetchWithResponse(url, { method: 'GET' });
    }

    async getActiveNegativeVariance(facilityId) {
        const url = `${this.baseUrl}unresolved-shorts?facilityId=${facilityId}`;
        return mmFetchWithResponse(url);
    }

    async getNegativeVarianceHistory(facilityId) {
        let url = `${this.baseUrl}unresolved-shorts/history?facilityId=${facilityId}`;
        return mmFetchWithResponse(url);
    }

    async getNegativeVarianceHistoryForSku(facilityId, sku, start, end) {
        let url = `${this.baseUrl}unresolved-shorts/history/${sku}?facilityId=${facilityId}&startTime=${start}&endTime=${end}`;
        return mmFetchWithResponse(url);
    }

    async getAdjustReasons(lotId) {
        let url = `${this.baseUrl}adjust/reasons?lotId=${lotId}`;
        return mmFetchWithResponse(url, { method: 'GET' });
    }

    async getInventoryOptions(facilityId) {
        let url = `${this.baseUrl}options?facilityId=${facilityId}`;
        return mmFetchWithResponse(url, { method: 'GET' });
    }
}

export { WarehouseInventoryV2Api };
