import React, { useContext, useEffect, useRef, useState } from 'react';
import { DataTable, Notifications } from '../../../../shared';
import { ActionsCell } from './ActionsCell';
import { ActionModal } from './ActionModal';
import './locationInventoryV2Styles.css';
import { Button, CircularProgress } from '@material-ui/core';
import { ChangeExpirationDateAction } from './actions/changeExpirationDateAction';
import { MoveCasesAndLooseUnitsFromKnownOriginAction } from './actions/moveCasesAndLooseUnitsAction';
import { BulkAdjustUnitsPerCaseAction } from './actions/bulkAdjustUnitsPerCaseAction';
import { AdjustCasesAndLooseUnitsAction } from './actions/adjustCasesAndLooseUnitsAction';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';
import { UserContext } from '../../../../contexts/UserContext';
import { PrintCaseLabelAction } from './actions/printCaseLabelAction';
import { ViewPalletsModal } from './ViewPalletsModal';

const warehouseInventoryV2Api = new WarehouseInventoryV2Api();

const LocationTabExpansion = ({ onLinkClicked, locationName, locationId, locationType, locInvType, onSuccess }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [rows, setRows] = useState([]);
    const [viewActions, setViewActions] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [actions, setActions] = useState([]);
    const [loadingLots, setLoadingLots] = useState(false);
    const user = useContext(UserContext);
    const isMounted = useRef(true);
    const [selectedLotId, setSelectedLotId] = useState([]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        getLotDataForLocation();
    }, [locationId]);

    const handleMovePalletSuccess = () => {
        console.log('handleMovePalletSuccess', locationId);
        getLotDataForLocation();
    };

    useEffect(() => {
        //filter actions based on location's type
        if (locationType !== 'PREP') {
            setActions(actionDefinitions.filter((action) => action.value !== 'prep'));
        } else {
            setActions(actionDefinitions);
        }
    }, [locationId, selectedRow]);

    const showPalletModal = async (row) => {
        setSelectedLotId(row.lotId);
    };

    const columns = [
        {
            Header: 'Lot Id',
            accessor: 'lotId',
            Cell: ({ row }) => {
                return (
                    <Button variant="text" color="primary" onClick={() => handleLotLinkClick(row.original.lotId)}>
                        {row.original.lotId}
                    </Button>
                );
            },
        },
        { Header: 'Partner Lot', accessor: 'partnerLotNumber' },
        { Header: 'Item Name', accessor: 'itemName' },
        {
            Header: 'External SKU',
            accessor: 'externalSku',
            Cell: ({ row }) => {
                return (
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => handleSkuLinkClick(row.original.externalSku, row.original.type)}
                    >
                        {row.original.externalSku}
                    </Button>
                );
            },
        },
        { Header: 'Partner Sku', accessor: 'partnerSku' },
        { Header: 'Expiration Date', accessor: 'expirationDate' },
        {
            Header: 'Pallets',
            accessor: 'pallets',
            Cell: ({ row }) => {
                const numPallets = row.original.pallets;

                return (
                    <Button
                        disabled={!numPallets}
                        onClick={() => {
                            showPalletModal(row.original);
                        }}
                    >
                        {!numPallets ? <span>0</span> : <strong>{numPallets}</strong>}
                    </Button>
                );
            },
        },
        { Header: 'Units per Case', accessor: 'unitsPerCase' },
        { Header: 'Cases', accessor: 'cases' },
        { Header: 'Loose Units', accessor: 'looseUnits' },
        { Header: 'Total Units', accessor: 'totalUnits' },
        { Header: 'PO', accessor: 'po' },
        { Header: 'Received Date', accessor: 'receivedDate' },
        {
            Header: 'Actions',
            Cell: ({ row }) => (
                <ActionsCell row={row} setSelectedRow={setSelectedRow} setViewActions={setViewActions} />
            ),
        },
    ];

    //API call to GET data
    const getLotDataForLocation = async () => {
        if (isMounted.current && !loadingLots) {
            setLoadingLots(true);
            const searchValues = [{ searchBy: 'locationId', searchTerm: locationId }];
            const result = await warehouseInventoryV2Api.getLots(user.getFacilityID(), searchValues);
            if (result.status) {
                setRows(
                    result.data.lots.map((lot) => {
                        return {
                            ...lot,
                            looseUnits: lot.numLooseUnits,
                            cases: lot.numCases,
                            pallets: lot.numPallets,
                            po: lot.purchaseOrderId,
                            active: lot.totalUnits > 0,
                        };
                    })
                );
            } else {
                setNotification({ text: result.msg, level: 'error' });
            }
            setLoadingLots(false);
        }
    };

    const actionDefinitions = [
        {
            text: 'Prep',
            value: 'prep',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Prep</span>;
            },
        },
        {
            text: 'Bulk Adjust Units per Case',
            value: 'bulkAdjustUnitsPerCase',
            componentJSX: function () {
                return (
                    <BulkAdjustUnitsPerCaseAction
                        lotId={selectedRow?.lotId}
                        unitsPerCase={selectedRow?.unitsPerCase}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
        {
            text: 'Adjust Cases and Loose Units',
            value: 'adjustCasesAndLooseUnits',
            componentJSX: function () {
                return (
                    <AdjustCasesAndLooseUnitsAction
                        locationId={locationId}
                        lotId={selectedRow?.lotId}
                        caseNum={selectedRow?.cases}
                        looseUnitNum={selectedRow?.looseUnits}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                        locInvType={locInvType}
                        palletNum={selectedRow?.pallets}
                        unitsPerCase={selectedRow?.unitsPerCase}
                    />
                );
            },
        },
        {
            text: 'Move Cases and Loose Units',
            value: 'moveCaseAndLooseUnits',
            componentJSX: function () {
                return (
                    <MoveCasesAndLooseUnitsFromKnownOriginAction
                        lotId={selectedRow?.lotId}
                        locationName={locationName}
                        locationId={locationId}
                        locInvType={locInvType}
                        onSuccess={handleSuccess}
                        numPallets={selectedRow?.pallets}
                    />
                );
            },
        },
        {
            text: 'Print Case Label',
            value: 'printCaseLabel',
            componentJSX: function () {
                return <PrintCaseLabelAction lotId={selectedRow?.lotId} onSuccess={handleSuccess} />;
            },
        },
        {
            text: 'Change Expiration Date',
            value: 'changeExpirationDate',
            componentJSX: function () {
                return (
                    <ChangeExpirationDateAction
                        lotId={selectedRow?.lotId}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
    ];

    const handleSkuLinkClick = (externalSku, type) => {
        onLinkClicked({ tabIndex: 2, searchBy: 'externalSku', searchTerm: externalSku, type: type });
    };

    const handleLotLinkClick = (lotId) => {
        onLinkClicked({ tabIndex: 0, searchBy: 'lotId', searchTerm: lotId });
    };

    const handleModalClose = () => {
        setViewActions(false);
        setSelectedRow({});
    };

    const handleViewPalletModalClose = () => {
        setSelectedLotId('');
    };

    const handleSuccess = () => {
        onSuccess();
    };

    return (
        <>
            <Notifications options={notification} />
            <div className="expanded-table-div">
                {loadingLots && <CircularProgress color="inherit" size={48} />}
                {!loadingLots && (
                    <DataTable
                        columns={columns}
                        data={rows}
                        editable={false}
                        tableContainerMaxHeight={600}
                        initialSortBy={[
                            {
                                id: 'expirationDate',
                                desc: false,
                            },
                        ]}
                    />
                )}
                <ActionModal
                    title={`Actions for Lot ${selectedRow?.lotId}`}
                    open={viewActions}
                    row={selectedRow}
                    actions={actions}
                    closeModal={handleModalClose}
                />
                <ViewPalletsModal
                    open={selectedLotId > 0}
                    closeModal={handleViewPalletModalClose}
                    lotId={selectedLotId}
                    locationName={locationName}
                    locationId={locationId}
                    setNotification={setNotification}
                    onMovePalletSuccess={handleMovePalletSuccess}
                />
            </div>
        </>
    );
};

export { LocationTabExpansion };
