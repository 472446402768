import React, { useState, useContext, useEffect } from 'react';
import { Paper } from '@material-ui/core';

let FBMApiDocs = () => {
    return (
        <Paper>
            <div className="flex items-center justify-center h-screen bg-gray-100">
                <div className="bg-white p-6 rounded-2xl shadow-xl max-w-sm text-center">
                    <h2 className="text-xl font-semibold mb-4">FBM Partner API Documentation</h2>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <a
                        href="/lettuce/swagger-ui/index.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 font-medium hover:underline"
                    >
                        Swagger API documentation ↗
                    </a>
                </div>
            </div>
        </Paper>
    );
};

export { FBMApiDocs };
