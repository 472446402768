import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab, Paper, Typography, Box } from '@material-ui/core';
import { ViewMicroGoPuffMFC } from '../components/viewGoPuffMFC';
import { CreateMicroGoPuffMFC } from '../components/createGoPuffMFC';
import { TabPanel, WholesaleAccountPicker } from '../../../shared';
import { warehouseApi } from '../../../api/warehouse';
import { UserContext } from '../../../contexts/UserContext';
import { ViewWholesaleMFC, CreateWholesaleMFC } from '../../wholesale/shared/index';

const GoPuffMicroFC = ({ baseUrl }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let [facilities, setFacilities] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };
    const { wholesaleAccount } = useContext(UserContext);

    let states = [
        { value: 'AL', text: 'Alabama' },
        { value: 'AK', text: 'Alaska' },
        { value: 'AZ', text: 'Arizona' },
        { value: 'AR', text: 'Arkansas' },
        { value: 'CA', text: 'California' },
        { value: 'CO', text: 'Colorado' },
        { value: 'CT', text: 'Connecticut' },
        { value: 'DE', text: 'Delaware' },
        { value: 'DC', text: 'District of Columbia' },
        { value: 'FL', text: 'Florida' },
        { value: 'GA', text: 'Georgia' },
        { value: 'HI', text: 'Hawaii' },
        { value: 'ID', text: 'Idaho' },
        { value: 'IL', text: 'Illinois' },
        { value: 'IN', text: 'Indiana' },
        { value: 'IA', text: 'Iowa' },
        { value: 'KS', text: 'Kansas' },
        { value: 'KY', text: 'Kentucky' },
        { value: 'LA', text: 'Louisiana' },
        { value: 'ME', text: 'Maine' },
        { value: 'MD', text: 'Maryland' },
        { value: 'MA', text: 'Massachusetts' },
        { value: 'MI', text: 'Michigan' },
        { value: 'MN', text: 'Minnesota' },
        { value: 'MS', text: 'Mississippi' },
        { value: 'MO', text: 'Missouri' },
        { value: 'MT', text: 'Montana' },
        { value: 'NE', text: 'Nebraska' },
        { value: 'NV', text: 'Nevada' },
        { value: 'NH', text: 'New Hampshire' },
        { value: 'NJ', text: 'New Jersey' },
        { value: 'NM', text: 'New Mexico' },
        { value: 'NY', text: 'New York' },
        { value: 'NC', text: 'North Carolina' },
        { value: 'ND', text: 'North Dakota' },
        { value: 'OH', text: 'Ohio' },
        { value: 'OK', text: 'Oklahoma' },
        { value: 'OR', text: 'Oregon' },
        { value: 'PA', text: 'Pennsylvania' },
        { value: 'RI', text: 'Rhode Island' },
        { value: 'SC', text: 'South Carolina' },
        { value: 'SD', text: 'South Dakota' },
        { value: 'TN', text: 'Tennessee' },
        { value: 'TX', text: 'Texas' },
        { value: 'UT', text: 'Utah' },
        { value: 'VT', text: 'Vermont' },
        { value: 'VA', text: 'Virginia' },
        { value: 'WA', text: 'Washington' },
        { value: 'WV', text: 'West Virginia' },
        { value: 'WI', text: 'Wisconsin' },
        { value: 'WY', text: 'Wyoming' },
    ];

    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities(true);
        if (!response.status) {
            setNotification({ text: 'Failed to get facilities', level: 'error' });
            return;
        }

        let facilitiesAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setFacilities(facilitiesAsOpts);
    };

    useEffect(() => {
        if (!!wholesaleAccount.id) {
            getFacilities();
        }
    }, [wholesaleAccount]);

    return (
        <Paper>
            <WholesaleAccountPicker />
            {!!wholesaleAccount.id && (
                <>
                    <Tabs
                        onChange={handleChange}
                        value={selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="View" value="0" />
                        <Tab label="Create" value="1" />
                    </Tabs>
                    <TabPanel selectedTab={selectedTab} index={'0'}>
                        <ViewWholesaleMFC
                            stateOpts={states}
                            mmFacilityOpts={facilities}
                            wholesaleAccount={wholesaleAccount}
                        />
                    </TabPanel>
                    <TabPanel selectedTab={selectedTab} index={'1'}>
                        <CreateWholesaleMFC
                            stateOpts={states}
                            mmFacilityOpts={facilities}
                            wholesaleAccount={wholesaleAccount}
                        />
                    </TabPanel>
                </>
            )}
        </Paper>
    );
};

export { GoPuffMicroFC };
