import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { DataTable, Notifications } from '../../../shared';
import { UserContext } from '../../../contexts/UserContext';
import { WarehouseInventoryV2Api } from '../../../api/warehouseInventoryV2';

const warehouseApiV2 = new WarehouseInventoryV2Api();

const InventoryHistoryJalapop = () => {
    const [rows, setRows] = useState([]);
    const [notification, setNotification] = useState({ text: '', level: '' });
    const { user } = useContext(UserContext);
    const getInventoryHistory = async (params) => {
        const res = await warehouseApiV2.getInventoryHistory(user.facilityID);
        if (!res.status) {
            setNotification({ text: 'Failed to get inventory events', level: 'error' });
            return;
        }

        setRows(res.data.events);
    };

    useEffect(() => {
        getInventoryHistory({});
    }, []);

    const columns = [
        { Header: 'Timestamp', accessor: 'timestamp' },
        { Header: 'Sku', accessor: 'externalSku' },
        { Header: 'Name', accessor: 'itemName' },
        { Header: 'Lot', accessor: 'lotID' },
        { Header: 'Event Type', accessor: 'eventType' },
        { Header: 'message', accessor: 'message' },
        { Header: 'User', accessor: 'authUser' },
    ];

    return (
        <Paper style={{ minHeight: '80vh' }}>
            <Notifications options={notification} />
            <h1>Inventory History</h1>
            <DataTable columns={columns} data={rows} editable={false} />
        </Paper>
    );
};

export { InventoryHistoryJalapop };
