import React, { useEffect } from 'react';
import { Button, Dialog, DialogContent } from '@material-ui/core';

const LocationAccuracyResultExclusionModal = ({ open, handleConfirm, handleClose }) => {
    useEffect(() => {
        if (!open) {
            handleClose();
        }
    }, [open]);

    return (
        <Dialog open={open} maxWidth="sm" fullWidth={true} onClose={handleClose}>
            <DialogContent>
                <h3>
                    Are you sure you want to exclude this cycle count from accuracy calculations? This action cannot be
                    undone.
                </h3>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleConfirm}>Confirm</Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export { LocationAccuracyResultExclusionModal };
