import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { Notifications } from '../../../../../shared';
import { UserContext } from '../../../../../contexts/UserContext';
import { warehouseApi } from '../../../../../api/warehouse';
import { WarehouseInventoryV2Api } from '../../../../../api/warehouseInventoryV2';
import { Button, Typography, TextField, Grid, Box } from '@material-ui/core';
import { SearchableSelect } from '../../../../../shared/components/SearchableSelect';

const warehouseApiV2 = new WarehouseInventoryV2Api();

const MoveCasesAndLooseUnitsToKnownDestinationAction = ({
    lotId,
    toLocationName,
    toLocationId,
    onSuccess,
    toPalletId = '',
}) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const { user } = useContext(UserContext);
    const [fromLocations, setFromLocations] = useState([]);
    const [fromPallets, setFromPallets] = useState([]);
    const [loadingFlags, setLoadingFlags] = useState({ fromPallet: false, toLocation: false, toPallet: false });
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showFromPalletField, setShowFromPalletField] = useState(false);
    const [selectedFromLocation, setSelectedFromLocation] = useState();
    const [formData, setFormData] = useState({
        numCases: 0,
        numUnits: 0,
        fromLocationId: '',
        toLocationId: toLocationId,
        fromPalletId: '',
        toPalletId: toPalletId,
    });

    const fromLocationOptions = useMemo(
        () => fromLocations.map((location) => ({ label: location.locationName, value: location })),
        [fromLocations]
    );

    const fromPalletOptions = useMemo(
        () => fromPallets.map((pallet) => ({ label: pallet.palletId.toString(), value: pallet.palletId })),
        [fromPallets]
    );

    useEffect(() => {
        queryFromLocations();
    }, []);

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error(
                'MoveCasesAndLooseUnitsToKnownDestinationAction intialized incorrectly onSuccess is not a function'
            );
        }
    }, [onSuccess]);

    useEffect(() => {
        // if selected from location is a pallet location, show the from pallet field
        if (selectedFromLocation && selectedFromLocation.inventoryType === 'Pallet') {
            setShowFromPalletField(true);
        } else {
            setShowFromPalletField(false);
        }
    }, [selectedFromLocation]);

    useEffect(() => {
        if (showFromPalletField) {
            queryFromPallets();
        }
    }, [showFromPalletField]);

    const queryFromLocations = async () => {
        setLoadingFlags((flags) => ({
            ...flags,
            fromLocation: true,
        }));

        const searchValues = [{ searchBy: 'lotId', searchTerm: lotId }];
        const result = await warehouseApiV2.getLocations(user.facilityID, searchValues);

        setLoadingFlags((flags) => ({
            ...flags,
            fromLocation: false,
        }));

        if (result.status) {
            const filteredLocations = result.data.locations.filter((item) => item.locationId !== toLocationId);
            setFromLocations(filteredLocations);
            if (!filteredLocations.length) {
                setShowError(true);
                setErrorMessage(`Lot ${lotId} is not found at any other location.`);
            } else {
                setShowError(false);
                setErrorMessage('');
            }
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const queryFromPallets = async () => {
        setLoadingFlags((flags) => ({
            ...flags,
            fromPallet: true,
        }));

        const result = await warehouseApiV2.getPalletsByLocationIdandLotId(
            selectedFromLocation.locationId,
            lotId,
            user.facilityID
        );
        if (result?.status) {
            setFromPallets(result.data.pallets ?? []);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }

        setLoadingFlags((flags) => ({
            ...flags,
            fromPallet: false,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formData.numCases === '' || formData.numUnits === '') {
            setShowError(true);
            setErrorMessage('Number of Cases and Number of Loose Units fields cannot be blank.');
            return;
        }
        if (formData.numCases === 0 && formData.numUnits === 0) {
            setShowError(true);
            setErrorMessage('Number of Cases and Number of Loose Units cannot both be 0.');
            return;
        }
        if (!formData.fromLocationId) {
            setShowError(true);
            setErrorMessage('You must select an origin location.');
            return;
        }
        if (showFromPalletField && !formData.fromPalletId) {
            setShowError(true);
            setErrorMessage('The selected location only stores pallets. Please select a pallet to move from.');
            return;
        }
        setShowError(false);
        setErrorMessage('');

        const result = await warehouseApi.moveCasesAndLooseUnits({
            originLocationID: selectedFromLocation.locationId,
            originPalletID: formData.fromPalletId,
            destinationLocationID: toLocationId,
            destinationPalletID: toPalletId,
            lotID: lotId,
            numCases: formData.numCases,
            numUnits: formData.numUnits,
        });

        if (result?.status) {
            onSuccess();
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const handleFormDataChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }, []);

    const handleFromLocationChange = useCallback((newValue) => {
        setSelectedFromLocation(newValue?.value);
        setFormData((prevData) => ({
            ...prevData,
            fromLocationId: newValue?.value.locationId,
        }));
    }, []);

    const handleFromPalletChange = useCallback((newValue) => {
        setFormData((prevData) => ({
            ...prevData,
            fromPalletId: newValue?.value,
        }));
    }, []);

    return (
        <>
            <Notifications options={notification} />
            <h3>
                Move Cases and Loose Units of Lot {lotId} to {toLocationName}
                {toPalletId ? `, pallet ${toPalletId}` : ''}
            </h3>
            <Box>
                {showError && <Typography style={{ color: 'red', marginBottom: 10 }}>{errorMessage}</Typography>}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <SearchableSelect
                                onChange={handleFromLocationChange}
                                options={fromLocationOptions}
                                loading={loadingFlags.fromLocation}
                                label="Move From Location"
                            />
                            {showFromPalletField && (
                                <>
                                    <SearchableSelect
                                        onChange={handleFromPalletChange}
                                        options={fromPalletOptions}
                                        loading={loadingFlags.fromPallet}
                                        label="Move From Pallet"
                                    />
                                </>
                            )}
                            <TextField
                                label="Number of cases to move"
                                name="numCases"
                                type="number"
                                value={formData.numCases}
                                onChange={handleFormDataChange}
                                variant="outlined"
                                fullWidth
                                margin="dense" // Reduced spacing
                                inputProps={{ min: 0, step: '1' }}
                                error={formData.numCases === ''}
                                helperText={formData.numCases === '' ? 'This field cannot be blank' : ''}
                            />
                            <TextField
                                label="Number of loose units to move"
                                name="numUnits"
                                type="number"
                                value={formData.numUnits}
                                onChange={handleFormDataChange}
                                variant="outlined"
                                fullWidth
                                margin="dense" // Reduced spacing
                                inputProps={{ min: 0, step: '1' }}
                                error={formData.numUnits === ''}
                                helperText={formData.numUnits === '' ? 'This field cannot be blank' : ''}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <Button type="submit" variant="outlined">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    );
};

export { MoveCasesAndLooseUnitsToKnownDestinationAction };
