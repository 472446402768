import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { DataTable, Notifications } from '../../../../shared';
import { ActionsCell } from './ActionsCell';
import { LocationTabExpansion } from './locationTabExpansion';
import { ActionModal } from './ActionModal';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';
import { CircularProgress } from '@material-ui/core';
import { EntireLocationEmptyAction } from './actions/entireLocationEmptyAction';
import { EmptyPalletCreator } from './emptyPalletCreator';

const warehouseInventoryV2 = new WarehouseInventoryV2Api();

const LocationTab = ({ onLinkClicked, queryParams }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [rows, setRows] = useState([]);
    const [locationsLoading, setLocationsLoading] = useState(false);
    const [viewActions, setViewActions] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const { user } = useContext(UserContext);

    //search bar queryParams effect
    useEffect(() => {
        handleSearch();
    }, [queryParams]);

    const handleSearch = async () => {
        //TODO handle the filtering of the row data to match queryPrams
        //TODO read and verify query params to pass to api call
        getLocations();
    };

    //Column Definitions
    const columns = [
        { Header: 'Location', accessor: 'locationName' },
        { Header: 'Active', accessor: 'active', type: 'checkbox' },
        { Header: 'Unique SKUs', accessor: 'uniqueSkus' },
        { Header: 'Lots', accessor: 'lots' },
        { Header: 'Pallets', accessor: 'pallets' },
        { Header: 'Cases', accessor: 'cases' },
        { Header: 'Loose Units', accessor: 'looseUnits' },
        { Header: 'Total Units', accessor: 'totalUnits' },
        {
            accessor: 'actions',
            Header: 'Actions',
            Cell: ({ row }) => (
                <ActionsCell row={row} setSelectedRow={setSelectedRow} setViewActions={setViewActions} />
            ),
        },
    ];

    //API call to GET data
    const getLocations = async () => {
        setLocationsLoading(true);
        const searchValues = [{ searchBy: queryParams.searchBy, searchTerm: queryParams.searchTerm }];

        if (queryParams.type) {
            searchValues.push({ searchBy: 'type', searchTerm: queryParams.type });
        }

        // Include empty locations if no filters
        const includeEmptyLocations = !searchValues.filter((item) => item.searchBy !== undefined).length;

        let locationResult = [];
        if (includeEmptyLocations) {
            let promises = [
                warehouseInventoryV2.getLocations(user.facilityID, searchValues),
                warehouseInventoryV2.getEmptyLocations(user.facilityID),
            ];
            const result = await Promise.all(promises);
            if (result.every((item) => item.status)) {
                locationResult = [...result[0].data.locations, ...result[1].data.locations];
            } else {
                setNotification({ text: 'Failed to get locations', level: 'error' });
                setLocationsLoading(false);
                return;
            }
        } else {
            const result = await warehouseInventoryV2.getLocations(user.facilityID, searchValues);
            if (!result.status) {
                setNotification({ text: result.msg, level: 'error' });
                setLocationsLoading(false);
                return;
            }
            locationResult = result.data.locations;
        }

        setRows(
            locationResult.map((location) => {
                return {
                    ...location,
                    locationName: location.locationName,
                    active: location.numLots > 0,
                    uniqueSkus: location.numSku,
                    lots: location.numLots,
                    pallets: location.numPallets,
                    cases: location.numCases,
                    looseUnits: location.numLooseUnits,
                    totalUnits: location.numTotalUnits,
                    locationId: location.locationId,
                    locInvType: location.inventoryType,
                };
            })
        );

        setLocationsLoading(false);
    };

    const handleOnRowClicked = (row) => {
        setSelectedRow(row.original);
    };

    const handleModalClose = () => {
        setViewActions(false);
    };

    const handleSuccess = (message = 'Success') => {
        setNotification({ text: message, level: 'success' });
        handleModalClose();
        getLocations();
    };

    //Action Definitions
    const actions = [
        {
            text: 'Entire Location Empty',
            value: 'entireLocationEmpty',
            componentJSX: function () {
                return (
                    <EntireLocationEmptyAction
                        locationId={selectedRow?.locationId}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
        {
            text: 'Create Empty Pallet',
            value: 'createEmptyPallet',
            componentJSX: function () {
                return <EmptyPalletCreator locationId={selectedRow?.locationId} />;
            },
        },
    ];

    return (
        <>
            <Notifications options={notification} />
            {locationsLoading && <CircularProgress color="inherit" size={48} />}
            {!locationsLoading && (
                <DataTable
                    csvExport={true}
                    csvExportFileName="location_inventory"
                    columns={columns}
                    data={rows}
                    editable={false}
                    tableContainerMaxHeight={800}
                    stickyHeader={true}
                    expandable={true}
                    ExpansionComponent={
                        <LocationTabExpansion
                            locationName={selectedRow?.locationName}
                            locationId={selectedRow?.locationId}
                            locationType={selectedRow?.type}
                            locInvType={selectedRow?.locInvType}
                            onLinkClicked={onLinkClicked}
                            onSuccess={handleSuccess}
                        />
                    }
                    onRowClicked={handleOnRowClicked}
                />
            )}
            <ActionModal
                title={`Actions for Location ${selectedRow.locationName}`}
                open={viewActions}
                row={selectedRow}
                actions={actions}
                closeModal={handleModalClose}
            />
        </>
    );
};

export { LocationTab };
