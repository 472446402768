import React from 'react';
import ReactDOM from 'react-dom/client';
import { initialize as initializeSentry } from './utils/sentryUtils';
import './index.css';
import { App } from './components';

const baseUrl = import.meta.env.DEV ? 'https://timothyorourke-dev.misfitsmarket.com' : '';

const { host } = window.location;
const { REACT_APP_VERSION: reactAppVersion } = import.meta.env;
const version = reactAppVersion || 'development';
const jalapenoSite = host.match(/^(.+)\.misfitsmarket\.com$/);

let env = 'development';

if (jalapenoSite) {
    const [, jalapenoSiteName] = jalapenoSite;
    if (jalapenoSiteName === 'jalapeno') {
        env = 'production';
    } else {
        env = jalapenoSiteName;
    }
}

initializeSentry(env, version);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<App />);
