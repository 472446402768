import React, { useState, useCallback, useContext, useEffect } from 'react';
import ExpandableDataGrid from '../../../../shared/components/expandableDataGrid/expandableDataGrid';
import { Button, Grid, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FBMOrdersV2Api } from '../../../../api/fbmOrdersV2';
import { UserContext } from '../../../../contexts/UserContext';
import { TextField } from '@mui/material';
import { SearchOutlined } from '@material-ui/icons';
import { useGetTableData } from '../../hooks/useGetTableData';
import { CancelFBMShipmentModal } from './CancelFBMShipmentModal';
import { Notifications } from '../../../../shared';
import { ChangeFBMShipmentCarrierServiceModal } from './ChangeFBMShipmentCarrierServiceModal';
import { ResetFBMShipmentStatusModal } from './ResetFBMShipmentStatusModal';

const ViewAllOrdersV2 = () => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [anchorEl, setAnchorEl] = useState(undefined);
    const [menuRowId, setMenuRowId] = useState(undefined);
    const [selectedRow, setSelectedRow] = useState(undefined);
    const [shipmentModal, setShipmentModal] = useState({
        cancelModalIsOpen: false,
        changeCarrierServiceModalIsOpen: false,
        resetStatusModalIsOpen: false,
        shipment: undefined,
    });
    const { fbmAccount, user } = useContext(UserContext);

    const fbmOrdersV2Api = new FBMOrdersV2Api();

    const fetchOrders = useCallback(() => {
        return fbmOrdersV2Api.getOrders(fbmAccount.fbmAccountName, user.facilityID);
    }, [fbmAccount, user.facilityID]);

    const {
        data: orderData,
        isLoading: ordersLoading,
        error: orderError,
        refetch: refetchOrders,
    } = useGetTableData(fetchOrders);

    const fetchShipments = useCallback(() => {
        return fbmOrdersV2Api.getShipments(selectedRow?.fbmOrderNum, fbmAccount.fbmAccountName);
    }, [selectedRow?.fbmOrderNum, fbmAccount.fbmAccountName]);

    const { data: shipmentsData, isLoading: shipmentsLoading, error: shipmentsError } = useGetTableData(fetchShipments);

    const [selectedRowsIds, setSelectedRowsIds] = useState([]);

    // Handle menu opening
    const handleMenuOpen = (event, rowId) => {
        // Stop propagation to prevent row expansion
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setMenuRowId(rowId);
    };

    const handleMenuClose = (event) => {
        if (event) {
            event.stopPropagation();
        }
        setAnchorEl(null);
        setMenuRowId(null);
    };

    const handleActionClick = (actionNumber, event) => {
        event.stopPropagation();
        handleMenuClose();
    };

    const columns = [
        { field: 'fbmOrderNum', headerName: 'FBM Order Number', width: 150 },
        { field: 'orderId', headerName: 'Order Id', width: 150 },
        { field: 'shipmentStatus', headerName: 'Status', width: 150 },
        { field: 'service', headerName: 'Service', width: 150 },
        { field: 'boxes', headerName: 'Box Count', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            aria-controls={`row-menu-${params.id}`}
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e, params.id)}
                            size="small"
                            startIcon={<MoreVertIcon />}
                        >
                            Actions
                        </Button>

                        <Menu
                            id={`row-menu-${params.id}`}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl) && menuRowId === params.id}
                            onClose={handleMenuClose}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <MenuItem onClick={(e) => handleActionClick(1, e)}>Action 1</MenuItem>
                            <MenuItem onClick={(e) => handleActionClick(2, e)}>Action 2</MenuItem>
                            <MenuItem onClick={(e) => handleActionClick(3, e)}>Action 3</MenuItem>
                        </Menu>
                    </>
                );
            },
        },
    ];

    const childColumns = [
        { field: 'shipmentId', headerName: 'Shipment ID', width: 150 },
        { field: 'status', headerName: 'Status', width: 150 },
        { field: 'carrier', headerName: 'carrier', width: 150 },
        { field: 'hub', headerName: 'hub', width: 150 },
        { field: 'trackingUrl', headerName: 'trackingUrl', width: 400 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            aria-controls={`row-menu-${params.id}`}
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e, params.id)}
                            size="small"
                            startIcon={<MoreVertIcon />}
                        >
                            Actions
                        </Button>

                        <Menu
                            id={`row-menu-${params.id}`}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl) && menuRowId === params.id}
                            onClose={handleMenuClose}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <MenuItem onClick={(e) => handleOpenCancelShipmentModal(params.row)}>
                                Cancel Shipment
                            </MenuItem>
                            <MenuItem onClick={(e) => handleOpenChangeCarrierServiceModal(params.row)}>
                                Change Carrier Service
                            </MenuItem>
                            <MenuItem onClick={(e) => handleOpenResetShipmentLabelModal(params.row)}>
                                Reset Label
                            </MenuItem>
                        </Menu>
                    </>
                );
            },
        },
    ];

    const bulkActions = [
        {
            componentJSX: function () {
                return (
                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{ marginRight: 10 }}
                        onClick={handleCancelSelected}
                    >
                        Cancel Selected
                    </Button>
                );
            },
        },
        {
            componentJSX: function () {
                return (
                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{ marginRight: 10 }}
                        onClick={handleUpgradeSelected}
                    >
                        Upgrade Selected
                    </Button>
                );
            },
        },
    ];

    const handleCancelSelected = useCallback(() => {
        //TODO: Implement cancel selected
    }, [selectedRowsIds]);

    const handleUpgradeSelected = useCallback(() => {
        //TODO: Implement upgrade selected
    }, [selectedRowsIds]);

    // Used for the rows that are selected via checkboxes
    const handleSelectedRowsChange = (selectedRows) => {
        setSelectedRowsIds(selectedRows);
    };

    const handleSearch = () => {
        //TODO: Implement search
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleOpenCancelShipmentModal = (row) => {
        setShipmentModal((prevState) => ({
            ...prevState,
            cancelModalIsOpen: true,
            shipment: row,
        }));
        handleMenuClose();
    };

    const handleCancelShipmentModalClose = () => {
        setShipmentModal((prevState) => ({
            ...prevState,
            cancelModalIsOpen: false,
            shipment: undefined,
        }));
    };

    const handleCancelShipmentModalSuccess = (msg) => {
        handleCancelShipmentModalClose();
        setNotification({ text: msg, level: 'success' });
        refetchOrders();
        setSelectedRow(undefined);
    };

    const handleCancelShipmentModalError = (msg) => {
        setNotification({ text: msg, level: 'error' });
    };

    const handleChangeCarrierServiceModalClose = () => {
        setShipmentModal((prevState) => ({
            ...prevState,
            changeCarrierServiceModalIsOpen: false,
            shipment: undefined,
        }));
    };
    const handleChangeCarrierServiceModalSuccess = (msg) => {
        handleChangeCarrierServiceModalClose();
        setNotification({ text: msg, level: 'success' });
        refetchOrders();
        setSelectedRow(undefined);
    };

    const handleChangeCarrierServiceModalError = (msg) => {
        setNotification({ text: msg, level: 'error' });
    };

    const handleOpenChangeCarrierServiceModal = (row) => {
        setShipmentModal((prevState) => ({
            ...prevState,
            changeCarrierServiceModalIsOpen: true,
            shipment: row,
        }));
        handleMenuClose();
    };

    const handleOpenResetShipmentLabelModal = (row) => {
        setShipmentModal((prevState) => ({
            ...prevState,
            resetStatusModalIsOpen: true,
            shipment: row,
        }));
        handleMenuClose();
    };

    const handleResetStatusModalClose = () => {
        setShipmentModal((prevState) => ({
            ...prevState,
            resetStatusModalIsOpen: false,
            shipment: undefined,
        }));
    };

    const handleResetStatusModalSuccess = (msg) => {
        handleResetStatusModalClose();
        setNotification({ text: msg, level: 'success' });
        refetchOrders();
        setSelectedRow(undefined);
    };

    const handleResetStatusModalError = (msg) => {
        setNotification({ text: msg, level: 'error' });
    };

    return (
        <>
            <Notifications options={notification} />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        id="filled-search"
                        label="Search"
                        type="search"
                        variant="outlined"
                        fullWidth
                        sx={{ '& .MuiInputBase-input': { padding: '8px 14px' } }}
                        onKeyPress={handleKeyPress}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button variant="outlined" startIcon={<SearchOutlined />} onClick={handleSearch}>
                        Search
                    </Button>
                </Grid>
                <Grid item xs={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {bulkActions.map((item, index) => (
                        <React.Fragment key={index}>{item.componentJSX()}</React.Fragment>
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <ExpandableDataGrid
                        rows={orderData}
                        columns={columns}
                        childRows={shipmentsData}
                        childColumns={childColumns}
                        isLoading={ordersLoading}
                        isChildrenLoading={shipmentsLoading}
                        childGridTitle="Shipments"
                        onSelectedRowsChange={handleSelectedRowsChange}
                        selectedRow={selectedRow}
                        onSelectedRowChange={setSelectedRow}
                    />
                </Grid>
            </Grid>
            <CancelFBMShipmentModal
                isOpen={shipmentModal.cancelModalIsOpen}
                onClose={handleCancelShipmentModalClose}
                onSuccess={handleCancelShipmentModalSuccess}
                onError={handleCancelShipmentModalError}
                shipmentData={shipmentModal.shipment}
            />
            <ChangeFBMShipmentCarrierServiceModal
                isOpen={shipmentModal.changeCarrierServiceModalIsOpen}
                onClose={handleChangeCarrierServiceModalClose}
                onSuccess={handleChangeCarrierServiceModalSuccess}
                onError={handleChangeCarrierServiceModalError}
                shipmentData={shipmentModal.shipment}
            />
            <ResetFBMShipmentStatusModal
                isOpen={shipmentModal.resetStatusModalIsOpen}
                onClose={handleResetStatusModalClose}
                onSuccess={handleResetStatusModalSuccess}
                onError={handleResetStatusModalError}
                shipmentData={shipmentModal.shipment}
            />
        </>
    );
};

export { ViewAllOrdersV2 };
