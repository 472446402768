import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { PrintReceivedPalletAndCaseLabels } from './printReceivedPalletAndCaseTags';

const PrintReceivedPalletAndCaseLabelsModal = ({ poMapId, type, onClose, open }) => {
    return (
        <div>
            <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
                <DialogTitle style={{ textAlign: 'center', paddingBottom: 0 }}>
                    Print Pallet And Case Labels
                </DialogTitle>
                <DialogContent style={{ margin: 0 }}>
                    <PrintReceivedPalletAndCaseLabels type={type} poMapId={poMapId} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export { PrintReceivedPalletAndCaseLabelsModal };
