import React, { useState, useContext } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { UploadCSVAndReviewComponent } from '../../../../shared/index';
import { partnershipsApi } from '../../../../api/partnerships';
import { UserContext } from '../../../../contexts/UserContext';

const UploadSubscriptions = ({}) => {
    const [open, setOpen] = useState(false);
    const { famAccount, roles } = useContext(UserContext);
    const [successMessage, setSuccessMessage] = useState('');

    const canCreateSubscriptions = roles.map((role) => role.name).includes('CREATE_FAM_SUBSCRIPTION');

    const handleUploadSubsConfirm = async (key) => {
        const res = await partnershipsApi.confirmSubscriptionsUpload(key, famAccount.partnershipSource);
        return res;
    };

    const handleUploadSubs = async (formData) => {
        formData.source = famAccount.partnershipSource;
        const res = await partnershipsApi.uploadSubscriptions(formData);
        return res;
    };

    const downloadTemplate = () => {
        let csvContent =
            'data:text/csv;charset=utf-8,First Name,Last Name,Line One,Line Two,' +
            'City,State,Zip,Email,Phone,Delivery Instructions,Delivery Day (Optional),' +
            'Frequency,Member ID,Number of Orders,List 1,Num Boxes 1,List 2,Num Boxes 2,' +
            'List 3,Num Boxes 3,List 4,Num Boxes 4,Allergens (allergen 1; allergen2),' +
            'Dislikes (dislike 1; dislike 2),Admin Emails (email 1; email 2),Order IDs (id1; id2)';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'subscription_upload_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    if (!canCreateSubscriptions) {
        return null;
    }

    return (
        <>
            <button
                onClick={() => {
                    setOpen(true);
                }}
                className="fam-button fam-button-yellow grotesk-bold"
            >
                Upload Subscriptions
            </button>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogContent>
                    {!!successMessage && <Alert severity="success">{successMessage}</Alert>}
                    <UploadCSVAndReviewComponent
                        fileLabel="Subscription CSV"
                        title="Upload Subscriptions"
                        upload={handleUploadSubs}
                        confirm={handleUploadSubsConfirm}
                        downloadTemplate={downloadTemplate}
                        columns={[
                            { Header: 'Row', accessor: 'rowNumber' },
                            { Header: 'First Name', accessor: 'firstName' },
                            { Header: 'Last Name', accessor: 'lastName' },
                            { Header: 'Address Line One', accessor: 'address1' },
                            { Header: 'Address Line Two', accessor: 'address2' },
                            { Header: 'City', accessor: 'city' },
                            { Header: 'State', accessor: 'province' },
                            { Header: 'Zip', accessor: 'zipCode' },
                            { Header: 'Email', accessor: 'email' },
                            { Header: 'Phone', accessor: 'phoneNumber' },
                            { Header: 'Delivery Instructions', accessor: 'deliveryInstructions' },
                            { Header: 'Day', accessor: 'deliveryDay' },
                            { Header: 'Frequency', accessor: 'frequency' },
                            { Header: 'Member ID', accessor: 'memberID' },
                            { Header: 'Number of Orders', accessor: 'numOrders' },
                            { Header: 'List 1', accessor: 'listName1' },
                            { Header: 'Num Boxes 1', accessor: 'numBoxes1' },
                            { Header: 'List 2', accessor: 'listName2' },
                            { Header: 'Num Boxes 2', accessor: 'numBoxes2' },
                            { Header: 'List 3', accessor: 'listName3' },
                            { Header: 'Num Boxes 3', accessor: 'numBoxes3' },
                            { Header: 'List 4', accessor: 'listName4' },
                            { Header: 'Num Boxes 4', accessor: 'numBoxes4' },
                            { Header: 'Allergens', accessor: 'allergensCsv' },
                            { Header: 'Dislikes', accessor: 'dislikesCsv' },
                            { Header: 'Admin Emails', accessor: 'adminEmailsCsv' },
                            { Header: 'Order IDs', accessor: 'partnerOrderIDs' },
                            {
                                Header: 'Errors',
                                accessor: 'errors',
                                width: '150px',
                                Cell: ({ row }) => {
                                    return (
                                        <div>
                                            <ul>
                                                {row.original?.errorsArr?.map((err) => (
                                                    <li>{err}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    );
                                },
                            },
                        ]}
                        onSuccess={() => {
                            setSuccessMessage(
                                'Items uploaded! You will receive results by email when all subscriptions have been processed'
                            );
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export { UploadSubscriptions };
