import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import { customerApi as api } from '../../../../api/customer';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { camelToTitleCase } from '../../../../utils/globalUtils';
import { cartExperienceDollarAmountFields } from '../../data/data';
import { formatDateString } from '../../../../utils/dateUtils/dateUtils';
import { PaymentDetailTooltip } from '../PaymentDetailTooltip';
const OrderDetailsModal = ({
    open,
    setOpen,
    orderNumber,
    setSelectedShipmentID,
    setCancelShipmentModalOpen,
    setSelectedChargeID,
    setNotification,
}) => {
    const useStyles = makeStyles(() => ({
        heading2: {
            margin: '15px 0 5px',
            fontSize: '20px',
        },
        heading3: {
            margin: '15px 0 5px',
            fontSize: '18px',
        },
        subheading: {
            margin: '5px 0 0',
            fontSize: '16px',
        },
        caption: {
            paddingLeft: '0!important',
        },
        orderItemRow: {
            '&:hover': {
                backgroundColor: '#c4ffff',
            },
        },
    }));
    const classes = useStyles();

    const [order, setOrder] = useState(null);

    const {
        addons,
        cartExperience,
        chargeID,
        coldKeeperFee,
        coldKeeperFeeTaxAmount,
        credits,
        creditAmount,
        discountAmount,
        discountCode,
        operationsFee,
        operationsFeeTaxAmount,
        shipping,
        shippingTaxAmount,
        subtotal,
        taxes,
        tipAmount,
        total,
        initialPersonalizationSeedingAmount,
        initialRecurringItemsSeedingAmount,
    } = order?.charge ?? {};

    const getOrderDetails = async () => {
        let res = await api.getOrderDetails(orderNumber);

        if (!res.status) {
            setNotification({ text: 'Error getting order details: ' + res.msg, level: 'error' });
            return;
        }

        // Sort items
        let miniSort = {};
        for (let i = 0; i < res.data.orderInfo.shipments.length; i++) {
            miniSort[res.data.orderInfo.shipments[i].shipmentNumber] = {};
        }

        setOrder(res.data.orderInfo);
    };

    useEffect(() => {
        if (open) {
            getOrderDetails();
        }
    }, [open]);

    if (!order) {
        return null;
    }

    const getColumnVisibility = (shipments) => {
        const result = {
            showDiscountColumn: false,
            showTaxColumn: false,
        };

        for (const shipment of shipments) {
            for (const item of shipment.items) {
                if (item.discountAmount > 0) {
                    result.showDiscountColumn = true;
                }
                if (item.taxAmount > 0) {
                    result.showTaxColumn = true;
                }
            }
        }
        return result;
    };

    // Show conditions so we don't crowd up table with empty cells if we don't need to
    const { showDiscountColumn, showTaxColumn } = getColumnVisibility(order.shipments);

    const isPlusMember = order.plusMembershipVariantID > 0;

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>Order Information</DialogTitle>
            <DialogContent>
                <div>
                    <strong>Order Number: </strong>{' '}
                    <Link to={`/system/order-deep-dive?orderNumber=${order.shopOrderNum}`}>{order.shopOrderNum}</Link>
                </div>
                <div>
                    <strong>Ordered At: </strong> {formatDateString(order?.createdOn, false, true)}
                </div>
                <div>
                    <strong>Num Boxes: </strong> {order.shipmentCount}
                </div>
                <div>
                    <strong>Delivery Day: </strong> {order.deliveryDay}
                </div>
                <div>
                    <strong>Shipping Addr: </strong> {order.shipAddr}
                </div>
                <div>
                    <strong>Box Type: </strong>{' '}
                    {order.productID === 6 ? 'Misfits Box' : order.productID === 5 ? 'Madness' : 'Mischief'}
                </div>
                <div>
                    <strong>Facility: </strong> {order.facility !== '' ? order.facility : 'UNKNOWN'}
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <h3 className={classes.heading3}>Cart Experience</h3>
                        {Object.entries(cartExperience ?? {}).map(([field, value]) => {
                            const displayLabel = camelToTitleCase(field);
                            let displayValue = value;

                            if (cartExperienceDollarAmountFields.includes(field)) {
                                displayValue = `$${value}`;
                            } else if (typeof value === 'boolean') {
                                displayValue = value ? 'True' : 'False';
                            }

                            return (
                                <div>
                                    <strong>{displayLabel}:</strong> {displayValue}
                                </div>
                            );
                        })}
                        <div>
                            <strong>Paid Membership:</strong> {isPlusMember ? 'Yes' : 'No'}
                        </div>
                        <div>
                            <strong>Initial Personalization Seeded Amount: </strong> $
                            {initialPersonalizationSeedingAmount?.toFixed(2) || 0}
                        </div>
                        <div>
                            <strong>Initial Recurring Seeded Amount: </strong> $
                            {initialRecurringItemsSeedingAmount?.toFixed(2) || 0}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h3 className={classes.heading3}>Charge</h3>
                        <div>
                            <strong>Subtotal: </strong> ${subtotal.toFixed(2)}
                        </div>
                        {discountAmount > 0 && (
                            <div>
                                <strong>Discount: </strong> -${discountAmount.toFixed(2)}
                            </div>
                        )}
                        {discountCode && (
                            <div>
                                <strong>Discount Code: </strong> ({discountCode})
                            </div>
                        )}
                        {addons && (
                            <div title="For Legacy Boxes">
                                <strong>Add ons: </strong> ${addons}
                            </div>
                        )}
                        <div>
                            <strong>Shipping: </strong> ${shipping.toFixed(2)}
                        </div>
                        <div>
                            <strong>Shipping Tax Amount: </strong> ${shippingTaxAmount.toFixed(2)}
                        </div>
                        <div>
                            <strong>Cold Keeper Fee: </strong> ${coldKeeperFee.toFixed(2)}
                        </div>
                        <div>
                            <strong>Cold Keeper Fee Tax Amount: </strong> ${coldKeeperFeeTaxAmount.toFixed(2)}
                        </div>
                        <div>
                            <strong>Operations Fee: </strong> ${operationsFee.toFixed(2)}
                        </div>
                        <div>
                            <strong>Operations Fee Tax Amount: </strong> ${(operationsFeeTaxAmount || 0).toFixed(2)}
                        </div>
                        <div title="Total taxes on this order including fee taxes">
                            <strong>Total Taxes: </strong> ${taxes.toFixed(2)}
                        </div>
                        <div>
                            <strong>Tip: </strong> ${tipAmount.toFixed(2)}
                        </div>
                        <div>
                            <strong>Credit: </strong> -$
                            {creditAmount ? creditAmount.toFixed(2) : credits.toFixed(2)}
                        </div>
                        <div>
                            <strong>Total: </strong> ${total.toFixed(2)}
                        </div>
                    </Grid>
                </Grid>

                <h2 className={classes.heading2}>Shipments</h2>
                {order.shipments.map((ship) => {
                    return (
                        <div>
                            <h4 className={classes.subheading}>Shipment {ship.shipmentNumber}</h4>
                            <div>
                                <strong>Delivery Date: </strong> {formatDateString(ship?.deliverDate, false, true)}
                            </div>
                            <div>
                                <strong>Shipping Status:</strong> {ship.shippingStatus}
                            </div>
                            <div>
                                <strong>Carrier:</strong> {ship.carrier ?? '-'}
                            </div>
                            <div>
                                <strong>OTD Status Box: </strong> {ship.otdStatus}
                            </div>
                            <div>
                                <strong>Tracking Info Box: </strong> {ship.trackingNum}
                            </div>
                            <div>
                                <strong>Tracking URL Box: </strong>{' '}
                                <a target="_blank" rel="noopener noreferrer" href={ship.trackingUrl}>
                                    {ship.trackingUrl}
                                </a>
                            </div>
                            {ship.hasOwnProperty('bringgTaskUrl') && ship.bringgTaskUrl !== '' && (
                                <div>
                                    <strong>Bringg Task Url: </strong>{' '}
                                    <a target="_blank" rel="noopener noreferrer" href={ship.bringgTaskUrl}>
                                        {ship.bringgTaskUrl}
                                    </a>
                                </div>
                            )}
                            <TableContainer>
                                <Table>
                                    <caption className={classes.caption}>
                                        Items In Box {ship.shipmentNumber}:
                                        {ship.adjustedShipmentTotal > 0 && (
                                            <Tooltip title={`Shipment total adjusted for OOS items`}>
                                                <span>&nbsp;${ship.adjustedShipmentTotal}&nbsp;/</span>
                                            </Tooltip>
                                        )}
                                        &nbsp;
                                        <Tooltip title={`Shipment total before OOS adjustments`}>
                                            <span>${ship.shipmentTotal}</span>
                                        </Tooltip>
                                    </caption>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Brand</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Qty</TableCell>
                                            <TableCell>Price</TableCell>
                                            {showTaxColumn && <TableCell>Tax</TableCell>}
                                            {showDiscountColumn && <TableCell>Discount</TableCell>}
                                            <TableCell>Notes</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ship.items.map((item) => {
                                            const isOOS = item.oos;
                                            return (
                                                <PaymentDetailTooltip
                                                    key={item.id}
                                                    item={item}
                                                    isPlusMember={isPlusMember}
                                                >
                                                    <TableRow key={item.id} className={classes.orderItemRow}>
                                                        <TableCell>{item.brand ?? ''}</TableCell>
                                                        <TableCell>{item.name}</TableCell>
                                                        <TableCell>{item.qty}</TableCell>
                                                        <TableCell>
                                                            <span>
                                                                {(item.priceForCustomer || item.price).toFixed(2)}
                                                            </span>
                                                        </TableCell>
                                                        {showTaxColumn && (
                                                            <TableCell>{(item.taxAmount || 0).toFixed(2)}</TableCell>
                                                        )}
                                                        {showDiscountColumn && (
                                                            <TableCell>
                                                                {(item.discountAmount || 0).toFixed(2)}
                                                            </TableCell>
                                                        )}
                                                        <TableCell>
                                                            {isOOS ? (
                                                                <span
                                                                    style={{
                                                                        background: '#FFCCCC',
                                                                        borderRadius: '8px',
                                                                        padding: '4px',
                                                                    }}
                                                                >
                                                                    Out Of Stock
                                                                </span>
                                                            ) : item.refundHistory && item.refundHistory.length > 0 ? (
                                                                <span
                                                                    style={{
                                                                        background: '#F7F7F7',
                                                                        borderRadius: '8px',
                                                                        padding: '4px',
                                                                    }}
                                                                >
                                                                    Refunded
                                                                </span>
                                                            ) : null}
                                                            {item.isPerk ? (
                                                                <Tooltip
                                                                    title={`Points Used: ${item.pointsUsed || '?'}`}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            background: '#F7F7F7',
                                                                            borderRadius: '8px',
                                                                            padding: '4px',
                                                                        }}
                                                                    >
                                                                        Perk
                                                                    </span>
                                                                </Tooltip>
                                                            ) : null}
                                                        </TableCell>
                                                    </TableRow>
                                                </PaymentDetailTooltip>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {ship.shippingStatus === 'DELIVERED' || ship.shippingStatus === 'CANCELLED' ? null : (
                                <Button
                                    style={{ margin: '5px 0 15px', color: 'red', borderColor: 'red' }}
                                    variant="outlined"
                                    onClick={() => {
                                        setSelectedChargeID(chargeID);
                                        setSelectedShipmentID(ship.shipmentID);
                                        setCancelShipmentModalOpen(true);
                                    }}
                                >
                                    Cancel Shipment {ship.shipmentNumber}
                                </Button>
                            )}
                        </div>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { OrderDetailsModal };
