import { warehouseApi as api } from '../../../api/warehouse';
import { UploadCSVAndReviewComponent } from '../../../shared';
import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

const ImportLoadsheetDialog = ({ packGroup, open, onClose, onSuccess, setNotification }) => {
    const uploadLoadsheet = async (formData) => {
        return await api.importLoadsheet(packGroup, formData);
    };

    const confirmLoadsheet = async (key) => {
        setNotification({
            text: 'Upload in progress. This can take up to a minute depending on the size of the file.',
            level: 'warning',
            autoHide: false,
        });
        return await api.confirmLoadsheetImport(key);
    };

    let columns = [
        { accessor: 'Location', Header: 'Line Slot' },
        { accessor: 'SKU', Header: 'FC Sku' },
        { accessor: 'Product Name', Header: 'Item Name' },
    ];

    const downloadLoadsheetTemplate = () => {
        let csvContent = `data:text/csv;charset=utf-8,Location,SKU,Product Name`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'loadsheet_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
            <DialogTitle>Import Loadsheet CSV</DialogTitle>
            <DialogContent>
                <UploadCSVAndReviewComponent
                    fileLabel="file"
                    columns={columns}
                    upload={uploadLoadsheet}
                    confirm={confirmLoadsheet}
                    onSuccess={onSuccess}
                    downloadTemplate={downloadLoadsheetTemplate}
                />
            </DialogContent>
        </Dialog>
    );
};

export { ImportLoadsheetDialog };
