import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { DataTable, Notifications } from '../../../../shared';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';

const warehouseInventoryV2Api = new WarehouseInventoryV2Api();

const ActiveNegativeVariance = ({}) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [rows, setRows] = useState([]);
    const { user } = useContext(UserContext);

    //Column Definitions
    const columns = [
        { Header: 'Partner SKU', accessor: 'partnerSku' },
        { Header: 'Partner Name', accessor: 'companySource' },
        { Header: 'MM SKU', accessor: 'sku' },
        { Header: 'Item Type', accessor: 'itemType' },
        { Header: 'Item Name', accessor: 'itemName' },
        { Header: 'Negative Variance Units', accessor: 'unresolvedUnits' },
    ];

    useEffect(() => {
        getNegativeVariance();
    }, []);

    //API call to GET data
    const getNegativeVariance = async () => {
        const result = await warehouseInventoryV2Api.getActiveNegativeVariance(user.facilityID);
        if (result.status) {
            setRows(result.data.items);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    return (
        <>
            <Notifications options={notification} />
            <DataTable columns={columns} data={rows} />
        </>
    );
};

export { ActiveNegativeVariance };
