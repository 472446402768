import React, { useState } from 'react';
import { makeStyles, Box, Typography, IconButton, Collapse, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 1),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    headerText: {
        fontWeight: 500,
    },
    content: {
        padding: theme.spacing(1.5),
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(0, 1, 1, 1),
    },
    divider: {
        margin: theme.spacing(0),
    },
}));

const CollapsibleFilterContainer = ({ filterName, children, onChange }) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const handleChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.header} onClick={handleToggle}>
                <Typography variant="body2" className={classes.headerText}>
                    {filterName}
                </Typography>
                <IconButton
                    size="small"
                    onClick={handleToggle}
                    aria-expanded={isExpanded}
                    aria-label={`Toggle ${filterName} filter`}
                >
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            <Collapse in={isExpanded} timeout={200}>
                <Box className={classes.content}>{React.cloneElement(children, { onChange: handleChange })}</Box>
            </Collapse>
            <Divider className={classes.divider} />
        </Box>
    );
};

export { CollapsibleFilterContainer };
