import React, { useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Notifications } from './Notifications';

const CopyToClipboard = ({ value }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(value);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset after 2 sec
        } catch (err) {
            setNotification({ text: 'Could not copy value to clipboard', level: 'error' });
        }
    };

    return (
        <div>
            <Notifications options={notification} />
            <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'} arrow>
                <IconButton onClick={handleCopy}>
                    {copied ? (
                        // Checkmark Icon
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="green">
                            <path d="M9 16.17l-4.17-4.17-1.41 1.41 5.58 5.58 12-12-1.41-1.41z" />
                        </svg>
                    ) : (
                        // Copy Icon
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="black">
                            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                        </svg>
                    )}
                </IconButton>
            </Tooltip>
        </div>
    );
};

export { CopyToClipboard };
