import React, { useContext, useEffect, useState } from 'react';
import { Notifications } from '../../../../../shared';
import { UserContext } from '../../../../../contexts/UserContext';
import { warehouseApi } from '../../../../../api/warehouse';
import { Button, Grid, Typography } from '@material-ui/core';
import { SearchableSelect } from '../../../../../shared/components/SearchableSelect';

const MovePalletAction = ({ palletId, onSuccess }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const { user } = useContext(UserContext);
    const [locations, setLocations] = useState([]);
    const [loadingLocations, setLoadingLocations] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState(undefined);

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('ChangeExpirationDateAction intialized incorrectly onSuccess is not a function');
        }
    }, [onSuccess]);

    useEffect(() => {
        queryLocations();
    }, []);

    const queryLocations = async () => {
        setLoadingLocations(true);
        const result = await warehouseApi.getLocations(user.facilityID, true, 'Pallet');
        setLoadingLocations(false);
        if (result.status) {
            setLocations(result.data.rows);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const handleSubmit = async () => {
        const result = await warehouseApi.movePallet(palletId, {
            newLocation: selectedLocationId,
        });

        if (result.status) {
            if (onSuccess instanceof Function) {
                onSuccess();
            }
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const handleOnChange = (newValue) => {
        setSelectedLocationId(newValue?.value);
    };

    return (
        <>
            <Notifications options={notification} />
            <h3>Move Pallet {palletId}</h3>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={10}>
                    <SearchableSelect
                        onChange={handleOnChange}
                        options={locations.map((location) => {
                            return { label: location.name, value: location.id };
                        })}
                        loading={loadingLocations}
                        label={'To Location'}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button type="submit" variant="outlined" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export { MovePalletAction };
