import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { DataTable, FormComponent, Notifications } from '../../../shared';
import { marketingApi as api } from '../../../api/marketing';
import moment from 'moment';

const ViewReferrals = () => {
    const [rows, setRows] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });

    const baseColumns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'newCustValue', Header: 'New Customer Credit' },
        { accessor: 'referrerValue', Header: 'Referrer Value' },
        { accessor: 'minimumOrderValue', Header: 'Minimum Order Value' },
        { accessor: 'termsAndConditions', Header: 'Terms & Conditions' },
        { accessor: 'effectiveDate', Header: 'Effective Date' },
    ];

    const columns = [...baseColumns];

    let getAllReferralRewards = async () => {
        let response = await api.getAllReferralRewards();
        if (response.status === false) {
            setNotification({ text: 'No referrals found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }
        console.log(response.data);
        if (response.data.rows) {
            response.data.rows.map((row) => {
                if (row.effectiveDate == null) {
                    row.effectiveDate = '';
                } else {
                    row.effectiveDate = moment(row.effectiveDate.date).format('MM/DD/YYYY');
                }

                return row;
            });
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getAllReferralRewards();
    }, []);

    return (
        <div>
            <h1>Referrals</h1>
            <Notifications options={notification} />

            <div>
                <DataTable columns={columns} data={rows} />
            </div>
        </div>
    );
};

export { ViewReferrals };
