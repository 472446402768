import React, { useState, useEffect, useContext } from 'react';
import { fbmApi as api } from '../../api/fbm';
import { Select, MenuItem } from '@material-ui/core';
import { UserContext } from '../../contexts/UserContext';

const WholesaleAccountPicker = ({ onChange, defaultValue = '', fromReportScreen = false }) => {
    const [wholesaleAccounts, setWholesaleAccounts] = useState([]);
    const [wholesaleAccountIDInternal, setWholesaleAccountIDInternal] = useState(defaultValue);

    const { user, wholesaleAccount, setWholesaleAccount } = useContext(UserContext);

    useEffect(() => {
        if (!user.id) {
            return;
        }
        if (wholesaleAccount?.id) {
            setWholesaleAccountIDInternal(wholesaleAccount.id);
        }

        getWholesaleAccounts();
    }, [user]);

    const getWholesaleAccounts = async () => {
        const res = await api.getFBMAccounts('', fromReportScreen);
        if (!res.status) {
            return;
        }

        const accounts = res.data.accounts.filter((acc) => {
            if (acc.fbmAccountName === 'gopuff' || acc.fbmAccountName === 'doordash') {
                //TODO Enable Door Dash when ready
                return acc;
            }
        });

        setWholesaleAccounts(accounts);
        if (!wholesaleAccount.id && accounts > 0) {
            setWholesaleAccountIDInternal(accounts[0].id);
        }
    };

    useEffect(() => {
        if (!!wholesaleAccountIDInternal) {
            let account = wholesaleAccounts.find((item) => item.id === wholesaleAccountIDInternal);
            if (account) {
                setWholesaleAccount(account);
            }
        }
    }, [wholesaleAccountIDInternal]);

    // This will be invisible for Wholesale users, it will just set the account
    if (user.wholesale || !user.id) {
        return null;
    }

    return (
        <span>
            <h2>
                Wholesale Account &nbsp;&nbsp;
                <Select
                    id="Wholesale_account_select"
                    value={wholesaleAccountIDInternal}
                    onChange={(event) => {
                        setWholesaleAccountIDInternal(event.target.value);
                    }}
                >
                    {wholesaleAccounts.map((item) => (
                        <MenuItem key={item.companyName} value={item.id}>
                            {item.companyName}
                        </MenuItem>
                    ))}
                </Select>
            </h2>
        </span>
    );
};

export { WholesaleAccountPicker };
