import React, { useEffect, useState } from 'react';

import { DataTable, Notifications } from '../../../../shared';
import { productApi as api } from '../../../../api/product';

const DATA_TABLE_COLUMNS = [
    { accessor: 'id', Header: 'ID' },
    { accessor: 'name', Header: 'Offer' },
    { accessor: 'credits', Header: 'Credits' },
    { accessor: 'creditsDurationDays', Header: 'Credit Duration Days' },
];

function ViewPlusMembershipOffers() {
    const [dataTableRows, setDataTableRows] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });

    useEffect(() => {
        const fetchPlusMembershipOffers = async () => {
            const response = await api.getPlusMembershipOffers();

            if (response.status === false) {
                setNotification({ text: response.msg, level: 'error' });
            } else {
                setDataTableRows(response.data.offers);
            }
        };

        fetchPlusMembershipOffers();
    }, []);

    return (
        <div>
            <h1>Plus Membership Offers</h1>
            <Notifications options={notification} />
            <DataTable columns={DATA_TABLE_COLUMNS} data={dataTableRows} />
        </div>
    );
}

export { ViewPlusMembershipOffers };
