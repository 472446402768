import React from 'react';

const PalletMovedToLocationDetailCell = ({ row }) => {
    const results = row?.expectedResults || [];

    return (
        <div style={{ margin: '10px 0px' }}>
            <div style={{ textDecoration: 'underline', textAlign: 'center', fontWeight: 'bold' }}>
                Pallet {results[0]?.expectedPalletID} Moved To Location
            </div>
            {results.map((result) => (
                <div key={result.expectedResultID} style={{ margin: '10px 0px' }}>
                    <div>
                        <strong>Item: </strong>
                        {result?.itemName}
                    </div>
                    <div>
                        <strong>Lot: </strong>
                        {result?.expectedLotID}
                    </div>
                    <div>
                        <strong>Units Moved: </strong>
                        {result?.totalExpectedUnits} units ({result?.expectedCaseCount} cases,{' '}
                        {result?.expectedLooseUnitCount} loose units)
                    </div>
                </div>
            ))}
        </div>
    );
};

export { PalletMovedToLocationDetailCell };
