import React from 'react';
import { Divider } from '@material-ui/core';

const MissingPalletDetailCell = ({ row }) => {
    const expectedResults = row?.expectedResults;
    const fromLocationEmptyModal = row?.metadata?.fromLocationEmpty;

    return (
        <div style={{ margin: '10px 0px' }}>
            <div style={{ textDecoration: 'underline', textAlign: 'center', fontWeight: 'bold' }}>
                <div>Pallet {row?.expectedResults[0]?.expectedPalletID} Moved To Missing</div>
            </div>
            {fromLocationEmptyModal && <div style={{ fontWeight: 'bold' }}>From Location Empty</div>}

            {expectedResults?.map((result, index) => (
                <div key={result?.expectedResultID}>
                    {index > 0 && <Divider variant="middle" style={{ margin: '5px' }} />}
                    <div>
                        <strong>Item: </strong>
                        {result?.itemName}
                    </div>
                    <div>
                        <strong>Lot: </strong>
                        {result?.expectedLotID}
                    </div>
                    <div>
                        <strong>Units Moved: </strong>
                        {result?.totalExpectedUnits} units ({result?.expectedCaseCount} cases,{' '}
                        {result?.expectedLooseUnitCount} loose units)
                    </div>
                </div>
            ))}
        </div>
    );
};

export { MissingPalletDetailCell };
