import React, { useReducer, useEffect, createContext } from 'react';

const tableConfigReducer = (config, newConfig) => {
    if (!newConfig) {
        localStorage.removeItem('dataTableConfig');
        return null;
    }

    return { ...config, ...newConfig };
};

const initialConfig = JSON.parse(localStorage.getItem('dataTableConfig'));

const DataTableContext = createContext();

const DataTableProvider = ({ children }) => {
    const [tableConfig, setTableConfig] = useReducer(tableConfigReducer, initialConfig || null);

    useEffect(() => {
        if (!!tableConfig) {
            localStorage.setItem('dataTableConfig', JSON.stringify(tableConfig));
        }
    }, [tableConfig]);

    const setColumnConfiguration = (tableName, config) => {
        setTableConfig({ ['table-' + tableName]: config });
    };

    const getColumnConfiguration = (tableName) => {
        return tableConfig?.['table-' + tableName] || null;
    };

    return (
        <DataTableContext.Provider
            value={{
                setColumnConfiguration,
                getColumnConfiguration,
            }}
        >
            {children}
        </DataTableContext.Provider>
    );
};

export { DataTableContext, DataTableProvider };
