import React from 'react';
import { Button, TextField } from '@material-ui/core';
import { PaymentDetailTooltip } from './PaymentDetailTooltip';

const RefundItemDetails = ({
    formData,
    refundItemsWithTaxonomy,
    refundableItems,
    setFormField,
    setItemsSelected,
    productSpill,
    customerIsPlusMember,
}) => {
    let itemsToMap = refundItemsWithTaxonomy?.length > 0 ? refundItemsWithTaxonomy : null;
    let arrForItemsSelected = [];
    let isSpill = productSpill ? 'true' : 'false';

    let getItemsForSelect = (arr) => {
        if (arr.length < 1) {
            return setItemsSelected(null);
        }

        return arr.map((item) => {
            let tmp = {};

            let findItem = refundableItems.find((obj) => obj.sellableItemModel.id === item.id);
            if (findItem) {
                tmp.name = findItem.sellableItemModel.name;
                tmp.price = findItem.sellableItemModel.price;
                tmp.qty = item.qty;
                tmp.tax = (findItem.taxAmount / findItem.qty) * item.qty;
                tmp.discount = (findItem.discountAmount / findItem.qty) * item.qty;
                tmp.freeLoyaltyGiftAmount = findItem.freeLoyaltyGiftAmount;
                arrForItemsSelected.push(tmp);
                tmp = {};
            }
            setItemsSelected(arrForItemsSelected);
        });
    };

    if (itemsToMap === null) {
        return <div></div>;
    }

    return (
        <div>
            {productSpill ? <h2>Select Spilled Product</h2> : <h2>Select Items</h2>}
            {itemsToMap.map((item) => {
                return (
                    <div>
                        {Object.keys(item.taxonomyItemNameObj).map((key) => {
                            //sort by name
                            item.taxonomyItemNameObj[key].sort((a, b) =>
                                a.sellableItemModel.name.localeCompare(b.sellableItemModel.name)
                            );
                            return (
                                <>
                                    <h4 style={{ fontSize: '16px', marginBottom: '5px' }}>{key}</h4>
                                    {item.taxonomyItemNameObj[key].map((itemDetails) => {
                                        let qty = 0;
                                        let affectedItem = formData.itemsAffected.find(
                                            (obj) => obj.id === itemDetails.sellableItemModel.id
                                        );
                                        if (affectedItem) {
                                            qty = affectedItem.qty;
                                        }
                                        return (
                                            <SelectedItem
                                                item={itemDetails}
                                                key={itemDetails.sellableItemModel.id}
                                                quantity={qty}
                                                customerIsPlusMember={customerIsPlusMember}
                                                setQuantity={(value) => {
                                                    let tmp = {};

                                                    formData.itemsAffected.forEach((item) => {
                                                        tmp[item.id] = item.qty;
                                                    });

                                                    if (Number(value) === 0) {
                                                        delete tmp[itemDetails.sellableItemModel.id];
                                                    } else if (Number(value) > 0) {
                                                        tmp[itemDetails.sellableItemModel.id] = value;
                                                    }

                                                    let arr = Object.keys(tmp).map((key) => ({
                                                        id: Number(key),
                                                        qty: tmp[key],
                                                        spill: isSpill,
                                                    }));
                                                    setFormField(arr);
                                                    getItemsForSelect(arr);
                                                }}
                                            />
                                        );
                                    })}
                                </>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

const SelectedItem = ({ item, quantity, setQuantity, customerIsPlusMember }) => {
    const perkPrice = item.freeLoyaltyGiftAmount * item.sellableItemModel.price;
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '3px',
                borderBottom: '1px solid #ddd',
            }}
        >
            <div
                style={{
                    width: '-webkit-fill-available',
                    paddingRight: '20px',
                }}
            >
                <p
                    style={{
                        margin: '0px',
                        fontWeight: 500,
                    }}
                >
                    {perkPrice > 0 && (
                        <span
                            style={{
                                background: '#D7E1B8',
                                borderRadius: '4px',
                                padding: '2px 4px',
                                marginRight: '5px',
                                fontSize: '12px',
                            }}
                        >
                            PERK
                        </span>
                    )}
                    <PaymentDetailTooltip item={item} isPlusMember={customerIsPlusMember}>
                        <span>
                            {!!item.brand && `${item.brand} | `}
                            {item.sellableItemModel.name}&nbsp;
                            {item.sellableItemModel.portionName != null ? item.sellableItemModel.portionName : ''}
                        </span>
                    </PaymentDetailTooltip>
                    {quantity > 0 && <span> &times; {quantity}</span>}
                </p>
                <p
                    style={{
                        margin: '0px',
                    }}
                >
                    {item.priceForCustomer > 0
                        ? `$${item.priceForCustomer.toFixed(2)}`
                        : `$${(item.sellableItemModel.price || 0).toFixed(2)}`}{' '}
                    &times; {item.qty}&nbsp;
                    <small>
                        {item.pointsUsed > 0 && `(Points: ${item.pointsUsed}) `}
                        {item.creditsUsed > 0 && `(Credits: ${item.creditsUsed.toFixed(2)}) `}
                        {item.totalForCard > 0 && `(Stripe: $${item.totalForCard.toFixed(2)}) `}
                        {item.taxAmount > 0 &&
                            `(Tax: ${(item.taxAmount < 0 ? 0 : item.taxAmount / item.qty).toFixed(2)}) `}
                        {item.discountAmount > 0 && `(Discount: ${(item.discountAmount / item.qty).toFixed(2)}) `}
                        {item.loyaltyDollarDiscount > 0 && `(Discount Perk: ${item.loyaltyDollarDiscount.toFixed(2)}) `}
                        {item.perkPrice > 0 && (
                            <span
                                style={{
                                    color: perkPrice ? '#2E8540' : '#000',
                                }}
                            >
                                (Item Perk: ${perkPrice.toFixed(2)})
                            </span>
                        )}
                        {/* I don't think this property exists in the BE anymore */}
                        {item.credited === true && <span style={{ color: 'red' }}>&nbsp;(Credited)</span>}
                    </small>
                </p>
            </div>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Button
                    style={{
                        background: '#4050B5',
                        color: '#FFFFFF',
                        minWidth: '24px',
                        height: '24px',
                        margin: '0 10px',
                        fontSize: 'xx-large',
                        borderRadius: '0',
                    }}
                    onClick={() => {
                        let tmp = Number(quantity);
                        if (tmp > 0) {
                            setQuantity(tmp - 1);
                        }
                    }}
                    aria-label="Decrement quantity"
                >
                    -
                </Button>
                <TextField
                    inputProps={{
                        readOnly: true,
                    }}
                    InputProps={{ disableUnderline: true }}
                    readOnly
                    value={quantity}
                    style={{
                        color: '#000',
                        background: '#F7F7F7',
                        borderRadius: '8px',
                        width: '34px',
                        height: '34px',
                        textAlignLast: 'center',
                    }}
                />
                <Button
                    style={{
                        background: '#4050B5',
                        color: '#FFFFFF',
                        minWidth: '24px',
                        height: '24px',
                        margin: '0 10px',
                        fontSize: 'x-large',
                        borderRadius: '0',
                    }}
                    onClick={() => {
                        let tmp = Number(quantity);
                        if (tmp < item.qty) {
                            setQuantity(tmp + 1);
                        }
                    }}
                    aria-label="Increment quantity"
                >
                    {' '}
                    +{' '}
                </Button>
            </div>
        </div>
    );
};

export { RefundItemDetails };
