import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    PartnershipTabs,
    Nav,
    ExpandableNotice,
    Tooltip,
    Input,
    Select,
    Combobox,
    PartnershipsPagination,
} from '../../shared';
import { partnershipsApi } from '../../../api/partnerships';
import { marketplaceApi } from '../../../api/marketplace';
import { UserContext } from '../../../contexts/UserContext';
import { Link } from 'react-router-dom';
import './lists.css';
import { Dialog, DialogContent, Checkbox, FormControlLabel, LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ExcludedSkuList } from '../createList/components/excludedSkuList';

const Lists = () => {
    const { famAccount, roles } = useContext(UserContext);
    const [lists, setLists] = useState([]);
    const [packGroups, setPackGroups] = useState([]);
    const [week, setWeek] = useState(null);
    const [year, setYear] = useState(null);
    const [pgNotices, setPgNotices] = useState({});

    const [duplicatingListID, setDuplicatingListID] = useState(null);
    const [duplicatingListName, setDuplicatingListName] = useState('');
    const [modalListID, setModalListID] = useState(null);
    const [modalType, setModalType] = useState('');
    const [replaceableItems, setReplaceableItems] = useState([]);
    const [currentWeekItems, setCurrentWeekItems] = useState([]);
    const [listCount, setListCount] = useState(0);
    const [canEditLists, setCanEditLists] = useState(false);

    /**
     * State for pagination
     */
    const [searchTerm, setSearchTerm] = useState('');

    const [activeLists, setActiveLists] = useState(true);
    const [newActiveLists, setNewActiveLists] = useState(true);

    const [page, setPage] = useState(0);
    const [newPage, setNewPage] = useState(0);

    const [loading, setLoading] = useState(false);

    const downloadLists = () => {
        partnershipsApi.getCuratedListsForPartnerCSV(famAccount.partnershipSource);
    };

    useEffect(() => {
        setCanEditLists(roles.map((role) => role.name).includes('UPDATE_CURATED_LISTS'));
    }, [roles]);

    const searchTimeoutRef = useRef(null);

    const handleListSearch = async (search) => {
        setSearchTerm(search);
        clearTimeout(searchTimeoutRef.current);

        searchTimeoutRef.current = setTimeout(() => {
            getLists({ newSearchTerm: search });
        }, 500);
    };

    const handleChangeActive = () => {
        setNewActiveLists(!activeLists);
    };

    // Cleanup the timeout on component unmount
    useEffect(() => {
        return () => clearTimeout(searchTimeoutRef.current);
    }, [searchTimeoutRef]);

    const loadNewDataAndSetState = async (attribute) => {
        const res = await getLists({});
        if (!res) {
            return;
        }

        if (attribute === 'newActiveLists') {
            setActiveLists(newActiveLists);
        } else if (attribute === 'newPage') {
            setPage(newPage);
        }
    };

    useEffect(() => {
        loadNewDataAndSetState('newActiveLists');
    }, [newActiveLists]);

    useEffect(() => {
        loadNewDataAndSetState('newPage');
    }, [newPage]);

    async function getLists({ newSearchTerm }) {
        setLoading(true);

        let pageNum = newPage;
        let name = searchTerm;
        let active = newActiveLists;

        if (newSearchTerm !== undefined) {
            name = newSearchTerm;
        }

        const res = await partnershipsApi.getCuratedListsForPartner(
            famAccount.partnershipSource,
            0,
            pageNum,
            name,
            active
        );

        if (!res.status || !res.data) {
            setLoading(false);
            return false;
        }

        setPackGroups(res.data.packGroups);
        setWeek(res.data.week);
        setYear(res.data.year);
        setLists(res.data.lists);
        setListCount(res.data.count);
        setPgNotices(res.data.notices);

        setLoading(false);
        return true;
    }

    async function getReplaceableItems() {
        const res = await partnershipsApi.getEmptyItemsForPartner(famAccount.partnershipSource);
        if (!res.status || !res.data) {
            return;
        }

        setReplaceableItems(res.data.items);
    }

    async function getPackGroupItems() {
        const res = await partnershipsApi.getItemsWithTaxonomy(famAccount.partnershipSource);
        if (!res.status) {
            return;
        }
        setCurrentWeekItems(res.data.rows);
    }

    useEffect(() => {
        if (!famAccount.partnershipSource) {
            return;
        }

        getLists({});

        getReplaceableItems();
        getPackGroupItems();
    }, [famAccount.partnershipSource]);

    useEffect(() => {
        if (!!duplicatingListID) {
            const selectedList = lists.find((list) => list.id === duplicatingListID);
            setDuplicatingListName(`${selectedList.name} - Copy`);
        }
    }, [duplicatingListID]);

    const updateListActive = async (active) => {
        let result = {
            status: false,
            error: '',
        };
        const res = await marketplaceApi.editCuratedListInfo(modalListID, { active: active });
        if (!res.status) {
            result.error = res.msg;
            return result;
        }

        getLists({});
        closeModal();
        result.status = true;
        return result;
    };

    const closeModal = () => {
        setModalListID(null);
        setModalType('');
    };

    const duplicateList = async (listID, listName) => {
        let result = {
            status: false,
            error: '',
        };
        const res = await partnershipsApi.duplicateList(listID, listName);
        if (!res.status) {
            result.error = res.msg;
            return result;
        }

        getLists({});
        setDuplicatingListName('');
        setDuplicatingListID(null);
        result.status = true;
        return result;
    };

    const tabs = [
        {
            name: 'Current Lists',
            component: (
                <CurrentLists
                    loading={loading}
                    lists={lists}
                    packGroups={packGroups}
                    pgNotices={pgNotices}
                    week={week}
                    year={year}
                    openDuplicateListModal={(listID) => {
                        setDuplicatingListID(listID);
                    }}
                    openModal={(type, listID) => {
                        setModalListID(listID);
                        setModalType(type);
                    }}
                    listCount={listCount}
                    page={page}
                    handleChangePage={async (newPage) => {
                        setNewPage(newPage);
                    }}
                    searchTerm={searchTerm}
                    handleListSearch={handleListSearch}
                    canEditLists={canEditLists}
                    activeLists={activeLists}
                    handleChangeActive={handleChangeActive}
                    refreshLists={() => {
                        getLists({});
                    }}
                />
            ),
        },
    ];

    if (canEditLists) {
        tabs.push({
            name: 'Bulk Replace',
            component: (
                <BulkReplaceListItems
                    week={week}
                    year={year}
                    replaceableItems={replaceableItems}
                    currentWeekItems={currentWeekItems}
                    getReplaceableItems={getReplaceableItems}
                />
            ),
        });
    }

    if (!famAccount?.partnershipSource) {
        return (
            <div className="page-container">
                <Nav />
                <div className="content-container">
                    <div className="width-100">
                        <div className="fam-header-container">
                            <div className="fam-header-item-one">
                                <div className="grotesk-bold header-text">Please Select a Partner</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="page-container">
            <Nav />
            <div className="content-container">
                <DuplicateListModal
                    duplicatingListName={duplicatingListName}
                    open={!!duplicatingListID && !!duplicatingListName}
                    closeModal={() => {
                        setDuplicatingListID(null);
                    }}
                    duplicateList={(name) => duplicateList(duplicatingListID, name)}
                />

                <DeleteListModal
                    open={!!modalListID && ['DEACTIVATE', 'REACTIVATE'].includes(modalType)}
                    modalType={modalType}
                    closeModal={closeModal}
                    updateListActive={updateListActive}
                />

                <div className="width-100">
                    <div className="fam-header-container">
                        <div className="fam-header-item-one">
                            <span className="grotesk-bold header-text">Curated Lists</span>
                            <span className="grotesk-regular">
                                Week {week}, {year}
                            </span>
                            <Tooltip text="Item availability for lists is applicable from Wed 4:00pm - Tues 11:45pm ET" />
                        </div>
                        <div className="fam-header-item-two">
                            <button
                                onClick={() => {
                                    downloadLists();
                                }}
                                className="fam-button fam-button-white grotesk-bold"
                            >
                                Download Lists
                            </button>

                            {canEditLists && (
                                <Link to={{ pathname: '/partners/create-list' }}>
                                    <button className="fam-button fam-button-yellow grotesk-bold">Create List</button>
                                </Link>
                            )}
                        </div>
                    </div>

                    {Object.keys(pgNotices).length > 0 && (
                        <ExpandableNotice
                            message={`Notices available! ${Object.keys(pgNotices).length} list(s) affected`}
                            expandedNotices={Object.keys(pgNotices).map((key) => pgNotices[key].name)}
                        />
                    )}

                    <PartnershipTabs tabs={tabs} />
                </div>
            </div>
        </div>
    );
};

const DeleteListModal = ({ open, closeModal, updateListActive, modalType }) => {
    const [error, setError] = useState('');

    const modalTypes = {
        DEACTIVATE: {
            text: 'Delete List?',
            action: async () => {
                const result = await updateListActive(false);
                if (!result.status) {
                    setError(result.error);
                }
            },
        },
        REACTIVATE: {
            text: 'Reactivate List?',
            action: async () => {
                const result = await updateListActive(true);
                if (!result.status) {
                    setError(result.error);
                }
            },
        },
    };

    return (
        <Dialog key="deactivate" open={open} maxWidth="sm" fullWidth={true} onClose={closeModal}>
            <DialogContent>
                <div>
                    <div>{modalTypes?.[modalType]?.text}</div>
                    &nbsp;&nbsp;
                    <div>
                        <button
                            onClick={modalTypes?.[modalType]?.action}
                            className="fam-button fam-button-yellow grotesk-bold"
                        >
                            Confirm
                        </button>
                    </div>
                    {!!error && <div className="fam-warning-text grotesk-regular">{error}</div>}
                </div>
            </DialogContent>
        </Dialog>
    );
};

const DuplicateListModal = ({ open, closeModal, duplicateList, duplicatingListName }) => {
    const [name, setName] = useState(duplicatingListName);
    const [duplicateError, setDuplicateError] = useState('');

    useEffect(() => {
        setName(duplicatingListName);
    }, [duplicatingListName]);

    return (
        <Dialog open={open} maxWidth="sm" fullWidth={true} onClose={closeModal}>
            <DialogContent>
                <div>
                    <div>Duplicate List?</div>
                    &nbsp;&nbsp;
                    <Input
                        label="List Name"
                        required={true}
                        value={name}
                        onChange={(value) => {
                            setName(value);
                        }}
                    />
                    &nbsp;&nbsp;
                    <div>
                        <button
                            onClick={async () => {
                                const result = await duplicateList(name);
                                if (!result.status) {
                                    setDuplicateError(result.error);
                                }
                            }}
                            className="fam-button fam-button-yellow grotesk-bold"
                        >
                            Confirm
                        </button>
                    </div>
                    {!!duplicateError && <div className="fam-warning-text grotesk-regular">{duplicateError}</div>}
                </div>
            </DialogContent>
        </Dialog>
    );
};

const BulkReplaceListItems = ({ week, year, replaceableItems, currentWeekItems, getReplaceableItems }) => {
    const [itemToReplace, setItemToReplace] = useState({ value: '' });
    const [replacement, setReplacement] = useState({ value: '' });
    const [filteredCurrentWeekItems, setFilteredCurrentWeekItems] = useState([]);
    const [countReplaceable, setCountReplaceable] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    /* Timeouts so errors don't show forever */
    useEffect(() => {
        if (!successMessage) {
            return;
        }
        setTimeout(() => {
            setSuccessMessage('');
        }, 5000);
    }, [successMessage]);
    useEffect(() => {
        if (!errorMessage) {
            return;
        }
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    }, [errorMessage]);

    useEffect(() => {
        setFilteredCurrentWeekItems([]);
        setReplacement({ value: '' });

        if (!itemToReplace.value) {
            return;
        }

        let facilityIDs = itemToReplace.items.map((item) => item.facilityID);
        facilityIDs = [...new Set(facilityIDs)];

        const invFiltered = currentWeekItems.filter((pg) => facilityIDs.includes(pg.facilityID)).map((pg) => pg.items);

        let flattened = invFiltered.reduce((a, b) => a.concat(b), []);
        let counts = flattened.reduce(
            (map, { externalSku }) => map.set(externalSku, (map.get(externalSku) || 0) + 1),
            new Map()
        );

        let common = invFiltered[0].filter(({ externalSku }) => {
            if (counts.get(externalSku) === facilityIDs.length) {
                return true;
            }
            return false;
        });

        setFilteredCurrentWeekItems(
            common.map((item) => ({
                value: item.externalSku,
                text: `${item.brand ? item.brand + ' ' : ''}${item.name}`,
                ...item,
            }))
        );
    }, [itemToReplace]);

    useEffect(() => {
        if (!itemToReplace.value || !replacement.value) {
            setCountReplaceable(0);
            return;
        }
        const replacementExtSku = replacement.externalSku;
        let count = 0;
        itemToReplace.items.forEach((i) => {
            if (!i.ineligibleSkusForReplacement.includes(replacementExtSku)) {
                count++;
            }
        });

        setCountReplaceable(count);
    }, [itemToReplace, replacement]);

    const bulkReplaceItem = async () => {
        let replacementExtSku = replacement.externalSku;
        let itemsToReplace = itemToReplace.items
            .filter((i) => !i.ineligibleSkusForReplacement.includes(replacementExtSku))
            .map((i) => {
                return { listID: i.listID, slot: i.slot, position: i.position, packGroupID: i.packGroupID };
            });

        let res = await partnershipsApi.replaceListItems(replacementExtSku, itemsToReplace);
        if (!res.status) {
            setErrorMessage(`Failed to replace item: ${res.msg}`);
            return;
        }

        setSuccessMessage(`Item Replaced!`);
        setItemToReplace({ value: '' });
        setReplacement({ value: '' });
        getReplaceableItems();
    };

    return (
        <div>
            <div className="fam-success-alert">
                {!!successMessage && <Alert severity="success">{successMessage}</Alert>}
            </div>
            <Combobox
                label="Select an Item to Replace"
                value={itemToReplace.value}
                onChange={({ selectedItem }) => {
                    setItemToReplace(selectedItem);
                }}
                required={true}
                options={replaceableItems.map((item) => ({
                    ...item,
                    value: item.externalSku,
                    text: `${item.name} - OOS`,
                }))}
            />
            <Combobox
                label="Select Replacement Item"
                value={replacement.value}
                onChange={({ selectedItem }) => {
                    setReplacement(selectedItem);
                }}
                required={true}
                options={filteredCurrentWeekItems}
            />

            {!!itemToReplace.value && (
                <div>
                    <ExpandableNotice
                        message={`${itemToReplace.name} is not for sale in week ${week}, ${year}. This item was previously in ${itemToReplace.items.length} lists:`}
                        expandedNotices={itemToReplace.items.map((i) => {
                            let baseMsg = `${i.facility} ${i.listName} - Slot ${i.slot}, Position ${i.position}`;

                            if (!!replacement.value) {
                                const replacementExtSku = replacement.externalSku;
                                const replacementName = `${replacement.brand ? replacement.brand + ' ' : ''}${
                                    replacement.name
                                }`;

                                if (i.ineligibleSkusForReplacement.includes(replacementExtSku)) {
                                    baseMsg += ` - CANNOT be replaced by ${replacementName} because this item is in another position in slot ${i.slot}`;
                                } else {
                                    baseMsg += ` - will be replaced by ${replacementName}`;
                                }
                            }
                            return baseMsg;
                        })}
                        initialExpandedValue={true}
                    />
                </div>
            )}

            <div>{!!errorMessage && <div className="fam-warning-text grotesk-regular">{errorMessage}</div>}</div>
            {countReplaceable > 0 && (
                <button onClick={bulkReplaceItem} className="fam-button fam-button-yellow grotesk-bold">
                    Replace {countReplaceable} Items
                </button>
            )}
        </div>
    );
};

const CurrentLists = ({
    lists,
    packGroups,
    pgNotices,
    week,
    year,
    openDuplicateListModal,
    listCount,
    page,
    handleChangePage,
    handleListSearch,
    searchTerm,
    canEditLists,
    handleChangeActive,
    activeLists,
    openModal,
    loading,
    refreshLists,
}) => {
    return (
        <div>
            <Input label="List Name" value={searchTerm} onChange={handleListSearch} />
            &nbsp;&nbsp;
            <div className="fam-header-container">
                <div className="fam-header-item-one">
                    <FormControlLabel
                        control={<Checkbox onChange={handleChangeActive} checked={activeLists} />}
                        label="Active Lists"
                    />
                </div>
                <div className="fam-header-item-two">
                    <PartnershipsPagination
                        count={listCount}
                        rowsPerPage={20}
                        page={page}
                        onChangePage={handleChangePage}
                    />
                </div>
            </div>
            {<div className="fam-loading-container">{loading && <LinearProgress color="secondary" />}</div>}
            {lists.map((list) => {
                let listNotices = pgNotices?.[list.id]?.packGroups || {};
                return (
                    <CuratedListInfo
                        key={list.name}
                        list={list}
                        packGroups={packGroups}
                        pgNotices={listNotices}
                        week={week}
                        year={year}
                        openModal={openModal}
                        openDuplicateListModal={openDuplicateListModal}
                        canEditLists={canEditLists}
                        refreshLists={refreshLists}
                    />
                );
            })}
        </div>
    );
};

const PackGroupListsTab = ({ list, packGroup }) => {
    let slotsForPackGroup = {};
    let listTotal = 0;
    Object.keys(list.slots)
        .sort((a, b) => (Number(a) < Number(b) ? -1 : 1))
        .forEach((slotKey) => {
            let slotItems =
                list.slots[slotKey]?.packGroups.find((item) => item.packGroupID === packGroup.id)?.items || [];
            slotsForPackGroup[slotKey] = slotItems;
            let positionOneItem = slotItems.find((item) => item.position === 1);
            if (!!positionOneItem) {
                listTotal += positionOneItem.price * positionOneItem.qty;
            }
        });
    return (
        <React.Fragment key={packGroup.id}>
            <div className="grotesk-regular">
                <span className="grotesk-bold">List Total: </span> ${listTotal.toFixed(2)}
            </div>
            &nbsp;&nbsp;
            {Object.keys(slotsForPackGroup).map((slot) => {
                let slotForPackGroup = slotsForPackGroup[slot];
                let itemStr = '';
                if (list.type === 'Taxonomy') {
                    itemStr = list?.slots?.[slot]?.taxonomyNames.join(', ');
                } else {
                    if (!!slotForPackGroup && slotForPackGroup.length > 0) {
                        itemStr = slotForPackGroup.find((i) => !!i.externalSku)?.name;
                    }
                }
                return (
                    <div key={slot}>
                        <span className="grotesk-bold">Slot {slot}</span>
                        &nbsp;&nbsp;
                        <span className="grotesk-regular">{itemStr}</span>
                    </div>
                );
            })}
        </React.Fragment>
    );
};

const CuratedListInfo = ({
    list,
    packGroups,
    pgNotices,
    week,
    year,
    openDuplicateListModal,
    openModal,
    canEditLists,
    refreshLists,
}) => {
    const [expanded, setExpanded] = useState(false);

    const tabs = packGroups.map((pg) => {
        return {
            name: pg.facility,
            component: <PackGroupListsTab list={list} packGroup={pg} />,
        };
    });

    return (
        <div className="curated-list-container">
            <div className="fam-header-container">
                <div className="fam-header-item-one">
                    <div className="grotesk-bold small-header-text white-space-pre">
                        {!list.active && 'DEACTIVATED - '}
                        {list.name}
                    </div>
                </div>
                <div className="fam-header-item-two">
                    {canEditLists && list.active && (
                        <>
                            <button
                                onClick={() => {
                                    openDuplicateListModal(list.id);
                                }}
                                className="fam-button fam-button-white grotesk-bold"
                            >
                                Duplicate
                            </button>
                            <Link to={{ pathname: '/partners/create-list', state: { list, week, year } }}>
                                <button className="fam-button fam-button-yellow grotesk-bold">Edit</button>
                            </Link>
                            <button
                                className="fam-button fam-button-red grotesk-bold"
                                onClick={() => {
                                    openModal('DEACTIVATE', list.id);
                                }}
                            >
                                Delete
                            </button>
                        </>
                    )}
                    {canEditLists && !list.active && (
                        <>
                            <button
                                className="fam-button fam-button-white grotesk-bold"
                                onClick={() => openModal('REACTIVATE', list.id)}
                            >
                                Reactivate List
                            </button>
                        </>
                    )}
                </div>
            </div>

            <div className="curated-list-divider"></div>

            {Object.keys(pgNotices).length > 0 && (
                <ExpandableNotice
                    message={`Notices for this list! ${Object.keys(pgNotices).length} pack group(s) affected.`}
                    expandedNotices={Object.keys(pgNotices)
                        .map((key) => pgNotices[key])
                        .flat()}
                />
            )}

            <div className="list-details-container">
                <div>
                    <span className="grotesk-bold">Type</span>
                    &nbsp;&nbsp;
                    <span className="grotesk-regular">{list.type}</span>
                </div>
                {list.priceVariance !== null && (
                    <div>
                        <span className="grotesk-bold">Percent Variance</span>
                        &nbsp;&nbsp;
                        <span className="grotesk-regular">{list.priceVariance}%</span>
                    </div>
                )}
                {list.minPrice !== null && list.maxPrice !== null && (
                    <>
                        <div>
                            <span className="grotesk-bold">Min Price</span>
                            &nbsp;&nbsp;
                            <span className="grotesk-regular">${list.minPrice}</span>
                        </div>
                        <div>
                            <span className="grotesk-bold">Max Price</span>
                            &nbsp;&nbsp;
                            <span className="grotesk-regular">${list.maxPrice}</span>
                        </div>
                    </>
                )}
                {list.excludedSkus?.length > 0 && (
                    <ExcludedSkuList
                        listID={list.id}
                        excludedSkus={list.excludedSkus}
                        getList={refreshLists}
                        listName={list.name}
                    />
                )}
                &nbsp;&nbsp;
                {expanded && <PartnershipTabs tabs={tabs} />}
            </div>
            <div className="curated-list-divider"></div>

            <div
                className="fam-warning-expand-button grotesk-regular align-center"
                onClick={() => setExpanded(!expanded)}
            >
                {expanded ? 'Collapse' : 'Expand'}
            </div>
        </div>
    );
};

export { Lists };
