import React, { useEffect, useState } from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { TabPanel } from '../../../shared';
import { LotTab } from './locationInventoryV2Components/lotTab';
import { LocationTab } from './locationInventoryV2Components/locationTab';
import { SkuTab } from './locationInventoryV2Components/skuTab';
import { PalletTab } from './locationInventoryV2Components/palletTab';
import { useLocation, useHistory } from 'react-router-dom';
import { LocationInventorySearchBar } from './locationInventoryV2Components/locationInventorySearchBar';

const LocationInventoryV2 = () => {
    const location = useLocation();
    const history = useHistory();

    const getQueryParams = () => {
        const params = new URLSearchParams(location.search);
        return {
            searchTerm: params.get('searchTerm') || undefined,
            searchBy: params.get('searchBy') || undefined,
            type: params.get('type') || undefined,
        };
    };

    const getTabFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return Number(params.get('tab')) || 0;
    };

    const [selectedTab, setSelectedTab] = useState(getTabFromUrl());
    const [queryParams, setQueryParams] = useState(getQueryParams());

    useEffect(() => {
        const updatedParams = { tab: selectedTab, ...queryParams };
        const filteredParams = Object.fromEntries(
            Object.entries(updatedParams).filter(([_, value]) => value !== undefined && value !== '')
        );

        const searchParams = new URLSearchParams(filteredParams);
        history.replace(`?${searchParams.toString()}`);
    }, [selectedTab, history, queryParams]);

    const searchInventory = async (searchBy, searchTerm, type) => {
        setQueryParams({ searchTerm, searchBy, type });
    };

    const tabChange = (event, value) => {
        setSelectedTab(value);
    };

    const handleLinkClicked = (value) => {
        searchInventory(value.searchBy, value.searchTerm, value.type ?? null);
        tabChange(null, value.tabIndex);
    };

    return (
        <Paper style={{ minHeight: '80vh' }}>
            <LocationInventorySearchBar queryParams={queryParams} onSearch={searchInventory} />
            <Tabs onChange={tabChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="Lot_Tab" label="Lot" value={0} />
                <Tab id="Location_Tab" label="Location" value={1} />
                <Tab id="Sku_Tab" label="SKU" value={2} />
                <Tab id="Pallet_Tab" label="Pallet" value={3} />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={0}>
                <LotTab onLinkClicked={handleLinkClicked} queryParams={queryParams} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={1}>
                <LocationTab onLinkClicked={handleLinkClicked} queryParams={queryParams} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={2}>
                <SkuTab onLinkClicked={handleLinkClicked} queryParams={queryParams} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={3}>
                <PalletTab onLinkClicked={handleLinkClicked} queryParams={queryParams} />
            </TabPanel>
        </Paper>
    );
};
export { LocationInventoryV2 };
