import React, { useState, useEffect } from 'react';
import { Grid, Chip } from '@material-ui/core';
import { DataTable } from '../../../shared';
import { catalogApi as api } from '../../../api/catalog';

let CatalogSummary = ({ tags, restricted }) => {
    let [itemInformation, setItemInformation] = useState([]);

    let getItemInformation = async () => {
        let res = await api.getRestrictedItemInformation();
        if (res.status === true) {
            setItemInformation(
                res.data.items.map((item) => {
                    item.id = item.externalSku;
                    return item;
                })
            );
        }
    };

    let [taxonomy, setTaxonomy] = useState({ l1: [], l2: [], l3: [], l4: [] });
    let getTaxonomy = async () => {
        let promises = [1, 2, 3, 4].map((lvl) => api.getTaxonomyByLevel(lvl, 'AISLE'));
        let res = await Promise.all(promises);
        if (res.every((item) => item.status)) {
            setTaxonomy({
                l1: res[0].data.categories,
                l2: res[1].data.categories,
                l3: res[2].data.categories,
                l4: res[3].data.categories,
            });
        }
    };

    useEffect(() => {
        getItemInformation();
        getTaxonomy();
    }, []);

    let columns = [
        { accessor: 'name', Header: 'Item Name', editProps: { type: 'input', multiline: true } },
        { accessor: 'externalSku', Header: 'External Sku' },
        {
            accessor: 'description',
            Header: 'Description',
            editable: true,
            editProps: { type: 'input', multiline: true },
            width: '100px',
        },
        {
            accessor: 'photoURLSmall',
            width: '125px',
            Header: 'Product Photo',
            editable: true,
            Cell: ({ row, saveEdit, column }) => {
                if (row.original[column.id]) {
                    return (
                        <div>
                            <img
                                src={`https://static.misfitsmarket.com/${row.original[column.id]}`}
                                style={{ height: '100px', width: '100px' }}
                            />
                        </div>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            accessor: 'photoURLNutritionalInfo',
            width: '100px',
            Header: 'Nutrition',
            editable: true,
            Cell: ({ row, saveEdit, column }) => {
                if (row.original[column.id]) {
                    return (
                        <div>
                            <img
                                src={`https://static.misfitsmarket.com/${row.original[column.id]}`}
                                style={{ height: '100px', width: '100px' }}
                            />
                        </div>
                    );
                } else {
                    return null;
                }
            },
        },
        { accessor: 'allergens', Header: 'Allergens', editable: true, editProps: { type: 'input', multiline: true } },
        {
            accessor: 'crossAllergens',
            Header: 'Cross Allergens',
            editable: true,
            editProps: { type: 'input', multiline: true },
        },
        {
            accessor: 'ingredients',
            Header: 'Ingredients',
            width: '150px',
            editable: true,
            editProps: { type: 'input', multiline: true },
        },
        { accessor: 'brand', Header: 'Brand' },
        {
            accessor: 'tags',
            Header: 'Tags',
            filterValues: (row) => row.original.tags.map((tag) => tag.name),
            Cell: ({ row }) => {
                return (
                    <div>
                        <div style={{ textAlign: 'center' }}>
                            {row.original.tags.map((item, i) => (
                                <Chip
                                    size="small"
                                    key={item.id}
                                    label={item.name}
                                    color="primary"
                                    style={{ margin: '2px 1px' }}
                                />
                            ))}
                        </div>
                    </div>
                );
            },
        },
        {
            Header: 'Aisles',
            width: '200px',
            Cell: ({ row, saveEdit, column }) => {
                let aisles = row.original.aisles;

                return (
                    <div>
                        {aisles.map((item, index) => {
                            return (
                                <div key={`${index}`} style={{ paddingTop: '2px' }}>
                                    {!!item.l1AisleID && (
                                        <span style={{ display: 'inline' }}>
                                            {taxonomy?.l1?.find((tx) => tx.id === item.l1AisleID)?.name}
                                        </span>
                                    )}
                                    {!!item.l2AisleID && (
                                        <span style={{ display: 'inline' }}>
                                            {'-> '}
                                            {taxonomy?.l2?.find((tx) => tx.id === item.l2AisleID)?.name}
                                        </span>
                                    )}
                                    {!!item.l3AisleID && (
                                        <span style={{ display: 'inline' }}>
                                            {'-> '}
                                            {taxonomy?.l3?.find((tx) => tx.id === item.l3AisleID)?.name}
                                        </span>
                                    )}
                                    {!!item.l4AisleID && (
                                        <span style={{ display: 'inline' }}>
                                            {'-> '}
                                            {taxonomy?.l4?.find((tx) => tx.id === item.l4AisleID)?.name}
                                        </span>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
        { accessor: 'lOneTaxonomy', Header: 'L1 Taxonomy' },
        { accessor: 'lTwoTaxonomy', Header: 'L2 Taxonomy' },
        { accessor: 'lThreeTaxonomy', Header: 'L3 Taxonomy' },
        { accessor: 'lFourTaxonomy', Header: 'L4 Taxonomy' },
    ];

    return (
        <Grid container>
            <DataTable title="Catalog Summary" columns={columns} data={itemInformation} />
        </Grid>
    );
};

export { CatalogSummary };
