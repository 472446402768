import React, { useCallback, useEffect, useState, useContext } from 'react';
import { FBMOrdersV2Api } from '../../../../api/fbmOrdersV2';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { UserContext } from '../../../../contexts/UserContext';

const CancelFBMShipmentModal = ({ isOpen, onClose, onSuccess, onError, shipmentData }) => {
    const [cancelReason, setCancelReason] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { fbmAccount } = useContext(UserContext);
    const fbmOrdersV2Api = new FBMOrdersV2Api();

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('CancelFBMShipmentModal intialized incorrectly onSuccess is not a function');
        }
        if (!(onError instanceof Function)) {
            console.error('CancelFBMShipmentModal intialized incorrectly onError is not a function');
        }
        if (!(onClose instanceof Function)) {
            console.error('CancelFBMShipmentModal intialized incorrectly onClose is not a function');
        }
    }, [onClose, onSuccess, onError]);

    const handleCancelReasonChange = (event) => {
        setCancelReason(event.target.value);
    };

    const handleClose = () => {
        setCancelReason('');
        onClose();
    };

    const handleCancelShipment = useCallback(async () => {
        setIsLoading(true);
        if (!cancelReason.trim()) {
            onError('Please provide a reason for cancellation');
            return;
        }
        const response = await fbmOrdersV2Api.cancelShipment(
            shipmentData.shipmentId,
            fbmAccount.fbmAccountName,
            cancelReason
        );
        if (response.status) {
            onSuccess(`Shipment ${shipmentData.shipmentId} cancelled successfully`);
            handleClose();
        } else {
            onError(response.msg || 'Failed to cancel shipment');
        }
        setIsLoading(false);
    }, [
        cancelReason,
        fbmAccount.fbmAccountName,
        fbmOrdersV2Api,
        handleClose,
        onError,
        onSuccess,
        shipmentData?.shipmentId,
    ]);

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            disableScrollLock={true}
            hideBackdrop={false}
        >
            <DialogTitle>Cancel Shipment</DialogTitle>
            <DialogContent>
                {shipmentData && (
                    <div style={{ marginBottom: '20px' }}>
                        <Typography variant="subtitle1">
                            <strong>Shipment ID:</strong> {shipmentData.shipmentId}
                        </Typography>
                        <Typography variant="subtitle1">
                            <strong>Carrier:</strong> {shipmentData.carrier}
                        </Typography>
                        <Typography variant="subtitle1">
                            <strong>Status:</strong> {shipmentData.status}
                        </Typography>
                    </div>
                )}
                <Typography variant="body1" style={{ marginBottom: '10px' }}>
                    Please provide a reason for cancellation:
                </Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="cancelReason"
                    label="Cancellation Reason"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={cancelReason}
                    onChange={handleCancelReasonChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={handleCancelShipment}
                    color="primary"
                    variant="contained"
                    disabled={isLoading || !cancelReason.trim()}
                >
                    {isLoading ? 'Cancelling...' : 'Confirm Cancellation'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { CancelFBMShipmentModal };
