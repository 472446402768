import React, { useState, useEffect } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { goPuffApi } from '../../../api/goPuff';
import { doorDashApi } from '../../../api/doorDash';

let CreateWholesaleMFC = ({ stateOpts, mmFacilityOpts, wholesaleAccount }) => {
    let [error, setError] = useState(null);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [api, setApi] = useState(wholesaleAccount.fbmAccountName === 'doordash' ? doorDashApi : goPuffApi);
    let formFields = [
        { name: 'name', inputElement: 'textField', label: 'Name', gridValue: 12, inputProps: { required: true } },
        { name: 'lineOne', inputElement: 'textField', label: 'Line One', gridValue: 6, inputProps: { required: true } },
        { name: 'lineTwo', inputElement: 'textField', label: 'Line Two', gridValue: 6 },
        { name: 'city', inputElement: 'textField', label: 'City', gridValue: 4, inputProps: { required: true } },
        {
            name: 'state',
            inputElement: 'select',
            gridValue: 6,
            label: 'State',
            inputProps: {
                required: true,
                opts: stateOpts,
            },
        },
        { name: 'zip', inputElement: 'textField', label: 'Zip', gridValue: 4 },
        { name: 'phone', inputElement: 'textField', label: 'Phone', gridValue: 6 },
        {
            name: 'mmFacilityId',
            inputElement: 'select',
            gridValue: 4,
            label: 'MM Facility',
            inputProps: {
                required: true,
                opts: mmFacilityOpts,
            },
        },
    ];

    useEffect(() => {
        if (wholesaleAccount.fbmAccountName === 'doordash') {
            setApi(doorDashApi);
        } else {
            setApi(goPuffApi);
        }
    }, [wholesaleAccount]);

    let create = async (formData, resetForm) => {
        let response = await api.createMicroFulfillmentCenter(formData);
        if (response.status === true) {
            setNotification({ text: 'mFC created!', level: 'success' });
            resetForm();
        } else {
            let message = 'Error creating';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        }
    };

    return (
        <div>
            <h1>Create {wholesaleAccount.companyName} Micro Fulfillment Center</h1>
            <Notifications options={notification} />
            {}
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        create(formData, resetForm);
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </div>
    );
};

export { CreateWholesaleMFC };
