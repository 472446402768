import React, { useState, useEffect } from 'react';
import { productionApi as api } from '../../../api/production';
import { warehouseApi } from '../../../api/warehouse';
import { FormComponent } from '../../../shared/components/FormComponent/formComponent';
import { Button, Select, InputLabel, FormControl, Grid } from '@material-ui/core';
import { Notifications } from '../../../shared/components/Notifications';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

let PackGroups = () => {
    let [week, setWeek] = useState('');
    let [year, setYear] = useState('');
    let [upcomingWeek, setUpcomingWeek] = useState(false);
    let [packGroups, setPackGroups] = useState([]);
    let [assignments, setAssignments] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [upcomingWeekPackGroups, setUpcomingWeekPackGroups] = useState([]);
    let [facilities, setFacilities] = useState([]);

    let arrivalDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    let getData = async (newWeek, newYear) => {
        let res = await api.getPackGroups({
            week: newWeek || week,
            year: newYear || year,
        });
        let assignments = await api.getPackGroupAssignments(newWeek || week, newYear || year);
        if (res.status === true) {
            let data = res.data;
            setUpcomingWeek(data.upcomingWeek);
            if (data.upcomingWeek === true) {
                setUpcomingWeekPackGroups(data.packGroups);
            }
            setWeek(data.week);
            setYear(data.year);
            setPackGroups(data.packGroups || []);
        }

        if (assignments.status === true) {
            let assignmentsByFacility = {};
            assignments.data.assignments.forEach((item) => {
                if (!assignmentsByFacility[item.facilityID]) {
                    assignmentsByFacility[item.facilityID] = {
                        facilityName: item.facilityName,
                        facilityID: item.facilityID,
                        assignments: [],
                        inventoryReleased: false,
                    };
                }
                assignmentsByFacility[item.facilityID].assignments.push(item);
                if (item.inventoryReleased) {
                    assignmentsByFacility[item.facilityID].inventoryReleased = true;
                }
            });
            setAssignments(Object.values(assignmentsByFacility));
        }
    };

    let getFacilities = async () => {
        let res = await warehouseApi.getFacilities(true);
        if (!res.status) {
            setNotification({ text: 'Failed to get facilities', level: 'error' });
            return;
        }

        let facilitiesAsOpts = res.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setFacilities(facilitiesAsOpts);
    };

    useEffect(() => {
        getData();
        getFacilities();
    }, []);

    let formFields = [
        {
            name: 'week',
            initialValue: week,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Week',
            inputProps: { required: true, type: 'number', max: '53' },
        },
        {
            name: 'year',
            initialValue: year,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Year',
            inputProps: { required: true, type: 'number', maxLength: '4' },
        },
    ];

    return (
        <div>
            <Notifications options={notification} />
            <h2>Pack Groups</h2>

            {week && year && (
                <FormComponent
                    formFields={formFields}
                    button={{ gridValue: 2, text: 'Set Week and Year' }}
                    onSubmit={async (formData) => {
                        getData(formData.week, formData.year);
                    }}
                />
            )}

            <br />
            <br />
            {upcomingWeek === true && (
                <FormComponent
                    formFields={[
                        {
                            name: 'name',
                            inputElement: 'textField',
                            gridValue: 5,
                            label: 'Pack Group Name',
                            inputProps: { required: true },
                        },
                        {
                            name: 'facilityID',
                            inputElement: 'select',
                            gridValue: 4,
                            label: 'Facility',
                            inputProps: { opts: facilities, required: true },
                        },
                    ]}
                    button={{ gridValue: 3, text: 'Add Pack Group' }}
                    onSubmit={async (formData, resetForm) => {
                        let res = await api.makePackGroup({ name: formData.name, facilityID: formData.facilityID });
                        if (res.status === true) {
                            setNotification({ text: 'Pack Group Added', level: 'success' });
                            getData();
                            resetForm();
                        }
                    }}
                />
            )}
            <Grid container>
                {assignments.map((assignment) => (
                    <Grid item xs={6} key={assignment.facilityID}>
                        <h3>{assignment.facilityName}</h3>
                        {arrivalDays.map((day) => {
                            let pg = assignment.assignments.find((item) => item.arrivalDay === day);
                            if (!pg) {
                                return null;
                            }
                            return (
                                <Grid item xs={12} container key={day}>
                                    <Grid item xs={3}>
                                        <h3>{day}</h3>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl>
                                            <InputLabel shrink={true}>Pack Group</InputLabel>
                                            <Select
                                                disabled={!upcomingWeek || assignment.inventoryReleased}
                                                label="Pack Group"
                                                value={pg?.packGroupID || ''}
                                                onChange={async (event) => {
                                                    let result = await api.updatePackGroupAssignment({
                                                        arrivalDay: pg.arrivalDay,
                                                        facilityID: pg.facilityID,
                                                        packGroupID: event.target.value,
                                                    });
                                                    if (!result.status) {
                                                        setNotification({
                                                            text: 'Failed to update pack group assignment',
                                                            level: 'error',
                                                        });
                                                        return;
                                                    }
                                                    setNotification({
                                                        text: 'Pack group assignment updated',
                                                        level: 'success',
                                                    });
                                                    getData();
                                                }}
                                                native
                                            >
                                                <option disabled value="">
                                                    Unassigned
                                                </option>
                                                {packGroups
                                                    .filter((group) => group.facilityID === assignment.facilityID)
                                                    .map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export { PackGroups };
