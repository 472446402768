import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab, Button } from '@material-ui/core';
import { FormComponent, Notifications, DataTable, TabPanel } from '../../../shared';
import { warehouseApi as api } from '../../../api/warehouse';
import { procurementApi } from '../../../api/procurement';
import { UserContext } from '../../../contexts/UserContext';
import { ViewPO } from '../../procurement';

const InternalTransfers = () => {
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [selectedTab, setSelectedTab] = useState('0');
    const handleChange = (event, value) => {
        setSelectedTab(value);
    };
    return (
        <>
            <Notifications options={notification} />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="Create Transfer" value="0" />
                <Tab label="Transfer ASNs" value="1" />
                <Tab label="Inventory To Transfer" value="2" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <CreateTransferASN setNotification={setNotification} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <ViewPO internalTransfers={true} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <InventoryToTransfer setNotification={setNotification} />
            </TabPanel>
        </>
    );
};

const InventoryToTransfer = ({ setNotification }) => {
    const user = useContext(UserContext);
    const [rows, setRows] = useState([]);
    const [facilities, setFacilities] = useState([]);

    const getFacilities = async () => {
        let response = await api.getFacilities(true);
        if (response.status === false) {
            return;
        }

        let facilitiesAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        facilitiesAsOpts.unshift({ text: 'Please Select', value: 0 });

        setFacilities(facilitiesAsOpts);
    };

    const columns = [
        { Header: 'ASN #', accessor: 'poID' },
        { Header: 'PO Status', accessor: 'status' },
        {
            Header: 'Destination Facility',
            accessor: 'destinationFacilityID',
            editable: false,
            editProps: {
                type: 'select',
                options: facilities,
            },
        },
        { Header: 'Pick Up Date', accessor: 'pickUpDate' },
        { Header: 'Lot', accessor: 'lotID' },
        { Header: 'Name', accessor: 'name' },
        { Header: 'External Sku', accessor: 'externalSku' },
        { Header: 'Item Master Sku', accessor: 'itemMasterSku' },
        {
            Header: 'Cases In Transfer Locations',
            Cell: ({ row }) => {
                return (
                    <div>
                        {row.original.numCasesInTransferLocations}/{row.original.numCasesOnASN}
                    </div>
                );
            },
        },
        { Header: 'Cases Left To Move', accessor: 'numCasesToMove' },
        {
            Header: 'Case Locations',
            Cell: ({ row }) => {
                return (
                    <>
                        {row.original.locationInventory.map((item) => {
                            return (
                                <div key={item.name}>
                                    <span style={{ fontWeight: 'bold' }}>{item.name}</span>: {item.numCases} cases
                                </div>
                            );
                        })}
                    </>
                );
            },
        },
    ];

    const getTransferInventory = async () => {
        let res = await api.getInternalTransferInventory(user.getFacilityID());
        if (!res.status) {
            setNotification({ text: 'Failed to get transfer inventory', level: 'error' });
            setRows([]);
        }
        setRows(res.data.rows);
    };

    useEffect(() => {
        getFacilities();
        getTransferInventory();
    }, []);

    return (
        <div>
            <DataTable columns={columns} data={rows} />
        </div>
    );
};

const CreateTransferASN = ({ setNotification }) => {
    const user = useContext(UserContext);

    const [buyers, setBuyers] = useState([]);
    const [facilities, setFacilities] = useState([]);

    useEffect(() => {
        getBuyers();
        getFacilities();
    }, []);

    const getBuyers = async () => {
        const response = await procurementApi.getBuyers();
        if (response.status === false) {
            setNotification({ text: 'Failed to get buyers: ' + response.msg, level: 'error' });
            setBuyers([]);
            return;
        }

        const buyerOpts = response.data.rows.map((row) => {
            return { text: row.firstName + ' ' + row.lastName, value: row.id };
        });
        setBuyers(buyerOpts);
    };

    const getFacilities = async () => {
        let response = await api.getFacilities(true);
        if (!response.status) {
            setNotification({ text: 'Failed to get facilities', level: 'error' });
            return;
        }

        let facilitiesAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setFacilities(facilitiesAsOpts);
    };

    const createInternalTransferASN = async (formData, resetForm) => {
        console.log(formData);
        if (formData.itemsInPO.length === 0) {
            setNotification({ text: 'Must provide at least 1 item', level: 'error' });
            return;
        }

        for (let item of formData.itemsInPO) {
            if (!item.numCases || !item.week || !item.numPallets) {
                setNotification({ text: 'Please complete all required item fields', level: 'error' });
                return;
            }
        }

        let sendParams = {
            pickUpDate: formData.pickUpDate,
            deliverDate: formData.scheduledDeliveryDate,
            deliverTime: formData.scheduledTime,
            specialInstructions: formData.specialInstr,
            originFacilityID: formData.originFacilityID,
            destinationFacilityID: formData.destinationFacilityID,
            items: formData.itemsInPO,
            buyers: formData.buyers.map((item) => item.value),
            minTempReq: formData.minTempReq,
            maxTempReq: formData.maxTempReq,
            pickupNumber: formData.pickupNumber,
            priority: formData.priority === 'YES' ? true : false,
        };

        let response = await api.createInternalTransferASN(sendParams);
        if (response.status === false) {
            let message = 'Error creating ASN';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        } else {
            setNotification({ text: 'Transfer ASN created!', level: 'success' });
            resetForm();
        }
    };

    // Define the form
    const formFields = [
        { name: 'pickUpDate', inputElement: 'date', disableTextInput: true, label: 'Pick Up Date', gridValue: 3 },
        {
            name: 'scheduledDeliveryDate',
            inputElement: 'date',
            disableTextInput: true,
            label: 'Scheduled Delivery Date',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'scheduledTime',
            inputElement: 'time',
            label: 'Scheduled delivery time',
            gridValue: 2,
            inputProps: { required: true },
        },
        {
            name: 'minTempReq',
            inputElement: 'textField',
            label: 'Min Temperature',
            gridValue: 2,
            inputProps: { type: 'number', integer: true },
        },
        {
            name: 'maxTempReq',
            inputElement: 'textField',
            label: 'Max Temperature',
            gridValue: 2,
            inputProps: { type: 'number', integer: true },
        },

        {
            name: 'originFacilityID',
            inputElement: 'select',
            label: 'Origin Facility',
            gridValue: 3,
            inputProps: { required: true, opts: facilities },
        },
        {
            name: 'destinationFacilityID',
            inputElement: 'select',
            label: 'Destination Facility',
            gridValue: 3,
            inputProps: { required: true, opts: facilities },
        },
        {
            name: 'buyers',
            inputElement: 'autocomplete',
            label: 'Procurement Stakeholder',
            gridValue: 4,
            inputProps: { required: true, multiple: true, opts: buyers },
        },
        { name: 'pickupNumber', inputElement: 'textField', label: 'Pick Up #', gridValue: 2 },

        { name: 'specialInstr', inputElement: 'textField', label: 'Special Instructions', gridValue: 9 },
        {
            name: 'priority',
            inputElement: 'select',
            label: 'Priority',
            gridValue: 3,
            inputProps: { required: true, opts: ['YES', 'NO'] },
        },
        {
            name: 'itemsInPO',
            label: 'Items in ASN',
            inputElement: ({ formData, setFormField }) => (
                <AddLotsToTransferASN
                    setNotification={setNotification}
                    formData={formData}
                    setFormField={setFormField}
                />
            ),
        },
    ];

    return (
        <>
            <FormComponent
                formFields={formFields}
                onSubmit={async (formData, resetForm) => {
                    createInternalTransferASN(formData, resetForm);
                }}
            />
        </>
    );
};

const AddLotsToTransferASN = ({ setNotification, formData, setFormField }) => {
    const user = useContext(UserContext);
    const [lots, setLots] = useState([]);
    const [itemsInPO, setItemsInPO] = useState([]);
    const [companySource, setCompanySource] = useState(null);

    useEffect(() => {
        setFormField(itemsInPO);
    }, [itemsInPO]);

    useEffect(() => {
        if (!!formData.originFacilityID) {
            getLotsAvailableToTransfer(formData.originFacilityID);
        }
    }, [formData.originFacilityID]);

    const clearItems = () => {
        setItemsInPO([]);
    };

    useEffect(() => {
        // reset form (after submit) clears formData.itemsInPO, but does not reset internal itemsInPO
        // we need to do this manually when the form data has been cleared and there is no initial value
        if (itemsInPO.length && !formData.itemsInPO) {
            clearItems();
            setCompanySource(null);
        }
    }, [formData]);

    // Add item submit function
    const addItemSubmit = (formData) => {
        const lot = lots.find((item) => item.lotID == formData.lotID);
        if (!lot) {
            setNotification({ text: 'Invalid lot', level: 'error' });
            return false;
        }

        if (formData.numCases > lot.numAvailableCases) {
            setNotification({
                text: `Lot ${lot.lotID} only has ${lot.numAvailableCases} available cases for transfer`,
                level: 'warning',
            });
            return false;
        }

        if (!!itemsInPO.find((itemInPO) => itemInPO.lotID === lot.lotID)) {
            setNotification({
                text: `Lot ${lot.lotID} is already on this transfer ASN.`,
                level: 'warning',
            });
            return false;
        }

        if (companySource !== null && lot.companySource !== companySource) {
            setNotification({
                text: `Cannot add ${lot.companySource} item to this ASN`,
                level: 'error',
            });
            return false;
        }

        const item = {
            lotID: lot.lotID,
            externalSku: lot.externalSku,
            itemMasterSku: lot.itemMasterSku,
            name: lot.itemNameInternal,
            numCases: formData.numCases,
            numPallets: formData.numPallets,
            expirationDate: lot.expiresOn,
            unitPrice: lot.unitPrice,
            suggestedRetailPrice: lot.suggestedRetailPrice,
            purchaseType: lot.purchaseType,
            vendorSku: lot.vendorSku,
            week: formData.week,
        };

        const items = itemsInPO.concat(item);
        setCompanySource(lot.companySource);
        setItemsInPO(items);
        return true;
    };

    // Remove an item from the PO
    const removeItemPressed = async (row) => {
        var array = [...itemsInPO];
        array.splice(row.index, 1);
        setItemsInPO(array);
    };

    const getLotsAvailableToTransfer = async (facilityID) => {
        const response = await api.getLotsAvailableForTransfer(facilityID);
        if (response.status === false) {
            setNotification({ text: 'Failed to get lots available for transfer: ' + response.msg, level: 'error' });
            setLots([]);
            return;
        }

        const data = response.data.rows
            .filter((row) => row.externalSku < 200000)
            .map((row) => {
                row.text = `Lot ${row.lotID} - Ext Sku ${row.externalSku} - ${row.itemNameInternal} - ${row.numAvailableCases} Cases - Exp ${row.expiresOn}`;
                row.value = row.lotID;
                return row;
            });

        setLots(data);
    };

    const itemFormFields = [
        {
            name: 'lotID',
            inputElement: 'autocomplete',
            label: 'Lot',
            gridValue: 12,
            inputProps: {
                required: true,
                opts: lots.filter((item) => companySource === null || item.companySource === companySource),
            },
        },
        {
            name: 'numCases',
            inputElement: 'textField',
            label: 'Case Quantity',
            gridValue: 4,
            inputProps: { type: 'number', integer: true, required: true },
        },
        {
            name: 'numPallets',
            inputElement: 'textField',
            label: 'Pallet Quantity',
            gridValue: 4,
            inputProps: { type: 'number', integer: true, required: true },
        },
        { name: 'week', inputElement: 'textField', label: 'Week number', gridValue: 4, inputProps: { required: true } },
    ];

    const addedItemColumns = [
        { accessor: 'lotID', Header: 'Lot' },
        { accessor: 'externalSku', Header: 'External Sku' },
        { accessor: 'itemMasterSku', Header: 'Item Master Sku' },
        { accessor: 'name', Header: 'Name' },
        {
            accessor: 'numCases',
            Header: 'Case Quantity',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: true },
        },
        {
            accessor: 'numPallets',
            Header: 'Pallet Quantity',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: true },
        },
        {
            accessor: 'csPallet',
            Header: 'Cases per pallet',
            Cell: ({ row }) => {
                return <div>{Math.ceil(row.original.numCases / row.original.numPallets)}</div>;
            },
        },

        { accessor: 'expirationDate', Header: 'Expiration Date' },
        { accessor: 'unitPrice', Header: 'Case Price' },
        { accessor: 'suggestedRetailPrice', Header: 'SRP' },
        { accessor: 'purchaseType', Header: 'Purchase Type' },
        { accessor: 'vendorSku', Header: 'Vendor Sku' },
        {
            accessor: 'week',
            Header: 'Week Number',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: true },
        },

        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Remove_${row.id}`}
                        onClick={() => {
                            removeItemPressed(row);
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <h3>Lots To Transfer</h3>
            <FormComponent
                formFields={itemFormFields}
                onSubmit={(formData, resetForm) => {
                    if (addItemSubmit(formData) === true) {
                        resetForm();
                    }
                }}
                button={{
                    text: 'Add',
                    gridValue: 1,
                    disabled: (formData) => {
                        if (!formData.lotID) {
                            return true;
                        }
                        return false;
                    },
                }}
            />
            <DataTable
                columns={addedItemColumns}
                data={itemsInPO}
                editable={true}
                saveEdit={({ id, field, value, row }) => {
                    const items = JSON.parse(JSON.stringify(itemsInPO)).map((item) => {
                        if (item.lotID === row.lotID) {
                            if (field === 'qty') {
                                item.itemTotal = '$' + item.unitPrice * value;
                            }
                            if (field === 'unitPrice') {
                                item.itemTotal = '$' + item.numCases * value;
                            }
                            item[field] = value;
                        }
                        return item;
                    });
                    setItemsInPO(items);
                }}
            />
        </div>
    );
};

export { InternalTransfers };
