import React, { useState, useEffect, useContext } from 'react';
import { productionApi as api } from '../../../api/production';
import { pricingApi } from '../../../api/pricing';
import { DataTable, FormComponent, Notifications, TabPanel, UploadCSVAndReviewComponent } from '../../../shared';
import { Tabs, Tab, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';

const UploadModal = function ({ packGroup, zone, closeModal, getData, rows }) {
    let columns = [
        { Header: 'Sellable Item ID', accessor: 'sellableItemID' },
        { Header: 'External Sku', accessor: 'externalSku' },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Price', accessor: 'price' },
        { Header: 'Errors', accessor: 'errors' },
    ];

    const upload = async (formData) => {
        const res = await pricingApi.uploadSellableItemPrices(packGroup.id, zone.id, formData);
        return res;
    };

    const confirm = async (key) => {
        const res = await pricingApi.confirmSellableItemPrices(key);
        return res;
    };

    const downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,Sellable Item ID,External Sku,Name,Price';
        csvContent += '\r\n';

        for (let row of rows) {
            csvContent += `${row.id},${row.externalSku},"${row.name}",${row.prices[zone.id]}`;
            csvContent += '\r\n';
        }
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${zone.name}_prices_week_${packGroup.week}_${packGroup.year}.csv`);
        document.body.appendChild(link);

        link.click();
    };

    return (
        <Dialog open={true} onClose={closeModal} fullWidth={true} maxWidth="lg">
            <DialogTitle>
                Upload Prices for Pricing Zone {zone.name}, Pack Group {packGroup.name}, Week {packGroup.week}{' '}
                {packGroup.year}
            </DialogTitle>
            <DialogContent>
                <UploadCSVAndReviewComponent
                    fileLabel="File"
                    columns={columns}
                    upload={upload}
                    confirm={confirm}
                    downloadTemplate={downloadTemplate}
                    downloadTemplateText="Download current data for zone"
                    onSuccess={() => {
                        getData();
                        closeModal();
                    }}
                />
                <br />
                <br />
            </DialogContent>
        </Dialog>
    );
};

const SellableItemPrices = function () {
    const [year, setYear] = useState('');
    const [week, setWeek] = useState('');
    const [selectedTab, setSelectedTab] = useState('');
    const [packGroup, setPackGroup] = useState(null);
    const [zones, setZones] = useState([]);
    const [items, setItems] = useState([]);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    let [notification, setNotification] = useState({ text: null, level: null });
    let user = useContext(UserContext);

    let getData = async ({ newWeek, newYear, packGroupID }) => {
        let packGroupsRes = await api.getPackGroups({
            facilityID: user.getFacilityID(),
            week: newWeek || week,
            year: newYear || year,
        });

        if (packGroupsRes.status === false) {
            return;
        }

        setWeek(packGroupsRes.data.week);
        setYear(packGroupsRes.data.year);

        if (!packGroupsRes.data.packGroups.length) {
            return;
        }

        const packGroup = packGroupsRes.data.packGroups[0];
        const pricesRes = await pricingApi.getSellableItemPrices(packGroup.id);
        if (!pricesRes.status) {
            setNotification({ text: 'Failed to get prices', level: 'error' });
            return;
        }

        packGroup.upcomingWeek = packGroupsRes.data.upcomingWeek;
        setPackGroup(packGroup);

        setZones(pricesRes.data.zones);
        setItems(pricesRes.data.items);
        setSelectedTab('0');
    };

    useEffect(() => {
        getData({});
    }, []);

    let formFields = [
        {
            name: 'week',
            initialValue: week,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Week',
            inputProps: { required: true, type: 'number', max: '53' },
        },
        {
            name: 'year',
            initialValue: year,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Year',
            inputProps: { required: true, type: 'number', maxLength: '4' },
        },
    ];

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    let toolbarActions = [
        {
            name: 'Set Prices',
            action: () => {
                setUploadModalOpen(true);
            },
        },
    ];

    return (
        <div>
            <Notifications options={notification} />
            {uploadModalOpen && (
                <UploadModal
                    rows={items}
                    packGroup={packGroup}
                    zone={zones[selectedTab]}
                    closeModal={() => {
                        setUploadModalOpen(false);
                    }}
                    getData={() => {
                        getData({});
                    }}
                    setNotification={setNotification}
                />
            )}
            <h2>
                Sellable Item Prices - Week {week}, {year} - Pack Group {packGroup?.name}
            </h2>
            {week && year && (
                <FormComponent
                    formFields={formFields}
                    button={{ gridValue: 2, text: 'Change Week/Year' }}
                    onSubmit={async (formData) => {
                        await getData({ newWeek: formData.week, newYear: formData.year });
                    }}
                />
            )}
            <br />
            <br />
            <div>
                {!!packGroup && selectedTab ? (
                    <div>
                        <Tabs
                            onChange={handleChange}
                            value={selectedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            {zones.map((zone, index) => (
                                <Tab key={zone.id} label={zone.name} value={`${index}`} />
                            ))}
                        </Tabs>
                        {zones.map((zone, index) => {
                            const priceAccessor = `prices.${zone.id}`;
                            return (
                                <TabPanel selectedTab={selectedTab} key={zone.id} index={`${index}`}>
                                    <div>
                                        <DataTable
                                            toolbarActions={
                                                zone.id !== 'control' /*&& packGroup.upcomingWeek*/
                                                    ? toolbarActions
                                                    : []
                                            }
                                            csvExport={zone.id === 'control'}
                                            data={items}
                                            columns={[
                                                { accessor: 'id', Header: 'Sellable Item ID' },
                                                { accessor: 'externalSku', Header: 'External Sku' },
                                                { accessor: 'name', Header: 'Name' },
                                                { accessor: priceAccessor, Header: 'Price' },
                                            ]}
                                        />
                                    </div>
                                </TabPanel>
                            );
                        })}
                    </div>
                ) : (
                    <div>No sellable item data found for this week.</div>
                )}
            </div>
        </div>
    );
};

export { SellableItemPrices };
