import React, { useCallback, useEffect, useState, useContext } from 'react';
import { FBMOrdersV2Api } from '../../../../api/fbmOrdersV2';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { UserContext } from '../../../../contexts/UserContext';
import { useGetTableData } from '../../hooks/useGetTableData';

const ChangeFBMShipmentCarrierServiceModal = ({ isOpen, onClose, onSuccess, onError, shipmentData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCarrier, setSelectedCarrier] = useState('');
    const [selectedServiceLevel, setSelectedServiceLevel] = useState('');
    const [carrierServiceMap, setCarrierServiceMap] = useState({});
    const { fbmAccount, user } = useContext(UserContext);
    const fbmOrdersV2Api = new FBMOrdersV2Api();

    const fetchCarrierServices = useCallback(() => {
        // For now we are only allowing fedex carrier services
        return fbmOrdersV2Api.getCarrierServices('fedex');
    }, []);

    const {
        data: carrierServices,
        isLoading: carrierServicesLoading,
        error: carrierServicesError,
    } = useGetTableData(fetchCarrierServices);

    useEffect(() => {
        if (carrierServices) {
            // Organize carrier services into a map of carrier -> service levels
            const serviceMap = carrierServices.reduce((acc, service) => {
                if (!acc[service.carrier]) {
                    acc[service.carrier] = [];
                }
                acc[service.carrier].push({
                    id: service.id,
                    serviceLevel: service.serviceLevel,
                    isDefault: service.isDefault,
                });
                return acc;
            }, {});
            setCarrierServiceMap(serviceMap);
        }
    }, [carrierServices]);

    // Reset service level when carrier changes
    useEffect(() => {
        setSelectedServiceLevel('');
    }, [selectedCarrier]);

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('CancelFBMShipmentModal intialized incorrectly onSuccess is not a function');
        }
        if (!(onError instanceof Function)) {
            console.error('CancelFBMShipmentModal intialized incorrectly onError is not a function');
        }
        if (!(onClose instanceof Function)) {
            console.error('CancelFBMShipmentModal intialized incorrectly onClose is not a function');
        }
    }, [onClose, onSuccess, onError]);

    const handleClose = () => {
        onClose();
    };

    const handleChangeCarrierService = useCallback(async () => {
        setIsLoading(true);
        const response = await fbmOrdersV2Api.changeShipmentCarrierService(
            shipmentData.shipmentId,
            fbmAccount.fbmAccountName,
            selectedServiceLevel,
            user.facilityID
        );
        if (response.status) {
            onSuccess(`Shipment ${shipmentData.shipmentId} carrier service changed successfully`);
            handleClose();
        } else {
            onError(response.msg || 'Failed to change carrier service');
        }
        setIsLoading(false);
    }, [
        fbmAccount.fbmAccountName,
        fbmOrdersV2Api,
        handleClose,
        onError,
        onSuccess,
        shipmentData?.shipmentId,
        selectedServiceLevel,
        user.facilityID,
    ]);

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            disableScrollLock={true}
            hideBackdrop={false}
        >
            <DialogTitle>Change Carrier Service</DialogTitle>
            <DialogContent>
                {shipmentData && (
                    <div style={{ marginBottom: '20px' }}>
                        <Typography variant="subtitle1">
                            <strong>Shipment ID:</strong> {shipmentData.shipmentId}
                        </Typography>
                        <Typography variant="subtitle1">
                            <strong>Carrier:</strong> {shipmentData.carrier}
                        </Typography>
                        <Typography variant="subtitle1">
                            <strong>Status:</strong> {shipmentData.status}
                        </Typography>
                    </div>
                )}
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Carrier</InputLabel>
                    <Select
                        value={selectedCarrier}
                        onChange={(e) => setSelectedCarrier(e.target.value)}
                        label="Carrier"
                    >
                        {Object.keys(carrierServiceMap).map((carrier) => (
                            <MenuItem key={carrier} value={carrier}>
                                {carrier.toUpperCase()}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel>Service Level</InputLabel>
                    <Select
                        value={selectedServiceLevel}
                        onChange={(e) => setSelectedServiceLevel(e.target.value)}
                        label="Service Level"
                        disabled={!selectedCarrier}
                    >
                        {selectedCarrier &&
                            carrierServiceMap[selectedCarrier]?.map((service) => (
                                <MenuItem key={service.id} value={service.id}>
                                    {service.serviceLevel}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleChangeCarrierService} color="primary" variant="contained" disabled={isLoading}>
                    {isLoading ? 'Changing...' : 'Confirm Service Change'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { ChangeFBMShipmentCarrierServiceModal };
