import React, { useCallback, useEffect, useState, useContext } from 'react';
import { FBMOrdersV2Api } from '../../../../api/fbmOrdersV2';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { UserContext } from '../../../../contexts/UserContext';

const ResetFBMShipmentStatusModal = ({ isOpen, onClose, onSuccess, onError, shipmentData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { fbmAccount, user } = useContext(UserContext);
    const fbmOrdersV2Api = new FBMOrdersV2Api();

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('ResetFBMShipmentStatusModal intialized incorrectly onSuccess is not a function');
        }
        if (!(onError instanceof Function)) {
            console.error('ResetFBMShipmentStatusModal intialized incorrectly onError is not a function');
        }
        if (!(onClose instanceof Function)) {
            console.error('ResetFBMShipmentStatusModal intialized incorrectly onClose is not a function');
        }
    }, [onClose, onSuccess, onError]);

    const handleClose = () => {
        onClose();
    };

    const handleResetStatus = useCallback(async () => {
        setIsLoading(true);
        const response = await fbmOrdersV2Api.resetShipmentStatus(shipmentData.shipmentId, fbmAccount.fbmAccountName);
        if (response.status) {
            onSuccess(`Shipment ${shipmentData.shipmentId} reset successfully`);
            handleClose();
        } else {
            onError(response.msg || 'Failed to reset status');
        }
        setIsLoading(false);
    }, [fbmAccount.fbmAccountName, fbmOrdersV2Api, handleClose, onError, onSuccess, shipmentData?.shipmentId]);

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            disableScrollLock={true}
            hideBackdrop={false}
        >
            <DialogTitle>Reset Shipment Status</DialogTitle>
            <DialogContent>
                {shipmentData && (
                    <div style={{ marginBottom: '20px' }}>
                        <Typography variant="subtitle1">
                            <strong>Shipment ID:</strong> {shipmentData.shipmentId}
                        </Typography>
                        <Typography variant="subtitle1">
                            <strong>Carrier:</strong> {shipmentData.carrier}
                        </Typography>
                        <Typography variant="subtitle1">
                            <strong>Status:</strong> {shipmentData.status}
                        </Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleResetStatus} color="primary" variant="contained" disabled={isLoading}>
                    {isLoading ? 'Resetting...' : 'Confirm Status Reset'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { ResetFBMShipmentStatusModal };
