import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Box,
    LinearProgress
} from '@material-ui/core';
import { FilterMenuControl } from './filterComponent/filterMenuControl';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';

const warehouseApi = new WarehouseInventoryV2Api();

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    groupByContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
    },
    formControl: {
        minWidth: 200,
    },
    loadingContainer: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
    }
}));

const FilterAndGroupByContainer = ({ onGroupChange, facilityId }) => {
    const classes = useStyles();
    const [groupBy, setGroupBy] = useState('sku');
    const [activeFilters, setActiveFilters] = useState([]);
    const [sourceOptions, setSourceOptions] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);

    useEffect(() => {
        const fetchSources = async () => {
            if (!facilityId) return;
            
            setLoadingOptions(true);
            try {
                const response = await warehouseApi.getInventoryOptions(facilityId);
                setSourceOptions(response.data.companySources || []);
            } catch (error) {
                console.error('Error fetching sources:', error);
                setSourceOptions([]);
            } finally {
                setLoadingOptions(false);
            }
        };

        fetchSources();
    }, [facilityId]);

    const INVENTORY_HISTORY_FILTERS = [
        {
            name: 'Date Range',
            type: 'dateRange',
            props: {
                includeTime: true
            },
            deletable: false
        },
        {
            name: 'Event Types',
            type: 'multiCheck',
            props: {
                options: [
                    'Moved Event',
                    'Inventory Adjusted Event',
                    'Packout Event',
                    'Received Event',
                    'Expiration Date Changed',
                    'QC Hold Status Change',
                    'Cycle Count Event'
                ],
                label: 'Event Types'
            },
            deletable: true
        },
        {
            name: 'SKU',
            type: 'textInput',
            props: {
                placeholder: 'Enter SKU...',
                formatValue: (value) => value.toUpperCase(),
                errorMessage: 'Please enter a valid SKU',
                multiple: true
            },
            deletable: true
        },
        {
            name: 'Lot',
            type: 'textInput',
            props: {
                placeholder: 'Enter Lot...',
                formatValue: (value) => value.toUpperCase(),
                errorMessage: 'Please enter a valid Lot',
                multiple: true
            },
            deletable: true
        },
        {
            name: 'Location',
            type: 'textInput',
            props: {
                placeholder: 'Enter Location...',
                formatValue: (value) => value.toUpperCase(),
                errorMessage: 'Please enter a valid Location',
                multiple: true
            },
            deletable: true
        },
        {
            name: 'Source',
            type: 'select',
            props: {
                options: sourceOptions,
                placeholder: 'Search sources...',
                loading: loadingOptions,
                multiple: true
            },
            deletable: true
        },
        {
            name: 'User',
            type: 'textInput',
            props: {
                placeholder: 'Enter User...',
                formatValue: (value) => value.toUpperCase(),
                errorMessage: 'Please enter a valid User',
                multiple: true
            },
            deletable: true
        },
        {
            name: 'Pallet ID',
            type: 'textInput',
            props: {
                placeholder: 'Enter Pallet ID...',
                formatValue: (value) => value.toUpperCase(),
                errorMessage: 'Please enter a valid Pallet ID',
                multiple: true
            },
            deletable: true
        }
    ];

    const handleGroupChange = (event) => {
        const value = event.target.value;
        setGroupBy(value);
        if (onGroupChange) {
            onGroupChange(value);
        }
    };

    const handleApplyFilters = (filters) => {
        setActiveFilters(filters);
    };

    const handleDeleteFilter = (filterToDelete) => {
        if (Array.isArray(filterToDelete)) {
            // Handle bulk deletion
            const filtersToKeep = activeFilters.filter(activeFilter => 
                !filterToDelete.some(filterToDelete => 
                    filterToDelete.filter === activeFilter.filter && 
                    filterToDelete.value === activeFilter.value
                )
            );
            setActiveFilters(filtersToKeep);
        } else {
            // Handle single deletion
            setActiveFilters(activeFilters.filter(filter =>
                !(filter.filter === filterToDelete.filter && filter.value === filterToDelete.value)
            ));
        }
    };

    return (
        <Box className={classes.container}>
            {loadingOptions ? (
                <Box className={classes.loadingContainer}>
                    <LinearProgress />
                </Box>
            ) : (
                <FilterMenuControl
                    filterConfigs={INVENTORY_HISTORY_FILTERS}
                    onApplyFilters={handleApplyFilters}
                    activeFilters={activeFilters}
                    onDeleteFilter={handleDeleteFilter}
                />
            )}

            <Box className={classes.groupByContainer}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="groupby-select-label">Group By</InputLabel>
                    <Select
                        labelId="groupby-select-label"
                        id="groupby-select"
                        value={groupBy}
                        onChange={handleGroupChange}
                    >
                        <MenuItem value="none">No Grouping</MenuItem>
                        <MenuItem value="sku">Group By Sku</MenuItem>
                        <MenuItem value="lot">Group By Lot</MenuItem>
                        <MenuItem value="location">Group By Location</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};

export { FilterAndGroupByContainer };
