import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { DataTable, Notifications } from '../../../../shared';
import { ActionsCell } from './ActionsCell';
import { LotTabExpansion } from './lotTabExpansion';
import { FormControlLabel, Button, CircularProgress, Checkbox } from '@material-ui/core';
import { StyledSwitch } from '../../../../shared/components/StyledSwitch';
import { ActionModal } from './ActionModal';
import { ChangeExpirationDateAction } from './actions/changeExpirationDateAction';
import { AdjustFromZeroAction } from './actions/adjustFromZeroAction';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';
import { BulkAdjustUnitsPerCaseAction } from './actions/bulkAdjustUnitsPerCaseAction';
import { PrintCaseLabelAction } from './actions/printCaseLabelAction';
import { QCInspectionAction } from './actions/qcInspectionAction';

const warehouseInventoryV2Api = new WarehouseInventoryV2Api();

const LotTab = ({ onLinkClicked, queryParams }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [rows, setRows] = useState([]);
    const [loadingLots, setLoadingLots] = useState(false);
    const [viewActions, setViewActions] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [activeLots, setActiveLots] = useState(true);
    const { user } = useContext(UserContext);
    const [actions, setActions] = useState([]);
    const [displayedType, setDisplayedType] = useState({ items: true, packaging: true });
    const [filteredRows, setFilteredRows] = useState([]);

    //search bar queryParams effect
    useEffect(() => {
        //TODO handle the filtering of the row data to match queryParams
        //TODO read and verify query params to pass to api call
        handleSearch();
    }, [queryParams, activeLots]);

    //filter actions based on selectedRow.type
    useEffect(() => {
        if (selectedRow?.active) {
            setActions(actionDefinitions.filter((action) => action.value !== 'adjustFromZero'));
        } else {
            setActions(actionDefinitions.filter((action) => action.value === 'adjustFromZero'));
        }
    }, [selectedRow]);

    useEffect(() => {
        setFilteredRows(rows);
    }, [rows]);

    useEffect(() => {
        if (displayedType.items && !displayedType.packaging) {
            setFilteredRows(rows.filter((row) => row.type !== 'PACKAGING'));
        } else if (!displayedType.items && displayedType.packaging) {
            setFilteredRows(rows.filter((row) => row.type !== 'ITEM'));
        } else if (!displayedType.items && !displayedType.packaging) {
            setFilteredRows([]);
        } else {
            setFilteredRows(rows);
        }
    }, [displayedType]);

    const handleSearch = async () => {
        //TODO handle the filtering of the row data to match queryPrams
        getLots();
    };

    //Column Definitions
    const columns = [
        { Header: 'Lot Id', accessor: 'lotId' },
        { Header: 'Partner Lot', accessor: 'partnerLotNumber' },
        { Header: 'Item Name', accessor: 'itemName' },
        {
            Header: 'External SKU',
            accessor: 'externalSku',
            Cell: ({ row }) => {
                return (
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => handleSkuLinkClick(row.original.externalSku, row.original.type)}
                    >
                        {row.original.externalSku}
                    </Button>
                );
            },
        },
        { Header: 'Partner SKU', accessor: 'partnerSku' },
        { Header: 'Expiration Date', accessor: 'expirationDate' },
        { Header: 'Pallets', accessor: 'pallets' },
        { Header: 'Units Per Case', accessor: 'unitsPerCase' },
        { Header: 'Cases', accessor: 'cases' },
        { Header: 'Loose Units', accessor: 'looseUnits' },
        { Header: 'Total Units', accessor: 'totalUnits' },
        { Header: 'Num Locations', accessor: 'numLocations' },
        { Header: 'PO', accessor: 'po' },
        { Header: 'Received Date', accessor: 'receivedDate' },
        { Header: 'Status', accessor: 'status' },
        {
            accessor: 'actions',
            Header: 'Actions',
            Cell: ({ row }) => (
                <ActionsCell row={row} setSelectedRow={setSelectedRow} setViewActions={setViewActions} />
            ),
        },
    ];

    //API call to GET data
    const getLots = async () => {
        setLoadingLots(true);
        const searchValues = [{ searchBy: queryParams.searchBy, searchTerm: queryParams.searchTerm }];
        if (queryParams.type) {
            searchValues.push({ searchBy: 'type', searchTerm: queryParams.type });
        }
        if (!activeLots) {
            searchValues.push({ searchBy: 'inactive', searchTerm: true });
        }
        const result = await warehouseInventoryV2Api.getLots(user.facilityID, searchValues);
        if (result.status) {
            setRows(
                result.data.lots.map((lot) => {
                    return {
                        ...lot,
                        looseUnits: lot.numLooseUnits,
                        cases: lot.numCases,
                        pallets: lot.numPallets,
                        po: lot.purchaseOrderId,
                        unitsPerCase: lot.unitsPerCase,
                        caseId: lot.caseId,
                        active: lot.active,
                    };
                })
            );
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
        setLoadingLots(false);
    };

    const handleSkuLinkClick = (externalSku, type) => {
        onLinkClicked({ tabIndex: 2, searchBy: 'externalSku', searchTerm: externalSku, type: type });
    };

    const handleOnRowClicked = (row) => {
        setSelectedRow(row.original);
    };

    const handleModalClose = () => {
        setViewActions(false);
    };

    const handleSuccess = (message = 'Success') => {
        setNotification({ text: message, level: 'success' });
        handleModalClose();
        getLots();
    };

    //Action Definitions
    const actionDefinitions = [
        {
            text: 'Bulk Adjust Units per Case',
            value: 'bulkAdjustUnitsPerCase',
            componentJSX: function () {
                return (
                    <BulkAdjustUnitsPerCaseAction
                        lotId={selectedRow?.lotId}
                        unitsPerCase={selectedRow?.unitsPerCase}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
        {
            text: 'Adjust From Zero',
            value: 'adjustFromZero',
            componentJSX: function () {
                return <AdjustFromZeroAction lotId={selectedRow?.lotId} onSuccess={handleSuccess} />;
            },
        },
        {
            text: 'Print Case Label',
            value: 'printCaseLabel',
            componentJSX: function () {
                return <PrintCaseLabelAction lotId={selectedRow?.lotId} onSuccess={handleSuccess} />;
            },
        },
        {
            text: 'Change Expiration Date',
            value: 'changeExpirationDate',
            componentJSX: function () {
                return (
                    <ChangeExpirationDateAction
                        lotId={selectedRow?.lotId}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
        {
            text: 'Request Inspection',
            value: 'requestInspection',
            componentJSX: function () {
                return (
                    <QCInspectionAction
                        requestInspection={true}
                        lotId={selectedRow?.lotId}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
        {
            text: 'Inspection Passed',
            value: 'inspectionPassed',
            componentJSX: function () {
                return (
                    <QCInspectionAction
                        requestInspection={false}
                        lotId={selectedRow?.lotId}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
    ];

    const handleActiveLotsChange = (event) => {
        setActiveLots(event.target.checked);
    };

    const handleDisplayTypeChange = (event) => {
        setDisplayedType({ ...displayedType, [event.target.name]: event.target.checked });
    };

    return (
        <>
            <Notifications options={notification} />
            <div>
                <FormControlLabel
                    control={<StyledSwitch checked={activeLots} onChange={handleActiveLotsChange} />}
                    label="Active Lots"
                    labelPlacement="end"
                />
            </div>
            {loadingLots && <CircularProgress color="inherit" size={48} />}
            {!loadingLots && (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox checked={displayedType.items} onChange={handleDisplayTypeChange} name="items" />
                        }
                        label="Finished Goods"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={displayedType.packaging}
                                onChange={handleDisplayTypeChange}
                                name="packaging"
                            />
                        }
                        label="Packaging"
                    />
                    <DataTable
                        csvExport={true}
                        csvExportFileName="lot_inventory"
                        columns={columns}
                        data={filteredRows}
                        editable={false}
                        tableContainerMaxHeight={800}
                        stickyHeader={true}
                        expandable={true}
                        ExpansionComponent={
                            <LotTabExpansion
                                selectedLotId={selectedRow?.lotId}
                                lotActive={selectedRow?.active}
                                onSuccess={handleSuccess}
                                onLinkClicked={onLinkClicked}
                            />
                        }
                        onRowClicked={handleOnRowClicked}
                        initialSortBy={[
                            {
                                id: 'expirationDate',
                                desc: false,
                            },
                        ]}
                    />
                </>
            )}
            <ActionModal
                title={`Actions for Lot ${selectedRow?.lotId}`}
                open={viewActions}
                row={selectedRow}
                actions={actions}
                closeModal={handleModalClose}
            />
        </>
    );
};

export { LotTab };
