import React, { useEffect, useState, useContext } from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DataTable } from '../../../../shared';
import { WarehouseLabelApi } from '../../../../api/warehouseLabels';
import printJS from 'print-js';
import { MovePalletAction } from './actions/movePalletAction';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';
import { UserContext } from '../../../../contexts/UserContext';

const labelApi = new WarehouseLabelApi();
const warehouseInventoryV2Api = new WarehouseInventoryV2Api();

const ViewPalletsModal = ({
    closeModal,
    open,
    locationName,
    lotId,
    locationId,
    setNotification,
    onMovePalletSuccess,
}) => {
    const [movingPalletId, setMovingPalletId] = useState(null);
    const [pallets, setPallets] = useState([]);
    const [loadingPallets, setLoadingPallets] = useState(false);
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (!(onMovePalletSuccess instanceof Function)) {
            console.error('ViewPalletsModal intialized incorrectly onMovePalletSuccess is not a function');
        }
    }, [onMovePalletSuccess]);

    const handleClose = () => {
        closeModal();
    };
    const cancelMovePallet = () => {
        setMovingPalletId(null);
    };

    let title = 'Pallets';
    if (lotId) {
        title += ` containing lot ${lotId}`;
    }
    if (locationName) {
        title += ` in location ${locationName}`;
    }

    const getPallets = async () => {
        setLoadingPallets(true);
        const res = await warehouseInventoryV2Api.getPallets(user.facilityID, [
            { searchBy: 'locationId', searchTerm: locationId },
            { searchBy: 'lotId', searchTerm: lotId },
        ]);
        setLoadingPallets(false);

        if (!res.status) {
            setNotification({ text: res.msg, level: 'error' });
            return;
        }

        setPallets(res.data.pallets);
    };

    useEffect(() => {
        if (!locationId || !lotId) {
            return;
        }

        getPallets();
    }, [locationId, lotId]);

    const columns = [
        { accessor: 'palletId', Header: 'Pallet' },
        { Header: 'Cases', accessor: 'numCases' },
        { Header: 'Loose Units', accessor: 'numLooseUnits' },
        { Header: 'Total Units', accessor: 'totalUnits' },
        { Header: 'Status', accessor: 'status' },
        {
            Header: 'Move',
            Cell: ({ row }) => {
                return (
                    <Button
                        onClick={() => {
                            setMovingPalletId(row.original.palletId);
                        }}
                    >
                        Move
                    </Button>
                );
            },
        },
    ];

    const handleMovePalletSuccess = () => {
        setNotification({ text: 'Success!', level: 'success' });
        setMovingPalletId(null);
        getPallets();
        onMovePalletSuccess();
    };

    const fileReaderCheck = (fr, tmpPDF) => {
        if (tmpPDF instanceof Blob) {
            fr.readAsDataURL(tmpPDF);
        } else {
            setNotification({ text: 'There was an issue printing the pdf', level: 'error' });
        }
    };

    let bulkActions = [
        {
            name: 'Download Labels',
            action: async (ids) => {
                const result = await labelApi.getMultiplePalletLabels(ids);
                if (result) {
                    var fr = new FileReader();
                    fr.addEventListener(
                        'load',
                        () => {
                            const tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                            printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                        },
                        false
                    );
                    fileReaderCheck(fr, result);
                } else {
                    setNotification({ text: 'There was an issue printing the pdf', level: 'error' });
                }
            },
        },
    ];

    return (
        <Dialog open={open} maxWidth="md" fullWidth={true} onClose={handleClose}>
            <DialogTitle>
                {title}
                <div style={{ float: 'right' }}>
                    <Button onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                {loadingPallets ? (
                    <CircularProgress color="inherit" size={48} />
                ) : (
                    <>
                        {!movingPalletId && (
                            <DataTable
                                columns={columns}
                                data={pallets.map((item) => ({ id: item.palletId, ...item }))}
                                selectMultiple={true}
                                bulkActions={bulkActions}
                            />
                        )}
                    </>
                )}
                {movingPalletId && (
                    <>
                        <MovePalletAction palletId={movingPalletId} onSuccess={handleMovePalletSuccess} />
                        &nbsp;&nbsp;
                        <Button onClick={cancelMovePallet}>Cancel</Button>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export { ViewPalletsModal };
