import React, { useState } from 'react';
import { FormComponent } from '../../../shared';
import { productionApi } from '../../../api/production';

const CreateAisleBanner = ({ setNotification, brands, aisles, getAisleBanners, packGroups }) => {
    const [placement, setPlacement] = useState();

    const createBanner = async (formData, resetForm) => {
        if (!formData.largeImage) {
            setNotification({ text: `Must provide a large image`, level: 'warning' });
            return false;
        }
        if (!formData.smallImage) {
            setNotification({ text: `Must provide a small image`, level: 'warning' });
            return false;
        }
        if (formData.largeImage.size > 300000) {
            setNotification({ text: `Large image size can't exceed 300KB`, level: 'warning' });
            return false;
        }
        if (formData.smallImage.size > 300000) {
            setNotification({ text: `Small image size can't exceed 300KB`, level: 'warning' });
            return false;
        }

        if (formData.mobileApp === true && formData.appClickThrough === '') {
            setNotification({
                text: 'Must select an App Click Through link if Mobile App is selected',
                level: 'warning',
            });
            return false;
        }

        let item = {
            aisleID: formData.aisleID,
            sponsoredBrands: formData.sponsoredBrands.map((item) => item.brand).join(';'),
            active: formData.active,
            copy: formData.copy,
            bannerName: formData.bannerName,
            backgroundColor: formData.backgroundColor ? formData.backgroundColor : '#000000',
            linkURL: formData.linkURL,
            linkTitle: formData.linkTitle,
            modalCopy: formData.modalCopy,
            packGroupIDs: formData.packGroupIDs.map((item) => item.value),
            mobileApp: formData.mobileApp ? formData.mobileApp === true : false,
            webApp: formData.webApp ? formData.webApp === true : false,
            appClickThrough: formData.appClickThrough,
            largeImage: formData.largeImage,
            smallImage: formData.smallImage,
        };

        let res = await productionApi.createAisleBanner(item);
        if (!res.status) {
            setNotification({ text: `Failed to create aisle banner`, level: 'error' });
        } else {
            setNotification({ text: `Aisle banner created`, level: 'success' });
            getAisleBanners();
            resetForm();
        }

        return res;
    };

    let formFields = [
        {
            name: 'placement',
            label: 'Placement',
            inputElement: 'select',
            gridValue: placement === 'AISLE' ? 2 : 3,
            inputProps: {
                required: true,
                opts: [
                    { text: 'Storefront', value: 'STOREFRONT' },
                    { text: 'Aisle', value: 'AISLE' },
                ],
                onChange: (e, val) => setPlacement(val),
            },
        },
        {
            name: 'packGroupIDs',
            label: 'Pack Groups',
            inputElement: 'autocomplete',
            gridValue: placement === 'AISLE' ? 2 : 3,
            inputProps: {
                multiple: true,
                opts: packGroups.map((item) => ({ text: '' + item.id, value: '' + item.id })),
            },
        },
        {
            name: 'aisleID',
            label: 'Aisle',
            inputElement: 'autocomplete',
            gridValue: 3,
            inputProps: {
                opts: aisles.map((val) => ({ text: `L${val.level} - ${val.name}`, value: val.id })),
            },
            display: () => placement === 'AISLE',
        },
        {
            name: 'bannerName',
            label: 'Banner Name',
            inputElement: 'textField',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'sponsoredBrands',
            label: 'Sponsored Brand Names',
            inputElement: 'autocomplete',
            gridValue: placement === 'AISLE' ? 2 : 3,
            inputProps: { multiple: true, opts: brands },
        },
        {
            name: 'active',
            label: 'Active?',
            inputElement: 'select',
            gridValue: 2,
            inputProps: { required: true, opts: ['Yes', 'No'] },
        },
        {
            name: 'backgroundColor',
            label: 'Background Color',
            gridValue: 2,
            inputProps: { required: true },
            inputElement: ({ formData, setFormField }) => (
                <div style={{ display: 'table', width: '70%' }}>
                    <span style={{ display: 'table-cell', textAlign: 'center' }}>
                        <h4>Background Color: </h4>
                    </span>
                    <span style={{ display: 'table-cell', textAlign: 'center' }}>
                        <input
                            type="color"
                            id="colorPicker"
                            onChange={(e) => {
                                setFormField(e.target.value);
                            }}
                        />
                    </span>
                </div>
            ),
        },
        { name: 'linkURL', label: 'Link URL', inputElement: 'textField', gridValue: 4 },
        { name: 'linkTitle', label: 'Link Title', inputElement: 'textField', gridValue: 4 },
        {
            name: 'largeImage',
            label: 'Banner Large Image (Max 300KB)',
            inputElement: 'file',
            gridValue: 6,
            inputProps: { required: true },
            accept: ['.jpg', 'jpeg', '.png'],
        },
        {
            name: 'smallImage',
            label: 'Banner Small Image (Max 300KB)',
            inputElement: 'file',
            gridValue: 6,
            inputProps: { required: true },
            accept: ['.jpg', 'jpeg', '.png'],
        },
        { gridValue: 1, inputElement: () => <div></div> },
        { name: 'mobileApp', label: 'Mobile App', inputElement: 'checkbox', gridValue: 3 },
        { name: 'webApp', label: 'Web App', inputElement: 'checkbox', gridValue: 3 },
        {
            name: 'appClickThrough',
            label: 'App Click Through (Only Required for Mobile App)',
            inputElement: 'select',
            gridValue: 4,
            inputProps: {
                opts: [
                    { text: 'Please Select', value: 0 },
                    { text: 'Recipe Inspiration', value: '/shop/recipe-inspiration' },
                    { text: 'All Items', value: '/shop/aisle/all' },
                ],
            },
            display: (formData) => formData.mobileApp === true,
        },
        { gridValue: 1, inputElement: () => <div></div> },
        { name: 'copy', label: 'Copy', textField: 'textEditor', inputProps: { multiline: true }, gridValue: 12 },
        {
            name: 'modalCopy',
            label: 'Modal Copy',
            textField: 'textEditor',
            inputProps: { multiline: true },
            gridValue: 11,
        },
    ];

    return (
        <FormComponent
            formFields={formFields}
            button={{
                text: 'Add',
                gridValue: 1,
            }}
            onSubmit={(formData, resetForm) => {
                createBanner(formData, resetForm);
            }}
        />
    );
};

export { CreateAisleBanner };
