import React, { useState, useEffect } from 'react';
import { partnershipsApi } from '../../../../api/partnerships';
import '../subscriptions.css';
import { Button, CircularProgress } from '@material-ui/core';
import { DataTable } from '../../../../shared/index';
import { OrderInfoCard } from '../../orders/components/orderInfoCard';
import moment from 'moment';

const ExpansionOrderDetailCompponent = ({ parentRow, source, setSuccessMessage }) => {
    const [orderDetails, setOrderDetails] = useState([]);

    const getOrderDetails = async (orderID) => {
        const res = await partnershipsApi.getPartnershipOrders(source, 0, '', {
            orderID,
        });
        if (!res.status || !res.data) {
            // setLoading(false);
            return;
        }

        if (res.data.rows.length !== 1) {
            return;
        }

        setOrderDetails(res.data.rows[0]);
    };

    useEffect(() => {
        if (parentRow.original.orderID > 0) {
            getOrderDetails(parentRow.original.orderID);
        }
    }, []);

    return (
        <>
            {Object.keys(orderDetails).length > 0 && (
                <OrderInfoCard
                    order={orderDetails}
                    getOrders={getOrderDetails}
                    setSuccessMessage={setSuccessMessage}
                    canCancelOrders={false}
                    collapsable={false}
                    hideHeader={true}
                />
            )}
        </>
    );
};

const SubscriptionFailedOrdersDataTable = ({
    failedOrders,
    source,
    listOptions,
    includeSubColumns = false,
    setErrorMessage,
    setSuccessMessage,
    getData,
}) => {
    const boxPerOrderOptions = [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
    ];

    const subColumns = [
        {
            Header: 'Subscription ID',
            accessor: 'subscriptionID',
        },
        {
            Header: 'First Name',
            accessor: 'firstName',
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
        },
    ];

    const failedOrderColumns = [
        // {
        //     Header: 'Cancel',
        //     Cell: ({ row }) => {
        //         return (
        //             <Button
        //                 onClick={() => {
        //                     console.log('cancel order');
        //                 }}
        //             >
        //                 Cancel Order
        //             </Button>
        //         );
        //     },
        // },
        { Header: 'Scheduled Delivery', accessor: 'scheduledDeliveryDate' },
        { Header: 'Error', accessor: 'errorMessage' },
        {
            Header: 'List',
            accessor: 'curatedListInfoID',
            editable: (row) => row.original.reasonCode !== 'notDelivered',
            editProps: {
                type: 'select',
                options: (row) => {
                    let options = listOptions;
                    if (row.hasOwnProperty('facility')) {
                        options = listOptions.filter((item) =>
                            item.facilities.map((f) => f.name).includes(row.facility)
                        );
                    }
                    return options;
                },
            },
        },
        {
            Header: 'Number of Boxes',
            accessor: 'quantity',
            editable: (row) => row.original.reasonCode !== 'notDelivered',
            editProps: { type: 'select', options: boxPerOrderOptions },
        },
        {
            Header: 'Details',
            Cell: ({ row }) => {
                if (row.original.reasonCode !== 'notDelivered') {
                    return null;
                }
                return <div className="fam-warning-expand-button">View Details</div>;
            },
        },
    ];

    const updateScheduledOrder = async (scheduledOrderID, updateValues) => {
        console.log(scheduledOrderID, updateValues, source);
        let result = await partnershipsApi.updateScheduledOrder(source, scheduledOrderID, updateValues);
        if (!result.status) {
            setErrorMessage(result.msg);
            return false;
        }

        return true;
    };

    const bulkActions = [
        {
            name: 'Retry',
            action: async (ids) => {
                setErrorMessage('');
                setSuccessMessage('');

                const result = await partnershipsApi.retryScheduledOrders(source, ids, false);
                if (!result.status) {
                    setErrorMessage(result.msg);
                    return;
                }
                setSuccessMessage(`Retrying order creation for ${ids.length} scheduled orders.`);
                getData();
            },
        },
        {
            name: 'Force Create (Ignore min/max and required slots)',
            action: async (ids) => {
                setErrorMessage('');
                setSuccessMessage('');

                const result = await partnershipsApi.retryScheduledOrders(source, ids, true);
                if (!result.status) {
                    setErrorMessage(result.msg);
                    return;
                }
                setSuccessMessage(
                    `Retrying order creation, ignoring list min/max and required slots, for ${ids.length} scheduled orders.`
                );
                getData();
            },
        },
    ];

    return (
        <div className="fam-data-table">
            <DataTable
                selectMultiple={true}
                className="fam-data-table"
                data={failedOrders.map((item) => {
                    if (!item.id) {
                        item.id = item.scheduledOrderID;
                    }
                    if (item.reasonCode === 'notDelivered') {
                        item.errorMessage = 'One or more packages not delivered by scheduled date';
                    }
                    if (item.status === 'RETRYING') {
                        item.errorMessage = 'RETRY IN PROGRESS';
                    }
                    // row.original.reasonCode
                    item.canUserSelectBulkAction = item.status === 'ERROR';
                    return item;
                })}
                columns={includeSubColumns ? [...subColumns, ...failedOrderColumns] : failedOrderColumns}
                editable={true}
                saveEdit={async ({ id, field, value }) => {
                    return updateScheduledOrder(id, { [field]: value });
                }}
                bulkActions={bulkActions}
                expandable={true}
                ExpansionComponent={
                    <ExpansionOrderDetailCompponent source={source} setSuccessMessage={setSuccessMessage} />
                }
            />
        </div>
    );
};

export { SubscriptionFailedOrdersDataTable };
