import React, { useState, useEffect } from 'react';
import { specialOrdersApi as api } from '../../../api/specialOrders';
import { marketingApi } from '../../../api/marketing';
import { Modal, Popover, Button } from '@material-ui/core';
import { DataTable, FormComponent } from '../..';
import moment from 'moment';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

const CancelModal = ({ id, closeModal, onSubmit }) => {
    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <h5>Cancel Order {id}?</h5>
                <Button
                    id="Confirm"
                    onClick={async () => {
                        let res = await onSubmit();
                        if (res === true) {
                            closeModal();
                            return;
                        }
                    }}
                >
                    Confirm
                </Button>
            </div>
        </Modal>
    );
};

const CloneOrderModal = ({ rowData, closeModal, onSubmit }) => {
    let [error, setError] = useState(null);
    let formFields = [
        {
            name: 'shipDate',
            inputElement: 'textField',
            label: 'Ship Date',
            inputProps: { required: true, type: 'date' },
        },
        { name: 'notes', inputElement: 'textField', label: 'Notes' },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let result = await onSubmit(formData);
                        if (result === true) {
                            closeModal();
                            return;
                        }
                        setError('Error creating special order');
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

const AddressModal = ({ rowData, closeModal, saveEdit }) => {
    let [error, setError] = useState(null);
    let { firstName, lastName, lineOne, lineTwo, city, province, zip, phone } = rowData;

    // {name, initialValue, inputElement, label, options, inputProps: {minDate, maxDate, type, required}, gridValue}
    let formFields = [
        {
            name: 'firstName',
            initialValue: firstName,
            inputElement: 'textField',
            label: 'First Name',
            gridValue: 6,
            inputProps: { required: true },
        },
        { name: 'lastName', initialValue: lastName, inputElement: 'textField', label: 'Last Name', gridValue: 6 },
        {
            name: 'lineOne',
            initialValue: lineOne,
            inputElement: 'textField',
            label: 'Address 1',
            gridValue: 8,
            inputProps: { required: true },
        },
        { name: 'lineTwo', initialValue: lineTwo, inputElement: 'textField', label: 'Address 2', gridValue: 4 },
        {
            name: 'city',
            initialValue: city,
            inputElement: 'textField',
            label: 'City',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'province',
            initialValue: province,
            inputElement: 'textField',
            label: 'State',
            gridValue: 2,
            inputProps: { required: true, maxLength: 2 },
        },
        {
            name: 'zip',
            initialValue: zip,
            inputElement: 'textField',
            label: 'Zip Code',
            inputProps: { maxLength: 5, required: true },
            gridValue: 3,
        },
        {
            name: 'phone',
            initialValue: phone,
            inputElement: 'textField',
            label: 'Phone',
            inputProps: { type: 'tel', maxLength: 10 },
            gridValue: 4,
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        formData.street1 = formData.lineOne;
                        formData.street2 = formData.lineTwo;
                        formData.state = formData.province;
                        let res = await saveEdit({ id: rowData.id, field: 'address', value: formData });
                        if (res === true) {
                            closeModal();
                            return;
                        }
                        setError('Error updating address');
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

const ActionCell = ({
    row,
    isShipping,
    cloneOrderPressed,
    cancelOrderPressed,
    downloadPDF,
    purchaseLabel,
    markShipped,
}) => {
    let [anchorEl, setAnchorEl] = useState(null);
    let [purchasingLabel, setPurchasingLabel] = useState(false);

    return (
        <div style={{ position: 'relative' }}>
            <Button
                id={`Actions_${row.id}`}
                aria-describedby="popover"
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                }}
            >
                <MoreHoriz />
            </Button>
            <Popover
                anchorEl={anchorEl}
                open={anchorEl ? true : false}
                onClose={() => {
                    setAnchorEl(null);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <div>
                    {!isShipping && (
                        <div>
                            <Button id={`Clone_${row.id}`} onClick={() => cloneOrderPressed(row.original)}>
                                Clone
                            </Button>
                        </div>
                    )}
                    {!isShipping &&
                        (row.original.status === 'AWAITING_PRINT' || row.original.status === 'FAILED_TO_PURCHASE') && (
                            <div>
                                <Button id={`Cancel_${row.id}`} onClick={() => cancelOrderPressed(row.original.id)}>
                                    Cancel
                                </Button>
                            </div>
                        )}
                    {isShipping && ['LABEL_PURCHASED', 'SHIPPED'].includes(row.original.status) && (
                        <div>
                            <Button id={`DownloadLabel_${row.id}`} onClick={() => downloadPDF(row.original.id)}>
                                Download Label
                            </Button>
                        </div>
                    )}
                    {isShipping && row.original.status === 'AWAITING_PRINT' && (
                        <div>
                            <Button
                                id={`PurchaseLabel_${row.id}`}
                                onClick={() => {
                                    setPurchasingLabel(true);
                                    purchaseLabel(row.original.id);
                                }}
                                disabled={purchasingLabel}
                            >
                                Purchase Label
                            </Button>
                        </div>
                    )}
                    {isShipping && row.original.status === 'LABEL_PURCHASED' && (
                        <div>
                            <Button id={`MarkShipped_${row.id}`} onClick={() => markShipped(row.original.id)}>
                                Mark Shipped
                            </Button>
                        </div>
                    )}
                </div>
            </Popover>
        </div>
    );
};

const SpecialOrders = ({ editable }) => {
    let [ordersInProgress, setOrdersInProgress] = useState([]);
    let [ordersComplete, setOrdersComplete] = useState([]);
    let [addressModalOpen, setAddressModalOpen] = useState(false);
    let [selectedRow, setSelectedRow] = useState({});
    let [cloneModalOpen, setCloneModalOpen] = useState(false);
    let [cancelModalOpen, setCancelModalOpen] = useState(false);

    let isShipping = editable === true ? false : true;

    let getOrdersInProgress = async () => {
        let orders = await api.getSpecialOrders('inProgress');
        setOrdersInProgress(orders);
    };

    let getOrdersComplete = async () => {
        let orders = await api.getSpecialOrders('complete');
        setOrdersComplete(orders);
    };

    let showAddressModal = (row) => {
        setSelectedRow(row);
        setAddressModalOpen(true);
    };

    let onSaveEdit = async ({ id, field, value }) => {
        let res = await api.updateSpecialOrder({ id, field, value });
        if (res === true) {
            await getOrdersInProgress();
        }
        return res === true ? true : false;
    };

    let cloneOrderPressed = async (order) => {
        await setSelectedRow(order);
        setCloneModalOpen(true);
    };

    let cancelOrderPressed = async (id) => {
        await setSelectedRow({ id });
        setCancelModalOpen(true);
    };

    let cloneOrder = async ({ shipDate, notes }, order) => {
        let quantity = 1;
        let {
            productName,
            recipientType,
            firstName,
            lastName,
            phone,
            lineOne,
            lineTwo,
            city,
            province,
            zip,
            recipientEmail,
        } = order;
        let obj = {
            shipDate,
            product: productName,
            type: recipientType,
            firstName,
            lastName,
            phone,
            street1: lineOne,
            street2: lineTwo,
            city,
            state: province,
            zip,
            notes,
            quantity,
            recipientEmail,
        };
        let res = await api.createSpecialOrder(obj);
        if (res.status === true) {
            getOrdersInProgress();
            return true;
        }
        return false;
    };

    let cancelOrder = async (id) => {
        let res = await api.cancelSpecialOrder(id);
        if (res === true) {
            getOrdersInProgress();
            return true;
        }
        return false;
    };

    let downloadPDF = (id) => {
        api.downloadPDF(id);
    };

    let purchaseLabel = async (id) => {
        let res = await api.purchaseLabel(id);
        if (res === true) {
            getOrdersInProgress();
            return;
        }
        // show error
    };

    let markShipped = async (id) => {
        let res = await api.markShipped(id);
        if (res === true) {
            getOrdersComplete();
            getOrdersInProgress();
            return;
        }
        // show error
    };

    let minDate = moment(new Date()).format('YYYY-MM-DD');
    let maxDate = moment(new Date()).add(2, 'months').format('YYYY-MM-DD');

    let columns = [
        {
            accessor: 'actions',
            Header: '',
            useFilter: false,
            sortable: false,
            width: '40px',
            Cell: ({ row }) =>
                ActionCell({
                    row,
                    isShipping,
                    cloneOrderPressed,
                    cancelOrderPressed,
                    downloadPDF,
                    purchaseLabel,
                    markShipped,
                }),
        },
        { accessor: 'id', Header: 'ID', editable: false, width: '75px' },
        {
            accessor: 'shipDate',
            Header: 'Ship Date',
            type: 'date',
            editable: true,
            width: '125px',
            editProps: { type: 'input', inputType: 'date', min: minDate, max: maxDate },
        },
        { accessor: 'status', Header: 'Status', editable: false },
        {
            accessor: 'productName',
            Header: 'Product',
            editable: true,
            editProps: {
                type: 'select',
                options: [
                    { value: 'mischief', text: 'Mischief' },
                    { value: 'madness', text: 'Madness' },
                ],
            },
        },
        {
            accessor: 'recipientType',
            Header: 'Type',
            editable: true,
            editProps: {
                type: 'select',
                options: [
                    { value: 'influencer', text: 'Influencer' },
                    { value: 'partnership', text: 'Partnership' },
                    { value: 'affiliate', text: 'Affiliate' },
                    { value: 'donation', text: 'Donation' },
                    { value: 'press', text: 'Press' },
                    { value: 'overnight', text: 'Overnight Non-Produce' },
                    { value: 'payment', text: 'Payment/Quid Pro Quo' },
                    { value: 'customer', text: 'Customer' },
                ],
            },
        },
        { accessor: 'firstName', Header: 'First Name', editable: true, customEdit: showAddressModal, width: '150px' },
        { accessor: 'lastName', Header: 'Last Name', editable: true, customEdit: showAddressModal, width: '150px' },
        { accessor: 'lineOne', Header: 'Street', editable: true, customEdit: showAddressModal, width: '200px' },
        { accessor: 'city', Header: 'City', editable: true, customEdit: showAddressModal },
        { accessor: 'province', Header: 'State', editable: true, customEdit: showAddressModal },
        { accessor: 'zip', Header: 'Zip', editable: true, customEdit: showAddressModal },
        { accessor: 'phone', Header: 'phone', editable: true, customEdit: showAddressModal },
        { accessor: 'notes', Header: 'Notes', editable: true, width: '300px' },
        { accessor: 'trackingNumber', Header: 'Tracking #', width: '150px' },
        { accessor: 'recipientEmail', Header: 'Email', editable: true },
        { accessor: 'requestor', Header: 'Requestor' },
        { accessor: 'createdAt', Header: 'Created At', type: 'date', width: '150px' },
    ];

    useEffect(() => {
        getOrdersInProgress();
        getOrdersComplete();
    }, []);

    return (
        <div>
            {addressModalOpen && (
                <AddressModal
                    rowData={selectedRow}
                    closeModal={() => {
                        setAddressModalOpen(false);
                        setSelectedRow({});
                    }}
                    saveEdit={onSaveEdit}
                />
            )}
            {cloneModalOpen && (
                <CloneOrderModal
                    closeModal={() => {
                        setCloneModalOpen(false);
                        setSelectedRow({});
                    }}
                    onSubmit={async (formData) => {
                        let res = await cloneOrder(formData, selectedRow);
                        return res;
                    }}
                />
            )}
            {cancelModalOpen && (
                <CancelModal
                    id={selectedRow.id}
                    closeModal={() => {
                        setCancelModalOpen(false);
                        setSelectedRow({});
                    }}
                    onSubmit={async () => {
                        let res = await cancelOrder(selectedRow.id);
                        return res;
                    }}
                />
            )}
            <h2>Special Orders</h2>
            <DataTable
                title="In Progress"
                columns={columns}
                data={ordersInProgress}
                editable={editable}
                saveEdit={onSaveEdit}
            />
            <DataTable title="Complete" columns={columns} data={ordersComplete} editable={false} />
        </div>
    );
};

export { SpecialOrders };
