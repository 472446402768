import React, { useState } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { creditApi as api } from '../../../api/credit';
import { CustomerBulkTemplateDownload } from '../../custmanage/components/CustomerBulkTemplateDownload';

let MassCredit = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [reasonOps, setReasonOps] = useState([
        'BULK_DAMAGE',
        'BULK_DOUBLE_DELAY',
        'BULK_MISSING_ORDER',
        'BULK_SUBSTITUTION',
        'BULK_WEATHER',
        'OTHER',
    ]);

    let formFields = [
        {
            name: 'creditValue',
            inputElement: 'textField',
            gridValue: 4,
            label: 'Value',
            inputProps: { required: true, type: 'number', step: '.01', max: '25' },
        },
        {
            name: 'reason',
            inputElement: 'select',
            label: 'Reason',
            gridValue: 8,
            inputProps: { required: true, opts: reasonOps },
        },
        {
            name: 'customerFile',
            inputElement: 'file',
            label: 'Customer ID File',
            gridValue: 12,
            inputProps: { required: true },
        },
    ];

    return (
        <div>
            <h1>Mass Credit</h1>
            <Notifications options={notification} />
            <CustomerBulkTemplateDownload csvColumns={'Customer ID'} fileName={'mass_credit'} />
            {}
            <div>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        let response = await api.massCredit(
                            formData.creditValue,
                            formData.reason,
                            formData.customerFile
                        );
                        if (response.status === false) {
                            setNotification({ text: 'Unable to credit ' + response.msg, level: 'error' });
                            return;
                        }

                        setNotification({ text: 'Success!', level: 'success' });
                        resetForm();
                    }}
                />
            </div>
        </div>
    );
};

export { MassCredit };
