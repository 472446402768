import React, { useState } from 'react';
import { Alert } from '@material-ui/lab';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    TextField,
    CircularProgress,
} from '@material-ui/core';
import { FormComponent } from '../../../../shared';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { receivingApi } from '../../../../api/receiving';
import { PartnerLotNumbersDateForm } from './partnerLotNumbersDateForm';
import { PalletCountForm } from './palletCountForm';
import { ExpirationDateForm } from './expirationDateForm';
import { PrintReceivedPalletAndCaseLabels } from '../receivedLabels/printReceivedPalletAndCaseTags';

const useStyles = makeStyles((theme) => {
    return {
        panelContainer: {
            borderColor: theme.palette.ivy.main,
            borderWidth: '3px',
            borderStyle: 'solid',
            borderRadius: '5px',
            margin: '10px 10px',
            padding: '0px',
        },
        panelSummary: {
            height: '70px',
            fontSize: '16px',
            padding: '25px',
        },
    };
});

let ReceiveLineItemWithPalletsModal = ({
    rowData,
    close,
    open,
    setNotification,
    poType,
    poID,
    setUpdatedPurchaseOrderStatus,
    internalTransfer = false,
    thirdPartyASN = false,
}) => {
    const [varianceAttemptCount, setVarianceAttemptCount] = useState(0);
    const [hasExpirationDateError, setHasExpirationDateError] = useState(false);
    const [overrideBadge, setOverrideBadge] = useState('');
    const [enteringOverrideBadgeNumber, setEnteringOverrideBadgeNumber] = useState(false);
    const [formData, setFormData] = useState({});
    const [receivingLineItem, setReceivingLineItem] = useState(false);
    const [showPrintLabels, setShowPrintLabels] = useState(false);
    const classes = useStyles();
    const theme = useTheme();

    let receiveLineItem = async () => {
        setReceivingLineItem(true);

        formData.palletCounts.forEach((count) => {
            if (Number(count) <= 0) {
                setNotification({ text: 'Cases on pallet must be greater than 0', level: 'error' });
                setReceivingLineItem(false);
                return;
            }
        });

        if (formData.palletCounts.length != formData.numPallets) {
            setNotification({ text: 'Number of pallets does not match specified count', level: 'error' });
            setReceivingLineItem(false);
            return;
        }

        if (formData.uniqueExpirationDates == true) {
            formData.expirationDates.forEach((expirationDate) => {
                if (expirationDate == '') {
                    setNotification({ text: 'Expiration date on pallet cannot be blank', level: 'error' });
                    setReceivingLineItem(false);
                    return;
                }
            });
            if (formData.expirationDates.length != formData.numPallets) {
                setNotification({
                    text: 'Number of pallet expiration dates does not match specified count',
                    level: 'error',
                });
                setReceivingLineItem(false);
                return;
            }
        }

        if (formData.multiplePartnerLotNumbers === true) {
            if (formData.partnerLotNumbers.length != formData.numPallets) {
                setNotification({
                    text: 'Number of pallet partner lot numbers does not match specified count',
                    level: 'error',
                });
                setReceivingLineItem(false);
                return;
            }
        }

        let res;
        let pallets = [];
        if (poType != 'PACKAGING') {
            for (let i = 0; i < Number(formData.numPallets); i++) {
                let date = '';
                if (formData.uniqueExpirationDates == true) {
                    date = formData.expirationDates[i];
                } else {
                    date = formData.expiration;
                }

                pallets.push({
                    caseCount: formData.palletCounts[i],
                    expiresOn: date,
                    partnerLotNumber:
                        formData.multiplePartnerLotNumbers === true
                            ? formData.partnerLotNumbers[i]
                            : formData.partnerLotNumber,
                });
            }

            res = await receivingApi.receiveLineItemWithPalletCounts({
                poMapID: rowData.original.id,
                pallets: pallets,
                receiveQty0: formData.receiveQty0,
                uniqueExpirationDates: formData.uniqueExpirationDates || false,
                overrideExpirationDateBadgeNumber: overrideBadge,
            });
        } else if (poType === 'PACKAGING') {
            for (let i = 0; i < Number(formData.numPallets); i++) {
                pallets.push({
                    caseCount: formData.palletCounts[i],
                    expiresOn: null,
                    partnerLotNumber:
                        formData.multiplePartnerLotNumbers === true
                            ? formData.partnerLotNumbers[i]
                            : formData.partnerLotNumber,
                });
            }
            res = await receivingApi.receivePackagingLineItemWithPalletCounts({
                poMapID: rowData.original.poMapID,
                pallets: pallets,
                receiveQty0: formData.receiveQty0,
            });
        }

        if (!res.status) {
            setHasExpirationDateError(res.data.expDateError);

            if (res.data.hasVarianceError && !res.data.hasFatalError) {
                if (res.data.varianceAttemptCount) {
                    setVarianceAttemptCount(parseInt(res.data.varianceAttemptCount));
                }
                setNotification({
                    text: res.msg,
                    level: 'error',
                    autoHide: false,
                });
            } else {
                setNotification({
                    text: 'Failed to receive purchase order line item. ' + res.msg,
                    level: 'error',
                    autoHide: false,
                });
            }
            setReceivingLineItem(false);
            return;
        }

        if (res.data.hasWarning) {
            let msg = `${internalTransfer ? 'Transfer ASN' : 'Purchase order'} line item received, with warnings: ${
                res.data.warning
            }`;
            setNotification({ text: msg, level: 'warning' });
        } else {
            let msg = `${internalTransfer ? 'Transfer ASN' : 'Purchase order'} line item received`;
            setNotification({ text: msg, level: 'success' });
        }
        setReceivingLineItem(false);
        setUpdatedPurchaseOrderStatus(poID, res.status.poStatus);
        if (poType != 'PACKAGING') {
            await Promise.all([
                receivingApi.receiveNetSuiteLineItem({
                    poMapID: rowData.original.id,
                    pallets: pallets,
                    receiveQty0: formData.receiveQty0,
                }),
                receivingApi.receiveTrucks({
                    poMapID: rowData.original.id,
                }),
            ]);
        }

        setShowPrintLabels(true);
    };

    let setInputPropBool = (inputPropName, item) => {
        if (inputPropName === 'required') {
            if (
                item.expirationType == 'EXPIRATION_DATE' ||
                item.expirationType == 'PRODUCTION_DATE' ||
                item.internalTransfer
            ) {
                return true;
            }
            return false;
        } else if (inputPropName === 'disabled') {
            if (
                item.expirationType == 'DAYS_AFTER_RECEIPT' ||
                item.expirationType == 'NO_EXPIRATION_DATE' ||
                item.internalTransfer
            ) {
                return true;
            }
            return false;
        } else if (inputPropName === 'label') {
            if (item.expirationType == 'PRODUCTION_DATE') {
                return 'Production date';
            }
            return 'Expiration';
        } else if (inputPropName === 'styleBackground') {
            if (
                item.expirationType == 'DAYS_AFTER_RECEIPT' ||
                item.expirationType == 'NO_EXPIRATION_DATE' ||
                item.internalTransfer
            ) {
                return 'lightgrey';
            }
            return 'white';
        } else if (inputPropName === 'styleOpacity') {
            if (
                item.expirationType == 'DAYS_AFTER_RECEIPT' ||
                item.expirationType == 'NO_EXPIRATION_DATE' ||
                item.internalTransfer
            ) {
                return '50%';
            }
            return '100%';
        }
    };

    const formFields = [
        {
            name: 'receiveQty0',
            inputElement: 'checkbox',
            initialValue: formData.receiveQty0 || false,
            gridValue: internalTransfer ? 12 : 4,
            label: 'Receive 0 Pallets',
        },
        {
            name: 'uniqueExpirationDates',
            display: poType != 'PACKAGING' && !internalTransfer,
            inputElement: 'checkbox',
            initialValue: formData.uniqueExpirationDates || false,
            gridValue: 4,
            label:
                rowData.original.expirationType == 'PRODUCTION_DATE'
                    ? 'Unique Production Dates'
                    : 'Unique Expiration Dates',
        },
        {
            name: 'multiplePartnerLotNumbers',
            display: thirdPartyASN && !formData.receiveQty0,
            inputElement: 'checkbox',
            initialValue: formData.multiplePartnerLotNumbers || false,
            gridValue: poType == 'PACKAGING' ? 8 : 4,
            label: 'Multiple Partner Lot Numbers',
        },
        {
            name: 'expiration',
            display: (formData) => formData.receiveQty0 === false && poType != 'PACKAGING',
            initialValue: formData.expiration || rowData.original.expiresOn,
            inputElement: 'date',
            label: setInputPropBool('label', rowData.original),
            gridValue: 4,
            inputProps: {
                required: setInputPropBool('required', rowData.original),
                disabled: formData.uniqueExpirationDates == true,
                style: {
                    background: setInputPropBool('styleBackground', rowData.original),
                    opacity: setInputPropBool('styleOpacity', rowData.original),
                },
            },
        },
        {
            name: 'partnerLotNumber',
            display: thirdPartyASN && !formData.receiveQty0,
            initialValue: formData.partnerLotNumber || rowData.original.partnerLotNumber,
            inputElement: 'textField',
            label: 'Partner Lot Number',
            gridValue: 4,
            inputProps: {
                required: false,
                disabled: formData.multiplePartnerLotNumbers,
            },
        },
        {
            name: 'numPallets',
            inputElement: 'textField',
            initialValue: formData.numPallets || '',
            inputProps: { type: 'number', required: true },
            label: 'Num Pallets',
            gridValue: poType == 'PACKAGING' ? 8 : 4,
            display: (formData) => formData.receiveQty0 === false,
        },
        {
            name: 'palletCounts',
            display: (formData) => formData.receiveQty0 === false,
            initialValue: formData.palletCounts || [],
            gridValue: 5,
            inputElement: ({ formData, setFormField }) => (
                <PalletCountForm formData={formData} setFormField={setFormField} />
            ),
        },
        {
            name: 'expirationDates',
            display: (formData) => formData.receiveQty0 === false && formData.uniqueExpirationDates == true,
            initialValue: formData.palletCounts || [],
            gridValue: 3,
            inputElement: ({ formData, setFormField }) => (
                <ExpirationDateForm
                    formData={formData}
                    setFormField={setFormField}
                    expiresOnDefault={formData.expiration || ''}
                    expirationType={rowData.original.expirationType}
                />
            ),
        },
        {
            name: 'partnerLotNumbers',
            display: formData.multiplePartnerLotNumbers,
            initialValue: formData.palletPartnerLotNumbers || [],
            gridValue: 3,
            inputElement: ({ formData, setFormField }) => (
                <PartnerLotNumbersDateForm
                    formData={formData}
                    setFormField={setFormField}
                    partnerLotNumberOnDefault={formData.partnerLotNumber || ''}
                />
            ),
        },
        {
            name: 'totalCases',
            inputElement: ({ formData, setFormField }) => {
                let total = 0;
                for (let num of formData.palletCounts) {
                    total += Number(num);
                }
                if (total !== formData.totalCases) {
                    setFormField(total);
                }
                return <div>Total Cases: {formData.totalCases}</div>;
            },
        },
    ];

    let getBackgroundColor = (poMapID, totalCases, expiration, receiveQty0, expirationType) => {
        if (receiveQty0) {
            return theme.palette.aloe.main;
        }

        if (rowData.poType === 'PACKAGING') {
            return Number(totalCases) > 0 ? theme.palette.aloe.main : theme.palette.mustard.main;
        } else {
            if (expirationType != 'NO_EXPIRATION_DATE') {
                return Number(totalCases) > 0 && !!expiration ? theme.palette.aloe.main : theme.palette.mustard.main;
            } else {
                return Number(totalCases) > 0 ? theme.palette.aloe.main : theme.palette.mustard.main;
            }
        }
    };

    if (!rowData) {
        return null;
    }

    return (
        <div>
            <Dialog open={open} onClose={close} fullWidth={true} maxWidth="lg" height="90%">
                <DialogTitle>
                    {showPrintLabels
                        ? 'Print Labels'
                        : `Receive Line Item for ${internalTransfer ? 'Transfer ASN' : 'Purchase Order'} ${poID}`}
                </DialogTitle>
                <DialogContent>
                    {showPrintLabels ? (
                        <PrintReceivedPalletAndCaseLabels
                            type={poType}
                            poMapId={poType === 'PACKAGING' ? rowData.original.poMapID : rowData.original.id}
                        />
                    ) : (
                        <>
                            {hasExpirationDateError && (
                                <Alert severity="error">
                                    Expiration Variance Error Found
                                    {!enteringOverrideBadgeNumber && (
                                        <Button
                                            onClick={() => {
                                                setEnteringOverrideBadgeNumber(true);
                                            }}
                                        >
                                            Override
                                        </Button>
                                    )}
                                    {enteringOverrideBadgeNumber && (
                                        <>
                                            <div>Enter badge number and re-submit</div>
                                            <TextField
                                                type="number"
                                                style={{ width: '100%', padding: 15 }}
                                                label="Badge Number"
                                                value={overrideBadge}
                                                onChange={(event) => {
                                                    setOverrideBadge(event.target.value);
                                                }}
                                            />
                                        </>
                                    )}
                                </Alert>
                            )}
                            <ExpansionPanel className={classes.panelContainer} expanded={true}>
                                <ExpansionPanelSummary
                                    className={classes.panelSummary}
                                    style={{
                                        backgroundColor: getBackgroundColor(
                                            rowData.original.poMapID,
                                            formData.totalCases,
                                            formData.expiration,
                                            formData.receiveQty0,
                                            rowData.original.expirationType
                                        ),
                                    }}
                                >
                                    <Grid container>
                                        <Grid id={`SkuExpansionComponent_${rowData.original.poMapID}`} rowData xs={6}>
                                            <h3>
                                                {poType != 'PACKAGING'
                                                    ? `Sku ${rowData.original.itemMasterSKU} - ${rowData.original.itemMasterName}`
                                                    : `Sku ${rowData.original.masterSku} - ${rowData.original.description} (${rowData.original.type})`}
                                            </h3>
                                        </Grid>
                                        <Grid rowData xs={6}>
                                            {formData && Number(formData.totalCases) > 0 && (
                                                <h3>
                                                    <div>Num Pallets: {formData.numPallets}</div>
                                                    <div>Pallet Case Total: {formData.totalCases}</div>
                                                </h3>
                                            )}
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container>
                                        <Grid rowData xs={12}>
                                            <FormComponent
                                                formFields={formFields}
                                                onFormDataChange={(formData) => {
                                                    setFormData(formData);
                                                }}
                                                onSubmit={() => {
                                                    receiveLineItem();
                                                }}
                                                hideSubmit={receivingLineItem}
                                            />
                                            {receivingLineItem && <CircularProgress />}
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export { ReceiveLineItemWithPalletsModal };
