import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Notifications, FormComponent } from '../../../../../shared';
import { UserContext } from '../../../../../contexts/UserContext';
import { warehouseApi } from '../../../../../api/warehouse';
import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { SearchableSelect } from '../../../../../shared/components/SearchableSelect';
import { WarehouseInventoryV2Api } from '../../../../../api/warehouseInventoryV2';
import { WarehouseLabelApi } from '../../../../../api/warehouseLabels';
import printJS from 'print-js';
import { EmptyPalletCreator } from '../emptyPalletCreator';

const warehouseApiV2 = new WarehouseInventoryV2Api();
const labelApi = new WarehouseLabelApi();

const AdjustFromZeroAction = ({
    lotId,
    onSuccess,
    locationId = null,
    palletId = null,
    reason = null,
    initialNumCases,
    initialNumUnits,
}) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const { user } = useContext(UserContext);
    const [locations, setLocations] = useState([]);
    const [loadingLocations, setLoadingLocations] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState(locationId);
    const [selectedLocationInventoryType, setSelectedLocationInventoryType] = useState(undefined);
    const [pallets, setPallets] = useState([]);
    const [palletsLoading, setPalletsLoading] = useState(false);
    const [selectedPalletId, setSelectedPalletId] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [reasons, setReasons] = useState([]);
    const [reasonsLoading, setReasonsLoading] = useState(false);
    const [selectedReason, setSelectedReason] = useState(null);

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('AdjustFromZeroAction intialized incorrectly onSuccess is not a function');
        }
    }, [onSuccess]);

    const palletOptions = useMemo(
        () => pallets.map((pallet) => ({ label: pallet.palletId.toString(), value: pallet.palletId })),
        [pallets]
    );

    const reasonOptions = useMemo(() => reasons.map((reason) => ({ label: reason, value: reason })), [reasons]);

    useEffect(() => {
        queryLocations();
        queryAdjustReasons();
    }, []);

    useEffect(() => {
        if (selectedLocationInventoryType === 'Pallet' || palletId) {
            queryPallets();
        }
    }, [selectedLocationInventoryType, selectedLocationId, palletId]);

    const queryAdjustReasons = async () => {
        setReasonsLoading(true);

        const result = await warehouseApiV2.getAdjustReasons(lotId);
        if (result?.status) {
            setReasons(result?.data?.reasons ?? []);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }

        setReasonsLoading(false);
    };

    const queryPallets = async () => {
        setPalletsLoading(true);

        const result = await warehouseApiV2.getPalletsByLocationId(selectedLocationId, user.facilityID);
        if (result?.status) {
            setPallets(result?.data?.pallets ?? []);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }

        setPalletsLoading(false);
    };

    const queryLocations = async () => {
        setLoadingLocations(true);
        const result = await warehouseApi.getLocations(user.facilityID, true);
        setLoadingLocations(false);
        if (result.status) {
            setLocations(result.data.rows);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const handleSubmit = async (formData) => {
        if (formData.numCases === 0 && formData.numUnits === 0) {
            setShowError(true);
            setErrorMessage('Number of Cases and Number of Loose Units cannot both be 0.');
            return;
        }
        setShowError(false);
        setErrorMessage('');

        const requestParams = {
            lotID: lotId,
            numCases: formData.numCases,
            numUnits: formData.numUnits,
            originLocationID: selectedLocationId,
            originPalletID: selectedPalletId,
            adjustFromZero: true,
            reason: selectedReason,
        };
        const result = await warehouseApi.adjustCaseAndUnitNums(requestParams);
        if (result.status) {
            onSuccess();
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const handleCreatePallet = async () => {
        queryPallets();
    };

    const handleLocationChange = (newValue) => {
        setSelectedLocationId(newValue?.value);
        setSelectedLocationInventoryType(newValue?.inventoryType);
    };

    const handlePalletChange = (newValue) => {
        setSelectedPalletId(newValue?.value);
    };

    const handleSelectedReasonChange = (newValue) => {
        setSelectedReason(newValue?.value);
    };

    const formFields = [
        {
            name: 'numCases',
            label: 'Cases',
            initialValue: initialNumCases ?? 0,
            gridValue: 6,
            inputProps: { required: true, type: 'number', integer: true },
        },
        {
            name: 'numUnits',
            label: 'Loose Units',
            initialValue: initialNumUnits ?? 0,
            gridValue: 6,
            inputProps: { required: true, type: 'number', integer: true },
        },
    ];

    return (
        <>
            <Notifications options={notification} />
            <h3>Adjust From Zero</h3>
            <Box>
                {showError && <Typography style={{ color: 'red', marginBottom: 10 }}>{errorMessage}</Typography>}
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <SearchableSelect
                            onChange={handleLocationChange}
                            options={locations.map((location) => {
                                return {
                                    label: location.name,
                                    value: location.id,
                                    inventoryType: location.inventoryType,
                                };
                            })}
                            loading={loadingLocations}
                            label={'Adjust in Location'}
                            initialValue={locationId}
                        />
                        &nbsp;&nbsp;
                        {selectedLocationInventoryType === 'Pallet' && (
                            <>
                                {!pallets.length && !palletsLoading && (
                                    <div>
                                        <Typography style={{ color: 'red', marginBottom: 10 }}>
                                            Location requires inventory to be placed on a pallet, but no pallets found
                                            in location.
                                        </Typography>
                                    </div>
                                )}
                                {
                                    <Grid container>
                                        <Grid item xs={!palletId ? 6 : 12}>
                                            {selectedLocationId && !palletId && (
                                                <EmptyPalletCreator
                                                    locationId={selectedLocationId}
                                                    onSuccess={handleCreatePallet}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={!palletId ? 6 : 12}>
                                            <SearchableSelect
                                                onChange={handlePalletChange}
                                                options={palletOptions}
                                                loading={palletsLoading}
                                                initialValue={palletId}
                                                label="Adjust On Pallet"
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </>
                        )}
                        <SearchableSelect
                            onChange={handleSelectedReasonChange}
                            options={reasonOptions}
                            loading={reasonsLoading}
                            label="Reason"
                            initialValue={reason}
                        />
                        <FormComponent formFields={formFields} onSubmit={handleSubmit} button={{ text: 'Submit' }} />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export { AdjustFromZeroAction };
