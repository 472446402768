import { mmFetchWithResponse } from './mmFetchWithResponse';
import { mmFetch } from './mmFetch';

let goPuffApi = {
    bulkDownloadBOLs: async (facilityID, lineHaulName, deliveryDate) => {
        let url = `api/partnerships/v1/goPuff/orders/bulkOrderBOLs?facilityID=${facilityID}&lineHaul=${lineHaulName}&deliveryDate=${deliveryDate}`;

        let respObj = {
            status: true,
            data: {},
            msg: '',
        };

        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                if (blob.type === 'application/json') {
                    return respObj;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'bulk_BOLS_' + lineHaulName + '_' + deliveryDate + '_' + '.html';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            } else {
                let decodedResp = await res.json();
                respObj.msg = decodedResp.msg;
                respObj.status = false;
            }
        } catch (e) {
            console.error('[goPuffApi - bulkDownloadBOLs] error', e.toString());
            respObj.msg = e.toString();
            respObj.status = false;
        }

        return respObj;
    },
    bulkDownloadPackSlips: async (facilityID, lineHaulName, deliveryDate) => {
        let url = `api/partnerships/v1/goPuff/orders/bulkOrderPackSlips?facilityID=${facilityID}&lineHaul=${lineHaulName}&deliveryDate=${deliveryDate}`;

        let respObj = {
            status: true,
            data: {},
            msg: '',
        };

        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                if (blob.type === 'application/json') {
                    return respObj;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'bulk_packslips_' + lineHaulName + '_' + deliveryDate + '_' + '.pdf';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            } else {
                let decodedResp = await res.json();
                respObj.msg = decodedResp.msg;
                respObj.status = false;
            }
        } catch (e) {
            console.error('[goPuffApi - bulkDownloadPackSlips] error', e.toString());
            respObj.msg = e.toString();
            respObj.status = false;
        }

        return respObj;
    },
    confirmAllocationUpload: async (key) => {
        let url = 'api/partnerships/v1/goPuff/allocation/bulk';
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    confirmOrderUpload: async (key) => {
        let url = 'api/partnerships/v1/goPuff/orders/bulk';
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    confirmPricingUpload: async (key) => {
        let url = 'api/partnerships/v1/goPuff/pricing/upload';
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    createMicroFulfillmentCenter: async (item) => {
        let url = `api/partnerships/v1/gopuff/microFC`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    downloadAllocationCsv: async (facilityID, deliveryDate) => {
        let url = `api/partnerships/v1/gopuff/allocation/csv?facilityID=${facilityID}&deliveryDate=${deliveryDate}`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'goPuffAlloction' + facilityID + '.csv';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.error('[goPuffApi - downloadAllocationCsv] error', e.toString());
        }
    },
    downloadBOL: async (shipmentID) => {
        let url = `api/partnerships/v1/goPuff/orders/bol/${shipmentID}`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'bol' + shipmentID + '.html';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.error('[goPuffApi - downloadBOL] error', e.toString());
        }
    },
    downloadInvoiceCsv: async (invoiceID) => {
        let url = `api/partnerships/v1/gopuff/invoices/${invoiceID}/csv`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'goPuffInvoice' + invoiceID + '.csv';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.error('[goPuffApi - downloadInvoiceCsv] error', e.toString());
        }
    },
    downloadPackSlip: async (shipmentID) => {
        let url = `api/partnerships/v1/goPuff/orders/packSlip/${shipmentID}`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'packslip' + shipmentID + '.pdf';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.error('[goPuffApi - getPackSlip] error', e.toString());
        }
    },
    getAllocationRollup: async (facilityID) => {
        let url = `api/partnerships/v1/gopuff/allocation/rollup?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLineHaulsWithOrders: async (facilityID) => {
        let url = `api/partnerships/v1/goPuff/lineHauls?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getMicroFulfillmentCenters: async () => {
        let url = `api/partnerships/v1/gopuff/microFC`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getInvoices: async (startDate, endDate, facilityID) => {
        let url = `api/partnerships/v1/gopuff/invoices?startDate=${startDate}&endDate=${endDate}&facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getOrders: async (facilityID) => {
        let url = `api/partnerships/v1/goPuff/orders?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getOrdersByLineHauls: async (facilityID, lineHaul, deliveryDate = null) => {
        let url = `api/partnerships/v1/goPuff/ordersByLineHaul?facilityID=${facilityID}&lineHaul=${lineHaul}&deliveryDate=${deliveryDate}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getOrdersWithinRange: async (facilityID, startDate, endDate) => {
        const url = `api/partnerships/v1/goPuff/orders?facilityID=${facilityID}&startDate=${startDate}&endDate=${endDate}`;
        const response = await mmFetchWithResponse(url);
        return response;
    },
    getPOCredits: async (startDate, endDate) => {
        let url = `api/partnerships/v1/goPuff/purchaseOrderCredit?startDate=${startDate}&endDate=${endDate}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPurchaseOrderCreditReasons: async () => {
        let url = `api/partnerships/v1/goPuff/purchaseOrderCredit/reasons`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPurchaseOrderDetails: async (poNum) => {
        let url = `api/partnerships/v1/goPuff/purchaseOrders/${poNum}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPricing: async () => {
        let url = `api/partnerships/v1/goPuff/pricing`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackoutRows: async (facilityID) => {
        let url = `api/partnerships/v1/gopuff/packout?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPrintableBatches: async (facilityID) => {
        let url = `api/partnerships/v1/gopuff/batches/printable?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPrintableBatchesDetails: async (key, value, deliveryDate) => {
        let url = `api/partnerships/v1/gopuff/batches/printable/details?${key}=${value}&deliveryDate=${deliveryDate}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPrintedBatches: async (facilityID) => {
        let url = `api/partnerships/v1/gopuff/batches/printed?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getShipmentDetails: async (shipmentID) => {
        let url = `api/partnerships/v1/goPuff/orders/details/${shipmentID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getTMSPalletUploads: async () => {
        const url = `api/partnerships/v1/goPuff/tms-uploads`;
        return await mmFetchWithResponse(url);
    },
    uploadTMSPalletData: async (data) => {
        let formData = new FormData();
        formData.append('tmsFile', data.file);

        const url = 'api/partnerships/v1/goPuff/tms-uploads/cache';
        return await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
    },
    confirmTMSPalletUpload: async (key) => {
        const url = 'api/partnerships/v1/goPuff/tms-uploads/confirm';
        return await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ key }),
        });
    },
    markTMSPalletUploadWithStatus: async (id, sendParams) => {
        const url = `api/partnerships/v1/goPuff/tms-uploads/mark-pallets/${id}`;
        return await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
    },
    updateTMSPalletData: async (id, item) => {
        const url = `api/partnerships/v1/goPuff/tms-uploads/update-pallet/${id}`;
        return await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
    },
    downloadTMSPalletDataCsv: async () => {
        const url = `api/partnerships/v1/goPuff/tms-uploads/download-csv`;
        try {
            const res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = `tms_pallet_data.csv`;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
            return res;
        } catch (e) {
            console.log('[downloadTMSPalletDataCsv] error', e.toString());
        }
    },
    issuePOCredit: async (items) => {
        let url = `api/partnerships/v1/goPuff/purchaseOrderCredit`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(items),
        });
        return response;
    },
    markDelivered: async (sendParams) => {
        let url = `api/partnerships/v1/goPuff/orders/delivered`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    printBatch: async (facilityID, key, value, deliveryDate) => {
        let url = `api/partnerships/v1/gopuff/batches/print?facilityID=${facilityID}&${key}=${value}&deliveryDate=${deliveryDate}`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'gopuffbatch.pdf';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.error('[goPuffApi - printBatch] error', e.toString());
        }
    },
    reprintBatch: async (batchID) => {
        let url = `api/partnerships/v1/gopuff/batches/reprint?batchID=${batchID}`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'gopuffbatch.pdf';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.error('[goPuffApi - reprintBatch] error', e.toString());
        }
    },
    sendInvoice: async (id) => {
        let url = `api/partnerships/v1/gopuff/invoices`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ id }),
        });
        return response;
    },
    sendPackoutFile: async (facilityID, packedDate) => {
        let url = `api/partnerships/v1/gopuff/sendPackoutFile?facilityID=${facilityID}&packedDate=${packedDate}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    updateMicroFulfillmentCenter: async (id, item) => {
        let url = `api/partnerships/v1/gopuff/microFC/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    uploadAllocation: async (data, facilityID) => {
        let formData = new FormData();
        formData.append('allocationFile', data.file);

        let url = `api/partnerships/v1/goPuff/allocation/bulk?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    uploadOrders: async (data) => {
        let formData = new FormData();
        formData.append('orderFile', data.file);

        let url = 'api/partnerships/v1/goPuff/orders/bulk';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    uploadPricing: async (data) => {
        let formData = new FormData();
        formData.append('pricingFile', data.file);

        let url = 'api/partnerships/v1/goPuff/pricing/upload';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    cancelOrder: async (purchaseOrderName) => {
        let url = `api/partnerships/v1/goPuff/orders/${purchaseOrderName}/cancel`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
};

export { goPuffApi };
