import React, { useState } from 'react';
import { DataTable } from '../../../shared';
import { Button, Modal } from '@material-ui/core';
import moment from 'moment';
import { downloadCSV } from '../../../shared/components/csvDownload';

export const ShipmentStatusDetailView = ({ rowData, closeModal }) => {
    let [selectedDetailList, setSelectedDetailList] = useState([]);
    let [showSelectedDetailList, setShowSelectedDetailList] = useState([]);

    const row = rowData.original;
    const orders = {
        ...row.orders,
        type: 'Total Orders',
    };
    const containers = {
        ...row.containers,
        type: 'Total Containers',
    };

    let columns = [
        { accessor: 'type', Header: '', sortable: false, useFilter: false },
        {
            accessor: 'PRE_PURCHASE',
            Header: 'Pre Purchase',
            sortable: false,
            useFilter: false,
            Cell: ({ cell: { value: initialValue }, column, row }) => {
                return (
                    <DetailNumberClickable
                        setShowSelectedDetailList={setShowSelectedDetailList}
                        setSelectedDetailList={setSelectedDetailList}
                        listOfIds={initialValue}
                        category={column.Header}
                        type={row.original.type}
                    />
                );
            },
        },
        {
            accessor: 'LABEL_PURCHASED',
            Header: 'Label Purchased',
            sortable: false,
            useFilter: false,
            Cell: ({ cell: { value: initialValue }, column, row }) => {
                return (
                    <DetailNumberClickable
                        setShowSelectedDetailList={setShowSelectedDetailList}
                        setSelectedDetailList={setSelectedDetailList}
                        listOfIds={initialValue}
                        category={column.Header}
                        type={row.original.type}
                    />
                );
            },
        },
        {
            accessor: 'LABEL_PRINTED',
            Header: 'Label Printed',
            sortable: false,
            useFilter: false,
            Cell: ({ cell: { value: initialValue }, column, row }) => {
                return (
                    <DetailNumberClickable
                        setShowSelectedDetailList={setShowSelectedDetailList}
                        setSelectedDetailList={setSelectedDetailList}
                        listOfIds={initialValue}
                        category={column.Header}
                        type={row.original.type}
                    />
                );
            },
        },
        {
            accessor: 'PACKED',
            Header: 'Packed',
            sortable: false,
            useFilter: false,
            Cell: ({ cell: { value: initialValue }, column, row }) => {
                return (
                    <DetailNumberClickable
                        setShowSelectedDetailList={setShowSelectedDetailList}
                        setSelectedDetailList={setSelectedDetailList}
                        listOfIds={initialValue}
                        category={column.Header}
                        type={row.original.type}
                    />
                );
            },
        },
        {
            accessor: 'ON_SHIPPING_PALLET',
            Header: 'On Shipping Pallet',
            sortable: false,
            useFilter: false,
            Cell: ({ cell: { value: initialValue }, column, row }) => {
                return (
                    <DetailNumberClickable
                        setShowSelectedDetailList={setShowSelectedDetailList}
                        setSelectedDetailList={setSelectedDetailList}
                        listOfIds={initialValue}
                        category={column.Header}
                        type={row.original.type}
                    />
                );
            },
        },
        {
            accessor: 'ON_TRUCK',
            Header: 'On Truck',
            sortable: false,
            useFilter: false,
            Cell: ({ cell: { value: initialValue }, column, row }) => {
                return (
                    <DetailNumberClickable
                        setShowSelectedDetailList={setShowSelectedDetailList}
                        setSelectedDetailList={setSelectedDetailList}
                        listOfIds={initialValue}
                        category={column.Header}
                        type={row.original.type}
                    />
                );
            },
        },
        {
            accessor: 'SHIPPED',
            Header: 'Shipped',
            sortable: false,
            useFilter: false,
            Cell: ({ cell: { value: initialValue }, column, row }) => {
                return (
                    <DetailNumberClickable
                        setShowSelectedDetailList={setShowSelectedDetailList}
                        setSelectedDetailList={setSelectedDetailList}
                        listOfIds={initialValue}
                        category={column.Header}
                        type={row.original.type}
                    />
                );
            },
        },
        {
            accessor: 'IN_TRANSIT',
            Header: 'In Transit',
            sortable: false,
            useFilter: false,
            Cell: ({ cell: { value: initialValue }, column, row }) => {
                return (
                    <DetailNumberClickable
                        setShowSelectedDetailList={setShowSelectedDetailList}
                        setSelectedDetailList={setSelectedDetailList}
                        listOfIds={initialValue}
                        category={column.Header}
                        type={row.original.type}
                    />
                );
            },
        },
        {
            accessor: 'DELIVERED',
            Header: 'Delivered',
            sortable: false,
            useFilter: false,
            Cell: ({ cell: { value: initialValue }, column, row }) => {
                return (
                    <DetailNumberClickable
                        setShowSelectedDetailList={setShowSelectedDetailList}
                        setSelectedDetailList={setSelectedDetailList}
                        listOfIds={initialValue}
                        category={column.Header}
                        type={row.original.type}
                    />
                );
            },
        },
        {
            accessor: 'ISSUE',
            Header: 'Issue',
            sortable: false,
            useFilter: false,
            Cell: ({ cell: { value: initialValue }, column, row }) => {
                return (
                    <DetailNumberClickable
                        setShowSelectedDetailList={setShowSelectedDetailList}
                        setSelectedDetailList={setSelectedDetailList}
                        listOfIds={initialValue}
                        category={column.Header}
                        type={row.original.type}
                    />
                );
            },
        },
        {
            accessor: 'CANCELLED',
            Header: 'Cancelled',
            sortable: false,
            useFilter: false,
            Cell: ({ cell: { value: initialValue }, column, row }) => {
                return (
                    <DetailNumberClickable
                        setShowSelectedDetailList={setShowSelectedDetailList}
                        setSelectedDetailList={setSelectedDetailList}
                        listOfIds={initialValue}
                        category={column.Header}
                        type={row.original.type}
                    />
                );
            },
        },
        {
            accessor: 'total',
            Header: 'Total',
            sortable: false,
            useFilter: false,
            Cell: ({ cell: { value: initialValue }, column, row }) => {
                return (
                    <DetailNumberClickable
                        setShowSelectedDetailList={setShowSelectedDetailList}
                        setSelectedDetailList={setSelectedDetailList}
                        listOfIds={initialValue}
                        category={column.Header}
                        type={row.original.type}
                    />
                );
            },
        },
    ];

    return (
        <Modal open={true} onClose={closeModal} style={{ overflowY: 'scroll' }}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '80%', margin: '10% auto', padding: '30px' }}>
                <DataTable
                    title={`Market: ${row.marketCode} \n Pack Date: ${row.expectedPackDate} (${row.expectedPackDay})`}
                    columns={columns}
                    data={[orders, containers]}
                />

                {showSelectedDetailList && <DetailedListView selectedCountList={selectedDetailList} />}
                <Button onClick={() => closeModal()}>Close</Button>
            </div>
        </Modal>
    );
};

const DetailedListView = ({ selectedCountList }) => {
    if (!selectedCountList?.ids) {
        return <p>Click on a category to view ids </p>;
    }
    const titleText = selectedCountList.type == 'Total Orders' ? 'Order Ids' : 'Container/Shipment Ids';
    const orderOrShipment = selectedCountList.type == 'Total Orders' ? 'orderNumber' : 'shipmentID';
    const columnCount = selectedCountList.ids.length > 20 ? 5 : 1;
    return (
        <div>
            <h2>
                List of {titleText} for {selectedCountList.category}
            </h2>
            <button onClick={() => downloadAsCSV(selectedCountList, titleText)}>Download CSV</button>
            <ul style={{ columnCount: columnCount }}>
                {selectedCountList.ids.map((item) => (
                    <li>
                        <a target="_blank" href={`/lettuce/system/order-deep-dive?${orderOrShipment}=${item}`}>
                            {item}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const downloadAsCSV = (selectedCountList, titleText) => {
    if (selectedCountList?.ids.length > 0) {
        const rows = [titleText, ...selectedCountList.ids];
        let csvContent = rows.join('\n');

        let today = moment();
        let filename = selectedCountList.category + `${today.format('_YYYY_MM_DD_HH_mm')}.csv`;

        downloadCSV(csvContent, filename);
    }
};

const DetailNumberClickable = ({ listOfIds, category, setSelectedDetailList, setShowSelectedDetailList, type }) => {
    return (
        <div
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <Button
                style={{ color: 'blue' }}
                onClick={async () => {
                    setSelectedDetailList({
                        ids: listOfIds,
                        category: category,
                        type: type,
                    });
                    setShowSelectedDetailList(true);
                }}
            >
                {listOfIds.length}
            </Button>
        </div>
    );
};
