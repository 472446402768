import React, { useContext, useEffect, useState } from 'react';
import { DataTable, TabPanel, Notifications, UploadCSVAndReviewComponent } from '../../../shared';
import { productApi as api } from '../../../api/product';
import moment from 'moment';

const ViewDriverTipsSettings = ({ setNotification }) => {
    const [rows, setRows] = useState([]);

    const columns = [
        { accessor: 'id', Header: 'ID', editable: false },
        { accessor: 'zipCode', Header: 'Zip Code', editable: false },
        {
            accessor: 'defaultVal',
            Header: 'Default',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'dollarAmount1',
            Header: 'Dollar Amount 1',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'dollarAmount2',
            Header: 'Dollar Amount 2',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'dollarAmount3',
            Header: 'Dollar Amount 3',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'percent1',
            Header: 'Percent 1',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'percent2',
            Header: 'Percent 2',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'percent3',
            Header: 'Percent 3',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'zipCodeEnabled',
            Header: 'Enabled',
            editable: true,
            type: 'checkbox',
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'poolType',
            Header: 'Pool Type',
            editable: true,
            editProps: { type: 'select', options: ['pool', 'individual'] },
        },
        { accessor: 'createdAt', Header: 'Created at', editable: false },
        { accessor: 'updatedAt', Header: 'Updated at', editable: false },
    ];

    const getItemsInTable = async () => {
        const response = await api.getDriverTipsSettings();
        if (response.status === false) {
            setNotification({ text: 'No driver tips settings found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.createdAt = moment(row.createdAt.date).format('MM/DD/YYYY HH:mm:ss');
                row.updatedAt = row.updatedAt ? moment(row.updatedAt.date).format('MM/DD/YYYY HH:mm:ss') : '';
                return row;
            });
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getItemsInTable();
    }, []);

    let saveEdit = async ({ id, field, value }) => {
        const item = { [field]: value };

        const response = await api.updateDriverTipsSettings(id, item);
        if (response.status === false) {
            let message = 'Error modifying driver tips setting';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        } else {
            setNotification({ text: 'Driver Tips Setting modified!', level: 'success' });
            await getItemsInTable();
        }
        return response.status;
    };

    return (
        <div>
            <DataTable data={rows} columns={columns} editable={true} saveEdit={saveEdit} />
        </div>
    );
};

export { ViewDriverTipsSettings };
