import React, { useState, useEffect, useContext, useMemo, forwardRef, useImperativeHandle } from 'react';
import {
    Collapse,
    Table as MUTable,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Checkbox,
    Toolbar,
    Typography,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SortIcon from '@material-ui/icons/Sort';
import { useTable, useSortBy, useFilters, useRowSelect } from 'react-table';
import { lighten, makeStyles } from '@material-ui/core/styles';
import {
    TextColumnFilter,
    SelectColumnFilter,
    MultiSelectColumnFilter,
    CheckboxFilter,
    DateFilter,
} from './components/filters';
import { DefaultCell } from './components/tableCells';
import './dataTable.css';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import moment from 'moment';

import { DataTableContext } from '../../../contexts/TableConfiguration';
import { downloadCSV } from '../csvDownload';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '10px!important',
    },
    highlight: {
        color: lighten(theme.palette.text.primary, 0.85),
        backgroundColor: theme.palette.primary.dark,
    },
    title: {
        flex: '1 1 100%',
    },
}));

const CustomizeColumnsModal = ({ columns, open, close, toggleShowColumn, shiftColumn }) => {
    return (
        <Dialog
            open={open}
            onClose={() => close()}
            maxWidth="sm"
            fullWidth={true}
            style={{ maxHeight: '750px', overlflowY: 'scroll' }}
        >
            <DialogTitle>Customize Columns</DialogTitle>
            <DialogContent>
                {columns.map((col) => {
                    return (
                        <div
                            key={col.Header}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                margin: '5px',
                                padding: '10px',
                                border: '1px solid lightgray',
                                borderRadius: '5px',
                            }}
                        >
                            <Checkbox
                                id={`${col.Header.replaceAll(' ', '')}_checkbox`}
                                color="primary"
                                checked={!col.hide}
                                onClick={() => {
                                    toggleShowColumn(col.Header, !col.hide);
                                }}
                            />
                            {col.Header}
                        </div>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button
                    id="OK"
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: '10px' }}
                    onClick={() => close()}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const DuplicateRowModal = ({ columns, open, close, row, CustomFormComponent, onSuccess }) => {
    // TBD: if no CustomFormComponent is provided, build a FormComponent based on table columns
    return (
        <Dialog
            open={open}
            onClose={() => {
                close();
            }}
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogContent>
                {CustomFormComponent &&
                    React.cloneElement(CustomFormComponent, {
                        initialValues: row || {},
                        onSuccess: () => {
                            onSuccess();
                            close();
                        },
                    })}
            </DialogContent>
        </Dialog>
    );
};

/**
 * Given a potentially nested path, and an object, return the value of the object at the path or null if it does not exist
 *
 * @example get("child.name", { child: {name: "Josh" }}) // "Josh"
 * @example get("child.name", { child: {} }) // null
 *
 * @param path string
 * @param obj object
 * @returns ?mixed
 */
function get(path, obj) {
    return path.split('.').reduce((child, path) => (child ? child[path] : null), obj);
}

const DataTable = forwardRef(
    (
        {
            columns,
            data,
            editable,
            selectMultiple = false,
            preSelectedRowIDs = [],
            saveEdit,
            onRowClicked,
            expandable = false,
            ExpansionComponent,
            title,
            bulkActions = [],
            toolbarActions = [],
            rowStyle,
            showPagination = true,
            initialRowsPerPage = 10,
            customizableColumns = false,
            tableName,
            duplicateRowSettings = {},
            csvExport = false,
            csvExportFileName = 'data',
            csvExportHeadersUseAccessor = false,
            csvExportAccessor = 'accessor',
            customExport = null,
            externalFilters = null,
            initialSortBy = [],
            tableContainerMaxHeight = null,
            stickyHeader = false,
            tableContainerClass = '',
        },
        ref
    ) => {
        const tableContext = useContext(DataTableContext);
        let tableConfig = null;

        if (tableName) {
            tableConfig = tableContext.getColumnConfiguration(tableName);
        }

        const tableContainerStyles = {};
        if (tableContainerMaxHeight) tableContainerStyles.maxHeight = tableContainerMaxHeight;

        const textColumn = {
            Filter: TextColumnFilter,
            Cell: DefaultCell,
        };

        function multiSelectFilterFn(rows, id, filterValue) {
            let colAccessor = id[0];
            return rows.filter((row) => {
                let colText = row.original[colAccessor];
                if (Array.isArray(filterValue) && filterValue.length) {
                    return filterValue.includes(colText);
                } else {
                    return true;
                }
            });
        }

        function selectFilterFn(rows, id, filterValue) {
            let colAccessor = id[0];
            let col = columns.find((col) => col.accessor === colAccessor);
            if (!col) {
                return [];
            }

            let colOptions = col.editProps?.options || [];

            return rows.filter((row) => {
                let value = row.original[colAccessor];

                let optArr;

                if (colOptions instanceof Function) {
                    optArr = colOptions(row.original);
                } else if (Array.isArray(colOptions)) {
                    optArr = colOptions.map((item) => {
                        if (typeof item === 'string') {
                            return { text: item, value: item };
                        } else {
                            return item;
                        }
                    });
                } else {
                    return false;
                }

                let opt = optArr.find((opt) => opt.value === value || opt.text === value);
                let colText;
                if (!opt || !opt.text) {
                    // option is not in dropdown, filter on the row's value
                    colText = row.original[colAccessor];
                } else {
                    // option is in dropdown, we want to filter by the option's text since
                    // value could be something other than what is displayed (an id)
                    colText = opt.text;
                }

                if (typeof colText !== 'string') {
                    colText = `${colText}`;
                }

                if (Array.isArray(filterValue)) {
                    if (filterValue.length) {
                        return filterValue.includes(colText);
                    } else {
                        return true;
                    }
                } else {
                    return colText.toLowerCase().includes(filterValue.toLowerCase());
                }
            });
        }

        function checkboxFilterFn(rows, id, filterValue) {
            if (filterValue === 'all' || filterValue === '') {
                return rows;
            }

            let colAccessor = id[0];
            return rows.filter((row) => {
                let value = get(colAccessor, row.original);
                return value === filterValue;
            });
        }

        function dateFilterFn(rows, id, filterValue) {
            let type = filterValue.filterType;

            if (type === 'all') {
                return rows;
            }

            let colAccessor = id[0];
            let date1 = moment(filterValue.date1);
            let date2 = '';
            if (!!filterValue.date2) {
                date2 = moment(filterValue.date2);
            }

            return rows.filter((row) => {
                let value = row.original[colAccessor];
                if (!value) {
                    return false;
                }

                let dateValue = moment(value);
                if (type === 'equals') {
                    return dateValue.format('YYYY/MM/DD') === date1.format('YYYY/MM/DD');
                } else if (type === 'greaterThan') {
                    return dateValue.isAfter(date1, 'day');
                } else if (type === 'lessThan') {
                    return dateValue.isBefore(date1, 'day');
                } else if (type === 'between') {
                    return dateValue.isBetween(date1, date2, 'day', '[]');
                }
            });
        }

        function arrayFilterFn(rows, id, filterValue) {
            let colAccessor = id[0];
            let col = columns.find((col) => col.accessor === colAccessor);
            if (!col) {
                return [];
            }

            if (!col.filterValues || !(col.filterValues instanceof Function)) {
                return [];
            }

            return rows.filter((row) => {
                let values = col.filterValues(row);
                return values.some((item) => item.toLowerCase().includes(filterValue.toLowerCase()));
            });
        }

        const filterTypes = useMemo(
            () => ({
                select: selectFilterFn,
                array: arrayFilterFn,
                checkbox: checkboxFilterFn,
                date: dateFilterFn,
                multiSelect: multiSelectFilterFn,
            }),
            [columns]
        );

        useEffect(() => {
            let cols = columns.map((item) => {
                let colItem = { ...item };
                colItem.type = item.type || 'string';
                colItem.editable = item.editable || false;
                colItem.width = item.width || '100px';
                colItem.sortable = item.hasOwnProperty('sortable') ? item.sortable : true;
                colItem.useFilter = item.hasOwnProperty('useFilter') ? item.useFilter : true;
                if (
                    !!externalFilters &&
                    externalFilters.hasOwnProperty(item.accessor) &&
                    !!externalFilters[item.accessor]
                ) {
                    colItem.filterDisabled = true;
                }
                if (editable) {
                    colItem.editProps = item.editProps ? item.editProps : { type: 'input', inputType: 'text' };
                }

                colItem.hide = item.hasOwnProperty('hide') ? item.hide : false;
                if (tableConfig) {
                    let c = tableConfig.find((col) => col.Header === item.Header);
                    if (c) {
                        colItem.hide = c.hide;
                    }
                }

                if (colItem.filterType) {
                    colItem.filter = colItem.filterType;

                    if (colItem.filterType === 'select') {
                        colItem.Filter = SelectColumnFilter;
                    } else if (colItem.filterType === 'multiSelect') {
                        colItem.Filter = MultiSelectColumnFilter;
                    }
                } else {
                    if (colItem.editProps?.type === 'select') {
                        // colItem.Filter = SelectColumnFilter;
                        colItem.filter = 'select';
                    }

                    if (colItem.filterValues) {
                        // these are the values that the filter will match
                        colItem.filter = 'array';
                    }

                    if (colItem.type === 'checkbox') {
                        colItem.Filter = CheckboxFilter;
                        colItem.filter = 'checkbox';
                    }

                    if (
                        colItem.editProps?.type === 'date' ||
                        colItem.editProps?.inputType === 'date' ||
                        colItem.type === 'date'
                    ) {
                        colItem.Filter = DateFilter;
                        colItem.filter = 'date';
                    }
                }

                return colItem;
            });

            if (duplicateRowSettings.enable) {
                cols.unshift({
                    Header: 'Duplicate',
                    editable: false,
                    Cell: ({ row }) => {
                        return (
                            <Button
                                id={`Duplicate_${row.id}`}
                                onClick={async () => {
                                    if (
                                        duplicateRowSettings.fetchAdditionalData &&
                                        duplicateRowSettings.fetchAdditionalData instanceof Function
                                    ) {
                                        let data = await duplicateRowSettings.fetchAdditionalData(row.original);
                                        row.original.additionalData = data;
                                    }
                                    setSelectedDuplicateRow(row.original);
                                    setDuplicateRowModalOpen(true);
                                }}
                            >
                                Duplicate
                            </Button>
                        );
                    },
                });
            }
            setInternalColumns(cols);
        }, [columns, externalFilters]);

        const [internalColumns, setInternalColumns] = useState([]);

        const sortTypes = useMemo(
            () => ({
                datetimeString: (rowA, rowB, id) => {
                    const rowAValue = new Date(rowA.values[id]);
                    const rowBValue = new Date(rowB.values[id]);
                    return rowAValue - rowBValue;
                },
            }),
            []
        );

        const sortBy = useMemo(() => initialSortBy, [initialSortBy]);

        const tableState = useTable(
            {
                columns: internalColumns.filter((item) => !item.hide),
                data,
                defaultColumn: textColumn,
                filterTypes,
                sortTypes,
                autoResetPage: false,
                autoResetSortBy: false,
                autoResetFilters: false,
                autoResetRowState: false,
                initialState: {
                    hiddenColumns: columns.filter((item) => item.hiddenColumn).map((item) => item.accessor),
                    sortBy,
                },
            },
            useFilters,
            useSortBy,
            useRowSelect
        );

        const { getTableProps, headerGroups, rows, prepareRow } = tableState;

        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
        const [expandedRow, setExpandedRow] = useState(null);
        const [selectedRows, setSelectedRows] = useState([]);
        const [customizeColumnsModalOpen, setCustomizeColumnsModalOpen] = useState(false);
        const [duplicateRowModalOpen, setDuplicateRowModalOpen] = useState(false);
        const [selectedDuplicateRow, setSelectedDuplicateRow] = useState(null);

        useEffect(() => {
            setPage(0);
            setExpandedRow(null);
            setSelectedRows([]);
        }, [tableState.state.filters]);

        // Listen for input changes outside
        useEffect(() => {
            if (!!externalFilters) {
                Object.keys(externalFilters).forEach((key) => {
                    tableState.setFilter(key, externalFilters[key]);
                });
            }
        }, [externalFilters]);

        const handleChangePage = (event, page) => {
            // setSelectedRows([]);
            setExpandedRow(null);
            setPage(page);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const handleRowClick = (row, index) => {
            if (onRowClicked && onRowClicked instanceof Function) {
                onRowClicked(row);
            }

            if (!expandable) {
                return;
            }

            if (expandedRow === index) {
                setExpandedRow(null);
            } else {
                setExpandedRow(index);
            }
        };

        const exportData = () => {
            const headerContent = internalColumns
                .filter((col) => col.hasOwnProperty(csvExportAccessor))
                .map((col) => (csvExportHeadersUseAccessor ? get(csvExportAccessor, col) : col.Header))
                .join(',');

            let csvContent = `${headerContent}\r\n`;

            for (let row of rows) {
                for (let col of internalColumns) {
                    if (!col.hasOwnProperty(csvExportAccessor)) {
                        continue;
                    }

                    const rowColAccessor = get(csvExportAccessor, col);
                    const cellContent = get(rowColAccessor, row.original);

                    if (cellContent === null) {
                        csvContent += ',';
                    } else {
                        csvContent += `"${cellContent}",`;
                    }
                }
                csvContent += '\r\n';
            }

            let today = moment();
            let filename = csvExportFileName + `${today.format('_YYYY_MM_DD_HH_mm')}.csv`;

            downloadCSV(csvContent, filename);
        };

        const toggleShowColumn = (colHeader, hide) => {
            if (!tableName) {
                return;
            }

            let cols = internalColumns.map((item) => {
                if (item.Header === colHeader) {
                    item.hide = hide;
                }
                return item;
            });

            setInternalColumns(cols);

            // save new configuration
            let tableConfiguration = cols.map((item) => ({
                Header: item.Header,
                hide: item.hasOwnProperty('hide') ? item.hide : false,
            }));
            tableContext.setColumnConfiguration(tableName, tableConfiguration);
        };

        const classes = useToolbarStyles();

        useEffect(() => {
            if (selectMultiple) {
                setSelectedRows(preSelectedRowIDs);
            } else {
                setSelectedRows([]);
            }
        }, [data]);

        // Expose methods or state through the ref
        useImperativeHandle(
            ref,
            () => ({
                getSelectedRows: () => selectedRows,
            }),
            [selectedRows]
        );

        return (
            <>
                {tableName && customizableColumns && (
                    <CustomizeColumnsModal
                        columns={internalColumns}
                        open={customizeColumnsModalOpen}
                        close={() => {
                            setCustomizeColumnsModalOpen(false);
                        }}
                        toggleShowColumn={toggleShowColumn}
                    />
                )}
                {
                    <DuplicateRowModal
                        columns={internalColumns}
                        open={duplicateRowModalOpen}
                        row={selectedDuplicateRow}
                        CustomFormComponent={duplicateRowSettings.CustomFormComponent}
                        onSuccess={duplicateRowSettings.onSuccess}
                        close={() => {
                            setSelectedDuplicateRow(null);
                            setDuplicateRowModalOpen(false);
                        }}
                    />
                }
                <Toolbar className={`${classes.root} ${selectedRows.length > 0 ? classes.highlight : ''}`}>
                    {selectedRows.length > 0 && (
                        <Grid justify="space-between" container spacing={10}>
                            <Grid item>
                                <div>
                                    {selectedRows.length === rowsPerPage && rows.length > selectedRows.length ? (
                                        <div>
                                            All {selectedRows.length} items on page selected.
                                            <span
                                                style={{ fontWeight: 'bold', cursor: 'pointer' }}
                                                onClick={() => {
                                                    let allIDs = rows.map((item) => item.original.id);
                                                    setSelectedRows(allIDs);
                                                }}
                                            >
                                                {' '}
                                                Select All {rows.length} in results?
                                            </span>
                                        </div>
                                    ) : (
                                        `${selectedRows.length} items selected`
                                    )}
                                </div>
                            </Grid>
                            <Grid item>
                                {bulkActions.map((item) => (
                                    <Button
                                        variant="outlined"
                                        style={{ margin: '0px 8px' }}
                                        id={item.name}
                                        key={item.name}
                                        disabled={item.hasOwnProperty('disabled') ? item.disabled : false}
                                        color="inherit"
                                        onClick={async () => {
                                            let res = await item.action(selectedRows);
                                            if (res === true) {
                                                setSelectedRows([]);
                                            }
                                        }}
                                    >
                                        {item.name}
                                    </Button>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                    {selectedRows.length === 0 && (
                        <Grid justify="space-between" container spacing={10}>
                            <Grid item>
                                <Typography variant="h5">{title}</Typography>
                            </Grid>
                            <Grid item>
                                <div style={{ textAlign: 'left' }}>
                                    {!!externalFilters &&
                                        Object.keys(externalFilters)
                                            .filter((key) => !!externalFilters[key])
                                            .map((key) => {
                                                let filtered = '';
                                                if (Array.isArray(externalFilters[key])) {
                                                    if (externalFilters[key].length === 1) {
                                                        filtered = externalFilters[key][0];
                                                    } else {
                                                        filtered = `${externalFilters[key][0]} - ${
                                                            externalFilters[key][externalFilters[key].length - 1]
                                                        }`;
                                                    }
                                                } else {
                                                    filtered = externalFilters[key];
                                                }
                                                return (
                                                    <span key={key}>
                                                        <span style={{ fontWeight: 'bold' }}>{key.toUpperCase()}:</span>{' '}
                                                        {filtered} &nbsp;&nbsp;
                                                    </span>
                                                );
                                            })}
                                </div>
                            </Grid>
                            <Grid item>
                                {toolbarActions.map((item, index) => (
                                    <ToolbarButton
                                        key={index}
                                        item={item}
                                        setSelectedRows={setSelectedRows}
                                        selectedRows={selectedRows}
                                    />
                                ))}
                                {customizableColumns && tableName && (
                                    <Button
                                        id="CustomizeColumns"
                                        color="secondary"
                                        style={{ marginLeft: '20px' }}
                                        variant="contained"
                                        onClick={() => {
                                            setCustomizeColumnsModalOpen(true);
                                        }}
                                    >
                                        <ViewColumnIcon />
                                    </Button>
                                )}
                                {csvExport && (
                                    <Button
                                        color="secondary"
                                        style={{ marginLeft: '20px' }}
                                        variant="contained"
                                        onClick={() => {
                                            if (customExport && customExport instanceof Function) {
                                                customExport(rows);
                                            } else {
                                                exportData();
                                            }
                                        }}
                                    >
                                        Export
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Toolbar>
                <TableContainer style={tableContainerStyles} className={tableContainerClass}>
                    <div style={{ width: '100%' }}>
                        <MUTable {...getTableProps()} stickyHeader={stickyHeader} className="dt-table" size="small">
                            <TableHead>
                                {headerGroups.map((headerGroup) => {
                                    const headerGroupProps = headerGroup.getHeaderGroupProps();
                                    return (
                                        <TableRow
                                            key={headerGroupProps.key}
                                            {...Object.fromEntries(
                                                Object.entries(headerGroupProps).filter(([key]) => key !== 'key')
                                            )}
                                        >
                                            {selectMultiple === true && (
                                                <TableCell>
                                                    <Checkbox
                                                        id="TableHeader_checkbox"
                                                        color="primary"
                                                        checked={selectedRows.length > 0}
                                                        onChange={(e) => {
                                                            if (e.target.checked === true) {
                                                                let allIDs = rows
                                                                    .slice(
                                                                        page * rowsPerPage,
                                                                        page * rowsPerPage + rowsPerPage
                                                                    )
                                                                    .filter(
                                                                        (row) =>
                                                                            (Object.keys(row.original).includes(
                                                                                'canUserSelectBulkAction'
                                                                            ) &&
                                                                                row.original.canUserSelectBulkAction) ||
                                                                            !('canUserSelectBulkAction' in row.original)
                                                                    )
                                                                    .map((item) => item.original.id);
                                                                setSelectedRows(allIDs);
                                                            } else if (e.target.checked === false) {
                                                                setSelectedRows([]);
                                                            }
                                                        }}
                                                    />
                                                </TableCell>
                                            )}
                                            {headerGroup.headers.map((column) => {
                                                const columnProps = column.getHeaderProps();
                                                return (
                                                    <TableCell
                                                        style={{ minWidth: column.width }}
                                                        key={columnProps.key}
                                                        {...Object.fromEntries(
                                                            Object.entries(columnProps).filter(([key]) => key !== 'key')
                                                        )}
                                                    >
                                                        <div {...column.getSortByToggleProps()}>
                                                            <span>{column.render('Header')}</span>
                                                            {column.sortable !== false && (
                                                                <div
                                                                    id={`${column.Header.replaceAll(
                                                                        ' ',
                                                                        ''
                                                                    )}_header_sorter`}
                                                                    className="dt-header-sort-container"
                                                                >
                                                                    <span className="dt-header-sort">
                                                                        {column.isSorted ? (
                                                                            column.isSortedDesc ? (
                                                                                <KeyboardArrowDownIcon className="dt-header-icon" />
                                                                            ) : (
                                                                                <KeyboardArrowUpIcon className="dt-header-icon" />
                                                                            )
                                                                        ) : (
                                                                            <SortIcon className="dt-header-icon" />
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            )}
                                                            {column.useFilter !== false && (
                                                                <div>
                                                                    <span>{column.render('Filter')}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    prepareRow(row);
                                    let styles = {};
                                    if (rowStyle && rowStyle instanceof Function) {
                                        styles = rowStyle(row.original);
                                    }
                                    return (
                                        <React.Fragment key={index}>
                                            <Row
                                                key={`row-${index}`}
                                                styles={styles}
                                                expandedRow={expandedRow}
                                                index={index}
                                                handleRowClick={handleRowClick}
                                                rowProps={{ ...row.getRowProps(), key: `row-${index}` }}
                                                selectMultiple={selectMultiple}
                                                selectedRows={selectedRows}
                                                row={row}
                                                setSelectedRows={setSelectedRows}
                                                editable={editable}
                                                saveEdit={saveEdit}
                                            />
                                            {expandable && (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={internalColumns.filter((item) => !item.hide).length}
                                                    >
                                                        <Collapse
                                                            unmountOnExit
                                                            in={expandedRow === index}
                                                            key={`expandable-${index}`}
                                                        >
                                                            {React.cloneElement(ExpansionComponent, {
                                                                parentRow: row,
                                                                setExpandedRow,
                                                            })}
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        </MUTable>
                    </div>
                </TableContainer>
                {showPagination && (
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
            </>
        );
    }
);

const ToolbarButton = ({ item, setSelectedRows, selectedRows }) => {
    let [disabled, setDisabled] = useState(item.disabled || false);
    if (disabled !== item.disabled) setDisabled(item.disabled);

    return (
        <Button
            id={`${item.name.replaceAll(' ', '')}`}
            color={item.color ?? 'secondary'}
            style={{ marginLeft: '20px' }}
            disabled={disabled}
            key={item.name}
            variant="contained"
            onClick={async () => {
                setDisabled(true);
                let res = await item.action(selectedRows);
                setDisabled(false);
                if (res === true) {
                    setSelectedRows([]);
                }
            }}
        >
            {item.name}
        </Button>
    );
};

const Row = ({
    styles,
    expandedRow,
    index,
    handleRowClick,
    rowProps,
    selectMultiple,
    selectedRows,
    row,
    setSelectedRows,
    editable,
    saveEdit,
}) => {
    let handleSaveEdit = async (obj) => {
        let res = true;
        if (saveEdit && saveEdit instanceof Function) {
            res = await saveEdit(obj);
        }
        return res;
    };

    return (
        <TableRow
            key={rowProps.key}
            style={styles}
            className={`${expandedRow === index ? 'selectedRow' : ''}`}
            onClick={() => {
                handleRowClick(row, index);
            }}
            {...Object.fromEntries(Object.entries(rowProps).filter(([key]) => key !== 'key'))}
        >
            {selectMultiple === true && (
                <TableCell className="dt-table-cell-class">
                    <Checkbox
                        disabled={
                            Object.keys(row.original).includes('canUserSelectBulkAction')
                                ? !row.original.canUserSelectBulkAction
                                : false
                        }
                        id={`RowCheckbox_${index}`}
                        checked={selectedRows.includes(row.original.id)}
                        onChange={(event) => {
                            let selected = JSON.parse(JSON.stringify(selectedRows));
                            let value = event.target.checked;
                            if (value === true) {
                                if (!selected.includes(row.original.id)) {
                                    selected.push(row.original.id);
                                }
                            } else if (value === false) {
                                let ind = selected.indexOf(row.original.id);
                                if (ind > -1) {
                                    selected.splice(ind, 1);
                                }
                            }
                            setSelectedRows(selected);
                        }}
                        color="primary"
                    />
                </TableCell>
            )}
            {row.cells.map((cell) => {
                let isCellEditable = function (cell, editable) {
                    if (editable !== true) {
                        return false;
                    }
                    if (cell.column.editable === false) {
                        return false;
                    }
                    if (cell.column.editable instanceof Function) {
                        return cell.column.editable(cell.row);
                    }
                    return true;
                };
                let cellEditable = isCellEditable(cell, editable);
                const cellProps = cell.getCellProps();
                return (
                    <TableCell
                        key={cellProps.key}
                        className="dt-table-cell-class"
                        {...Object.fromEntries(Object.entries(cellProps).filter(([key]) => key !== 'key'))}
                    >
                        {cell.render('Cell', { editable: cellEditable, saveEdit: handleSaveEdit })}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

export { DataTable };
