import React, { useRef, useState } from 'react';
import {
  makeStyles,
  IconButton,
  Paper,
  Typography,
  Button,
  Box,
  Popper,
  Fade,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import { CollapsibleFilterContainer } from './collapsibleFilterContainer';
import { FilterChipsContainer } from './filterChipsContainer';
import { DateRangeFilter } from './filters/dateRangeFilter';
import { MultiCheckFilter } from './filters/multiCheckFilter';
import { TextInputFilter } from './filters/textInputFilter';
import { SelectFilter } from './filters/selectFilter';
import { SearchableSelectFilter } from './filters/searchableSelectFilter';

//This is a map of filter types to their corresponding components
//It can eventually be moved to a registry type of file in the future if the filter types get too complex
const FILTER_COMPONENTS = {
  dateRange: DateRangeFilter,
  multiCheck: MultiCheckFilter,
  textInput: TextInputFilter,
  select: SelectFilter,
  searchableSelect: SearchableSelectFilter
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  filterButton: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    height: '56px',
    width: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chipsContainer: {
    flex: 1,
  },
  menuContainer: {
    width: '400px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '80vh',
    zIndex: theme.zIndex.modal,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    padding: theme.spacing(1),
    overflowY: 'auto',
    flexGrow: 1,
    minHeight: '200px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
  },
  applyButton: {
    minWidth: '100px',
  },
}));

const FilterMenuControl = ({
  filterConfigs = [],
  onApplyFilters,
  className,
  activeFilters = [],
  onDeleteFilter
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const buttonRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterChange = (filterName, filterData) => {
    setFilterValues(prev => ({
      ...prev,
      [filterName]: Array.isArray(filterData) ? filterData : [filterData]
    }));
  };

  const handleApply = () => {
    if (onApplyFilters) {
      const newActiveFilters = Object.values(filterValues)
        .filter(filters => filters !== null)
        .flat()
        .map(filter => ({
          filter: filter.type,
          value: filter.chipLabel,
          deletable: filter.deletable
        }));
      onApplyFilters(newActiveFilters);
      setIsOpen(false);
    }
  };

  const renderFilterComponent = (config) => {
    const FilterComponent = config.component || FILTER_COMPONENTS[config.type];
    if (!FilterComponent) {
      console.warn(`No component found for filter type: ${config.type}`);
      return null;
    }

    return (
      <FilterComponent
        type={config.type}
        name={config.name}
        deletable={config.deletable}
        onFilterChange={(data) => handleFilterChange(config.name, data)}
        {...(config.props || {})}
      />
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.filterRow}>
        <IconButton
          ref={buttonRef}
          color="primary"
          className={`${classes.filterButton} ${className || ''}`}
          onClick={handleToggle}
          aria-label="Filter options"
        >
          <FilterListIcon />
        </IconButton>

        <Box className={classes.chipsContainer}>
          <FilterChipsContainer
            activeFilters={activeFilters}
            filterConfigs={filterConfigs}
            onDelete={onDeleteFilter}
          />
        </Box>
      </Box>

      <Popper open={isOpen} anchorEl={buttonRef.current} placement="right-start" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Paper className={classes.menuContainer} elevation={3}>
              <Box className={classes.header}>
                <Typography variant="subtitle1">Filter Options</Typography>
                <IconButton size="small" onClick={handleToggle} aria-label="close filter menu">
                  <CloseIcon />
                </IconButton>
              </Box>

              <Box className={classes.content}>
                {filterConfigs.map((config) => (
                  <CollapsibleFilterContainer
                    key={config.name}
                    filterName={config.name}
                  >
                    {renderFilterComponent(config)}
                  </CollapsibleFilterContainer>
                ))}
              </Box>

              <Box className={classes.footer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApply}
                  className={classes.applyButton}
                >
                  Apply
                </Button>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export { FilterMenuControl };
