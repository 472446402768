import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import { FormComponent, Notifications } from '../../../../../shared';
import { warehouseApi } from '../../../../../api/warehouse';
import { Typography } from '@material-ui/core';
import { UserContext } from '../../../../../contexts/UserContext';
import { SearchableSelect } from '../../../../../shared/components/SearchableSelect';
import { WarehouseInventoryV2Api } from '../../../../../api/warehouseInventoryV2';

const warehouseApiV2 = new WarehouseInventoryV2Api();

const AdjustCasesAndLooseUnitsAction = ({
    locationId = null,
    palletId = null,
    lotId,
    caseNum,
    looseUnitNum,
    onSuccess,
    onCancel,
    locInvType,
    palletNum,
    unitsPerCase,
    initialReason,
}) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [currentFormData, setCurrentFormData] = useState({ caseNum: caseNum, looseUnitNum: looseUnitNum });
    const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [fromPallets, setFromPallets] = useState([]);
    const [fromPalletsLoading, setFromPalletsLoading] = useState(false);
    const [showFromPalletField, setShowFromPalletField] = useState(false);
    const [selectedFromPallet, setSelectedFromPallet] = useState();
    const { user } = useContext(UserContext);
    const [reasons, setReasons] = useState([]);
    const [reasonsLoading, setReasonsLoading] = useState(false);
    const [selectedReason, setSelectedReason] = useState(null);

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('AdjustCasesAndLooseUnitsAction intialized incorrectly onSuccess is not a function');
        }
        if (!(onCancel instanceof Function)) {
            console.error('AdjustCasesAndLooseUnitsAction initialized incorrectly onCancel is not a function');
        }
    }, [onSuccess, onCancel]);

    const fromPalletOptions = useMemo(
        () => fromPallets.map((pallet) => ({ label: pallet.palletId.toString(), value: pallet.palletId })),
        [fromPallets]
    );

    const reasonOptions = useMemo(() => reasons.map((reason) => ({ label: reason, value: reason })), [reasons]);

    useEffect(() => {
        setShowFromPalletField(
            (locInvType === 'Pallet' && palletId == null) || (locInvType === 'Both' && palletNum > 0)
        );
        queryAdjustReasons();
    }, []);

    useEffect(() => {
        if (showFromPalletField) {
            queryFromPallets();
        }
    }, [showFromPalletField]);

    const queryFromPallets = async () => {
        setFromPalletsLoading(true);

        const result = await warehouseApiV2.getPalletsByLocationIdandLotId(locationId, lotId, user.facilityID);
        if (result?.status) {
            setFromPallets(result?.data?.pallets ?? []);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }

        setFromPalletsLoading(false);
    };

    const queryAdjustReasons = async () => {
        setReasonsLoading(true);

        const result = await warehouseApiV2.getAdjustReasons(lotId);
        if (result?.status) {
            setReasons(result?.data?.reasons ?? []);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }

        setReasonsLoading(false);
    };

    const formFields = [
        {
            name: 'numCases',
            label: 'Cases',
            initialValue: currentFormData.caseNum,
            gridValue: 3,
            inputProps: { required: true, type: 'number', integer: true },
        },
        {
            name: 'numUnits',
            label: 'Loose Units',
            initialValue: currentFormData.looseUnitNum,
            gridValue: 3,
            inputProps: { required: true, type: 'number', integer: true },
        },
    ];

    const checkForLargeAdjustment = (formNumCases, formNumUnits) => {
        const caseAdjustment = Number(formNumCases) - caseNum;
        const caseThreshold = 5;
        const unitThreshold = 5 * unitsPerCase;
        const unitAdjustment = Number(formNumUnits) - looseUnitNum;

        if (Math.abs(caseAdjustment) > caseThreshold || Math.abs(unitAdjustment) > unitThreshold) {
            setConfirmationMessage(
                `This is a large adjustment: Adjusting inventory by ${Math.abs(caseAdjustment)} cases and ${Math.abs(
                    unitAdjustment
                )} units.`
            );
            setShowConfirmationMessage(true);
            return;
        }

        setConfirmationMessage('');
        setShowConfirmationMessage(false);
    };

    const handleSubmit = async (formData) => {
        const requestParams = {
            lotID: lotId,
            numCases: formData.numCases,
            numUnits: formData.numUnits,
        };
        if (locationId) {
            requestParams.originLocationID = locationId;
        }
        if (palletId) {
            requestParams.originPalletID = palletId;
        } else if (selectedFromPallet) {
            requestParams.originPalletID = selectedFromPallet;
        }
        if (selectedReason) {
            requestParams.reason = selectedReason;
        }
        const result = await warehouseApi.adjustCaseAndUnitNums(requestParams);
        if (result.status) {
            if (!result.data.adjustmentsMade) {
                setNotification({
                    text: 'Inventory already matches the requested quantities - no adjustment made',
                    level: 'warning',
                });
            } else {
                onSuccess();
            }
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const handleFormDataChange = (formData) => {
        setCurrentFormData({ caseNum: formData.numCases, looseUnitNum: formData.numUnits });
        checkForLargeAdjustment(formData.numCases, formData.numUnits);
    };

    const handleFromPalletChange = useCallback((newValue) => {
        setSelectedFromPallet(newValue?.value);
    }, []);

    const handleSelectedReasonChange = useCallback((newValue) => {
        setSelectedReason(newValue?.value);
    }, []);

    return (
        <>
            <Notifications options={notification} />
            <h3>Adjust cases and loose units</h3>
            {showFromPalletField && (
                <SearchableSelect
                    onChange={handleFromPalletChange}
                    options={fromPalletOptions}
                    loading={fromPalletsLoading}
                    label="Adjust From Pallet"
                />
            )}
            <SearchableSelect
                onChange={handleSelectedReasonChange}
                options={reasonOptions}
                loading={reasonsLoading}
                label="Reason"
                initialValue={initialReason}
            />
            <FormComponent
                formFields={formFields}
                onSubmit={handleSubmit}
                button={{ text: 'Submit', gridValue: 1 }}
                onFormDataChange={handleFormDataChange}
            />
            {showConfirmationMessage && (
                <>
                    <Typography style={{ color: 'red', marginBottom: 10 }}>{confirmationMessage}</Typography>
                </>
            )}
        </>
    );
};

export { AdjustCasesAndLooseUnitsAction };
