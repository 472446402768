import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications } from '../../../shared';
import { fbmApi as api, fbmApi } from '../../../api/fbm';
import { CircularProgress } from '@material-ui/core';

const FBMItemLotDetails = ({ parentRow }) => {
    const [notification, setNotification] = useState({ text: null, level: null });

    const cols = [
        { Header: 'Lot Number', accessor: 'lotID' },
        { Header: 'Partner Lot', accessor: 'partnerLotNumber' },
        { Header: 'Received On', accessor: 'receivedAt' },
        { Header: 'Expires On', accessor: 'expiresOn' },
        { Header: 'Num Units', accessor: 'numUnits' },
        { Header: 'Status', accessor: 'status' },
    ];

    return (
        <div style={{ backgroundColor: '#f4f5fb' }}>
            <Notifications options={notification} />
            <DataTable
                columns={cols}
                data={parentRow.original.lotDetails ?? []}
                initialSortBy={[
                    {
                        id: 'numUnits',
                        desc: true,
                    },
                ]}
            />
        </div>
    );
};
export { FBMItemLotDetails };
