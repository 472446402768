import React, { useState } from 'react';
import { Tabs, Tab, Paper, Grid } from '@material-ui/core';
import { ChargeDayChanges } from './chargeDayChanges';
import { NoLineHaulZips } from './noLineHaulZips';
import { TabPanel, DataTable } from '../../../shared';

const ZipMapResultDetails = ({ parentRow }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let [expandedResult, setExpandedResult] = useState('');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    let toggleExpandedResult = (value) => {
        if (value === expandedResult) {
            setExpandedResult(null);
        } else {
            setExpandedResult(value);
        }
    };

    if (!parentRow?.original?.results) {
        return null;
    }

    let results = parentRow.original.results;

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="Summary_Tab" label="Summary" value="0" />
                <Tab id="ChargeDayChanges_Tab" label="Charge Day Changes" value="1" />
                <Tab id="SubsonIncorrectLineHauls_Tab" label="Subs on Incorrect Line Hauls" value="2" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <Grid container>
                    {results?.missingRequiredHeaders?.backup?.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <h3 style={{ color: 'red' }}>Missing Required Headers - Backup File</h3>
                            </Grid>
                            <ul>
                                {results.missingRequiredHeaders.backup.map((item) => (
                                    <li key={item}>{item}</li>
                                ))}
                            </ul>
                        </>
                    )}
                    {results?.missingRequiredHeaders?.optimal?.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <h3 style={{ color: 'red' }}>Missing Required Headers - Optimal File</h3>
                            </Grid>
                            <ul>
                                {results.missingRequiredHeaders.optimal.map((item) => (
                                    <li key={item}>{item}</li>
                                ))}
                            </ul>
                        </>
                    )}
                    {results?.invalidZips?.backup?.length > 0 && (
                        <>
                            <Grid
                                onClick={() => {
                                    toggleExpandedResult('invalidZipBackup');
                                }}
                                item
                                xs={12}
                            >
                                <h3>Invalid Zips - Backup ({results.invalidZips.backup.length})</h3>
                            </Grid>
                            {expandedResult === 'invalidZipBackup' && (
                                <ul>
                                    {results.invalidZips.backup.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}
                    {results?.invalidZips?.optimal?.length > 0 && (
                        <>
                            <Grid
                                onClick={() => {
                                    toggleExpandedResult('invalidZipOptimal');
                                }}
                                item
                                xs={12}
                            >
                                <h3>Invalid Zips - Optimal ({results.invalidZips.optimal.length})</h3>
                            </Grid>
                            {expandedResult === 'invalidZipOptimal' && (
                                <ul>
                                    {results.invalidZips.optimal.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}
                    {results?.invalidFacilityNames?.optimal?.length > 0 && (
                        <>
                            <Grid
                                onClick={() => {
                                    toggleExpandedResult('invalidFacilityNamesOptimal');
                                }}
                                item
                                xs={12}
                            >
                                <h3 style={{ color: 'red' }}>
                                    Invalid Facility Names - Optimal ({results.invalidFacilityNames.optimal.length})
                                </h3>
                            </Grid>
                            {expandedResult === 'invalidFacilityNamesOptimal' && (
                                <ul>
                                    {results.invalidFacilityNames.optimal.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}
                    {results?.invalidFacilityNames?.backup?.length > 0 && (
                        <>
                            <Grid
                                onClick={() => {
                                    toggleExpandedResult('invalidFacilityNamesBackup');
                                }}
                                item
                                xs={12}
                            >
                                <h3 style={{ color: 'red' }}>
                                    Invalid Facility Names - Backup ({results.invalidFacilityNames.backup.length})
                                </h3>
                            </Grid>
                            {expandedResult === 'invalidFacilityNamesBackup' && (
                                <ul>
                                    {results.invalidFacilityNames.backup.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}
                    {results?.duplicateZips?.length > 0 && (
                        <>
                            <Grid
                                item
                                xs={12}
                                onClick={() => {
                                    toggleExpandedResult('duplicateZips');
                                }}
                            >
                                <h3 style={{ color: 'red' }}>
                                    Duplicate Zips in Optimal File ({results.duplicateZips.length})
                                </h3>
                            </Grid>
                            {expandedResult === 'duplicateZips' && (
                                <ul>
                                    {results.duplicateZips.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}
                    {results?.missingLineHauls?.backup?.length > 0 && (
                        <Grid item xs={12}>
                            <h3>Missing Line Hauls - Backup</h3>
                            <div>These line hauls do not exist in the database</div>
                            <ul>
                                {results.missingLineHauls.backup.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </Grid>
                    )}
                    {results?.missingLineHauls?.optimal?.length > 0 && (
                        <Grid item xs={12}>
                            <h3>Missing Line Hauls - Optimal</h3>
                            <div>These line hauls do not exist in the database</div>
                            <ul>
                                {results.missingLineHauls.optimal.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </Grid>
                    )}
                    {results?.invalidTransitDays?.backup?.length > 0 && (
                        <Grid item xs={12}>
                            <h3 style={{ color: 'red' }}>Invalid Transit Days - Backup</h3>
                            <div>Transit days must be 1 or 2</div>
                            <ul>
                                {results.invalidTransitDays.backup.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </Grid>
                    )}
                    {results?.invalidTransitDays?.optimal?.length > 0 && (
                        <Grid item xs={12}>
                            <h3 style={{ color: 'red' }}>Invalid Transit Days - Optimal</h3>
                            <div>Transit days must be 1 or 2</div>
                            <ul>
                                {results.invalidTransitDays.optimal.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </Grid>
                    )}
                    {results?.transitDayConflicts?.acrossFiles?.length > 0 && (
                        <Grid item xs={12}>
                            <h3 style={{ color: 'red' }}>Transit Day Conflicts - Across Files</h3>
                            <div>
                                These zips have conflicting transit days between the optimal and backup files for the
                                same carrier/hub
                            </div>
                            <DataTable
                                toolbarActions={[
                                    {
                                        name: 'Download',
                                        action: () => {
                                            let csvContent = 'data:text/csv;charset=utf-8,ZipCode,Carrier,Hub\r\n';
                                            for (let row of results.transitDayConflicts.acrossFiles) {
                                                csvContent += `${row.zipCode},${row.carrier},${row.hub}\r\n`;
                                            }
                                            var encodedUri = encodeURI(csvContent);
                                            var link = document.createElement('a');
                                            link.setAttribute('href', encodedUri);
                                            link.setAttribute('download', 'transit_day_conflicts_in_files.csv');
                                            document.body.appendChild(link);
                                            link.click();
                                        },
                                    },
                                ]}
                                columns={[
                                    { accessor: 'zipCode', Header: 'Zip Code' },
                                    { accessor: 'carrier', Header: 'Carrier' },
                                    { accessor: 'hub', Header: 'Hub' },
                                ]}
                                data={results.transitDayConflicts.acrossFiles}
                            />
                        </Grid>
                    )}
                    {results?.transitDayConflicts?.acrossDBTables?.length > 0 && (
                        <Grid item xs={12}>
                            <h3 style={{ color: 'red' }}>Transit Day Conflicts - Across DB Tables</h3>
                            <div>
                                These zips would create conflicting transit days between the optimal and backup zip map
                                database tables
                            </div>
                            <ul>
                                {results.transitDayConflicts.acrossDBTables.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </Grid>
                    )}
                    {results?.transitDayConflicts?.optimal?.length > 0 && (
                        <Grid item xs={12}>
                            <h3 style={{ color: 'red' }}>Transit Day Conflicts - Optimal File</h3>
                            <div>
                                These zips in the uploaded optimal file have conflicting transit days with what is in
                                the optimal zip map
                            </div>
                            <TransitDayConflictsTable data={results.transitDayConflicts.optimal} />
                        </Grid>
                    )}
                    {results?.transitDayConflicts?.backup?.length > 0 && (
                        <Grid item xs={12}>
                            <h3 style={{ color: 'red' }}>Transit Day Conflicts - Backup File</h3>
                            <div>
                                These zips in the uploaded backup file have conflicting transit days with what is in the
                                backup zip map
                            </div>
                            <TransitDayConflictsTable data={results.transitDayConflicts.backup} />
                        </Grid>
                    )}
                    {results?.zipsNoBackup?.length > 0 && (
                        <>
                            <Grid
                                item
                                xs={12}
                                onClick={() => {
                                    toggleExpandedResult('zipsNoBackup');
                                }}
                            >
                                <h3>Zip Codes with No Options (across all days) ({results.zipsNoBackup.length})</h3>
                            </Grid>
                            {expandedResult === 'zipsNoBackup' && (
                                <ul>
                                    {results.zipsNoBackup.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}
                    {results?.invalidZones?.length > 0 && (
                        <>
                            <Grid
                                item
                                xs={12}
                                onClick={() => {
                                    toggleExpandedResult('invalidZones');
                                }}
                            >
                                <h3 style={{ color: 'red' }}>Invalid Zones ({results.invalidZones.length})</h3>
                                <div>Zone in backup file must be between 2 and 4</div>
                            </Grid>
                            {expandedResult === 'invalidZones' && (
                                <ul>
                                    {results.invalidZones.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}
                </Grid>
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <ChargeDayChanges data={results.chargeDayChanges} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <NoLineHaulZips data={results.zipsNoLineHaul} deliveryDay={parentRow.original.deliveryDay} />
            </TabPanel>
        </Paper>
    );
};

const TransitDayConflictsTable = ({ data }) => {
    if (typeof data[0] === 'object' && data[0].hasOwnProperty('zipCode')) {
        return (
            <DataTable
                toolbarActions={[
                    {
                        name: 'Download',
                        action: () => {
                            let csvContent =
                                'data:text/csv;charset=utf-8,ZipCode,Carrier,Hub,TransitDays,OldTransitDays,Facility\r\n';
                            for (let row of data) {
                                csvContent += `${row.zipCode},${row.carrier},${row.hub},${row.newTransitDays},${row.oldTransitDays},${row.facility}\r\n`;
                            }
                            var encodedUri = encodeURI(csvContent);
                            var link = document.createElement('a');
                            link.setAttribute('href', encodedUri);
                            link.setAttribute('download', 'transit_day_conflicts.csv');
                            document.body.appendChild(link);
                            link.click();
                        },
                    },
                ]}
                columns={[
                    { accessor: 'zipCode', Header: 'Zip Code' },
                    { accessor: 'carrier', Header: 'Carrier' },
                    { accessor: 'hub', Header: 'Hub' },
                    { accessor: 'newTransitDays', Header: 'Transit Days in File' },
                    { accessor: 'oldTransitDays', Header: 'Transit Days in Database' },
                    { accessor: 'facility', Header: 'Facility' },
                ]}
                data={data}
            />
        );
    }

    return (
        <ul>
            {data.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );
};

export { ZipMapResultDetails };
